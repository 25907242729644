<template>
  <div class="video_black">
    <video
      id="myVideo"
      ref="myvideo"
      class="video-js vjs-default-skin vjs-big-play-centered vjs-16-9"
      controls
      preload="auto"
    >
      <source type="video/mp4" :src="videoOptions.src" />
    </video>
  </div>
</template>
<script>
// 直接引入videojs会报错
import _videojs from 'video.js'
import 'video.js/dist/video-js.css'
const videojs = window.videojs || _videojs
export default {
  name: 'showVideo',
  data () {
    return {
      videoOptions: {
        controls: true,
        src: ''
      },
      player: null
    }
  },
  created () {
    this.videoOptions.src = this.$route.query.fileUrl
  },
  mounted () {
    this.initVideo()
    document.addEventListener('keydown', this.disableSaveShortcut)
    const video = this.$el.querySelector('video')
    const player = videojs(video)
    player.on('contextmenu', event => {
      event.preventDefault()
    })
  },
  methods: {
    disableSaveShortcut (event) {
      if (event.metaKey && event.keyCode === 83) {
        event.preventDefault()
      }
    },
    initVideo () {
      // 页面dom元素渲染完毕，执行回调里面的方法
      const options = {
        controls: true,
        // 自动播放属性,muted:静音播放
        autoplay: true,
        // 建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
        preload: 'auto',
        controlBar: {
          playToggle: true
        }
      }
      this.player = videojs(this.$refs.myvideo, options, function () {
        const _this = this
        this.on('timeupdate', function () {
          this.playTime = this.currentTime()
          const playTime = this.currentTime()
          setTimeout(function () {
            localStorage.setItem('cacheTime', playTime)
          }, 500)

          const time = localStorage.getItem('cacheTime')
          if (this.currentTime() - Number(time) > 2) {
            this.current = Number(time)
            this.currentTime(this.current)
          } else {
            this.current = this.currentTime()
          }
        })

        this.on('ended', function () {
          console.log('ended', '播放结束')
        })

        this.on('progress', function () {
          console.log('progress', '开始播放')
        })

        this.on('error', function () {
          _this.$message.warning('异常，无法播放')
        })
      })
    }
  }
}

</script>
<style lang="less">
// .video_black {
//   width: 100%;
//   height: 100%;
// }
// /*修改播放器样式*/
// .video-js {
//   width: 100% !important;
//   height: 100% !important;
// }
// .video-js .vjs-big-play-button {
//   background-color: rgba(0, 0, 0, 0.45);
//   font-size: 3em;
//   border-radius: 50%;
//   height: 1.5em !important;
//   width: 1.5em !important;
//   line-height: 1.5em !important;
//   margin-top: -1em !important;
// }
// .vjs-paused .vjs-big-play-button,
// .vjs-paused .vjs-big-play-button {
//   display: block !important;
// }
// .myVideo-dimensions {
//   width: 100% !important;
//   height: 100% !important;
//   display: block !important;
// }
// .vjs-poster {
//   background-size: 100% 100% !important;
// }
</style>
