<template>
  <div class="page">
    <div class="banner">
      <img @click="toIndex" src="https://cdn.spbcn.org/ghac/ghac_homepage_logo1.png" class="logo" alt="">
      <div class="left">
        <div class="title title1">华夏中国梦</div>
        <div class="title title2">少年世界说</div>
        <div class="entitle">Teenagers Express Chinese Dream To The World</div>
      </div>
      <img class="right" src="https://cdn.spbcn.org/ghac/Ghac_center.png" alt="">
    </div>
    <div class="download">
      <div class="download-item" v-for="(item, i) of list" :key="i">
        <div class="icon"><img :src="item.icon" alt=""></div>
        <div class="tit">环球文遗小使者大会课件插件</div>
        <div class="tit">{{ item.type }}版</div>
        <div class="tip" @click="show(i)">{{i == 0 ? '使用说明' : ''}}</div>
        <div class="btn" :class="!item.open ? 'disabled' : ''" @click="download(item)">下载</div>
      </div>
    </div>
    <el-dialog
      title="使用说明"
      :visible.sync="visible"
      width="800px"
      :before-close="handleClose">
      <div class="tip-txt" style="margin-top: 0;">1、应用程序下载到本地后，双击进行安装。</div>
      <img class="tip-img" src="../../assets/images/step/1.jpg" alt="">
      <img class="tip-img" src="../../assets/images/step/2.jpg" alt="">
      <div class="tip-txt">2、安装完成后，在弹出的文件夹中，找到Office Presentation，右键选择“以管理员身份运行”以启动程序。</div>
      <img class="tip-img" src="../../assets/images/step/3.jpg" alt="">
      <img class="tip-img" src="../../assets/images/step/4.jpg" alt="">
      <div class="tip-txt">3、程序启动后，桌面右下角显示环球文遗小使者大会LOGO，此时可打开网站预览PPT课件。</div>
      <img class="tip-img" src="../../assets/images/step/5.jpg" alt="">
      <div class="tip-txt">4、右键点击程序图标，选择“跟随系统启动”。</div>
      <img class="tip-img" src="../../assets/images/step/6.jpg" alt="">
      <div class="tip-txt">5、如已安装此插件，仍提示下载，请检查程序是否已启动。</div>
      <div class="tip-txt">6、使用此插件需同时安装Office办公软件，请确认已安装Office2016应用，如同时安装了Office和WPS等其他办公软件，请卸载WPS等其他办公软件后使用。</div>

    </el-dialog>
  </div>
</template>
<script>
export default {
  data () {
    return {
      list: [
        {
          icon: 'https://cdn.spbcn.org/ghac/Ghac_windows.png',
          type: 'Windows',
          open: true
        },
        {
          icon: 'https://cdn.spbcn.org/ghac/Ghac_mac.png',
          type: 'Mac',
          open: false
        }
      ],
      visible: false
    }
  },
  methods: {
    download (item) {
      if (!item.open) return
      window.open('https://cdn.spbcn.org/ghac/OfficePresentation.sfx.v24.1.24.exe')
    },
    toIndex () {
      this.$router.go(-1)
    },
    show (i) {
      if (i === 0) {
        this.visible = true
      }
    }
  }
}
</script>
<style scoped lang="less">
.page{
  font-family: hzgb;
  height: 100vh;
  overflow-y: auto;
}
.banner{
  width: 100%;
  height: 360px;
  background-image: url(https://cdn.spbcn.org/ghac/login/HQWY_DL_BJ_S.png);
  background-repeat: repeat;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  .title{
    font-size: 50px;
    margin-bottom: 10px;
  }
  .title1{
    color: #000;
    margin-top: 50px;
  }
  .title2{
    color: #b51c25;
    text-align: right;
  }
  .left{
    flex: 1;
    margin-left: 150px;
  }
  .right{
    height: 340px;
    margin-right: 150px;
  }
}
.logo{
  height: 56px;
  position: absolute;
  left: 0;
  top: 20px;
}
.entitle{
  font-size: 15px;
  margin-top: 20px;
}

.download{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
.download-item{
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 40px;
  margin: 0 30px;
  .icon{
    width: 120px;
    height: 120px;
    border-radius: 140px;
    background-color: #eef0f5;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    img{
      width: 50px;
    }
  }
  .tit{
    color: #000;
    font-size: 16px;
    margin-top: 6px;
  }
  .btn{
    font-size: 18px;
    color: #fff;
    background-color: #b51c25;
    width: 120px;
    height: 36px;
    border-radius: 36px;
    line-height: 36px;
    text-align: center;
    cursor: pointer;
    margin-top: 20px;
    &.disabled{
      cursor: not-allowed;
      background-color: #c9c9c9;
    }
  }
}
.tip{
  font-size: 12px;
  font-family: '微软雅黑';
  color: #b51c25;
  margin-top: 12px;
  height: 16px;
  cursor: pointer;
}
.tip-txt{
  font-size: 14px;
  font-family: '微软雅黑';
  color: #555;
  margin-bottom: 10px;
  margin-top: 20px;
}
.tip-img{
  // max-width: 400px;
  // margin-bottom: 20px;
  display: block;
}
</style>
