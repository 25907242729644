<template>
  <div>
    <div class="dibannerclass" :class="isMobile ? 'mobile' : ''">
      <el-carousel :interval="5000" id="el-carousel" height="100%" :indicator-position=" isMobile ? 'none' : ''"  arrow="always">
        <el-carousel-item v-for="(item,index) in images" :key="index">
          <div v-if="item.type === 'video'">
            <video class="video" :src="item.url" controls></video>
          </div>
          <div v-else class="banner-img-class1" :style="'background-image: url('+ item.url +');'">
            <div class="title-backview1">
              <div>{{ item.title }}</div>
            </div>
          </div>
          <!-- <img :src="item.url" alt="" /> -->
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="red-backview" v-if="!isMobile">
      <div class="red-lineview"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      images: [
        { url: 'https://cdn.spbcn.org/ghac/banner/gczp/1.jpg', title: '工程作品' },
        { url: 'https://cdn.spbcn.org/ghac/banner/gczp/2.jpg', title: '工程作品' },
        { url: 'https://cdn.spbcn.org/ghac/banner/gczp/3.jpg', title: '工程作品' },
        { url: 'https://cdn.spbcn.org/ghac/banner/gczp/4.jpg', title: '工程作品' },
        { url: 'https://cdn.spbcn.org/ghac/banner/gczp/5.jpg', title: '工程作品' },
        { url: 'https://cdn.spbcn.org/ghac/banner/hqdwt/1.jpg', title: '环球大舞台' },
        { url: 'https://cdn.spbcn.org/ghac/banner/hqdwt/2.jpg', title: '环球大舞台' },
        { url: 'https://cdn.spbcn.org/ghac/banner/hqdwt/3.jpg', title: '环球大舞台' },
        { url: 'https://cdn.spbcn.org/ghac/banner/hqdwt/4.jpg', title: '环球大舞台' },
        { url: 'https://cdn.spbcn.org/ghac/banner/hqdwt/5.jpg', title: '环球大舞台' },
        { url: 'https://cdn.spbcn.org/ghac/banner/hqjnh/1.jpg', title: '环球嘉年华' },
        { url: 'https://cdn.spbcn.org/ghac/banner/hqjnh/2.jpg', title: '环球嘉年华' },
        { url: 'https://cdn.spbcn.org/ghac/banner/hqjnh/3.jpg', title: '环球嘉年华' },
        { url: 'https://cdn.spbcn.org/ghac/banner/hqjnh/4.jpg', title: '环球嘉年华' },
        { url: 'https://cdn.spbcn.org/ghac/banner/hqjnh/5.jpg', title: '环球嘉年华' },
        { url: 'https://cdn.spbcn.org/ghac/banner/hq.mp4', type: 'video' }
      ]
    }
  }
}
</script>
<style lang="less">
.dibannerclass {
  width: 1000px;
  height: 469px;
  z-index: 99;
}
.title-backview1 {
  width: 60%;
  font-size: 16px;
  font-family: FZLBJW;
  font-weight: 900;
  padding: 6px 20px;
  color: white;
  background: linear-gradient(to right, rgb(181, 28, 37), rgba(181, 28, 37, 0));
  // background-color: rgb(181, 28, 37);
}
.banner-img-class1 {
  width: 1000px;
  height: 470px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  // display: flex;
  // align-items: center;
  // justify-content: center;
}
.video{
  width: 1000px;
  height: 470px;
}

.el-carousel {
  width: 1000px;
  height: 470px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
::v-deep .el-carousel__button{
  width: 10px;
}
.red-backview {
  width: 1000px;
  height: 25px;
}

.red-lineview {
  width: 1000px;
  height: 10px;
  // margin-top: 15px;
  background-color: #e50211;
}
.mobile{
  width: 100% !important;
  height: 760px !important;
  .banner-img-class1{
    width: 100%;
    height: 100%;
  }
  .el-carousel{
    width: 100%;
    height: 100%;
  }
  .title-backview1{
    font-size: 60px !important;
  }
  .video{
    width: 100%;
    height: 100%;
  }
}
</style>
