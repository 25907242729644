<template>
  <div class="backview">
    <div class="course-class-topback">
      <img
        class="course-left-backimg"
        src="../../../../../src/assets/images/homepage_left.png"
        @click="backClicked"
      />
      <div class="course-title">我的课程</div>
    </div>
    <el-empty
      v-if="schoolList.length === 0"
      description="暂无学校信息"
    ></el-empty>
    <div class="course-type-back" v-else>
      <div
        class="course-type-content"
        v-for="(item, index) in schoolList"
        v-bind:key="index"
        @click="courseDetailClicked(item)"
      >
        <div class="school_backview">
          <div class="course-tye-name">{{ item.schoolName }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserSchoolList } from '@/http/api'
export default {
  data () {
    return {
      schoolList: []
    }
  },
  created () {
    this.getSchoolList()
  },
  methods: {
    backClicked () {
      this.$router.back()
    },
    getSchoolList () {
      const schoolData = JSON.parse(localStorage.getItem('saveSchoolInfo'))
      getUserSchoolList({ userId: schoolData.userId }).then((res) => {
        console.log('schoolList', res)
        this.schoolList = res.data
      }).catch((err) => {
        console.log('err1111', err)
      })
    },
    courseDetailClicked (item) {
      console.log('item', item)
      this.$router.push({
        path: '/aboutUserData/cebsbCommunityInfoView/cebsbCourseManage/cebsbInfo_user_courseMember',
        query: {
          schoolId: item.schoolId
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.backview {
  height: 100%;
  width: 100%;
}
.course-class-topback {
  width: 100%;
  height: 60px;
  background-color: rgb(178, 178, 178);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.course-left-backimg {
  width: 30px;
  height: 30px;
  margin-left: 10px;
}
.course-title {
  margin-left: 10px;
  font-size: 18px;
  font-weight: 900;
}
.course-type-back {
  width: 100%;
  margin: 30px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: row;
}
.course-type-content {
  // width: 160px;
  margin-bottom: 20px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.school_backview {
  width: 260px;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(https://cdn.spbcn.org/chineseWord/applets/images/course_school_back.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  // background-color: red;
}
.course-tye-name {
  font-size: 16px;
  font-family: hzgb;
  width: 96%;
  text-align: center;
  color: white;
}
</style>
