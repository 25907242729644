<template>
  <div class="top-backview">
    <div class="contnet-backview">
      <!-- 顶部菜单 -->
      <!-- <Topbar class="topbar"></Topbar> -->
      <!-- banner -->
      <div class="dibannerclass">
        <el-carousel
          :interval="3000"
          indicator-position="none"
          id="el-carousel"
          :height="dataHeight"
        >
          <el-carousel-item v-for="item in images" :key="item.index">
            <img :src="item.url" alt="" class="banner-img-class" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="bannerbottom">
        <div class="title-backview">英语语言素养素质拓展课</div>
      </div>
      <div class="red-backview">
        <div class="red-lineview"></div>
      </div>
      <!-- 中间内容部分 -->
      <div class="centerclass">
        <div class="center-titleview">
          <div class="titleStr">英语语言素养素质拓展课</div>
        </div>
        <div class="course-description-backview">
          <div class="description-titleStr">
            UIE推出的英语语言素质素养拓展课程并非是传统的学科类课程，会结合SPBCN汉英系列赛事，打造英语词源语系课程、英语魔力构词课程、英语自然拼读课程、经典阅读课程以及疯狂听说课程，系统地全方位地将英语知识与应用能力以及竞赛三个维度带着学生们深度了解语言的魅力。
          </div>
          <!-- <img
            src="https://cdn.spbcn.org/ghac/assets/feiyiprograms1_1_icon.png"
            alt=""
            class="description-titleStr-image"
          /> -->
          <div class="description-menuback">
            <div
              class="menuback-class"
              v-for="item in menuList"
              :key="item.index"
            >
              <img class="menu-imgclass" :src="item.url" alt="" />
              <div class="menu-titlestr">{{ item.titltStr }}</div>
            </div>
          </div>
        </div>
        <div class="course-description-lineview"></div>
        <div class="course-description-listview">
          <img
            src="https://cdn.spbcn.org/ghac/assets/feiyiprograms1_1_image1.png"
            alt=""
            class="description-listview-image"
          />
          <div class="course-description-rightStr">
            词根主要来源的希腊语和拉丁语，魔力构词课程主要讲解单词背后的词根渊源，学习最经典的构建单词方法，了解希腊语和拉丁语的来源不仅可以帮助记忆单词的含义，而且可以帮助学生联想及推测在其他地方遇到的新单词的含义。
            我们在课程设置中，每一个词根都配有相对应的单词，以及测验，学生每掌握一个单词可以让他们的词汇量扩充至几个单词。同时，结合对单词的分析和解释，借助联想和思维导图的方式，认识生单词，强化已知单词，达到学百词记千词的效果。
          </div>
        </div>
        <div class="course-description-listview">
          <img
            src="https://cdn.spbcn.org/ghac/assets/feiyiprograms1_1_image2.png"
            alt=""
            class="description-listview-image"
          />
          <div class="course-description-rightStr">
            词源语系涵盖英语中常见的14大词源语系，包括：Arabic、Eponyms、Slavic、Latin、Greek、French等，课程中通过分析不同国家的背景文化特征和罗列代表性事物再利用典型单词了解发音规律，结合图片和动画了解单词背后的含义，使单词记忆变得更加生动有趣，容易记忆。
          </div>
        </div>
        <div class="course-description-listview">
          <img
            src="https://cdn.spbcn.org/ghac/assets/feiyiprograms1_1_image3.png"
            alt=""
            class="description-listview-image"
          />
          <div class="course-description-rightStr">
            针对低年级学生，特别制定自然拼读课程体系，帮助学生梳理字母和字母组合的发音规律，通过单词接龙、闪卡等游戏形式，结合动画、儿歌等活泼可爱的形式，让学生在无形中掌握发音规律，
            5 夭学会 120 ＋条发音规律，寓教千乐，轻轻松松记单词。
          </div>
        </div>
        <div class="course-description-listview">
          <img
            src="https://cdn.spbcn.org/ghac/assets/feiyiprograms1_1_image4.png"
            alt=""
            class="description-listview-image"
          />
          <div class="course-description-rightStr">
            疯狂听说让孩子们体验不一样的听说课程，精选经典影视作品作为学习教材，节选经典片段，学习不同语境下英语的使用方法，背诵经典台词，模仿影视原声的发音技巧和语气声调，同时学习情绪控制和丧情管理。针对不同年级，精选迪士尼动画电影和哈利波特等家喻户晓的经典作品，让学生体验“声临其境”的紧张和刺激。为了激发学生的创造力和想象力，营地
            rd
            ］时举办了戏剧之夜，让学生自主创作英文剧本，设计角色台词，彩排表演等，培养学生的团队协作能力和表演表达能力。
          </div>
        </div>
        <div class="course-description-listview">
          <img
            src="https://cdn.spbcn.org/ghac/assets/feiyiprograms1_1_image5.png"
            alt=""
            class="description-listview-image"
          />
          <div class="course-description-rightStr">
            经典阅读课程，精选文原版名著，严格对标兰斯阅读指数、剑桥分级英语、托福、雅思等，
            CEFR
            欧洲语言标准体系、学生完成李果前预读，筛选适合不同英语水平的英文原版著名读物。营地要求在进入营地之前开始准备学习内容，作技巧，课上老师充当引导角色，提出问题，学习原汁原味的地道表达和写带领学生积极思考，提升学生口语表达能力，同时通过对作品的分析，能力。最后学生可以写下或说出阅读完作品的感受，语、阅读、写作、认知与视野等综合能力的提升。采用分组讨沦的形式锻炼学生的批判性思维和问题解决老师给出简练的点评，最终达到口
          </div>
        </div>
        <div class="course-description-listview">
          <img
            src="https://cdn.spbcn.org/ghac/assets/feiyiprograms1_1_image6.png"
            alt=""
            class="description-listview-image"
          />
          <div class="course-description-rightStr">
            与冠军面对面沟通，了解冠军背后付出的汗水和努力，感受冠军成长之路，树立学习榜样，冠军和冠军教练手把手教你单词记忆技巧，如何通过发音拼单词，如何通过词根拼单词，如何把单词分为更小的单位来记忆，以及在拼词比赛中如何灵活通过问问题的方式获得更多的线索和时间来拼出单词。
          </div>
        </div>
      </div>
      <!-- 底部内容 -->
    </div>
  </div>
</template>

<script>
// import Topbar from '@/components/Topbar.vue'
// import Footer from '@/components/Footer.vue'
export default {
  props: {
    dataHeight: {
      type: String,
      default: '100%'
    }
  },
  data () {
    return {
      images: [{ url: 'https://cdn.spbcn.org/ghac/assets/feiyiprograms1_1_banner.png' }],
      menuList: [
        { url: 'https://cdn.spbcn.org/ghac/assets/programs1_1gai_icon1.png', titltStr: '英语魔力构词课程' },
        { url: 'https://cdn.spbcn.org/ghac/assets/programs1_1gai_icon2.png', titltStr: '英语词源语系课程' },
        { url: 'https://cdn.spbcn.org/ghac/assets/programs1_1gai_icon3.png', titltStr: '英语自然拼读课程' },
        { url: 'https://cdn.spbcn.org/ghac/assets/programs1_1gai_icon4.png', titltStr: '英语疯狂听说课程' },
        { url: 'https://cdn.spbcn.org/ghac/assets/programs1_1gai_icon5.png', titltStr: '英语经典阅读课程' }]
    }
  },
  components: {
    // Topbar,
    // Footer
  }
}
</script>

<style lang="less" scoped>
// .topbar {
//   z-index: 1000;
//   width: 1000px;
//   padding: 0;
//   margin: 0;
// }
.contnet-backview {
  width: 1000px;
  background-color: black;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.top-backview {
  width: 100%;
  // padding-bottom: 10px;
  background-color: rgb(216, 216, 216);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.dibannerclass {
  width: 1000px;
  height: 469px;
  z-index: 99;
  background-color: rgb(0, 0, 0);
}
.bannerbottom {
  width: 1000px;
  height: 100px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 150px;
  z-index: 100;
}
.title-backview {
  height: 60px;
  font-size: 32px;
  font-family: FZLBJW;
  font-weight: 900;
  text-align: center;
  line-height: 60px;
  padding: 0px 20px;
  color: white;
  border: 2px solid rgb(255, 255, 255);
}
.red-backview {
  width: 1000px;
  height: 25px;
}
.red-lineview {
  width: 1000px;
  height: 6px;
  margin-top: 15px;
  background-color: #e50211;
}
.centerclass {
  width: 1000px;
  display: flex;
  flex-direction: column;
  background-image: url(https://cdn.spbcn.org/ghac/assets/feiyi_background_mix.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.center-titleview {
  width: 377px;
  height: 42px;
  margin-top: 30px;
  background: url(https://cdn.spbcn.org/ghac/assets/feiyi_homepage_title.png);
  background-size: 100%;
}

.titleStr {
  width: 280px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  font-size: 24px;
  font-family: FZLBJW;
  color: white;
  font-weight: bolder;
}

.course-description-backview {
  width: 800px;
  margin-left: 60px;
  margin-top: 30px;
  padding: 30px 40px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 30px;
}
.description-listview-image {
  width: 200px;
  height: 133px;
}
.description-titleStr {
  width: 800px;
  text-indent: 2em;
  font-family: FZLBJW;
  font-size: 20px;
  color: rgb(41, 41, 41);
  margin-bottom: 30px;
}
.course-description-lineview {
  width: 880px;
  height: 4px;
  background-color: #e50211;
  margin: 20px 60px;
}
.description-titleStr-image {
  width: 800px;
  height: 144px;
}
.course-description-listview {
  width: 860px;
  margin-left: 70px;
  height: 150px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.course-description-rightStr {
  width: 560px;
  height: 120px;
  text-indent: 2em;
  background-color: white;
  font-size: 15px;
  font-family: FZLBJW;
  color: rgb(41, 41, 41);
  border-radius: 20px;
  padding: 15px 40px 0px 40px;
}
.footerclass {
  width: 1000px;
}
.banner-img-class {
  width: 1000px;
  height: 470px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.description-menuback {
  width: 800px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.menuback-class {
  width: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.menu-imgclass {
  width: 100px;
  height: 100px;
}
.menu-titlestr {
  width: 160px;
  margin-top: 10px;
  text-align: center;
  font-size: 19px;
  color: black;
  font-family: FZLBJW;
}
.el-carousel {
  width: 1000px;
  height: 470px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
