import { render, staticRenderFns } from "./conferenceTheme.vue?vue&type=template&id=3d601c28&scoped=true&"
import script from "./conferenceTheme.vue?vue&type=script&lang=js&"
export * from "./conferenceTheme.vue?vue&type=script&lang=js&"
import style0 from "./conferenceTheme.vue?vue&type=style&index=0&id=3d601c28&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d601c28",
  null
  
)

export default component.exports