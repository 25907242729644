<template>
  <div>
    <div class="course-class-topback">
      <img
        class="course-left-backimg"
        src="../../../../../src/assets/images/homepage_left.png"
        @click="backClicked"
      />
      <el-image
        class="course-left-title"
        :src="courseData.typeLogo"
        fit="cover"
      ></el-image>
      <!-- <div class="course-title">{{ courseData.typeName }}</div> -->
    </div>
    <div class="course-type-back">
      <div
        class="course-type-content"
        v-for="(item, index) in courseData.childrenCourseType"
        v-bind:key="index"
        @click="courseClassClicked(item)"
      >
        <img class="course-type-image" :src="item.typeLogo" fit="contain" />
        <div class="course-tye-name">{{ item.typeName }}</div>
      </div>
    </div>
    <el-dialog
      :visible.sync="courseNodataDialogVisible"
      width="20%"
      :show-close="false"
      center
    >
      <div
        style="width: 100%; text-align: center; color: white; padding: 20px 0px"
      >
        课程正在开发中
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      courseNodataDialogVisible: false,
      courseData: JSON.parse(this.$route.query.classData)
    }
  },
  methods: {
    backClicked () {
      this.$router.back()
    },
    // 菜单点击事件
    courseClassClicked (item) {
      if (item.childrenCourseType && item.childrenCourseType.length !== 0) {
        this.$router.push({
          path: '/aboutUserData/cebsbCommunityInfoView/cebsbCourseManage/cebsbInfo_community_courseDetail',
          query: {
            classData: JSON.stringify(item)
          }
        })
      } else {
        // this.courseNodataDialogVisible = true
        this.$message.warning('课程正在开发中')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.course-class-topback {
  width: 100%;
  height: 60px;
  background-color: rgb(178, 178, 178);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.course-left-backimg {
  width: 30px;
  height: 30px;
  margin-left: 10px;
}
.course-left-title {
  // width: 40px;
  height: 50px;
  margin-left: 10px;
}
.course-title {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 900;
}
.course-type-back {
  width: 100%;
  margin: 30px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: row;
}
.course-type-content {
  width: 200px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.course-type-image {
  width: 100px;
  height: 100px;
}
.course-tye-name {
  width: 200px;
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
  color: black;
  font-weight: 900;
}
</style>
