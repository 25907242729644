<template>
  <div>
    <div class="individual-home-back">
      <div
        class="individual-menu-content"
        v-for="(item, index) in individualMenuList"
        v-bind:key="index"
        @click="individualMenuClicked(item)"
      >
        <img class="individual-menu-image" :src="item.typeLogo" />
        <el-button class="individual-menu-name">{{ item.typeName }}</el-button>
      </div>
    </div>
    <el-dialog
      title="新增社团信息"
      :visible.sync="createdAssociationdialog"
      @close="createdAssociationdialogClose"
      width="30%"
    >
      <el-form
        :model="associationModel"
        :rules="associationRules"
        label-width="130px"
        ref="associationFormRef"
        style="width: 100%"
      >
        <el-form-item label="社团名称" prop="associationName">
          <el-input
            v-model="associationModel.associationName"
            placeholder="请输入社团名"
            style="width: 100%"
            maxlength="100"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="createdAssociationdialog = false">取 消</el-button>
        <el-button type="primary" @click="submitChangeContactClicked()"
          >添 加</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { saveAssociation } from '@/http/api'
export default {
  data () {
    return {
      associationModel: {
      },
      createdAssociationdialog: false,
      associationRules: {
        associationName: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      individualMenuList: [
        {
          typeLogo: require('@/assets/images/individual-exam.png'),
          typeName: '申请线下考试',
          typeIndex: '1',
          turnUrl: '/userInfo_individualPapers'
        },
        {
          typeLogo: require('@/assets/images/individual-score.png'),
          typeName: '查询成绩',
          typeIndex: '2',
          turnUrl: '/userInfo_individualScore'
        },
        {
          typeLogo: require('@/assets/images/individual-team.png'),
          typeName: '创建社团',
          typeIndex: '3',
          turnUrl: '/userInfo_individualPapers'
        }
      ]
    }
  },
  methods: {
    individualMenuClicked (itemData) {
      if (itemData.typeIndex === '3') {
        this.createdAssociationdialog = true
      } else {
        this.$router.push({
          path: itemData.turnUrl
        })
      }
    },
    createdAssociationdialogClose () {
      this.$refs.associationFormRef.resetFields()
    },
    submitChangeContactClicked () {
      const schoolData = JSON.parse(localStorage.getItem('saveSchoolInfo'))
      console.log('schoolData', schoolData)
      this.associationModel.schoolId = schoolData.list[0].schoolId
      this.associationModel.schoolName = schoolData.schoolName
      saveAssociation(this.associationModel).then((res) => {
        this.createdAssociationdialog = false
        this.$message.success('创建成功')
      }).catch((err) => {
        console.log('err1111', err)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.individual-home-back {
  width: 100%;
  margin: 30px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: row;
}
.individual-menu-content {
  width: 180px;
  padding: 10px 0px;
  margin-bottom: 20px;
  border: 1px solid #edb037;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 30px;
}
.individual-menu-image {
  width: 50px;
  height: 50px;
  margin-top: 40px;
}
.individual-menu-name {
  width: 120px;
  height: 35px;
  font-size: 12px;
  margin: 40px 0px 20px;
  color: #646464;
  border: 1px solid #737373;
  background-color: rgba(0, 0, 0, 0);
}
</style>
