<template>
  <div class="top-backview">
    <div class="contnet-backview">
      <!-- 顶部菜单 -->
      <!-- <Topbar class="topbar"></Topbar> -->
      <!-- banner -->
      <div class="dibannerclass">
        <el-carousel
          :interval="3000"
          indicator-position="none"
          id="el-carousel"
          :height="dataHeight"
        >
          <el-carousel-item v-for="item in images" :key="item.index">
            <img :src="item.url" alt="" class="banner-img-class" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="bannerbottom">
        <div class="title-backview">人文素养探究课程</div>
      </div>
      <div class="red-backview">
        <div class="red-lineview"></div>
      </div>
      <!-- 中间内容部分 -->
      <div class="centerclass">
        <div class="center-titleview">
          <div class="titleStr">人文素养探究课程</div>
        </div>
        <div class="course-description-backview">
          国际理解教育人文素养探究课程主要分为<span style="color: red"
            >建筑工程类、美食文化类、服饰纺织类、美术工艺类、节令民俗类、医药文化类、曲艺戏剧类和体育杂技类</span
          >等八个主题。为了更好的使学生增强对“非遗”等传统文化全面的民族认同感以及增加对同类文化在中西方的不同呈现形式的了解，<span
            style="color: red"
            >俱乐部推出的这一课程通过双语的“非遗”教育与素质教育紧密结合，形成系统、科学、高校的教育传承模式。</span
          >以深圳学校所在的粤港澳地区为例，课程将会针对深圳市、广东省省当地的特色非物质文化遗产进行深度剖析，让学生们认识到家乡的中国传统文化以及增进海内外同类型文化的相互理解。
        </div>
        <div class="center-challengesbackTwo">
          <div class="challeng-listback">
            <div class="challeng-menuback">
              <img
                class="challengmenuImage"
                src="https://cdn.spbcn.org/ghac/assets/feiyi_homepage_icon1.png"
                alt=""
              />
              <img
                class="challengTitleImage"
                mode="heightFix"
                src="https://cdn.spbcn.org/ghac/assets/feiyi_homepage_icontext1.png"
                alt=""
              />
            </div>
            <div class="challeng-menuback">
              <img
                class="challengmenuImage"
                src="https://cdn.spbcn.org/ghac/assets/feiyi_homepage_icon5.png"
                alt=""
              />
              <img
                class="challengTitleImage"
                mode="heightFix"
                src="https://cdn.spbcn.org/ghac/assets/feiyi_homepage_icontext5.png"
                alt=""
              />
            </div>
          </div>
          <div class="challeng-listback">
            <div class="challeng-menuback">
              <img
                class="challengmenuImage"
                src="https://cdn.spbcn.org/ghac/assets/feiyi_homepage_icon2.png"
                alt=""
              />
              <img
                class="challengTitleImage"
                mode="heightFix"
                src="https://cdn.spbcn.org/ghac/assets/feiyi_homepage_icontext2.png"
                alt=""
              />
            </div>
            <div class="challeng-menuback">
              <img
                class="challengmenuImage"
                src="https://cdn.spbcn.org/ghac/assets/feiyi_homepage_icon6.png"
                alt=""
              />
              <img
                class="challengTitleImage"
                mode="heightFix"
                src="https://cdn.spbcn.org/ghac/assets/feiyi_homepage_icontext6.png"
                alt=""
              />
            </div>
          </div>
          <div class="challeng-listback">
            <div class="challeng-menuback">
              <img
                class="challengmenuImage"
                src="https://cdn.spbcn.org/ghac/assets/feiyi_homepage_icon3.png"
                alt=""
              />
              <img
                class="challengTitleImage"
                mode="heightFix"
                src="https://cdn.spbcn.org/ghac/assets/feiyi_homepage_icontext3.png"
                alt=""
              />
            </div>
            <div class="challeng-menuback">
              <img
                class="challengmenuImage"
                src="https://cdn.spbcn.org/ghac/assets/feiyi_homepage_icon7.png"
                alt=""
              />
              <img
                class="challengTitleImage"
                mode="heightFix"
                src="https://cdn.spbcn.org/ghac/assets/feiyi_homepage_icontext7.png"
                alt=""
              />
            </div>
          </div>
          <div class="challeng-listback">
            <div class="challeng-menuback">
              <img
                class="challengmenuImage"
                src="https://cdn.spbcn.org/ghac/assets/feiyi_homepage_icon4.png"
                alt=""
              />
              <img
                class="challengTitleImage"
                mode="heightFix"
                src="https://cdn.spbcn.org/ghac/assets/feiyi_homepage_icontext4.png"
                alt=""
              />
            </div>
            <div class="challeng-menuback">
              <img
                class="challengmenuImage"
                src="https://cdn.spbcn.org/ghac/assets/feiyi_homepage_icon8.png"
                alt=""
              />
              <img
                class="challengTitleImage"
                mode="heightFix"
                src="https://cdn.spbcn.org/ghac/assets/feiyi_homepage_icontext8.png"
                alt=""
              />
            </div>
          </div>
          <div class="challeng-listback">
            <div class="challeng-menuback">
              <img
                class="challengmenuImage"
                src="https://cdn.spbcn.org/ghac/assets/feiyi_homepage_icon9.png"
                alt=""
              />
              <img
                class="challengTitleImage"
                mode="heightFix"
                src="https://cdn.spbcn.org/ghac/assets/feiyi_homepage_icontext9.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <!-- <img
          src="https://cdn.spbcn.org/ghac/assets/feiyiprograms1_2_icon.png"
          alt=""
          class="feiyiprograms1_2_icon"
        /> -->
        <div class="course-description-lineview"></div>
        <img
          src="https://cdn.spbcn.org/ghac/assets/feiyiprograms1_2_image.png"
          alt=""
          class="feiyiprograms1_2_image"
        />
      </div>
      <!-- 底部内容 -->
    </div>
  </div>
</template>

<script>
// import Topbar from '@/components/Topbar.vue'
// import Footer from '@/components/Footer.vue'
export default {
  props: {
    dataHeight: {
      type: String,
      default: '100%'
    }
  },
  data () {
    return {
      images: [{ url: 'https://cdn.spbcn.org/ghac/assets/feiyiprograms1_2_banner.png' }]
    }
  },
  components: {
    // Topbar,
    // Footer
  }
}
</script>

<style lang="less" scoped>
// .topbar {
//   z-index: 1000;
//   width: 1000px;
//   padding: 0;
//   margin: 0;
// }
.contnet-backview {
  width: 1000px;
  background-color: black;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.top-backview {
  width: 100%;
  // padding-bottom: 10px;
  background-color: rgb(216, 216, 216);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.dibannerclass {
  width: 1000px;
  height: 469px;
  z-index: 99;
  background-color: rgb(0, 0, 0);
}
.bannerbottom {
  width: 1000px;
  height: 100px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 150px;
  z-index: 100;
}
.title-backview {
  height: 60px;
  font-size: 32px;
  font-family: FZLBJW;
  font-weight: 900;
  text-align: center;
  line-height: 60px;
  padding: 0px 20px;
  color: white;
  border: 2px solid rgb(255, 255, 255);
}
.red-backview {
  width: 1000px;
  height: 25px;
}
.red-lineview {
  width: 1000px;
  height: 6px;
  margin-top: 15px;
  background-color: #e50211;
}
.centerclass {
  width: 1000px;
  height: 1453px;
  display: flex;
  flex-direction: column;
  background-image: url(https://cdn.spbcn.org/ghac/assets/feiyi_background_mix.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.center-titleview {
  width: 377px;
  height: 42px;
  margin-top: 30px;
  background: url(https://cdn.spbcn.org/ghac/assets/feiyi_homepage_title.png);
  background-size: 100%;
}

.titleStr {
  width: 240px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  font-size: 22px;
  font-family: FZLBJW;
  color: white;
  font-weight: bolder;
}

.course-description-backview {
  width: 800px;
  margin-left: 60px;
  margin-top: 30px;
  font-family: FZLBJW;
  font-size: 24px;
  padding: 50px 40px;
  background-color: #ffffff;
  border-radius: 30px;
  text-indent: 2em;
}
.description-titleStr {
  width: 800px;
  text-indent: 2em;
  font-size: 18px;
  font-family: FZLBJW;
  color: rgb(41, 41, 41);
  margin-bottom: 30px;
}
.course-description-lineview {
  width: 880px;
  height: 4px;
  background-color: #e50211;
  margin: 30px 60px;
}
.feiyiprograms1_2_icon {
  margin-left: 100px;
  width: 800px;
  margin-top: 40px;
}
.feiyiprograms1_2_image {
  width: 800px;
  margin-top: 40px;
  margin-left: 100px;
}
.footerclass {
  width: 1000px;
}
.banner-img-class {
  width: 1000px;
  height: 470px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.center-challengesbackTwo {
  width: 800px;
  height: 400px;
  margin-left: 100px;
  // background-color: white;
  // border: 2px solid rgb(255, 0, 0);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.challeng-listback {
  width: 160px;
  height: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.challeng-menuback {
  width: 160px;
  height: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.challengmenuImage {
  width: 90px;
  height: 90px;
  background-size: 100%;
}
.challengTitleImage {
  margin-top: 10px;
  width: 110px;
  height: 20px;
}
.el-carousel {
  width: 1000px;
  height: 470px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
