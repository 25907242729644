<template>
  <div>
    <div class="login-container">
      <img src="https://cdn.spbcn.org/ghac/login/HQWY_DL_logo_03.png" class="logo1" @click="handleToIndex" alt="">
      <img src="https://cdn.spbcn.org/ghac/login/HQWY_DL_logo_01.png" class="logo2" alt="">
      <div class="left">
        <img class="logo" src="https://cdn.spbcn.org/ghac/login/HQWY_DL_logo_02.png" alt="">
        <img src="https://cdn.spbcn.org/ghac/login/HQWY_DL_YS_02.png" alt="">
      </div>
      <div style="width: 38%;"></div>
      <div class="center">
        <div class="steps">
          <div class="back" @click="goBack">< 返回</div>
          <div class="step-bar">
            <el-steps :active="active" finish-status="success" align-center>
              <el-step title="提交信息"></el-step>
              <el-step title="报名缴费"></el-step>
            </el-steps>
            <div class="" v-if="active === 0">
              <div style="margin-top: 20px;">
                <el-descriptions :column="3" border>
                  <el-descriptions-item label="文化区域">{{ gareaStr }}</el-descriptions-item>
                    <el-descriptions-item label="学校省市区">{{ schoolAreaStr }}</el-descriptions-item>
                    <el-descriptions-item label="学校名称">{{ schoolName }}</el-descriptions-item>
                    <el-descriptions-item label="组别">{{ schoolLevelStr }}</el-descriptions-item>
                    <el-descriptions-item label="指导教师姓名">{{ formData.teacherName1 }}</el-descriptions-item>
                    <el-descriptions-item label="指导教师电话">{{ formData.teacherPhone1 }}</el-descriptions-item>
                </el-descriptions>
              </div>
              <div class="teamNum">
                <div>拟参赛队伍数量</div>
                <div class="num">{{ formData.teamNum }}</div>
              </div>
              <el-form
                :model="formData"
                :rules="formRules"
                ref="schoolRegistRuleFormRef"
                label-width="90px"
                style=" margin-top: 20px"
              >
                <!-- <el-form-item label="学校省市区" prop="schoolArea">
                  {{ schoolAreaStr }}
                  <el-cascader
                    placeholder="请选择省市区"
                    style="width: 100%"
                    v-model="formData.schoolArea"
                    :options="areasData"
                    :props="{
                      expandTrigger: 'hover',
                      label: 'name',
                      value: 'id',
                      children: 'areas'
                    }"
                  ></el-cascader>
                </el-form-item>
                <el-form-item label="学校名称" prop="schoolId">
                  {{ schoolName }}
                  <el-select
                    placeholder="请选择"
                    style="width: 100%"
                    v-model="formData.schoolId"
                  >
                    <el-option
                      v-for="item in provincesSchoolList"
                      :key="item.id"
                      :label="item.schoolName"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="指导教师姓名" prop="teacherName1">
                  {{ formData.teacherName1 }}
                  <el-input
                    v-model="formData.teacherName1"
                    placeholder="请输入"
                    maxlength="20"
                    clearable
                  ></el-input>
                </el-form-item>
                <el-form-item label="指导教师电话" prop="teacherPhone1">
                  {{ formData.teacherPhone1 }}
                  <el-input
                    v-model="formData.teacherPhone1"
                    placeholder="请输入"
                    maxlength="20"
                    clearable
                  ></el-input>
                </el-form-item>
                <el-form-item label="俱乐部组别" prop="groupLevel">
                  {{ schoolLevelStr }}
                  <el-select
                    placeholder="请选择"
                    style="width: 100%"
                    v-model="formData.groupLevel"
                  >
                    <el-option
                      v-for="item in schoolLevelArr"
                      :key="item.dictCode"
                      :label="item.dictLabel"
                      :value="item.dictValue"
                    ></el-option>
                  </el-select>
                </el-form-item> -->
                <el-row>
                  <!-- <el-col :span="12">
                    <el-form-item label="俱乐部名称" prop="clubName">
                      <el-input
                        v-model="formData.clubName"
                        placeholder="请输入"
                        maxlength="20"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </el-col> -->
                  <!-- <el-col :span="12">
                    <el-form-item label="拟参赛队伍数量" prop="teamNum">
                      <el-input-number v-model="formData.teamNum" :min="1"></el-input-number>
                    </el-form-item>
                  </el-col> -->
                </el-row>
                <el-row v-for="(teamItem, index) in formData.list" :key="index">
                  <el-col :span="7">
                    <el-form-item label="展演名称">
                      <el-input v-model="teamItem.teamName"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="7">
                    <el-form-item label="展演类目">
                      <el-select
                        placeholder="请选择"
                        style="width: 100%"
                        v-model="teamItem.teamCategory"
                      >
                        <el-option
                          v-for="(item, i) in categoryArr"
                          :key="i"
                          :label="item"
                          :value="item"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="7">
                    <el-form-item label="展演形式">
                      <el-select
                        placeholder="请选择"
                        style="width: 100%"
                        v-model="teamItem.teamForm"
                      >
                        <el-option
                          v-for="(item, i) in formArr"
                          :key="i"
                          :label="item"
                          :value="item"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="3" class="operate">
                    <i class="el-icon-circle-plus" @click="addItem"></i>
                    <i class="el-icon-remove" v-if="formData.list.length > 1" @click="removeItem(index)"></i>
                  </el-col>
                </el-row>
              </el-form>
              <el-button type="primary" class="submit" @click="next">下一步</el-button>
            </div>
            <div class="" v-if="active === 1">
              <div v-if="!havePayed">
                <img v-if="qrImgSrc" :src="qrImgSrc" class="qrCode" alt="">
                <div v-else class="qrCodeLoading">
                  <i class="el-icon-loading"></i>
                  <div>正在生成订单</div>
                </div>
                <div class="scan">微信扫码支付报名费用</div>
                <div class="btns">
                  <el-button class="btn1" @click="handleToCancelPay">取消支付</el-button>
                  <el-button class="btn2" @click="getPayResult">我已完成支付</el-button>
                </div>
              </div>
              <div v-else class="mt-40">
                <el-descriptions :column="2" border>
                    <el-descriptions-item label="学校省市区">{{ schoolAreaStr }}</el-descriptions-item>
                    <el-descriptions-item label="学校名称">{{ schoolName }}</el-descriptions-item>
                    <el-descriptions-item label="指导教师姓名">{{ formData.teacherName1 }}</el-descriptions-item>
                    <el-descriptions-item label="指导教师电话">{{ formData.teacherPhone1 }}</el-descriptions-item>
                    <el-descriptions-item label="组别">{{ schoolLevelStr }}</el-descriptions-item>
                    <!-- <el-descriptions-item label="俱乐部名称">{{ formData.clubName }}</el-descriptions-item> -->
                    <!-- <el-descriptions-item label="展演类目">{{ formData.category }}</el-descriptions-item> -->
                    <el-descriptions-item label="拟参赛队伍数量">{{ formData.teamNum }}</el-descriptions-item>
                    <el-descriptions-item label="缴费状态">
                      <el-tag type="success">已缴费</el-tag>
                    </el-descriptions-item>
                </el-descriptions>
                <el-table :data="formData.list" style="margin: 20px auto;" border>
                  <el-table-column label="队伍" type="index"></el-table-column>
                  <el-table-column prop="teamName" label="展演名称"></el-table-column>
                  <el-table-column prop="teamCategory" label="展演类目"></el-table-column>
                  <el-table-column prop="teamForm" label="展演形式"></el-table-column>
                </el-table>
                <div class="btns">
                  <el-button class="btn2" @click="handleToCancelPay">返回</el-button>
                </div>
              </div>
            </div>
            <div class="tips">
              <div class="tip-item">一、注册报名时间：2024年2月1日-6月30日</div>
              <div class="tip-item">二、注册服务费：980元/队</div>
              <div class="tip-item2">三、注册服务明细：</div>
              <div class="tip-item2">队伍注册费：80元</div>
              <div class="tip-item2">国家组委会一对一线下或线上指导一次（价值800元）</div>
              <div class="tip-item">指导手册一本（价值100元）</div>
              <div class="tip-item">四、展演时间：2024年11月1日-3日（三天两晚）</div>
              <div class="tip-item">五、展演地点：北京•国家会展中心</div>
              <div class="tip-item2">六、展演价格：</div>
              <div class="tip-item2">A 套餐价格：4980元/人（包食宿）</div>
              <div class="tip-item">B 套餐价格：2980 元/人（不包食宿）附赠住宿官方协议指导价</div>
              <div class="tip-item2">七、展演项目提报时间：即日起至2024年6月30日截止</div>
              <div class="tip-item">注：提报展演项目需要接受组委会审核，每个区域遴选队伍控制在40支以内，每校不超过2支队。</div>
              <div class="tip-item2">八、退费说明：</div>
              <div class="tip-item2">如因校方原因无故缺席展演，注册费一律不退。</div>
              <div class="tip-item2">如因组委会原因未能展演成功，按实际已消耗费用扣除相应费用后退费。</div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <img src="https://cdn.spbcn.org/ghac/login/HQWY_DL_YS_01.png" alt="">
      </div>
    </div>
    <div class="footer">
      <span>GLOBAL INTANGIBLE CULTURAL HERITAGE</span>
      <span>LITTLE AMBASSADORS' CONFERENCE</span>
    </div>
  </div>
</template>

<script>
import areaJson from '../../assets/area.json'
import { getSchoolInfo, getSysUserInfo } from '@/http/api'

export default {
  data () {
    return {
      activityType: 3,
      active: 0,
      areasData: areaJson.areas,
      provincesSchoolList: [],
      dict_schoolGroup: this.$curUtils.dict_schoolGroup(),
      categoryArr: ['美术工艺类', '建筑工程类', '美食文化类', '服饰纺织类'],
      formArr: ['探究报告', '广告展板', 'PPT演示', '舞台剧展示', '工程作品'],
      formData: {
        // clubName: '',
        // category: '',
        groupLevel: '',
        garea: '', // 文化区域
        schoolAreaCodes: '',
        schoolAreaNames: '',
        schoolId: '',
        schoolName: '',
        teacherName1: '',
        // "teacherName2": '',
        teacherPhone1: '',
        // "teacherPhone2": '',
        teamNum: 1, // 参赛队伍数
        list: [ // 展演信息
          {
            teamName: '',
            teamCategory: '',
            teamForm: '' // 展演形式
          }
        ]
      },
      formRules: {
        // category: [{ required: true, message: '请输入', trigger: 'change' }],
        // teamNum: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      gareaStr: '',
      schoolAreaStr: '',
      schoolName: '',
      schoolLevelArr: [],
      havePayed: false,

      qrImgSrc: '',
      orderNo: ''
    }
  },
  created () {
    this.active = Number(this.$route.query.step)
    this.getSchoolInfo()
    this.getUserInfo()
    if (this.active === 1) {
      this.getInfo()
    }
  },
  mounted () {
    // 绑定监听事件
    window.addEventListener('keydown', this.keyDown)
  },
  destroyed () {
    // 销毁事件
    window.removeEventListener('keydown', this.keyDown, false)
  },
  methods: {
    goBack () {
      this.$router.push('/club/home')
    },
    // 获取学校信息
    getSchoolInfo () {
      const schoolData = JSON.parse(localStorage.getItem('saveSchoolInfo'))
      console.log('schoolData', schoolData)
      const schoolId = schoolData.list[0].schoolId
      getSchoolInfo({ schoolId }).then((res) => {
        this.schoolAreaStr = res.data.province + res.data.city + res.data.area
        this.schoolName = res.data.schoolName
        // this.schoolLevelArr = res.data.schoolLevel.split(',').map(item => {
        //   return this.dict_schoolGroup.find(sch => sch.dictValue === item)
        // })
        this.formData.groupLevel = res.data.schoolLevel
        this.schoolLevelStr = this.dict_schoolGroup.find(item => item.dictValue === res.data.schoolLevel).dictLabel
        this.formData.schoolAreaCodes = `${res.data.provinceCode}/${res.data.cityCode}/${res.data.areaCode}`
        this.formData.schoolAreaNames = `${res.data.province}/${res.data.city}/${res.data.area}`
        this.formData.schoolId = schoolId
        this.formData.schoolName = res.data.schoolName
        this.gareaStr = res.data.gareaStr
        this.formData.garea = res.data.garea
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    getUserInfo () {
      getSysUserInfo().then((res) => {
        this.formData.teacherName1 = res.data.sysUser.userName
        this.formData.teacherPhone1 = res.data.sysUser.phone
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    handleToIndex () {
      this.$router.push('/homeView')
    },
    // 点击回车键登录
    keyDown (e) {
      // 回车则执行登录方法 enter键的ASCII是13
      if (e.keyCode === 13) {
        // this.mainLogin() // 定义的登录方法
      }
    },
    next () {
      this.$refs.schoolRegistRuleFormRef.validate(async valid => {
        if (!valid) return
        const empty = this.formData.list.find(item => !item.teamName || !item.teamCategory || !item.teamForm)
        if (empty) {
          return this.$message.warning('请将参赛队伍信息补充完整')
        }
        this.$http.post('itsoRegister/register', this.formData).then(res => {
          if (res.code === 200) {
            this.$message.success('已提交')
            this.active = 1
            this.havePayed = false
            this.getProductInfo()
          }
        })
      })
    },

    getProductInfo () {
      this.$http.get('product/getTbProductSingle', { params: { activityType: this.activityType, subCategory: 1, subTypeCategory: 1, category: 2 } }).then(res => {
        if (res.code === 200) {
          console.log(res.data)
          this.createOrder(res.data)
        }
      })
    },
    createOrder (data) {
      const paramsData = {
        activityType: data.activityType,
        // addressId: "",
        num: this.formData.teamNum,
        orderSource: 2,
        payType: '微信',
        paymentAmount: this.formData.teamNum * Number(data.tbProductDetails[0].price),
        productId: data.id,
        tbProductDetailId: data.tbProductDetails[0].id
        // teamId: ""
      }
      this.$http.post('order/creatTbProductOrder', paramsData).then(res => {
        if (res.data) {
          this.qrImgSrc = res.data.qcPicCode
          this.orderNo = res.data.orderNo
        }
      })
    },
    getPayResult () {
      this.$http.post('order/getTbProductOrderIsFinish', { orderNo: this.orderNo }).then(res => {
        if (res.code === 200) {
          if (res.success) {
            this.getInfo()
          } else {
            this.$confirm('系统检测到订单未支付成功', '提示', {
              confirmButtonText: '重新支付',
              cancelButtonText: '取消支付',
              type: 'warning'
            }).then(() => {

            }).catch(() => {
              this.handleToCancelPay()
            })
          }
        }
      })
    },
    handleToCancelPay () {
      this.$router.push('/club/home')
    },
    getInfo() {
      this.$http.get('itsoRegister/info').then(res => {
        if (res.code === 200) {
          if (res.data.status === 0) { // 未缴费
            this.getProductInfo()
          } else { // 已缴费
            this.havePayed = true
          }

          this.schoolAreaStr = res.data.schoolAreaNames
          this.schoolName = res.data.schoolName
          this.formData.teacherName1 = res.data.teacherName1
          this.formData.teacherPhone1 = res.data.teacherPhone1
          this.formData.clubName = res.data.clubName
          this.formData.category = res.data.category
          this.formData.teamNum = res.data.teamNum
          this.schoolLevelStr = this.dict_schoolGroup.find(item => item.dictValue === res.data.groupLevel).dictLabel
        }
      })
    },
    addItem () {
      this.formData.list.push({
        teamName: '',
        teamCategory: '',
        teamForm: ''
      })
      this.calcTeamNum()
    },
    removeItem (i) {
      this.formData.list.splice(i, 1)
      this.calcTeamNum()
    },
    calcTeamNum () {
      this.formData.teamNum = this.formData.list.length
    }

  }
}
</script>

<style lang="less" scoped>
.el-button--primary {
  background-color: #b81c25;
  border: 1px solid #b81c25;
  margin-bottom: 0px;
  &:hover{
    background-color: #b81c25;
    border-color: #b81c25;
  }
  span {
    font-family: Microsoft YaHei UI;
    color: #fff;
    font-size: 20px;
    letter-spacing: 1px;
  }
}
.el-button--text {
  color: #b81c25;
  font-size: 14px;
  text-decoration: underline;
}
.top-left-backhome {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 20px;
  top: 20px;
}
.top-left-backTitle {
  // margin-left: 0px;
  color: #b81c25;
  border: 0;
  background-color: white;
}
.login-container {
  background: url(https://cdn.spbcn.org/ghac/login/HQWY_DL_BJ_S.png);
  background-repeat: repeat;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  .left{
    width: 32%;
    box-sizing: border-box;
    padding-left: 120px;
    padding-top: 40px;
    position: relative;
    img{
      width: 360px;
    }
    .logo{
      position: absolute;
      width: 50px;
      bottom: -20px;
      left: 90px;
    }
  }
  .right{
    width: 30%;
    box-sizing: border-box;
    padding-left: 80px;
    padding-top: 80px;
    img{
      width: 220px;
    }
  }
  .logo1{
    position: absolute;
    top: 32px;
    left: 42px;
    height: 60px;
    cursor: pointer;
    z-index: 100000;
  }
  .logo2{
    position: absolute;
    top: 32px;
    right: 42px;
    height: 60px;
  }
}
.footer{
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 20px;
  font-family: regular;
  font-size: 16px;
  span{
    margin: 0 40px;
  }
}
.center{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}
.steps{
  width: 760px;
  max-height: 90vh;
  overflow-y: auto;
  background: #f9f9f9;
  border-radius: 18px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  padding: 10px 24px;
  .back{
    color: #b81c25;
    font-size: 12px;
    cursor: pointer;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 2px solid #b81c25;
  }
}
.step-bar{
  // width: 400px;
  margin: 0 auto;
}
/deep/ .el-step__title.is-process{
  color: #b81c25;
}
/deep/ .el-step__head.is-process{
  color: #b81c25;
  .el-step__icon.is-text{
    border-color: #b81c25;
  }
}
/deep/ .el-step__title.is-success{
  color: #c9aa68;
}
/deep/ .el-step__head.is-success{
  color: #c9aa68;
  .el-step__icon.is-text{
    border-color: #c9aa68;
  }
}
/deep/ .el-step__title{
  font-size: 12px;
}
.el-button--primary {
  background-color: #b81c25;
  border: 1px solid #b81c25;
  margin: 0 auto 10px;
  display: block;
  &:hover{
    background-color: #b81c25;
    border-color: #b81c25;
  }
  span {
    font-family: Microsoft YaHei UI;
    color: #fff;
    font-size: 20px;
    letter-spacing: 1px;
  }
}
.tips{
  color: #958363;
  font-size: 10px;
  margin-top: 20px;
  .tip-item{
    margin-bottom: 4px;
  }
  .tip-item2{
    // margin-bottom: 4px;
  }
}
.scan{
  color: #000;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
}

.flex{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.label{
  width: 140px;
  text-align: right;
  font-size: 14px;
  color: #666;
}
.value{
  flex: 1;
  margin-left: 30px;
  font-size: 14px;
  color: #000;
}
.qrCode{
  width: 130px;
  height: 130px;
  display: block;
  margin: 10px auto;
}
.qrCodeLoading{
  color: #b81c25;
  font-size: 16px;
  width: 130px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 10px auto;
  i{
    font-size: 20px;
  }
}
.btns{
  margin-top: 20px;
  text-align: center;
  .btn1{
    border-color: #b81c25;
    color: #b81c25;
    margin-right: 20px;
  }
  .btn2{
    background-color: #b81c25;
    border-bottom: #b81c25;
    color: #fff;
  }
}
.mt-40{
  margin: 40px auto 0;
  width: 600px;
}
.teamNum{
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #606266;
  margin: 20px 0;
  .num{
    color: #b81c25;
    font-size: 16px;
    font-weight: bold;
    margin-left: 12px;
  }
}
.operate{
  display: flex;
  height: 40px;
  align-items: center;
  i{
    font-size: 20px;
    color: #b81c25;
    margin-left: 20px;
    cursor: pointer;
  }
}
</style>
