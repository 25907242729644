<template>
  <div>
    <el-breadcrumb>
      <el-breadcrumb-item>初选</el-breadcrumb-item>
      <el-breadcrumb-item>申请线下考试</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <el-card style="margin: 15px">
      <el-steps :active="progress">
        <el-step title="试卷申请" icon="el-icon-s-order"></el-step>
        <el-step title="初赛考试" icon="el-icon-s-cooperation"></el-step>
        <el-step title="试卷寄回" icon="el-icon-s-finance"></el-step>
        <el-step title="试卷批阅" icon="el-icon-s-platform"></el-step>
        <el-step title="成绩统计" icon="el-icon-s-marketing"></el-step>
        <el-step title="晋级结果" icon="el-icon-s-claim"></el-step>
      </el-steps>
    </el-card>
    <el-card style="margin: 15px">
      <el-row class="title_row" align="middle" type="flex">
        <el-col :span="9">
          <span>申请考量信息</span>
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="papersModelList"
        border
        stripe
      >
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column label="赛季" prop="season"></el-table-column>
        <el-table-column label="申请老师" prop="teacherName"></el-table-column>
        <el-table-column label="联系电话" prop="phone"></el-table-column>
        <el-table-column
          label="比赛开始时间"
          prop="expectedTime"
        ></el-table-column>
        <el-table-column label="参赛年级" prop="gameGroupStr"></el-table-column>
        <el-table-column label="总考量" prop="examNum"></el-table-column>
        <el-table-column
          label="进度"
          prop="applyStatus"
          :formatter="formatterApplyStatusRule"
        ></el-table-column>
        <el-table-column label="操作" width="140px">
          <template scope="scope">
            <el-button
              type="text"
              size="small"
              @click="showPaperDetailDialog(scope.row)"
              >查看详情</el-button
            >
            <el-button
              v-if="scope.row.applyStatus === 3"
              type="text"
              size="small"
              @click="sentRefusedClicked(scope.row)"
              >确认寄回</el-button
            >
            <el-button
              v-if="scope.row.applyStatus === 7"
              type="text"
              size="small"
              @click="searchRefusedClicked(scope.row)"
              >晋级结果</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 寄出编辑信息的dialog -->
    <el-dialog
      title="试卷寄出"
      :visible.sync="expressInfoDialogVisible"
      width="40%"
      @close="expressInfoDialogClose"
    >
      <el-form
        :model="auditForm"
        label-width="100px"
        ref="expressInfoFormRef"
        :rules="expressInfoRules"
        style="width: 100%"
      >
        <el-form-item label="快递公司" prop="expressType">
          <el-select
            placeholder="请选择"
            v-model="auditForm.expressType"
            style="width: 100%"
          >
            <el-option
              v-for="item in dict_express"
              :key="item.dictSort"
              :label="item.dictLabel"
              :value="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="快递单号" prop="expressNumber">
          <el-input
            type="textarea"
            v-model="auditForm.expressNumber"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sentInfo">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getPersonalPaperApplyList, queryPreliminaryProcessInfo, getSeasonActivityIng, confirmToSendBack } from '@/http/api'
// import middleJs from '@/middleJs/middleJs.js'
export default {
  data () {
    return {
      expressInfoDialogVisible: false,
      applyStatusList: this.$curUtils.getApplyStatusWithNum,
      expressInfoRules: {
        expressType: [{ required: true, message: '请选择', trigger: 'change' }],
        expressNumber: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      progressList: [],
      progress: -1,
      measureProgress: {},
      dict_express: this.$curUtils.getDataList(this, 'express_type').then(value => { this.dict_express = value }),
      papersModelList: [],
      auditForm: {
        expressType: '',
        expressNumber: ''
      }
    }
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    // 获取当前赛季信息
    seasonActivityIng () {
      getSeasonActivityIng().then((res) => {
        const schoolData = JSON.parse(localStorage.getItem('saveSchoolInfo'))
        this.measureProgress.activityType = res.data.activityType
        this.measureProgress.season = res.data.season
        this.measureProgress.scheduleFlag = this.$chnEngStatusCode.INDIVIDUAL_PRELIMINARY
        this.measureProgress.schoolId = schoolData.list[0].schoolId
        this.queryPreliminaryProcessInfo()
      }).catch((err) => {
        console.log('err1111', err)
      })
    },
    // 获取考量进度
    queryPreliminaryProcessInfo () {
      queryPreliminaryProcessInfo(this.measureProgress).then((res) => {
        console.log('queryPreliminaryProcessInfo', res)
        if (JSON.stringify(res.data) !== '{}') {
          this.preliminaryExam = [res.data.testPaperApply, res.data.preliminaryExam, res.data.testPaperSendBack, res.data.testPaperReview, res.data.scoreStatistics, res.data.promotionResult]
          this.preliminaryExam.forEach((item, index) => {
            if (item === 1) {
              this.progress = index + 1
              console.log('this.progress', this.progress)
            }
          })
        } else {
          this.progress = -1
        }
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 获取考量申请列表
    getIndividualConsiderations () {
      getPersonalPaperApplyList().then((res) => {
        const newList = res.data.map((value, groupIndex, array) => {
          console.log('value', value)
          const gradeList = JSON.parse(value.gradeListStr)
          let gradeStr = ''
          for (let index = 0; index < gradeList.length; index++) {
            const element = gradeList[index]
            gradeStr = gradeStr.concat(element.gradeName + ',')
          }
          value.gameGroupStr = gradeStr
          return value
        })
        this.papersModelList = newList
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 查看学校申请个人考量详情
    showPaperDetailDialog (data) {
      // if (this.progress === 3 || this.progress === 4 || this.progress === 5) {
      //   middleJs.$emit('changeSelectIndex')
      // } else {
      this.$router.push({
        path: '/userInfo_IndividualPapersDetail',
        query: {
          paperDetailId: data.id
        }
      })
      // }
    },
    // 续费
    renewalEditClicked (data) {
      const orderDataForm = {}
      orderDataForm.season = data.season
      orderDataForm.stepIndex = '0'
      orderDataForm.productFlag = this.$chnEngStatusCode.APPLY_TEST_PAPER
      // orderDataForm.enrollmentId = data.id
      localStorage.setItem('orderPayDataForm', JSON.stringify(orderDataForm))
      this.$router.push({
        path: '/paymentProcess',
        query: {
          processType: this.$chnEngStatusCode.registrantsSchoolOrder
        }
      })
    },
    formatterApplyStatusRule (data) {
      console.log('applyStatusdata', data)
      return this.$curUtils.getApplyStatusWithNum(data.applyStatus)
    },
    // 确认寄出点击
    sentRefusedClicked (rowData) {
      console.log('rowData', rowData)
      this.auditForm.applyType = 1
      this.auditForm.paperId = rowData.id
      this.expressInfoDialogVisible = true
      this.paperApplyExpressDelivery()
    },
    expressInfoDialogClose () {
      this.$refs.expressInfoFormRef.resetFields()
    },
    // 试卷寄出信息提交
    sentInfo () {
      this.paperApplyExpressDelivery()
    },
    // 试卷寄出信息提交接口
    paperApplyExpressDelivery () {
      confirmToSendBack(this.auditForm).then((res) => {
        this.$refs.expressInfoFormRef.resetFields()
        this.expressInfoDialogVisible = false
        this.$message.success('操作成功')
        this.created()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 查看晋级结果
    searchRefusedClicked () {
      this.$router.push('/userInfo_individualScore')
    }
  },
  created () {
    this.getIndividualConsiderations()
    this.seasonActivityIng()
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-descriptions__header {
  margin-bottom: 5px;
}
.group-titleclass {
  width: 100px;
  font-size: 14px;
  color: #000000;
}
.grade-classnumber {
  width: 100%;
  display: flex;
  font-size: 12px;
  margin-bottom: 10px;
  flex-direction: row;
  flex-wrap: wrap;
}
.classListClass {
  margin-left: 20px;
  font-size: 10px;
}
::v-deep .el-dialog__title {
  font-size: 16px;
}
::v-deep .el-dialog__body {
  padding: 10px 20px 5px 40px;
}
</style>
