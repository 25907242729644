export default {
  /**
   * 活动类型
   */
  activityType: 1, // 代表环球文遗
  activityCourseType: 3, // 代表环球文遗课程
  /**
   * 登录type
   */
  LOGINTYPE: 1, // 代表网站
  /**
  * 获取验证码状态
   */
  LOGINSMSTYPE: '1', // 登录
  INDIVIDUALREGISTTYPE: '2', // 个人注册
  SCHOOLREGISTTYPE: '3', // 学校注册
  TEAMSIGNUP: '4', // 学校团体报名
  REGISTRANTS: '5', // 学校个人报名
  /**
   * 跳转订单来源
   */
  registrantsOrder: '1', // 个人跳转
  registrantsSchoolOrder: '2', // 学校报名个人信息
  teamSchoolOrder: '3', // 学校报名团体信息
  /**
   * 判断学校整体参加个人考量是否上报
   */
  ENROLLMENT_NO: 3001, // 未提交
  ENROLLMENT_NO_PAY: 3002, // 已提交未支付
  ENROLLMENT_YES_PAY: 3003, // 已支付
  /**
   * 支付来源
   */
  PAY_ORDERSOURCE: 0, // 网站
  /**
   * 支付类型
   */
  PAY_ORDERTYPE: 0, // 网站
  /**
   * 商品ID
   */
  PRODUCT_SCHOOLPERSON: '1', // 学校报名个人赛
  /**
   * 产品规格类型Code码
   */
  PRODUCT_SPECSTYPE_CODE_SCHOOLPERSON: 1, // 学校报名个人赛
  /**
   * 产品规格类型内容Code码
   */
  PRODUCT_SPECSTYPE_VALUE_CODE_SCHOOLPERSON: 1, // 学校报名个人赛
  /**
   * 商品productFlag
   */
  APPLY_TEST_PAPER: 'APPLY_TEST_PAPER', // 个人赛申报考量
  TEAM_COMPETITION_REGISTRATION: 'TEAM_COMPETITION_REGISTRATION', // 团体赛报名
  TEAM_CATEGORY: 2,
  TEAM_SUBCATEGORY: 8,
  TEAM_SUBTYPECATEGORY: 2,
  /**
   * 初赛考量申请进度标识
   */
  INDIVIDUAL_PRELIMINARY: 'INDIVIDUAL_PRELIMINARY', // 初赛,
  /**
   * 赛程信息
   */
  PRELIMINARY_SEASONRACEID: '1' // 初赛
}
