<template>
  <div>
    <!-- 筛选 -->
    <el-card style="margin: 15px">
      <el-row class="title_row">
        <el-col>
          <span>战队信息筛选</span>
        </el-col>
      </el-row>
      <el-form
        :model="queryForm"
        class="filter_Form"
        label-width="80px"
        ref="queryFormRef"
      >
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="赛季" prop="season">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                v-model="queryForm.season"
                clearable
              >
                <el-option
                  v-for="item in dict_cebsbSeason"
                  :key="item.season"
                  :label="item.season"
                  :value="item.season"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="组别" prop="teamLevel">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                v-model="queryForm.teamLevel"
                clearable
              >
                <el-option
                  v-for="item in dict_schoolGroup"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row :gutter="20" type="flex" justify="end">
        <el-col :span="4">
          <el-button type="primary" style="width: 100%" @click="getDataList"
            >查询</el-button
          >
        </el-col>
        <el-col :span="4">
          <el-button type="primary" style="width: 100%" @click="reset"
            >重置</el-button
          >
        </el-col>
      </el-row>
    </el-card>
    <!-- 获取战队列表 -->
    <el-card style="margin: 15px">
      <el-row class="title_row" align="middle" type="flex">
        <el-col :span="5">
          <span
            style="display: -moz-inline-box; display: inline-block;width: 200px;">战队信息列表</span>
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="dataList"
        border
        stripe
      >
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column label="战队名称" prop="teamName"> </el-table-column>
        <el-table-column label="赛季" prop="season"> </el-table-column>
        <el-table-column
          label="组别"
          prop="teamLevel"
          :formatter="groupFormatter"
        ></el-table-column>
        <el-table-column label="领队老师" prop="teacherMasterName">
        </el-table-column>
        <el-table-column
          label="手机号"
          prop="teacherMasterPhone"
          width="120px"
        ></el-table-column>
        <el-table-column label="缴费状态" prop="payed" width="100px">
          <template slot-scope="scope">
            <el-tag
              type="warning"
              v-if="!scope.row.payed || scope.row.payed + '' === '0'"
              size="mini"
              >未缴费</el-tag
            >
            <el-tag
              type="success"
              v-else-if="scope.row.payed + '' === '1'"
              size="mini"
              >已缴费</el-tag
            >
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80px">
          <template slot-scope="scope">
            <!-- <el-button
              type="text"
              icon="el-icon-delete"
              size="small"
              @click="delecteEditDialog(scope.row)"
              >删除</el-button
            > -->
            <el-button
              v-if="scope.row.payed + '' === '0'"
              type="text"
              size="small"
              @click="renewalEditClicked(scope.row)"
              >缴费</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import { getTeamListByActivityType, getSeasonActivityList } from '@/http/api'
export default {
  data () {
    return {
      dataList: [],
      dict_schoolGroup: this.$curUtils.dict_schoolGroup(),
      dict_cebsbSeason: [],
      queryForm: {
        season: '2022~2023',
        activityType: this.$chnEngStatusCode.activityType
      }
    }
  },
  created () {
    this.getSeasonActivityList()
  },
  methods: {
    // 重置筛选条件
    reset () {
      this.$refs.queryFormRef.resetFields()
      this.getDataList()
    },
    groupFormatter (data) {
      let temp = '未知'
      for (const key in this.dict_schoolGroup) {
        if (Object.hasOwnProperty.call(this.dict_schoolGroup, key)) {
          const element = this.dict_schoolGroup[key]
          if (element.dictValue === data.teamLevel + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    // 获取赛季列表
    getSeasonActivityList () {
      this.$curUtils.openLoading(this)
      getSeasonActivityList().then((res) => {
        console.log('getSeasonActivityList', res)
        this.dict_cebsbSeason = res.data
        for (let index = 0; index < res.data.length; index++) {
          const element = res.data[index]
          if (element.isIng === 1) {
            this.queryForm.season = element.season
            this.getDataList()
          }
        }
        this.$curUtils.closeLoading(this)
      }).catch((err) => {
        this.$curUtils.closeLoading(this)
        console.log('返回的err', err)
      })
    },
    // 获取战队列表数据
    getDataList () {
      getTeamListByActivityType(this.queryForm).then((res) => {
        this.$curUtils.closeLoading(this)
        this.dataList = res.data
      }).catch((err) => {
        this.$curUtils.closeLoading(this)
        console.log('返回的err', err)
      })
    },
    // 删除战队弹窗提示
    delecteEditDialog (rowData) {
      this.$confirm('是否确认删除该战队', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delecteGameStudent(rowData)
      }).catch(() => {})
    },
    // 确认删除战队
    async delecteGameStudent (rowData) {
      this.$message.success('接口未实现')
      // this.getDataList()
    },
    // 续费
    renewalEditClicked (data) {
      console.log('data', data)
      const orderDataForm = {}
      orderDataForm.season = data.season
      orderDataForm.stepIndex = '0'
      orderDataForm.productFlag = this.$chnEngStatusCode.TEAM_COMPETITION_REGISTRATION
      orderDataForm.category = this.$chnEngStatusCode.TEAM_CATEGORY
      orderDataForm.subCategory = this.$chnEngStatusCode.TEAM_SUBCATEGORY
      orderDataForm.subTypeCategory = this.$chnEngStatusCode.TEAM_SUBTYPECATEGORY
      // orderDataForm.enrollmentId = data.enrollmentId
      orderDataForm.teamId = data.teamId
      localStorage.setItem('orderPayDataForm', JSON.stringify(orderDataForm))
      this.$router.push({
        path: '/paymentProcess',
        query: {
          processType: this.$chnEngStatusCode.teamSchoolOrder
        }
      })
    }
  }
}
</script>

<style>
</style>
