<template>
  <div class="top-backview">
    <div class="contnet-backview">
      <!-- 顶部菜单 -->
      <!-- <Topbar class="topbar"></Topbar> -->
      <!-- banner -->
      <div class="dibannerclass">
        <el-carousel
          :interval="3000"
          indicator-position="none"
          id="el-carousel"
        >
          <el-carousel-item v-for="item in images" :key="item.index">
            <img :src="item.url" alt="" class="banner-img-class" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="bannerbottom">
        <div class="title-backview">国际理解教育与全球胜任力</div>
      </div>
      <!-- 中间内容 -->
      <div class="underscenterclass">
        <div class="center-titlestr">
          全球胜任力的概念最早可追溯至“国际理解”一词的提出。1974年，联合国教科文组织第18届大会通过的《关于教育促进国际理解、合作与和平及教育与人权和基本自由相联系的建议》提出国际理解教育，为全球胜任力的提出奠定了思想基础。2017年12月12日，经合组织教育与技能司和哈佛大学教育研究生院零点项目共同主持了《PISA全球素养框架：让我们的青年为一个包容和可持续的世界做好准备》发布会，提出全球胜任力的官方定义：青少年能够分析当地、全球和跨文化的问题，理解和欣赏他人的观点和世界观，与不同文化背景的人进行开放、得体和有效的互动，以及为集体福祉和可持续发展采取行动的能力。
        </div>
        <div>
          <img
            src="https://cdn.spbcn.org/ghac/assets/aboutus1_2_image.png"
            alt=""
            class="center-image1"
          />
        </div>
        <div>
          <img
            src="https://cdn.spbcn.org/ghac/assets/aboutus1_2_text1.png"
            alt=""
            class="center-image2"
          />
        </div>
        <div class="center-titlestr2">
          “国际理解教育”理念是 1946
          年联合国教科文组织第一次大会上正式提出的。当时，刚刚结束不久的两次世界大战给人类带来巨大伤害，人们希望通过教育达成各国、各文化之间的理解，以实现和平。
        </div>
        <div class="center-titlestr2">
          1993年以雅克·德洛尔（Jacques
          Delors）为主席的国际21世纪教育委员会成立，历时3年的广泛调查和深入研究，于1996年向UNESCO提交了著名报告--《learning：the
          treasure within（教育：财富蕴藏其中）（Delors Report）》。
        </div>
        <div class="center-titlestr2">
          该报告系统回答了面对信息社会和知识经济时代挑战的教育使命，提出，面向21世纪人人必须掌握四种基本学习（即“教育的四个支柱”）。
        </div>
        <div>
          <img
            src="https://cdn.spbcn.org/ghac/assets/aboutus1_2_text2.png"
            alt=""
            class="center-image3"
          />
        </div>
        <div>
          <img
            src="https://cdn.spbcn.org/ghac/assets/aboutus_zhuangshi.png"
            alt=""
            class="center-spiritview-bottomimg"
          />
        </div>
      </div>
      <!-- 底部内容 -->
    </div>
  </div>
</template>

<script>
// import Topbar from '@/components/Topbar.vue'
// import Footer from '@/components/Footer.vue'
export default {
  props: {
    dataHeight: {
      type: String,
      default: '100%'
    }
  },
  data () {
    return {
      images: [{ url: 'https://cdn.spbcn.org/ghac/assets/aboutus1_2_banner.png' }]
    }
  },
  components: {
    // Topbar,
    // Footer
  }
}
</script>

<style lang="less" scoped>
// .topbar {
//   z-index: 1000;
//   width: 1000px;
//   padding: 0;
//   margin: 0;
// }
.contnet-backview {
  width: 1000px;
  background-color: black;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.top-backview {
  width: 100%;
  // padding-bottom: 10px;
  background-color: rgb(216, 216, 216);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.dibannerclass {
  width: 1000px;
  height: 260px;
  z-index: 99;
  background-color: rgb(0, 0, 0);
}
.bannerbottom {
  width: 1000px;
  height: 100px;
  display: flex;
  background-color: black;
  align-items: center;
  justify-content: center;
}
.title-backview {
  width: 400px;
  height: 60px;
  font-size: 32px;
  font-family: FZLBJW;
  font-weight: 900;
  text-align: center;
  line-height: 60px;
  color: white;
  border: 2px solid rgb(255, 255, 255);
}
.el-carousel {
  width: 1000px;
  height: 260px;
}
.banner-img-class {
  width: 1000px;
  height: 260px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.underscenterclass {
  width: 1000px;
  // height: 1553px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(https://cdn.spbcn.org/ghac/assets/feiyi_background.png);
  padding: 0px;
  // padding-bottom: 0px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.center-titlestr {
  margin-top: 30px;
  width: 800px;
  text-indent: 2em;
  font-size: 18px;
  font-family: FZLBJW;
  color: rgb(41, 41, 41);
}
.center-titlestr2 {
  width: 800px;
  text-indent: 2em;
  font-size: 18px;
  font-family: FZLBJW;
  color: rgb(41, 41, 41);
}
.center-image1 {
  width: 800px;
  height: 142px;
  margin-top: 20px;
}
.center-image2 {
  width: 400px;
  height: 31px;
  margin-top: 30px;
  margin-bottom: 5px;
}
.center-image3 {
  width: 875px;
  height: 403px;
  margin-left: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.center-spiritview-bottomimg {
  width: 1000px;
  height: 90px;
  margin-top: 20px;
  margin-bottom: -20px;
}
.footerclass {
  width: 1000px;
}
</style>
