<template>
  <div class="top-backview">
    <div class="contnet-backview">
      <!-- 顶部菜单 -->
      <!-- <Topbar class="topbar"></Topbar> -->
      <!-- banner -->
      <div class="dibannerclass">
        <el-carousel
          :interval="3000"
          indicator-position="none"
          id="el-carousel"
          :height="dataHeight"
        >
          <el-carousel-item v-for="item in images" :key="item.index">
            <img :src="item.url" alt="" class="banner-img-class" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="bannerbottom">
        <div class="title-backview">
          <div>华夏非遗探究项目</div>
          <div class="en">Domestic Research and Exploration</div>
        </div>
      </div>
      <div class="red-backview">
        <div class="red-lineview"></div>
      </div>
      <div class="centerclass" v-for="(item,i) of list" :key="i">
        <div class="center-titleview">
          <div class="titleStr">{{item.title}} ｜ {{item.enTitle}}</div>
        </div>
        <img class="center-img" :src="item.img" />
        <div class="center-text">{{ item.text }}</div>
      </div>
      <div class="area">
        <div class="imgs">
          <img src="https://cdn.spbcn.org/ghac/pictures/ghac_guonei_wuyue.png" alt="">
          <img src="https://cdn.spbcn.org/ghac/pictures/ghac_guonei_bashu.png" alt="">
          <img src="https://cdn.spbcn.org/ghac/pictures/ghac_guonei_lingnan.png" alt="">
          <img src="https://cdn.spbcn.org/ghac/pictures/ghac_guonei_yanzhao.png" alt="">
          <img src="https://cdn.spbcn.org/ghac/pictures/ghac_guonei_qiangui.png" alt="">
        </div>
        <div class="text">大会筛选了一系列致力于保护和传承非物质文化遗产的博物馆、民俗馆等作为参观研学的基地，特邀非遗传承保护人进行实地讲解和展演，让学生感受他们对非遗事业的热爱，体会非遗的魅力。</div>
      </div>
      <div class="centerclass">
        <div class="center-titleview">
          <div class="titleStr">探究详情 ｜ Inquiry Details</div>
        </div>
        <div class="sec-title">参与流程</div>
        <div class="flowpath">
          <div class="flowpath-item" v-for="(item, i) of flowpath" :key="i">
            <div class="title">{{ item.title }}</div>
            <div class="desc">{{ item.desc }}</div>
            <img class="icon" v-if="i !== flowpath.length - 1" src="https://cdn.spbcn.org/ghac/hxtj/icon.png" alt="">
          </div>
        </div>
        <div class="sec-title">探究内容</div>
        <div class="search-content">
          <div class="search-content-item" v-for="(item, i) of content" :key="i">
            <div class="title">{{ item.title }}</div>
            <div class="desc" :style="'background-image: url('+ item.img +');'">
              <div class="desc-text">{{ item.desc }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="centerclass">
        <div class="center-titleview">
          <div class="titleStr">探究特色 ｜ Inquiry Features</div>
        </div>
        <div class="special" v-for="(item,i) of special" :key="i">
          <div class="sec-title">{{ item.title }}</div>
          <img class="img" :src="item.img" alt="">
          <div class="center-text">{{ item.text }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Topbar from '@/components/Topbar.vue'
// import Footer from '@/components/Footer.vue'
export default {
  props: {
    dataHeight: {
      type: String,
      default: '100%'
    }
  },
  data () {
    return {
      images: [{ url: 'https://cdn.spbcn.org/ghac/hxtj/HXTJ_banner.png' }],
      list: [
        {
          title: '探究理念',
          enTitle: 'Inquiry Philosophy',
          img: 'https://cdn.spbcn.org/ghac/hxtj/HXTJ_TJLN_banner.png',
          text: '“纸上得来终觉浅，绝知此事要躬行”。在为学生提供课堂理论知识的同时，大会还精心设计了一系列华夏文化探究活动。根据不同文化区域特色、学生年龄特点和各学科教学内容需要，通过组织学生集体学习、集中探究的方式，孩子们可以走出相对封闭的校园，实现理论知识与实践经验的握手言欢，夯实知识的纹理，从而促进学生学习和成长的第二次飞跃。'
        },
        {
          title: '探究区位',
          enTitle: 'Inquiry Location',
          img: 'https://cdn.spbcn.org/ghac/hxtj/HXTJ_TJQW_banner.png',
          text: '大会甄选国内有代表性的五大文化区域，让学生可以进行非遗探索，学生可以去燕赵大地感受非遗的豪迈、去江南水乡体验吴风越韵，去黔贵地区见识多彩非遗，去粤见岭南观非遗碰撞，去天府之国领略瑰里巴蜀。'
        }
      ],
      flowpath: [
        {
          title: '组队准备阶段',
          desc: '筛选具备一定语言和文化知识储备的学生，进行校园组队'
        },
        {
          title: '社团学习阶段',
          desc: '组队学生通过社团课程学习，进一步了解本区域非遗相关知识'
        },
        {
          title: '校内选拔阶段',
          desc: '通过选拔确认本校的代表队伍及学生'
        },
        {
          title: '国内探究阶段',
          desc: '通过国内探究，完成本区域文化与其他区域文化的对比学习'
        },
        {
          title: '展演筹备阶段',
          desc: '将前期学习积累的知识落到实地，完成环球文遗小使者大会展演的筹备工作'
        }
      ],
      content: [
        {
          title: '文化区域探究',
          img: 'https://cdn.spbcn.org/ghac/hxtj/HXTJ_TJXQ_banner_01.png',
          desc: '学生通过对所在文化区域和其他不同文化区域进行广域性探究， 感受不同文化区域的历史变迁、风俗文化，对不同区域的文化底色、古往今来的 沿革有初步的认识和了解。'
        },
        {
          title: '博物馆探究',
          img: 'https://cdn.spbcn.org/ghac/hxtj/HXTJ_TJXQ_banner_02.png',
          desc: '学生还会到各地的博物馆、文化馆进行探访。每座博物馆里的文 物都在诉说着当地历史的变迁。通过研学探究，学生在博物馆里可以遇见当地的 历史、了解当地的历史。每座文化馆里，学生都能够感受到一个城市、一个地区 的文化发展脉络，感受当地独具特色的文化传统。'
        },
        {
          title: '匠人工坊学习',
          img: 'https://cdn.spbcn.org/ghac/hxtj/HXTJ_TJXQ_banner_03.png',
          desc: '在对文化区域的历史和文化发展有基本的了解后，学生也会去 到当地非遗匠人工坊进行实践学习，亲身体验不同非遗工艺的制作流程，亲手制 作属于自己的非遗艺术品，从而对非遗有更深切的体验和认识。'
        }
      ],
      special: [
        {
          title: '专业导师全程讲解',
          img: 'https://cdn.spbcn.org/ghac/hxtj/HXTJ_TJTS_banner_01.png',
          text: '每一段华夏非遗探究之旅都会有专业的导师全程陪伴，每一位导师都是来自各高校相关主题领域的专家。他们既是陪伴者，全程照顾学生们的起居、心理;也是引导者，用自己的专业积累为学生讲解探究项目中涉及的相关知识以及遇到的问题;同时还是传递者，在陪伴学生的过程中把自己对教育的思考与探索传递给每一位学生，践行自己的教育理念，传递教育理想。'
        },
        {
          title: '链接各地优质资源',
          img: 'https://cdn.spbcn.org/ghac/hxtj/HXTJ_TJTS_banner_02.png',
          text: '大会与各省市教育局、文旅局、非遗中心、匠人工坊形成链接，依托他们提供的一系列官方优质资源，学生不仅可以走进不同地区的博物馆、文化馆，了解当地的历史文化;同时还可以深入非遗中心、匠人工坊，动手体验非遗项目的制作。通过理论与实践相结合的方式，华夏非遗探究为学生提供最深刻、最直观的非遗知识和动手体验，让他们能够了解各文化区域优秀的非遗知识和传统。'
        }
      ]
    }
  },
  components: {
    // Topbar,
    // Footer
  }
}
</script>

<style lang="less" scoped>
// .topbar {
//   z-index: 1000;
//   width: 1000px;
//   padding: 0;
//   margin: 0;
// }
.contnet-backview {
  width: 1000px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-bottom: 80px;
}
.top-backview {
  width: 100%;
  // padding-bottom: 10px;
  background-color: rgb(216, 216, 216);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.dibannerclass {
  width: 1000px;
  height: 469px;
  z-index: 99;
}
.bannerbottom {
  width: 1000px;
  height: 100px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 150px;
  z-index: 100;
}
.title-backview {
  height: 60px;
  font-size: 32px;
  font-family: FZLBJW;
  font-weight: 900;
  text-align: center;
  line-height: 60px;
  padding: 0px 20px;
  color: white;
  border: 2px solid rgb(255, 255, 255);
  .en{
    font-size: 24px;
  }
}
.red-backview {
  width: 1000px;
  height: 25px;
}
.red-lineview {
  width: 1000px;
  height: 10px;
  // margin-top: 15px;
  background-color: #e50211;
}
.centerclass {
  width: 1000px;
}
.center-titleview {
  display: inline-block;
  padding-right: 100px;
  height: 42px;
  margin-top: 50px;
  background: url(https://cdn.spbcn.org/ghac/assets/feiyi_homepage_title.png);
  background-size: auto 100%;
  background-position: right;
  background-repeat: no-repeat;
}

.titleStr {
  // width: 600px;
  height: 100%;
  line-height: 42px;
  // text-align: center;
  font-size: 24px;
  font-family: FZLBJW;
  color: white;
  font-weight: bolder;
  padding-left: 30px;
  background-color: #b81b26;
}

.banner-img-class {
  width: 1000px;
  height: 470px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.el-carousel {
  width: 1000px;
  height: 470px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.center-img{
  width: 1000px;
  // height: 186px;
  border-radius: 30px;
  margin-top: 40px;
}
.center-text{
  font-size: 20px;
  font-family: FZLBJW;
  line-height: 32px;
  margin-top: 20px;
  text-indent: 2em;
}
.area{
  width: 1000px;
  margin-top: 60px;
  .imgs{
    overflow-x: auto;
    display: flex;
    img{
      width: 230px;
      margin-right: 20px;
    }
  }
  .text{
    width: 1000px;
    background-color: #a32b26;
    border-radius: 12px;
    padding: 20px;
    color: #fff;
    font-size: 16px;
    margin-top: 24px;
    line-height: 30px;
  }
}
.special{
  margin-bottom: 20px;
  .img{
    width: 1000px;
    border-radius: 12px;
  }
}
.sec-title{
  width: 260px;
  height: 38px;
  background-image: url(https://cdn.spbcn.org/ghac/ghac_button_highlight.png);
  background-size: 100% 100%;
  text-align: center;
  color: #fff;
  font-family: hzgb;
  font-size: 18px;
  line-height: 38px;
  margin: 40px auto 30px;
  letter-spacing: 2px;
}
.flowpath-item{
  .title{
    width: 240px;
    height: 32px;
    background-image: linear-gradIEnt(to bottom, #f9d291, #ed9028);
    border: 1px solid #904145;
    border-radius: 40px;
    font-family: hzgb;
    font-size: 18px;
    color: #000;
    line-height: 32px;
    text-align: center;
    margin: 0 auto 10px;
  }
  .desc{
    width: 700px;
    height: 40px;
    background: url(https://cdn.spbcn.org/ghac/hxtj/HXTJ_TJXQ_02.png);
    background-size: 100% 100%;
    margin: 0 auto 10px;
    font-size: 18px;
    font-family: hzgb;
    color: #9c031d;
    line-height: 40px;
    text-align: center;
  }
  .icon{
    width: 24px;
    margin: 0 auto 20px;
    display: block;
  }
}
.search-content-item{
  .title{
    color: #9e0200;
    font-family: hzgb;
    font-size: 24px;
    text-align: center;
    margin: 20px 0 20px;
  }
  .desc{
    width: 1000px;
    height: 320px;
    background-size: cover;
    background-position: center;
    border-radius: 16px;
    display: flex;
    align-items: center;
    .desc-text{
      width: 100%;
      height: 100px;
      background-color: rgba(0,0,0,.5);
      color: #fff;
      font-size: 16px;
      padding: 30px;
      box-sizing: border-box;
    }
  }
}
</style>
