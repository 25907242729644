<template>
  <div>
    <div class="theme">
      <div class="center-container theme-flex">
        <div class="theme-title">八大主题</div>
        <div class="theme-list">
          <template v-for="item,index of themes">
            <div :key="item.id" class="theme-item" :class="item.active ? 'active' : ''" @click="changeThemeItem(index)">
              <img :src="item.typeLogo" alt="">
              <div class="white-bg"></div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="type-bar">
      <div class="center-container type-flex">
        <div class="type-item" :class="item.active ? 'active' : ''" v-for="item,index of types" :key="item.id" @click="changeTypeItem(index)">{{item.typeName}}</div>
      </div>
    </div>
    <div class="center-container">
      <!-- banner -->
      <div class="page-banner" :class="'page-banner' + activeTypeIndex">
        <div class="h1">{{activeType.typeName}}</div>
      </div>
    </div>
    <!-- 大会活动文化选区 -->
    <div class="area">
      <div class="center-container">
        <div class="center-titleview">
          <div class="titleStr">大会活动文化选区 ｜ Cultural Areas</div>
        </div>
        <div class="area-desc">
          <div class="map" :class="'map' + activeTypeIndex"><img class="map-img" :src="typeInfo[activeTypeIndex].leftPicture" alt="" /></div>
          <div class="area-desc-txt">
            <div>{{ typeInfo[activeTypeIndex].desc1 }}</div>
            <div>{{ typeInfo[activeTypeIndex].desc2 }}</div>
          </div>
        </div>
        <div class="book">
          <div class="book-item" v-for="item of activeType.childrenCourseType" :key="item.id">
            <img @click="handleToDetail(item)" class="book-img" :src="item.typeLogo" alt="">
          </div>
        </div>
      </div>
    </div>
    <!-- <Courses :area="activeArea" :bannerData="bannerData" :courseList="courseList" :total="total" v-else></Courses> -->
  </div>
</template>
<script>
export default {
  data () {
    return {
      themes: [], // 八大主题
      types: [], // 华夏、海外
      activeTheme: {}, // 选中的主题
      activeType: {}, // 选中海外、华夏
      activeTypeIndex: 0,
      activeArea: {}, // 选中的区域 吴越、巴蜀等
      scrollTop: 0,
      typeInfo: [
        {
          desc1: '中国作为一个幅员辽阔的泱泱大国，各地的自然、地理、人文条件千差万别。中国文化在漫长的发展历程中，因其腹地开阔，南北东西各路相激相荡，燕赵、齐鲁与荆楚、岭南相互吸纳，三秦、三晋与巴蜀、吴越互为应援，呈现区域文化多样化发展的局面。',
          desc2: '基于中国传统文化区域的划分，大会以八大文化区域为探究范畴，鼓励青少年去探究异彩纷呈、生机盎然的华夏文化，去感受不同区域和而不同、刚健自强的文化机制。在每个地区，大会特别甄选了部分重点城市，作为不同文化区域的代表城市，并将在这些城市着重推动国际理解教育的开展和非遗教育的传承。',
          leftPicture: 'https://cdn.spbcn.org/ghac/pictures/map.png'
        },
        {
          desc1: '在环球文遗小使者大会展演中表现优异的学生队伍，将有机会受邀代表华夏文明出访海外，为学生提供进行中外非遗对比的机会和平台。学生将化身华夏文明小使者，代表优秀的中国非遗，前往世界各地，与来自不同国家、不同文化背景下的青少年进行深入交流。',
          desc2: '这样的出访与交流不仅能为孩子们开启通往更广阔的世界的大门，激发他们探索未知世界的兴趣和热情，让他们看到不同地域、不同民族之间的文化差异，也对培养他们的综合素质，建立健全的世界观、人生观、价值观大有裨益，更是可以以此推动华夏文明的延续和传承，推动中华非遗在世界的传播力和影响力，推动人类文化多样性和人类命运共同体的构建和完善。',
          leftPicture: 'https://cdn.spbcn.org/ghac/pictures/haiwai.png'
        }
      ],
      courseList: [],
      total: 0,
      bannerData: []
    }
  },
  created () {
    this.getClasses(1)
    this.getClasses(2)
  },
  methods: {
    getClasses (type) {
      this.$http.get('/external/querySeeEightTopics', { params: { type } }).then(res => {
        if (res.code === 200) {
          res.data.forEach((item, i) => {
            item.active = i === 0
          })
          if (type === 1) {
            this.themes = res.data
            this.activeTheme = res.data[0]
          } else if (type === 2) {
            this.types = res.data
            this.activeType = res.data[0]
            this.activeTypeIndex = 0
          }
        }
      })
    },
    handleToDetail (item) {
      // 主题/国内海外/区域
      console.log(this.activeTheme, this.activeType, item)
      this.activeArea = item
      // typeId: 主题id,海外国内id,区域id 拼接成的字符串，后端要求这样传，不理解但尊重
      const query = {
        // typeId: `${this.activeTheme.id},${this.activeType.id},${item.id}`
        themeId: this.activeTheme.id,
        cId: this.activeType.id,
        areaId: item.id
        // area: item.typeName
      }
      this.$router.push({
        path: '/classesList',
        query
      })
    },
    changeThemeItem(index) {
      this.themes.find(item => item.active).active = false
      this.themes[index].active = true
      this.activeTheme = this.themes[index]
    },
    changeTypeItem(index) {
      this.types.find(item => item.active).active = false
      this.types[index].active = true
      this.activeType = this.types[index]
      this.activeTypeIndex = index
    }
  }
}
</script>
<style lang="less" scoped>
.theme{
  background-color: #b81b26;
  padding: 16px 0 0;
  .theme-flex{
    display: flex;
  }
  .theme-title{
    font-size: 24px;
    font-family: regular;
    color: #000;
    width: 120px;
    margin-top: 10px;
  }
  .theme-list{
    overflow: hidden;
    flex: 1;
  }
  .theme-item{
    float: left;
    width: 198px;
    height: 60px;
    margin-left: 20px;
    margin-bottom: 12px;
    position: relative;
    cursor: pointer;
    img{
      width: 198px;
      height: auto;
      position: relative;
      z-index: 1;
    }
    &.active{
      .white-bg{
        width: 100%;
        height: 39px;
        background-color: #fff;
        position: absolute;
        left: 0;
        top: 17px;
        z-index: 0;
      }
    }
  }
}

.type-bar{
  background-color: #dcdcdc;
  padding: 20px 0;
}
.type-flex{
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.type-item{
  width: 250px;
  height: 40px;
  background-image: url(https://cdn.spbcn.org/ghac/ghac_button_nomal.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  font-size: 24px;
  font-family: regular;
  color: #fff;
  text-align: center;
  line-height: 36px;
  cursor: pointer;
  &.active{
    background-image: url(https://cdn.spbcn.org/ghac/ghac_button_highlight.png);
    color: #000;
  }
}

.page-banner{
  width: 100%;
  height: 400px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.page-banner0{
    background-image: url(https://cdn.spbcn.org/ghac/pictures/ghac_banner.png);
  }
  &.page-banner1{
    background-image: url(https://cdn.spbcn.org/ghac/pictures/ghac_banner_haiwai.png);
  }
  .h1{
    height: 80px;
    font-size: 46px;
    font-family: FZLBJW;
    font-weight: 900;
    text-align: center;
    line-height: 80px;
    padding: 0px 60px;
    color: white;
    border: 2px solid rgb(255, 255, 255);
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    background: rgba(255,0,0,.1);
  }
}

.area-desc{
  display: flex;
  align-items: center;
}
.map{
  width: 400px;
  height: 400px;
  background-color: #d2d2d2;
  border-radius: 12px;
  box-sizing: border-box;
  margin-right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  .map-img{
    width: 100%;
  }
  &.map0{
    padding: 16px;
  }
}
.area-desc-txt{
  flex: 1;
  font-size: 20px;
  font-family: FZLBJW;
  color: #333;
  line-height: 32px;
  text-indent: 2em;
}

.book{
  overflow: hidden;
  margin-top: 40px;
  .book-item{
    float: left;
    // width: 330px;
    width: 500px;
    text-align: center;
    margin-bottom: 40px;
    cursor: pointer;
    .book-img{
      // width: 300px;
      width: auto;
      height: 580px;
    }
  }
}
.center-titleview {
  display: inline-block;
  padding-right: 100px;
  height: 42px;
  margin-top: 50px;
  background: url(https://cdn.spbcn.org/ghac/assets/feiyi_homepage_title.png);
  background-size: auto 100%;
  background-position: right;
  background-repeat: no-repeat;
  margin-bottom: 40px;
}

.titleStr {
  // width: 600px;
  height: 42px;
  line-height: 42px;
  // text-align: center;
  font-size: 24px;
  font-family: FZLBJW;
  color: white;
  font-weight: bolder;
  padding-left: 30px;
  background-color: #b81b26;
}
</style>
<!-- https://cdn.spbcn.org/ghac/ghac_homepage_logo1.png -->
