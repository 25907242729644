<template>
  <div class="top-backview">
    <div class="contnet-backview">
      <!-- 顶部菜单 -->
      <!-- <Topbar class="topbar"></Topbar> -->
      <!-- banner -->
      <!-- <div class="dibannerclass">
        <el-carousel
          :interval="3000"
          indicator-position="none"
          id="el-carousel"
          :height="dataHeight"
        >
          <el-carousel-item v-for="item in images" :key="item.index">
            <img :src="item.url" alt="" class="banner-img-class" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="red-backview">
        <div class="red-lineview"></div>
      </div> -->
      <ghacBanner></ghacBanner>
      <div class="topMenuBackview">
        <div
          class="menu-backview"
          v-for="(item, index) in menuList"
          :class="{ selectColor: index == isactive }"
          @click="fn(index)"
          :key="item.idnex"
        >
          {{ item.title }}
        </div>
      </div>
      <div class="anchorview" id="centerTitleOne"></div>
      <!-- 中间内容部分 -->
      <div class="centerclass">
        <div class="center-titleview">
          <div class="titleStr">大会简介</div>
        </div>
        <div class="course-description-backview">
          <div class="description-contentStr">
            《环球文遗小使者大会》起源于2021年拥有5000年璀璨历史文化的中国，在后疫情时代大会为响应联合国17个可持续发展目标的战略规划，遵照联合国构建人类命运共同体的理念。为推进联合国教科文组织在全球青少年范围内国际理解教育的实施，提升全球青少年人文素养能力，特别甄选世界非物质文化遗产作为大会文化探究的基础，开设8大类型挑战赛，设置近百个主题500多个项目.通过博古、通今、创未来的大会挑战赛思路，让青少年在通晓本国传统文化的基础上实现地域和全球国际比较教育理解，并且通过学校体制内的学科的参透去探究自己未来感兴趣的大学专业和职业。在挑战赛中把传统的历史文化和现实生活的场景进行融合探究，并激发学生对于未来科学和生活的创新。
          </div>
          <div class="description-contentStr">
            《环球文遗小使者大会》的研发智库包含联合国教科文组织相关专家学者，也包含各个国家教育相关部门、非物质文化遗产相关部门并且联合全球和地区各高校的教授和学者共同和设计和进行评定工作。大会将通过学校机构和家庭社区两个区域甄选参加《环球文遗小使者大会》全球挑战赛的战队。并且将为组织参赛的学校和社区提供，相关的课程体系和研学探究方案，让备赛的代表队能够有充足的知识贮备和准备参赛作品的场景和工具。
          </div>
          <div class="description-contentStr">
            该项大会发起方UIE聚知优国际教育集团，作为《SPELLING BEE of
            CHINA》中国地区组委会已经成功在中国地区的60多个城市举办此项活动，SPELLING
            BEE英语素质教育活动1925年起源于美国，作为全球范围内影响力最大，参与人数最多的青少年活动，已经成功在全球5大洲100多个国家开展将近百年的历史，每年在全球范围拥有近5000万的学生参与。SPELLING
            BEE凭借其简单和公平的英语拼词活动，深受各国青少年和家庭的爱戴。学好英文向世界表达中国璀璨传统文化，将是中国地区青少年的使命，并且通过大会让每一位参与者增强学生的科学和人文素养的同时，真正以学生兴趣为导向的去了解学科知识的魅力，去探究自己未来大学的专业和未来人生的职业.
          </div>
        </div>
        <div class="anchorview" id="centerTitleTwo"></div>
        <div class="center-titleview">
          <div class="titleStr">大会主旨</div>
        </div>
        <div class="course-description-keynote">
          <div course-list-view class="list-backview">
            <div class="left-lineview"></div>
            <div class="center-contentstr">
              构建全球青少年人类命运共同体 增进全球青少年国际理解教育实施
            </div>
            <img
              src="https://cdn.spbcn.org/ghac/assets/feiyiprograms4_2_icon.png"
              alt=""
              class="right-img"
            />
          </div>
          <div course-list-view class="list-backview">
            <div class="left-lineview"></div>
            <div class="center-contentstr">
              发现每一位青少年的天赋激发学生对学科和未来职业和生活创新
            </div>
            <img
              src="https://cdn.spbcn.org/ghac/assets/feiyiprograms4_2_icon.png"
              alt=""
              class="right-img"
            />
          </div>
          <div course-list-view class="list-backview">
            <div class="left-lineview"></div>
            <div class="center-contentstr">
              弘扬全球各国家文化 加强世界文化表达
            </div>
            <img
              src="https://cdn.spbcn.org/ghac/assets/feiyiprograms4_2_icon.png"
              alt=""
              class="right-img"
            />
          </div>
        </div>
        <div class="center-titleview">
          <div class="titleStr">中国区大会选区</div>
        </div>
        <img
          src="https://cdn.spbcn.org/ghac/assets/feiyiprograms4_2_image.png"
          alt=""
          class="mapOfChina-img"
        />
        <div class="anchorview" id="centerTitleThree"></div>
        <div class="center-titleview" id="centerTitleThree">
          <div class="titleStr">大会形式</div>
        </div>
        <div class="assembly-situation-backview">
          <div course-list-view class="list-backview-description">
            <div class="left-lineview"></div>
            <div class="center-contentstr">大会对学生发展规划</div>
            <img
              src="https://cdn.spbcn.org/ghac/assets/feiyiprograms4_2_icon.png"
              alt=""
              class="right-img"
            />
          </div>
          <div class="situation-situation-back">
            <img
              src="https://cdn.spbcn.org/ghac/assets/feiyiprograms4_3_theme1.png"
              alt=""
              class="list-left-imgs"
            />
            <img
              src="https://cdn.spbcn.org/ghac/assets/feiyiprograms4_3_theme1.png"
              alt=""
              class="list-left-imgs"
            />
            <img
              src="https://cdn.spbcn.org/ghac/assets/feiyiprograms4_3_theme1.png"
              alt=""
              class="list-left-imgs"
            />
            <img
              src="https://cdn.spbcn.org/ghac/assets/feiyiprograms4_3_theme1.png"
              alt=""
              class="list-left-imgs"
            />
            <img
              src="https://cdn.spbcn.org/ghac/assets/feiyiprograms4_3_theme5.png"
              alt=""
              class="list-left-imgs5"
            />
          </div>
          <div course-list-view class="list-backview-description">
            <div class="left-lineview"></div>
            <div class="center-contentstr">八大主题年度挑战</div>
            <img
              src="https://cdn.spbcn.org/ghac/assets/feiyiprograms4_2_icon.png"
              alt=""
              class="right-img"
            />
          </div>
          <div class="center-challengesbackTwo">
            <div class="challeng-listback">
              <div class="challeng-menuback">
                <img
                  class="challengmenuImage"
                  src="https://cdn.spbcn.org/ghac/assets/feiyi_homepage_icon1.png"
                  alt=""
                />
                <img
                  class="challengTitleImage"
                  mode="heightFix"
                  src="https://cdn.spbcn.org/ghac/assets/feiyi_homepage_icontext1.png"
                  alt=""
                />
              </div>
              <div class="challeng-menuback">
                <img
                  class="challengmenuImage"
                  src="https://cdn.spbcn.org/ghac/assets/feiyi_homepage_icon5.png"
                  alt=""
                />
                <img
                  class="challengTitleImage"
                  mode="heightFix"
                  src="https://cdn.spbcn.org/ghac/assets/feiyi_homepage_icontext5.png"
                  alt=""
                />
              </div>
            </div>
            <div class="challeng-listback">
              <div class="challeng-menuback">
                <img
                  class="challengmenuImage"
                  src="https://cdn.spbcn.org/ghac/assets/feiyi_homepage_icon2.png"
                  alt=""
                />
                <img
                  class="challengTitleImage"
                  mode="heightFix"
                  src="https://cdn.spbcn.org/ghac/assets/feiyi_homepage_icontext2.png"
                  alt=""
                />
              </div>
              <div class="challeng-menuback">
                <img
                  class="challengmenuImage"
                  src="https://cdn.spbcn.org/ghac/assets/feiyi_homepage_icon6.png"
                  alt=""
                />
                <img
                  class="challengTitleImage"
                  mode="heightFix"
                  src="https://cdn.spbcn.org/ghac/assets/feiyi_homepage_icontext6.png"
                  alt=""
                />
              </div>
            </div>
            <div class="challeng-listback">
              <div class="challeng-menuback">
                <img
                  class="challengmenuImage"
                  src="https://cdn.spbcn.org/ghac/assets/feiyi_homepage_icon3.png"
                  alt=""
                />
                <img
                  class="challengTitleImage"
                  mode="heightFix"
                  src="https://cdn.spbcn.org/ghac/assets/feiyi_homepage_icontext3.png"
                  alt=""
                />
              </div>
              <div class="challeng-menuback">
                <img
                  class="challengmenuImage"
                  src="https://cdn.spbcn.org/ghac/assets/feiyi_homepage_icon7.png"
                  alt=""
                />
                <img
                  class="challengTitleImage"
                  mode="heightFix"
                  src="https://cdn.spbcn.org/ghac/assets/feiyi_homepage_icontext7.png"
                  alt=""
                />
              </div>
            </div>
            <div class="challeng-listback">
              <div class="challeng-menuback">
                <img
                  class="challengmenuImage"
                  src="https://cdn.spbcn.org/ghac/assets/feiyi_homepage_icon4.png"
                  alt=""
                />
                <img
                  class="challengTitleImage"
                  mode="heightFix"
                  src="https://cdn.spbcn.org/ghac/assets/feiyi_homepage_icontext4.png"
                  alt=""
                />
              </div>
              <div class="challeng-menuback">
                <img
                  class="challengmenuImage"
                  src="https://cdn.spbcn.org/ghac/assets/feiyi_homepage_icon8.png"
                  alt=""
                />
                <img
                  class="challengTitleImage"
                  mode="heightFix"
                  src="https://cdn.spbcn.org/ghac/assets/feiyi_homepage_icontext8.png"
                  alt=""
                />
              </div>
            </div>
            <div class="challeng-listback">
              <div class="challeng-menuback">
                <img
                  class="challengmenuImage"
                  src="https://cdn.spbcn.org/ghac/assets/feiyi_homepage_icon9.png"
                  alt=""
                />
                <img
                  class="challengTitleImage"
                  mode="heightFix"
                  src="https://cdn.spbcn.org/ghac/assets/feiyi_homepage_icontext9.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <!-- <img
            src="https://cdn.spbcn.org/ghac/assets/feiyiprograms1_2_icon.png"
            alt=""
            class="content-image"
          /> -->
        </div>
      </div>
      <!-- 底部内容 -->
    </div>
  </div>
</template>

<script>
// import Topbar from '@/components/Topbar.vue'
// import Footer from '@/components/Footer.vue'
import ghacBanner from '@/components/ghacBanner.vue'
export default {
  props: {
    dataHeight: {
      type: String,
      default: '100%'
    }
  },
  data () {
    return {
      isactive: 0,
      images: [{ url: 'https://cdn.spbcn.org/ghac/assets/feiyiprograms4_1_banner.png' }],
      menuList: [
        { title: '大会简介' },
        { title: '大会主旨' },
        { title: '大会形式' }
      ]
    }
  },
  methods: {
    fn: function (index) {
      this.isactive = index
      if (index === 0) {
        document
          .getElementById('centerTitleOne')
          .scrollIntoView({ block: 'start', behavior: 'smooth' })
      } else if (index === 1) {
        document
          .getElementById('centerTitleTwo')
          .scrollIntoView({ block: 'start', behavior: 'smooth' })
      } else {
        document
          .getElementById('centerTitleThree')
          .scrollIntoView({ block: 'start', behavior: 'smooth' })
      }
    }
  },
  components: {
    // Topbar,
    // Footer
    ghacBanner
  }
}
</script>

<style lang="less" scoped>
// .topbar {
//   z-index: 1000;
//   width: 1000px;
//   padding: 0;
//   margin: 0;
// }
.contnet-backview {
  width: 1000px;
  // background-color: black;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.top-backview {
  width: 100%;
  // padding-bottom: 10px;
  background-color: rgb(216, 216, 216);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.dibannerclass {
  width: 1000px;
  height: 470px;
  background-color: rgb(0, 0, 0);
  position: relative;
}
.title-backview {
  height: 60px;
  font-size: 32px;
  font-family: FZLBJW;
  font-weight: 900;
  text-align: center;
  line-height: 60px;
  padding: 0px 20px;
  color: white;
  border: 2px solid rgb(255, 255, 255);
}
.red-backview {
  width: 1000px;
  // height: 25px;
}
.anchorview {
  width: 1000px;
  height: 1px;
}
.red-lineview {
  width: 1000px;
  height: 10px;
  background-color: red;
  margin-top: 20px;
  margin-bottom: 10px;
}
.course-description-backview {
  width: 800px;
  margin-left: 60px;
  margin-top: 30px;
  padding: 50px 40px;
  background-color: #ffffff;
  border-radius: 30px;
}
.footerclass {
  width: 1000px;
}
// .banner-img-class {
//   width: 1000px;
//   height: 470px;
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
//   -moz-background-size: 100% 100%;
// }
.centerclass {
  width: 1000px;
  //   height: 1153px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  // background-image: url(https://cdn.spbcn.org/ghac/assets/feiyi_background.png);
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  // -moz-background-size: 100% 100%;
}
.center-titleview {
  width: 377px;
  height: 42px;
  margin-top: 30px;
  background: url(https://cdn.spbcn.org/ghac/assets/feiyi_homepage_title.png);
  background-size: 100%;
}

.titleStr {
  width: 280px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  font-size: 24px;
  font-family: FZLBJW;
  color: white;
  font-weight: bolder;
}
.topMenuBackview {
  width: 1000px;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  z-index: 110;
  // background-color: rgba(0, 0, 0, 0.5);
  // bottom: 0;
  // position: absolute;
}
.menu-backview {
  height: 40px;
  border-radius: 23px;
  padding: 0px 20px;
  font-size: 22px;
  font-weight: bolder;
  color: white;
  text-align: center;
  line-height: 40px;
  font-family: FZLBJW;
  background-color: black;
  // border: 3px solid rgb(255, 255, 255);
}
.description-contentStr {
  text-indent: 2em;
  margin-top: 10px;
  font-family: FZLBJW;
  font-size: 20px;
}
.course-description-keynote {
  width: 1000px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.list-backview {
  width: 800px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.list-backview-description {
  width: 800px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.left-lineview {
  width: 3px;
  height: 20px;
  background-color: #e50211;
}
.center-contentstr {
  padding: 5px 5px;
  font-size: 22px;
  font-weight: bolder;
  font-family: FZLBJW;
  color: #e50211;
}
.right-img {
  width: 25px;
  height: 16px;
}
.mapOfChina-img {
  width: 800px;
  height: 380px;
  margin-left: 100px;
  margin-top: 30px;
}
.assembly-situation-backview {
  width: 800px;
  background-color: white;
  border-radius: 20px;
  padding: 20px 30px;
  margin: 20px 70px;
}
.situation-situation-back {
  width: 800px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.list-left-imgs {
  width: 120px;
  height: 94px;
}
.list-left-imgs5 {
  width: 99px;
  height: 94px;
}
.content-image {
  width: 750px;
  height: 290px;
  margin-top: 20px;
  margin-left: 25px;
}
.center-challengesbackTwo {
  width: 800px;
  height: 400px;
  background-color: white;
  // border: 2px solid rgb(255, 0, 0);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.challeng-listback {
  width: 160px;
  height: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.challeng-menuback {
  width: 160px;
  height: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.challengmenuImage {
  width: 90px;
  height: 90px;
  background-size: 100%;
}
.challengTitleImage {
  margin-top: 10px;
  width: 110px;
  height: 20px;
}
.selectColor {
  background-color: #b71b26;
}
.el-carousel {
  width: 1000px;
  height: 470px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
::v-deep .el-carousel__button{
  width: 10px;
}
</style>
