<template>
  <div class="top-backview">
    <div class="contnet-backview">
      <!-- 顶部菜单 -->
      <!-- <Topbar class="topbar"></Topbar> -->
      <!-- banner -->
      <div class="dibannerclass">
        <el-carousel
          :interval="3000"
          indicator-position="none"
          id="el-carousel"
          :height="dataHeight"
        >
          <el-carousel-item v-for="item in images" :key="item.index">
            <img :src="item.url" alt="" class="banner-img-class" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="bannerbottom">
        <div class="title-backview">新时代的外交思想</div>
      </div>
      <!-- 中间内容部分 -->
      <div class="centerclass">
        <div class="center-titleback">
          十八大以来，深刻把握新时代中国和世界发展大势，在对外工作上进行一系列重大理论和实践创新，形成了新时代中国特色社会主义外交思想。
        </div>
        <div>
          <img
            src="https://cdn.spbcn.org/ghac/assets/aboutus1_1_title.png"
            alt=""
            class="center-spiritview-image"
          />
        </div>
        <div class="center-spiritview-content">
          <img
            src="https://cdn.spbcn.org/ghac/assets/aboutus1_1_text.png"
            alt=""
            class="center-spiritview-image2"
          />
        </div>
        <div class="center-spiritview-user">
          <img
            src="https://cdn.spbcn.org/ghac/assets/aboutus1_1_image.png"
            alt=""
            class="center-spiritview-image3"
          />
        </div>
        <div class="center-spiritview-bottomimg">
          <img
            src="https://cdn.spbcn.org/ghac/assets/aboutus_zhuangshi.png"
            alt=""
            class="center-spiritview-image4"
          />
        </div>
      </div>
      <!-- 底部内容 -->
    </div>
  </div>
</template>

<script>
// import Topbar from '@/components/Topbar.vue'
// import Footer from '@/components/Footer.vue'
export default {
  props: {
    dataHeight: {
      type: String,
      default: '100%'
    }
  },
  data () {
    return {
      images: [{ url: 'https://cdn.spbcn.org/ghac/assets/aboutus1_1_banner.png' }]
    }
  },
  components: {
    // Topbar,
    // Footer
  }
}
</script>

<style lang="less" scoped>
// .topbar {
//   z-index: 1000;
//   width: 1000px;
//   padding: 0;
//   margin: 0;
// }
.contnet-backview {
  width: 1000px;
  background-color: black;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.top-backview {
  width: 100%;
  // padding-bottom: 10px;
  background-color: rgb(216, 216, 216);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.dibannerclass {
  width: 1000px;
  height: 260px;
  z-index: 99;
  background-color: rgb(0, 0, 0);
}
.center-spiritview-image {
  margin-top: 10px;
  width: 860px;
  height: 53px;
}
.center-spiritview-image2 {
  width: 860px;
  height: 414px;
}
.center-spiritview-image3 {
  width: 860px;
  height: 333px;
}
.center-spiritview-image4 {
  width: 1000px;
  height: 90px;
}
.bannerbottom {
  width: 1000px;
  height: 100px;
  display: flex;
  background-color: black;
  align-items: center;
  justify-content: center;
}
.title-backview {
  width: 400px;
  height: 60px;
  font-size: 32px;
  font-family: FZLBJW;
  font-weight: 900;
  text-align: center;
  line-height: 60px;
  color: white;
  border: 2px solid rgb(255, 255, 255);
}
.centerclass {
  width: 1000px;
  // height: 1553px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(https://cdn.spbcn.org/ghac/assets/feiyi_background.png);
  padding: 0px;
  // padding-bottom: 0px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.banner-img-class {
  width: 1000px;
  height: 260px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.center-titleback {
  width: 780px;
  height: 88px;
  font-family: FZLBJW;
  font-size: 20px;
  padding: 10px 40px 30px 40px;
  text-indent: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  background-image: url(https://cdn.spbcn.org/ghac/assets/aboutus1_1_back.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.footerclass {
  width: 1000px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}
.center-spiritview {
  width: 860px;
  height: 53px;
  margin-top: 10px;
}
.center-spiritview-content {
  width: 860px;
  height: 414px;
  margin-top: 30px;
}
.center-spiritview-user {
  width: 860px;
  height: 333px;
  margin-top: 10px;
}
.center-spiritview-bottomimg {
  width: 1000px;
  height: 90px;
  margin-top: 20px;
  margin-bottom: -20px;
}
.el-carousel {
  width: 1000px;
  height: 260px;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
