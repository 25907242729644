
<template>
  <div class="top-backview">
    <div class="contnet-backview">
      <!-- 顶部菜单 -->
      <!-- <Topbar class="topbar"></Topbar> -->
      <!-- banner图 -->
      <div class="bannerclass">
        <el-carousel
          :interval="3000"
          indicator-position="none"
          id="el-carousel"
          :height="dataHeight"
        >
          <el-carousel-item v-for="item in images" :key="item.index">
            <img :src="item.url" alt="" class="banner-img-class" />
          </el-carousel-item>
        </el-carousel>
        <div class="topMenuBackview">
          <div
            class="menu-backview"
            v-for="(item, index) in menuList"
            :class="{ selectColor: index == isactive }"
            @click="fn(index)"
            :key="item.idnex"
          >
            {{ item.title }}
          </div>
        </div>
      </div>
      <!-- banner红线 -->
      <div style="background-color: #000;">
        <div class="bottom-redline"></div>
      </div>
      <div class="anchorview" id="centerTitleOne"></div>
      <!-- 中间部分内容 -->
      <div class="center-backview">
        <div class="center-titleview">
          <div class="titleStr">新时代的外交思想</div>
        </div>
        <div class="centerclass">
          <div class="center-titleback">
            十八大以来，深刻把握新时代中国和世界发展大势，在对外工作上进行一系列重大理论和实践创新，形成了新时代中国特色社会主义外交思想。
          </div>
          <div>
            <img
              src="https://cdn.spbcn.org/ghac/assets/aboutus1_1_title.png"
              alt=""
              class="center-spiritview-image"
            />
          </div>
          <div class="diplomaticThoughts">
            <div>
              <img
                src="https://cdn.spbcn.org/ghac/assets/aboutus1_1_imagenew.png"
                alt=""
                class="center-spiritview-image3"
              />
            </div>
            <div>
              <img
                src="https://cdn.spbcn.org/ghac/assets/aboutus1_1_text.png"
                alt=""
                class="center-spiritview-image2"
              />
            </div>
          </div>
          <div class="anchorview" id="centerTitleTwo"></div>
        </div>
        <div class="center-titleview">
          <div class="titleStr">国际理解教育与全球胜任力</div>
        </div>
        <div class="centerclass">
          <div class="educationContent-backview">
            <div class="center-titlestr">
              全球胜任力的概念最早可追溯至“国际理解”一词的提出。1974年，联合国教科文组织第18届大会通过的《关于教育促进国际理解、合作与和平及教育与人权和基本自由相联系的建议》提出国际理解教育，为全球胜任力的提出奠定了思想基础。2017年12月12日，经合组织教育与技能司和哈佛大学教育研究生院零点项目共同主持了《PISA全球素养框架：让我们的青年为一个包容和可持续的世界做好准备》发布会，提出全球胜任力的官方定义：青少年能够分析当地、全球和跨文化的问题，理解和欣赏他人的观点和世界观，与不同文化背景的人进行开放、得体和有效的互动，以及为集体福祉和可持续发展采取行动的能力。
            </div>
            <div>
              <img
                src="https://cdn.spbcn.org/ghac/assets/aboutus1_2_image.png"
                alt=""
                class="center-image1"
              />
            </div>
          </div>
          <div class="educationContent-backview">
            <div>
              <img
                src="https://cdn.spbcn.org/ghac/assets/aboutus1_2_text1.png"
                alt=""
                class="center-image2"
              />
            </div>
            <div class="center-titlestr2">
              “国际理解教育”理念是 1946
              年联合国教科文组织第一次大会上正式提出的。当时，刚刚结束不久的两次世界大战给人类带来巨大伤害，人们希望通过教育达成各国、各文化之间的理解，以实现和平。
            </div>
            <div class="center-titlestr2">
              1993年以雅克·德洛尔（Jacques
              Delors）为主席的国际21世纪教育委员会成立，历时3年的广泛调查和深入研究，于1996年向UNESCO提交了著名报告--《learning：the
              treasure within（教育：财富蕴藏其中）（Delors Report）》。
            </div>
            <div class="center-titlestr2">
              该报告系统回答了面对信息社会和知识经济时代挑战的教育使命，提出，面向21世纪人人必须掌握四种基本学习（即“教育的四个支柱”）。
            </div>
            <div>
              <img
                src="https://cdn.spbcn.org/ghac/assets/aboutus1_2_text2.png"
                alt=""
                class="center-image3"
              />
            </div>
          </div>
          <div class="anchorview" id="centerTitleThree"></div>
        </div>
        <div class="center-titleview">
          <div class="titleStr">PBL项目式学习与5C核心素养</div>
        </div>
        <div class="centerclass">
          <div class="center-titlestr">
            PBL全称为Project-based
            learning即基于项目的学习方式，也就是项目教学法。最早起源于20世纪50年代的医学教育，PBL教学法主要是指在教师的指导下，通过一个完整项目的选题、设计、执行、评估全过程，对于某个产品、服务项目进行全方位的学习理解。项目式学习为的是培养孩子/学生的批判性思维和解决问题的能力，过程中要培养团队合作能力，创新创造能力，自我管理能力，公众演讲和表达能力等等。为了实现这些目标，项目的设计有七大关键核心要素。
          </div>
          <div>
            <img
              src="https://cdn.spbcn.org/ghac/assets/aboutus1_3_image1.png"
              alt=""
              class="center-PBLimage1"
            />
          </div>
          <div class="center-titlestr">
            国际理解教育人文素养课程是在《21世纪核心素养5C模型研究报告（中文版）》成果的基础上实施的。这份报告吸纳了中国学者在相关领域的研究成果，并基于我国社会、经济、科技、教育发展需求，进一步追问“打下中国根基、兼具国际视野”的人应该具有哪些素养，提出了“21世纪核心素养5C模型”并搭建框架、阐述内涵。并在这个模式下，产生专家、导师、教练和学生四个角色。
          </div>
          <div>
            <img
              src="https://cdn.spbcn.org/ghac/assets/aboutus1_3_image2.png"
              alt=""
              class="center-PBLimage2"
            />
          </div>
        </div>
      </div>
      <div class="center-spiritview-bottomimg">
        <img
          src="https://cdn.spbcn.org/ghac/assets/aboutus_zhuangshi.png"
          alt=""
          class="center-spiritview-image4"
        />
      </div>
      <!-- 底部内容 -->
    </div>
  </div>
</template>

<script>
// import Topbar from '@/components/Topbar.vue'
// import Footer from '@/components/Footer.vue'
export default {
  props: {
    dataHeight: {
      type: String,
      default: '100%'
    }
  },
  data () {
    return {
      isactive: 0,
      images: [{ url: 'https://cdn.spbcn.org/ghac/assets/feiyi_bannernew.png' }],
      menuList: [
        { title: '新时代的外交思想' },
        { title: '国际理解教育与全球胜任力' },
        { title: 'PBL项目式学习与5C核心素养' }
      ]
    }
  },
  methods: {
    fn: function (index) {
      this.isactive = index
      if (index === 0) {
        document
          .getElementById('centerTitleOne')
          .scrollIntoView({ block: 'start', behavior: 'smooth' })
      } else if (index === 1) {
        document
          .getElementById('centerTitleTwo')
          .scrollIntoView({ block: 'start', behavior: 'smooth' })
      } else {
        document
          .getElementById('centerTitleThree')
          .scrollIntoView({ block: 'start', behavior: 'smooth' })
      }
    }
  },
  components: {
    // Topbar,
    // Footer
  }
}
</script>

<style lang="less" scoped>
.marginTop {
  margin-top: 30px;
}
// .topbar {
//   width: 1000px;
//   padding: 0;
//   margin: 0;
//   z-index: 1000;
// }
// .top-backview {
//   width: 100%;
//   padding-bottom: 10px;
//   background-color: rgb(216, 216, 216);
//   display: flex;
//   align-items: center;
//   flex-direction: column;
// }
.contnet-backview {
  width: 1000px;
  // background-color: black;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  background-color: rgba(0, 0, 0, 0.15);
}

.bannerclass {
  width: 1000px;
  height: 470px;
  background-color: rgb(0, 0, 0);
  position: relative;
}
.bottom-redline {
  width: 1000px;
  height: 10px;
  background-color: red;
  margin-top: 20px;
  margin-bottom: 10px;
}
.center-backview {
  width: 1000px;
  // background-image: url(https://cdn.spbcn.org/ghac/assets/feiyi_background.png);
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  // -moz-background-size: 100% 100%;
  padding: 0px 0px 20px 0px;
}
.centerclass {
  width: 1000px;
  // height: 1553px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.topMenuBackview {
  width: 1000px;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  z-index: 110;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  position: absolute;
}
.menu-backview {
  height: 40px;
  border-radius: 23px;
  padding: 0px 20px;
  font-size: 22px;
  font-weight: bolder;
  color: white;
  text-align: center;
  line-height: 40px;
  font-family: FZLBJW;
  background-color: black;
  border: 3px solid rgb(255, 255, 255);
}
.selectColor {
  background-color: #b71b26;
}
.footerclass {
  width: 1000px;
}
.banner-img-class {
  width: 1000px;
  height: 470px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.center-titleview {
  width: 450px;
  height: 50px;
  background: url(https://cdn.spbcn.org/ghac/assets/feiyi_homepage_title.png);
  background-size: 100%;
}
.titleStr {
  width: 400px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 24px;
  margin-top: 20px;
  color: white;
  font-weight: bolder;
  font-family: FZLBJW;
}
.educationContent-backview {
  width: 840px;
  padding: 20px;
  background-color: white;
  border-radius: 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.center-titleback {
  width: 780px;
  height: 88px;
  font-family: FZLBJW;
  font-size: 20px;
  padding: 10px 40px 30px 40px;
  text-indent: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  background-image: url(https://cdn.spbcn.org/ghac/assets/aboutus1_1_back.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.diplomaticThoughts {
  width: 800px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.center-spiritview-image {
  margin-top: 10px;
  width: 860px;
  height: 53px;
}
.center-spiritview-image2 {
  width: 550px;
  height: 265px;
  margin-left: 15px;
}
.center-spiritview-image3 {
  width: 265px;
  height: 265px;
}
.center-spiritview-bottomimg {
  width: 1000px;
  height: 90px;
}
.center-spiritview-image4 {
  width: 1000px;
  height: 90px;
}
.center-titlestr {
  margin-top: 10px;
  width: 800px;
  text-indent: 2em;
  font-size: 18px;
  font-family: FZLBJW;
  color: rgb(41, 41, 41);
}
.center-titlestr2 {
  width: 800px;
  text-indent: 2em;
  font-size: 18px;
  font-family: FZLBJW;
  color: rgb(41, 41, 41);
}
.center-image1 {
  width: 800px;
  height: 142px;
  margin-top: 20px;
}
.center-image2 {
  width: 400px;
  height: 31px;
  margin-top: 30px;
  margin-bottom: 5px;
}
.center-image3 {
  width: 875px;
  height: 403px;
  margin-left: 5px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.center-PBLimage1 {
  width: 880px;
  height: 279px;
  margin-top: 20px;
}
.center-PBLimage2 {
  margin-top: 30px;
  width: 880px;
  height: 320px;
}
.el-carousel {
  width: 1000px;
  height: 470px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}
.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
