<template>
  <div class="top-backview">
    <div class="contnet-backview">
      <!-- 顶部菜单 -->
      <!-- <Topbar class="topbar"></Topbar> -->
      <!-- banner -->
      <div class="dibannerclass">
        <el-carousel :interval="3000" :height="dataHeight">
          <el-carousel-item v-for="item in images" :key="item.index">
            <img :src="item.url" alt="" class="banner-img-class" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="bannerbottom">
        <div class="title-backview">学校服务</div>
      </div>
      <!-- 中间内容部分 -->
      <div class="centerclass">
        <div class="center-titleview">
          <div class="titleStr">课程监管与服务</div>
        </div>
        <div class="course-content-backview">
          <div
            class="course-list-backview"
            v-for="item in contentList"
            :key="item.index"
          >
            <img :src="item.numImg" alt="" class="num-img-class" />
            <div class="oneline-view"></div>
            <img :src="item.conImg" alt="" class="content-image" />
            <div class="twoline-view"></div>
            <div class="right-content-view">
              <div class="right-top-str">{{ item.conTitleStr }}</div>
              <div class="right-bottom-str">{{ item.contentStr }}</div>
            </div>
          </div>
        </div>
        <div class="content-listTabview">
          <div
            :class="[leftIsSelect == 1 ? 'tabview-class' : 'tabview-unclass']"
            @click="leftClicked"
          >
            学校荣誉
          </div>
          <div
            :class="[leftIsSelect == 0 ? 'tabview-class' : 'tabview-unclass']"
            @click="rightClicked"
          >
            学生荣誉
          </div>
        </div>
        <div class="content-backview" v-if="leftIsSelect === 1">
          <div class="content-rowView">
            <img
              class="content-leftimg"
              src="https://cdn.spbcn.org/ghac/assets/aboutus4_icon1.png"
              width="100px"
            />
            <div class="content-lineview"></div>
            <div class="content-rightStr">
              颁发《UIE国际理解教育非遗国际传承示范学校》
            </div>
          </div>
          <div class="content-rowView">
            <img
              class="content-leftimg"
              src="https://cdn.spbcn.org/ghac/assets/aboutus4_icon2.png"
              width="100px"
            />
            <div class="content-lineview"></div>
            <div class="content-rightStr">
              授权认证《UIE国际理解教育认证指导教师》
            </div>
          </div>
          <div class="content-rowView">
            <img
              class="content-leftimg"
              src="https://cdn.spbcn.org/ghac/assets/aboutus4_icon3.png"
              width="100px"
            />
            <div class="content-lineview"></div>
            <div class="content-rightStr">
              协助组织举办《校级的国际理解教育校园活动》
            </div>
          </div>
          <div class="content-rowView">
            <img
              class="content-leftimg"
              src="https://cdn.spbcn.org/ghac/assets/aboutus4_icon4.png"
              width="100px"
            />
            <div class="content-lineview"></div>
            <div class="content-rightStr">
              联合中图集团发布年度《校级国际理解教育成果白皮书》
            </div>
          </div>
        </div>

        <div class="content-backview" v-if="leftIsSelect === 0">
          <div class="content-rowView">
            <img
              class="content-leftimg"
              src="https://cdn.spbcn.org/ghac/assets/aboutus4_icon5.png"
              width="100px"
            />
            <div class="content-lineview"></div>
            <div class="content-rightStr">实现学生外语学科跨学科延伸舞台</div>
          </div>
          <div class="content-rowView">
            <img
              class="content-leftimg"
              src="https://cdn.spbcn.org/ghac/assets/aboutus4_icon6.png"
              width="100px"
            />
            <div class="content-lineview"></div>
            <div class="content-rightStr">
              成就学生成为中国民间青少年外交小使者
            </div>
          </div>
          <div class="content-rowView">
            <img
              class="content-leftimg"
              src="https://cdn.spbcn.org/ghac/assets/aboutus4_icon7.png"
              width="100px"
            />
            <div class="content-lineview"></div>
            <div class="content-rightStr">让学生渗透学科知识和探索职业发展</div>
          </div>
          <div class="content-rowView">
            <img
              class="content-leftimg"
              src="https://cdn.spbcn.org/ghac/assets/aboutus4_icon8.png"
              width="100px"
            />
            <div class="content-lineview"></div>
            <div class="content-rightStr">
              学生国际理解教育社团活动成果有机会在《英语周报》、《环球时报》和《中图校园国际理解教育成果白皮书》发表
            </div>
          </div>
        </div>
      </div>
      <!-- 底部内容 -->
    </div>
  </div>
</template>

<script>
// import Topbar from '@/components/Topbar.vue'
// import Footer from '@/components/Footer.vue'
export default {
  props: {
    dataHeight: {
      type: String,
      default: '100%'
    }
  },
  data () {
    return {
      leftIsSelect: 1,
      leftimageUrl: 'https://cdn.spbcn.org/ghac/assets/about_us4gai_title_on.png',
      rightimageUrl: 'https://cdn.spbcn.org/ghac/assets/about_us4gai_title_off.png',
      images: [{ url: 'https://cdn.spbcn.org/ghac/assets/aboutus4_banner.png' }],
      contentList: [
        {
          numImg: 'https://cdn.spbcn.org/ghac/assets/feiyiprograms1_3_icon1.png',
          conImg: 'https://cdn.spbcn.org/ghac/assets/feiyiprograms1_3_image1.png',
          conTitleStr: '第一阶段：课件研究',
          contentStr: '由聚知优国际理解教育研发专家团队研发'
        },
        {
          numImg: 'https://cdn.spbcn.org/ghac/assets/feiyiprograms1_3_icon2.png',
          conImg: 'https://cdn.spbcn.org/ghac/assets/feiyiprograms1_3_image2.png',
          conTitleStr: '第二阶段：教师选拔',
          contentStr: '来自于产研学基地在校优秀硕士研究生'
        },
        {
          numImg: 'https://cdn.spbcn.org/ghac/assets/feiyiprograms1_3_icon3.png',
          conImg: 'https://cdn.spbcn.org/ghac/assets/feiyiprograms1_3_image3.png',
          conTitleStr: '第三阶段：师资团队搭建',
          contentStr: '经过层层面试、试讲最终确认进校教师'
        },
        {
          numImg: 'https://cdn.spbcn.org/ghac/assets/feiyiprograms1_3_icon4.png',
          conImg: 'https://cdn.spbcn.org/ghac/assets/feiyiprograms1_3_image4.png',
          conTitleStr: '第四阶段：师资培训',
          contentStr:
            '进校教师们在入校前要进过5-10次试讲并根据老师们上课的风格对课件进行进一步加工'
        },
        {
          numImg: 'https://cdn.spbcn.org/ghac/assets/feiyiprograms1_3_icon5.png',
          conImg: 'https://cdn.spbcn.org/ghac/assets/feiyiprograms1_3_image5.png',
          conTitleStr: '第五阶段：进校授课与跟课监督',
          contentStr:
            '教师进校上课必须由赛区总负责人带着研发团队课程监督员一起进校督课，并对课堂进行评价'
        },
        {
          numImg: 'https://cdn.spbcn.org/ghac/assets/feiyiprograms1_3_icon6.png',
          conImg: 'https://cdn.spbcn.org/ghac/assets/feiyiprograms1_3_image6.png',
          conTitleStr: '第六阶段：向学校提供反馈并根据校方要求进行调整',
          contentStr: ''
        },
        {
          numImg: 'https://cdn.spbcn.org/ghac/assets/feiyiprograms1_3_icon7.png',
          conImg: 'https://cdn.spbcn.org/ghac/assets/feiyiprograms1_3_image7.png',
          conTitleStr:
            '第七阶段：配合学校做好课程总结、研学、校园微博物馆建设以及参加环球文遗小使者大会的准备工作',
          contentStr: ''
        }
      ]
    }
  },
  methods: {
    leftClicked () {
      this.leftIsSelect = 1
    },
    rightClicked () {
      this.leftIsSelect = 0
    }
  },
  components: {
    // Topbar,
    // Footer
  }
}
</script>

<style lang="less" scoped>
// .topbar {
//   z-index: 1000;
//   width: 1000px;
//   padding: 0;
//   margin: 0;
// }
.contnet-backview {
  width: 1000px;
  background-color: black;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.top-backview {
  width: 100%;
  // padding-bottom: 10px;
  background-color: rgb(216, 216, 216);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.dibannerclass {
  width: 1000px;
  height: 260px;
  z-index: 99;
  background-color: rgb(0, 0, 0);
}
.bannerbottom {
  width: 1000px;
  height: 100px;
  display: flex;
  background-color: black;
  align-items: center;
  justify-content: center;
}
.title-backview {
  height: 60px;
  font-size: 32px;
  font-family: FZLBJW;
  font-weight: 900;
  text-align: center;
  line-height: 60px;
  padding: 0px 20px;
  color: white;
  border: 2px solid rgb(255, 255, 255);
}
.centerclass {
  width: 1000px;
  // height: 750px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
  background-image: url(https://cdn.spbcn.org/ghac/assets/aboutus4_background.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.content-backview {
  width: 800px;
  margin: 30px 60px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
  padding: 40px;
  display: flex;
  flex-direction: column;
  border-radius: 30px;
  background-color: rgba(255, 255, 255, 0.7);
}
.content-rowView {
  width: 800px;
  height: 65px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.content-rightStr {
  width: 680px;
  font-size: 18px;
  font-family: FZLBJW;
  color: black;
}
.content-lineview {
  width: 4px;
  height: 30px;
  margin: 0px 10px;
  background-color: #dd1c1a;
}
.content-leftimg {
  width: 60px;
  height: 60px;
}
.footerclass {
  width: 1000px;
}
.el-carousel {
  width: 1000px;
  height: 260px;
}
.banner-img-class {
  width: 1000px;
  height: 260px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.center-titleview {
  width: 377px;
  height: 42px;
  margin-top: 30px;
  background: url(https://cdn.spbcn.org/ghac/assets/feiyi_homepage_title.png);
  background-size: 100%;
}

.titleStr {
  width: 240px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  font-size: 24px;
  font-family: FZLBJW;
  color: white;
  font-weight: bolder;
}

.course-content-backview {
  width: 800px;
  margin-left: 60px;
  margin-top: 30px;
  padding: 40px;
  // background-color: #ffffff;
  background-color: rgba(255,255,255,0.7);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.course-list-backview {
  width: 800px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 0px;
}
.num-img-class {
  width: 80px;
  height: 80px;
}

.oneline-view {
  width: 2px;
  height: 100px;
  background-color: rgb(114, 114, 114);
  margin: 0 10px;
}
.content-image {
  width: 240px;
  height: 103px;
}
.twoline-view {
  width: 4px;
  height: 50px;
  margin: 0 10px;
  background-color: #b81926;
}
.right-content-view {
  width: 350px;
  display: flex;
  flex-direction: column;
}
.right-top-str {
  width: 350px;
  font-size: 18px;
  font-family: FZLBJW;
  color: black;
  font-weight: 900;
}
.right-bottom-str {
  width: 350px;
  font-size: 16px;
  font-family: FZLBJW;
  color: rgb(48, 48, 48);
}
.content-listTabview {
  width: 1000px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
  justify-content: space-around;
}
.tabview-unclass {
  width: 315px;
  height: 42px;
  font-size: 22px;
  color: #ffffff;
  font-weight: 700;
  text-align: center;
  line-height: 42px;
  background: url(https://cdn.spbcn.org/ghac/assets/about_us4gai_title_off.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.tabview-class {
  width: 315px;
  height: 42px;
  font-size: 22px;
  color: #ffffff;
  font-weight: 700;
  text-align: center;
  line-height: 42px;
  background: url(https://cdn.spbcn.org/ghac/assets/about_us4gai_title_on.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
</style>
