<template>
  <div>
    <div class="contnet-backview">
      <ghacBanner></ghacBanner>
      <ghacTab :activeIndex="2"></ghacTab>
      <div class="centerclass">
        <div class="center-titleview">
          <div class="titleStr">评奖体系及流程细节 ｜ Evaluation System&Process Details</div>
        </div>
        <!-- <div class="third-title">流程细节</div> -->
        <div class="page_text">
          <div>
            大会活动分为开、闭幕式、环球大舞台、环球博览会、环球嘉年华、环球之夜社交晚宴6个项目板块。每支队伍所完成的探究报告、广告展板、PPT演示、舞台剧展示和工程作品共5项任务，将在以上6个项目板块中进行展示，并参与评审。组委会专业评审组及大众评审团，将根据不同的评分规则，对参演队伍提交完成的每一项任务进行打分。
          </div>
        </div>
        <div class="flow2">
          <div class="flow2-item" :class="currentIndex === i ? 'active' : ''" @mouseover="mouseoverFn(i)" v-for="(item, i) of flow2" :key="i">
            <img :src="item.img" alt="">
            <div class="title">{{ item.title }}</div>
          </div>
        </div>
        <div class="carousel-box" v-if="imgs.length">
          <!-- <el-carousel type="card" class="carousel" indicator-position="none" :interval="5000" arrow="never">
            <el-carousel-item v-for="(item,i) of imgs" :key="i">
              <div class="theme-banner" :style="'background-image: url(' + item + ');'"></div>
            </el-carousel-item>
          </el-carousel> -->
          <img :src="item" v-for="(item,i) of imgs" :key="i" class="img-item" />
        </div>
        <div class="mt-60" v-if="currentIndex === 0">
          <div class="third-title">探究报告评分规则</div>
          <div class="rules">
            <!--  -->
            <div class="rules3-1">
              <div class="rules3-1-text">选题明确、符合发布主题限定的探究范畴</div>
              <img class="img" src="https://cdn.spbcn.org/ghac/page/3/3.png" alt="">
              <div class="rules3-1-text">选题能够呼应联合国17个可持续发展目标中的一个或多个</div>
            </div>
            <div class="rules-center mb-60">
              <div>选题能够体现所选主题要解决的问题</div>
            </div>
            <!--  -->
            <div class="rules-center">
              <div>报告各部分齐全、格式正确、语句通顺流畅、语法标点正确</div>
            </div>
            <div class="rules3-1">
              <div class="rules3-1-text">
                <div class="l1">思路层次清晰，主题明确突出，概括全面准确，具备一定的广度和深度</div>
                <div class="l2">能够体现跨地区、跨文化的对比与探究</div>
              </div>
              <img class="img" src="https://cdn.spbcn.org/ghac/page/3/1.png" alt="">
              <div class="rules3-1-text">
                <div class="l1">引用文献的真实性、权威性、规范性</div>
                <div class="l2">探究方法科学，具备一定的挑战性;探究过程合理，具备一定的渐进性</div>
              </div>
            </div>
            <div class="rules-center mb-60">
              <div>能够体现跨学科知识的融合，以及对未来大学专业的初步探索</div>
            </div>
            <!--  -->
            <div class="rules3-1 mb-40">
              <div class="rules3-1-text">能够体现小组对探究主题的新收获和新见解</div>
              <img class="img" src="https://cdn.spbcn.org/ghac/page/3/2.png" alt="">
              <div class="rules3-1-text">选题具备一定的科学性、创新性和可行性</div>
            </div>
            <el-row class="mb-60">
              <el-col :span="8" v-for="(item, i) of list4" :key="i">
                <div class="score">{{ item }}</div>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="mt-60" v-if="currentIndex === 1">
          <div class="third-title">广告展板评分规则</div>
          <div class="rules">
            <!--  -->
            <div class="rules3-1">
              <div class="rules3-1-text">选题明确、符合发布主题限定的探究范畴</div>
              <img class="img" src="https://cdn.spbcn.org/ghac/page/3/3.png" alt="">
              <div class="rules3-1-text">选题能够呼应联合国17个可持续发展目标中的一个或多个</div>
            </div>
            <div class="rules-center mb-60">
              <div>设计理念突出，具有一定的深度</div>
            </div>
            <!--  -->
            <div class="rules-center">
              <div>所占空间、所用材质符合要求</div>
            </div>
            <div class="rules3-1">
              <div class="rules3-1-text">
                <div class="l1">条理清晰、符合逻辑、表达完整</div>
                <div class="l2">内容准确、有效使用可靠的参考资料</div>
              </div>
              <img class="img" src="https://cdn.spbcn.org/ghac/page/3/1.png" alt="">
              <div class="rules3-1-text">
                <div class="l1">素材选取恰当、符合所选主题</div>
                <div class="l2">整体色彩搭配和谐，具有美感</div>
              </div>
            </div>
            <div class="rules-center mb-60">
              <div>排版布局合理、书写正确、字迹美观</div>
            </div>
            <!--  -->
            <div class="rules3-1 mb-60">
              <div class="rules3-1-text">版式设计生动活泼，富有新意</div>
              <img class="img" src="https://cdn.spbcn.org/ghac/page/3/12.png" alt="">
              <div class="rules3-1-text">表现手法创新和视觉冲击强</div>
            </div>
            <el-row class="mb-60">
              <el-col :span="8" v-for="(item, i) of list4" :key="i">
                <div class="score">{{ item }}</div>
              </el-col>
            </el-row>
          </div>

        </div>
        <div class="mt-60" v-if="currentIndex === 2">
          <div class="third-title">PPT演示评分规则</div>
          <div class="rules">
            <!--  -->
            <div class="rules3-1 mb-60">
              <div class="rules3-1-text">选题明确、符合发布主题限定的探究范畴</div>
              <img class="img" src="https://cdn.spbcn.org/ghac/page/3/5.png" alt="">
              <div class="rules3-1-text">选题能够呼应联合国17个可持续发展目标中的一个或多个</div>
            </div>
            <!--  -->
            <div class="rules3-1 mb-60">
              <div class="rules3-1-text">
                <div class="l1">思路层次清晰，主题明确突出，概括全面准确，具备一定的广度和深度</div>
                <div class="l2">能够体现跨地区、跨文化的对比与探究</div>
              </div>
              <img class="img" src="https://cdn.spbcn.org/ghac/page/3/4.png" alt="">
              <div class="rules3-1-text">
                <div class="l1">结构合理、逻辑顺畅，内容之间具有层次性和连贯性</div>
                <div class="l2">能够恰当运用文本、图片、表格、图表、图形、动画、音视频等表现工具</div>
              </div>
            </div>
            <!--  -->
            <div class="rules3-1">
              <div class="rules3-1-text">版式设计生动活泼，富有新意，视觉效果好，有较强的表现力和感染力</div>
              <img class="img" src="https://cdn.spbcn.org/ghac/page/3/6.png" alt="">
              <div class="rules3-1-text">语言表达得体、流利，讲述具有感染力</div>
            </div>
            <div class="rules-center mb-60">
              <div>与舞台剧配合得当，相得益彰</div>
            </div>
            <el-row class="mb-60">
              <el-col :span="8" v-for="(item, i) of list4" :key="i">
                <div class="score">{{ item }}</div>
              </el-col>
            </el-row>

          </div>
        </div>
        <div class="mt-60" v-if="currentIndex === 3">
          <div class="third-title">舞台剧展示评分规则</div>
          <div class="rules">
            <!--  -->
            <div class="rules3-1">
              <div class="rules3-1-text">展演内容能够体现所选探究主题</div>
              <img class="img" src="https://cdn.spbcn.org/ghac/page/3/10.png" alt="">
              <div class="rules3-1-text">展演内容能够呼应联合国17个可持续发展目标中的一个或多个</div>
            </div>
            <div class="rules-center mb-60">
              <div>内容详实准确，富有创意，扣人心弦</div>
            </div>
            <!--  -->
            <div class="rules3-1">
              <div class="rules3-1-text">语言结构严谨、构思巧妙、简练流畅</div>
              <img class="img" src="https://cdn.spbcn.org/ghac/page/3/9.png" alt="">
              <div class="rules3-1-text">发音标准、口齿清晰、声音洪亮、语速恰当</div>
            </div>
            <div class="rules-center mb-60">
              <div>语音语调、节奏语气富于变化，与内容搭配得当</div>
            </div>
            <!--  -->
            <div class="rules3-1 mb-60">
              <div class="rules3-1-text">
                <div class="l1">叙事合理、表演自然、人物角色鲜明</div>
                <div class="l2">有效运用姿态、动作、手势、表情，富有感染力</div>
              </div>
              <img class="img" src="https://cdn.spbcn.org/ghac/page/3/7.png" alt="">
              <div class="rules3-1-text">
                <div class="l1">舞台表现力强，具有艺术观赏性</div>
                <div class="l2">团队之间配合默契、成员与道具、音乐之间相得益彰</div>
              </div>
            </div>
            <!--  -->
            <div class="rules3-1 mb-60">
              <div class="rules3-1-text">道具具有美感、造型设计优美，色彩搭配恰当，制作精良</div>
              <img class="img" src="https://cdn.spbcn.org/ghac/page/3/8.png" alt="">
              <div class="rules3-1-text">作品贴合探究主题，富有创意</div>
            </div>
            <el-row class="mb-60">
              <el-col :span="8" v-for="(item, i) of list4" :key="i">
                <div class="score">{{ item }}</div>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="mt-60" v-if="currentIndex === 4">
          <div class="third-title">工程作品评分规则</div>
          <div class="rules">
            <!--  -->
            <div class="rules3-1 mb-60">
              <div class="rules3-1-text">选题明确、符合发布主题限定的探究范畴</div>
              <img class="img" src="https://cdn.spbcn.org/ghac/page/3/5.png" alt="">
              <div class="rules3-1-text">选题能够呼应联合国17个可持续发展目标中的一个或多个</div>
            </div>
            <!--  -->
            <div class="rules3-1">
              <div class="rules3-1-text">创意新颖、 形式活泼</div>
              <img class="img" src="https://cdn.spbcn.org/ghac/page/3/16.png" alt="">
              <div class="rules3-1-text">完全由团队手工制作而非组装，符合原创性</div>
            </div>
            <div class="rules-center mb-60">
              <div>所占空间、所用材质符合要求</div>
            </div>
            <!--  -->
            <div class="rules3-1 mb-60">
              <div class="rules3-1-text">外形别致、制作精良</div>
              <img class="img" src="https://cdn.spbcn.org/ghac/page/3/15.png" alt="">
              <div class="rules3-1-text">色彩搭配得当、具有美感</div>
            </div>
            <el-row class="mb-60">
              <el-col :span="8" v-for="(item, i) of list4" :key="i">
                <div class="score">{{ item }}</div>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="center-titleview">
          <div class="titleStr">大会奖项和荣誉 ｜ Awards and Honors</div>
        </div>
        <div class="red-bg-con">
          <div class="red-bg">大会根据参加展演的队伍数量，以及团队和个人的表现，按比例设置团队和个人奖项。</div>
        </div>
        <div class="sec-title">年度团体奖项</div>
        <el-row>
          <el-col :offset="3" :span="6" class="honor mt-60">
            <img src="https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_DHJX_03.png" alt="">
            <div class="tip">意向图 奖杯以实物为准</div>
            <div class="honor-title">年度学校银奖</div>
          </el-col>
          <el-col :span="6" class="honor">
            <img src="https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_DHJX_02.png" alt="">
            <div class="tip">意向图 奖杯以实物为准</div>
            <div class="honor-title">年度学校金奖</div>
          </el-col>
          <el-col :span="6" class="honor mt-60">
            <img src="https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_DHJX_01.png" alt="">
            <div class="tip">意向图 奖杯以实物为准</div>
            <div class="honor-title">年度学校铜奖</div>
          </el-col>
        </el-row>
      </div>
      <div class="sec-title">年度教师奖项</div>
      <div class="centerclass">
        <el-row>
          <el-col :offset="3" :span="6" class="honor">
            <img class="honor-p" src="https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_DHJX_04.png" alt="">
          </el-col>
          <el-col :span="6" class="honor">
            <img class="honor-p" src="https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_DHJX_05.png" alt="">
          </el-col>
          <el-col :span="6" class="honor">
            <img class="honor-p" src="https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_DHJX_06.png" alt="">
          </el-col>
        </el-row>
      </div>
      <div class="honor-bg">
        <el-row class="centerclass" style="margin: 0 auto;">
          <el-col :span="8" class="honor">
            <div class="honor-title">年度教师银奖</div>
          </el-col>
          <el-col :span="8" class="honor">
            <div class="honor-title">年度教师金奖</div>
          </el-col>
          <el-col :span="8" class="honor">
            <div class="honor-title">年度教师铜奖</div>
          </el-col>
        </el-row>
      </div>
      <div class="sec-title">年度个人奖项</div>
      <div class="centerclass">
        <el-row>
          <el-col :offset="3" :span="6" class="honor">
            <img class="honor-p" src="https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_DHJX_07.png" alt="">
          </el-col>
          <el-col :span="6" class="honor">
            <img class="honor-p" src="https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_DHJX_08.png" alt="">
          </el-col>
          <el-col :span="6" class="honor">
            <img class="honor-p" src="https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_DHJX_09.png" alt="">
          </el-col>
        </el-row>
      </div>
      <div class="honor-bg">
        <el-row class="centerclass" style="margin: 0 auto;">
          <el-col :offset="3" :span="6" class="honor">
            <div class="honor-title1">
              年度最佳文遗<br/>小匠人奖
            </div>
          </el-col>
          <el-col :span="6" class="honor">
            <div class="honor-title1">
              年度最佳文遗<br/>小演说家奖
            </div>
          </el-col>
          <el-col :span="6" class="honor">
            <div class="honor-title1">
              年度最佳文遗<br/>小作家奖
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="centerclass">
        <el-row>
          <el-col :offset="6" :span="6" class="honor">
            <img class="honor-p" src="https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_DHJX_10.png" alt="">
          </el-col>
          <el-col :span="6" class="honor">
            <img class="honor-p" src="https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_DHJX_11.png" alt="">
          </el-col>
        </el-row>
      </div>
      <div class="honor-bg">
        <el-row class="centerclass" style="margin: 0 auto;">
          <el-col :offset="6" :span="6" class="honor">
            <div class="honor-title1">
              年度最佳文遗<br/>演艺奖
            </div>
          </el-col>
          <el-col :span="6" class="honor">
            <div class="honor-title1">
              年度最佳文遗<br/>设计奖
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import ghacBanner from '../../components/ghacBanner2.vue'
import ghacTab from '../../components/ghacTab.vue'
export default {
  components: {
    ghacTab,
    ghacBanner
  },
  data () {
    return {
      currentIndex: 0,
      imgs: [],
      flow2: [ // 流程细节
        {
          img: 'https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_LCXJ_ICON_01.png',
          title: '探究报告',
          imgs: []
        },
        {
          img: 'https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_LCXJ_ICON_02.png',
          title: '广告展板',
          imgs: [
            'https://cdn.spbcn.org/ghac/awards/ggzb/1.jpg',
            'https://cdn.spbcn.org/ghac/awards/ggzb/2.jpg',
            'https://cdn.spbcn.org/ghac/awards/ggzb/3.jpg',
            'https://cdn.spbcn.org/ghac/awards/ggzb/4.jpg'
          ]
        },
        {
          img: 'https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_LCXJ_ICON_03.png',
          title: 'PPT演示',
          imgs: [
            'https://cdn.spbcn.org/ghac/awards/pptys/1.jpg?v=20240705',
            'https://cdn.spbcn.org/ghac/awards/pptys/2.jpg?v=20240705',
            'https://cdn.spbcn.org/ghac/awards/pptys/3.jpg',
            'https://cdn.spbcn.org/ghac/awards/pptys/4.jpg',
            'https://cdn.spbcn.org/ghac/awards/pptys/5.jpg'
          ]
        },
        {
          img: 'https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_LCXJ_ICON_04.png',
          title: '舞台剧展示',
          imgs: [
            'https://cdn.spbcn.org/ghac/awards/wtjzs/1.jpg?v=20240705',
            'https://cdn.spbcn.org/ghac/awards/wtjzs/2.jpg',
            'https://cdn.spbcn.org/ghac/awards/wtjzs/3.jpg',
            'https://cdn.spbcn.org/ghac/awards/wtjzs/4.jpg',
            'https://cdn.spbcn.org/ghac/awards/wtjzs/5.jpg',
            'https://cdn.spbcn.org/ghac/awards/wtjzs/6.jpg'
          ]
        },
        {
          img: 'https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_LCXJ_ICON_05.png',
          title: '工程作品',
          imgs: [
            'https://cdn.spbcn.org/ghac/awards/gczp/1.jpg',
            'https://cdn.spbcn.org/ghac/awards/gczp/2.jpg',
            'https://cdn.spbcn.org/ghac/awards/gczp/3.jpg',
            'https://cdn.spbcn.org/ghac/awards/gczp/4.jpg',
            'https://cdn.spbcn.org/ghac/awards/gczp/5.jpg'
          ]
        }
      ],
      list4: ['A-优秀: 10-9分', 'B-良好: 8-6分', 'C-提升: 5-4分']

    }
  },
  methods: {
    mouseoverFn(i) {
      this.currentIndex = i
      this.imgs = this.flow2[i].imgs
    }
  }
}
</script>
<style lang="less" scoped>
.contnet-backview {
  // width: 1000px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-bottom: 80px;
}
::v-deep .el-carousel__button{
  width: 10px;
}
.center-titleview {
  display: inline-block;
  padding-right: 100px;
  height: 42px;
  margin-top: 50px;
  background: url(https://cdn.spbcn.org/ghac/assets/feiyi_homepage_title.png);
  background-size: auto 100%;
  background-position: right;
  background-repeat: no-repeat;
}

.titleStr {
  // width: 600px;
  height: 42px;
  line-height: 42px;
  // text-align: center;
  font-size: 24px;
  font-family: FZLBJW;
  color: white;
  font-weight: bolder;
  padding-left: 30px;
  background-color: #b81b26;
}
.red-bg-con{
  text-align: center;
}
.red-bg{
  height: 50px;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 50px;
  line-height: 50px;
  color: #fff;
  font-family: regular;
  font-size: 18px;
  background-color: #b81c25;
  display: inline-block;
  margin: 40px auto 20px;
}
.honor{
  text-align: center;
  img{
    width: 50%;
  }
}
.honor-title{
  width: 260px;
  height: 42px;
  background-image: url(https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_DHJX_00.png);
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
  line-height: 42px;
  font-family: regular;
  font-size: 18px;
  color: #fff;
  text-align: center;
  margin: 10px auto 40px;
}
.mt-60{
  margin-top: 60px;
}
.honor-p{
  width: 300px;
}
.honor-bg{
  width: 100%;
  height: 64px;
  background: #eadfc7;
  margin-top: 20px;
  margin-bottom: 40px;
}
.honor-title1{
  text-align: center;
  width: 200px;
  margin: 0 auto;
  font-family: regular;
  font-size: 18px;
  color: #000;
  padding-top: 10px;
}
.sec-title {
  width: 260px;
  height: 38px;
  background-image: url(https://cdn.spbcn.org/ghac/ghac_button_highlight.png);
  background-size: 100% 100%;
  text-align: center;
  color: #fff;
  font-family: hzgb;
  font-size: 18px;
  line-height: 38px;
  margin: 40px auto 30px;
  letter-spacing: 2px;
}
.tip{
  font-size: 12px;
  color: #a1a1a1;
}
.centerclass {
  width: 1000px;
}

.third-title {
  width: 240px;
  height: 46px;
  padding-left: 46px;
  box-sizing: border-box;
  background-image: url(https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_01.png);
  background-size: auto 100%;
  background-repeat: no-repeat;
  font-family: hzgb;
  color: #fff;
  font-size: 14px;
  text-align: center;
  line-height: 46px;
  letter-spacing: 3px;
}
.page_text {
  font-size: 20px;
  font-family: FZLBJW;
  line-height: 32px;
  margin-top: 20px;

  div {
    text-indent: 2em;
  }
}
.flow2 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 40px;
  .flow2-item {
    text-align: center;
    position: relative;
    cursor: pointer;

    &:after {
      content: '';
      display: block;
      width: 100px;
      height: 2px;
      background-color: #000;
      position: absolute;
      right: -100px;
      top: 60px;
    }

    &:last-child {
      &::after {
        display: none;
      }
    }

    img {
      width: 120px;
      margin-bottom: 10px;
    }

    .title {
      font-size: 22px;
      font-family: regular;
      color: #000;
      padding: 2px 0 6px;
    }
    &:hover .title, &.active .title{
      color: #fff;
      background: #b81b26;
      border-radius: 8px;
    }
  }
}
.rules {
  margin: 40px 0;

  .rules3-1 {
    display: flex;
    align-items: center;
    justify-content: center;

    .img {
      width: 300px;
    }
  }

  .rules3-1-text {
    width: 250px;
    margin: 0 20px;
    font-size: 16px;

    .l1 {
      margin-bottom: 100px;
    }
  }

  .rules-center {
    div {
      width: 250px;
      margin: 20px auto;
      font-size: 16px;
      text-align: left;
    }
  }
}
.score {
  width: 200px;
  color: #fff;
  height: 26px;
  line-height: 26px;
  text-align: center;
  border-radius: 26px;
  background-color: #b81c25;
  margin: 0 auto;
  font-size: 14px;
}
.mb-60{
  margin-bottom: 60px;
}
.mb-40{
  margin-bottom: 60px;
}
.carousel-box{
  width: 100%;
  margin: 40px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .img-item{
    width: 150px;
    height: auto;
    margin: 0 10px;
    &:hover{
      animation:mymove .5s forwards;
      -webkit-animation:mymove .5s forwards; /*Safari and Chrome*/
    }
  }
}
@keyframes mymove
{
  from {width:150px;}
  to {width:250px;}
}

@-webkit-keyframes mymove /*Safari and Chrome*/
{
  from {width:150px;}
  to {width:250px;}
}
.carousel{
  height: 300px;
}
.theme-banner{
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-size: contain;
  background-color: #000;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
