<!-- 社团课程 -->
<template>
  <div>
    <div class="theme">
      <div class="center-container theme-flex">
        <div class="theme-title">八大主题</div>
        <div class="theme-list">
          <template v-for="item,index of themes">
            <div :key="item.id" class="theme-item" :class="item.active ? 'active' : ''" @click="changeThemeItem(index)">
              <img :src="item.typeLogo" alt="">
              <div class="white-bg"></div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="type-bar">
      <div class="center-container type-flex">
        <div class="type-item" :class="item.active ? 'active' : ''" v-for="item,index of types" :key="item.id" @click="changeTypeItem(index)">{{item.typeName}}</div>
      </div>
    </div>
    <div class="center-container">
      <!-- 轮播 -->
      <div class="carousel" v-if="bannerData.length">
        <el-carousel
          :interval="5000"
          ref="carousel"
          arrow="never"
          indicator-position="none"
          @setActiveItem="setActiveItemFn"
          @change="bannerChange"
        >
          <el-carousel-item v-for="item in bannerData" :key="item.id">
            <div class="page-banner-course" :style="'background-image: url('+ item.courseThumbnail +');'">
              <div class="page-banner-left">
                <div class="h2">{{item.courseTypeStr}}</div>
                <div class="h1">{{item.courseName}}</div>
                <div class="h2">主讲人：{{ item.keynote }}</div>
                <div class="btn" v-if="item.isFree === 0" @click="courseDetailClicked(item)">进入学习</div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
        <div class="page-banner-right">
          <ul>
            <div class="h1">特惠课程</div>
            <li @click="handleToChangeBanner(i)" v-for="(item,i) in bannerData" :key="item.id">
              <div class="h2" :class="activeBannerIndex === i ? 'active' : ''">{{ item.courseTypeStr }}-{{item.courseName}}</div>
            </li>
          </ul>
        </div>
      </div>
      <!-- 课程列表 -->
      <div class="course">
        <div class="search">
          <div>
            <!-- <span v-if="area" class="search-back" @click="back">>{{ area }}</span> -->
          </div>
          <div>
            <span>课程类型：</span>
            <el-select v-model="courseType" placeholder="请选择" clearable @change="getCourses" @clear="getCourses" style="margin-right: 20px;">
              <el-option label="视频课程" value="2"></el-option>
              <el-option label="课件" value="1"></el-option>
            </el-select>
            <span>区域：</span>
            <el-select v-model="areaId" placeholder="请选择" @change="getCourses" @clear="getCourses">
              <el-option :label="opt.typeName" :value="opt.id" v-for="opt of areaList" :key="opt.id"></el-option>
            </el-select>
          </div>
        </div>
        <template v-if="courseList.length">
          <div class="course-list">
            <div class="course-item" v-for="(item,index) of courseList" :key="index">
              <courseItem :item="item" :outSide="true" @refresh="getCourses"></courseItem>
            </div>
          </div>
          <div class="page">
            <el-pagination
              background
              layout="prev, pager, next"
              :current-page="pageNum"
              :page-size="pageSize"
              :total="total"
              @current-change="handleCurrentChange"
            ></el-pagination>
          </div>
        </template>
        <div v-else class="default">
          <el-empty description="暂未上传课程"></el-empty>
        </div>
      </div>
      <div class="upload" @click="handleToDownload">下载安装环球文遗官方课件插件</div>
    </div>
  </div>
</template>
<script>
import courseItem from '../../components/courseItem.vue'
export default {
  components: {
    courseItem
  },
  data () {
    return {
      themes: [], // 八大主题
      types: [], // 华夏、海外
      // activeTheme: {}, // 选中的主题
      // activeType: {}, // 选中海外、华夏
      activeTypeIndex: 0,
      activeArea: {}, // 选中的区域 吴越、巴蜀等
      // area: '',
      themeId: '', // 选中的主题id
      cId: '', // 选中海外、华夏id
      areaId: '',
      value: '',
      activeBannerIndex: 0,
      pageNum: 1,
      pageSize: 9,
      courseList: [],
      bannerData: [],
      courseType: '',
      total: 0,
      areaList: []
    }
  },
  created () {
    this.themeId = this.$route.query.themeId
    this.cId = this.$route.query.cId
    this.areaId = this.$route.query.areaId

    this.getClasses(1) // 8主题
    this.getClasses(2)
  },
  methods: {
    back() {
      this.$router.push('/classesHome')
    },
    getClasses (type) {
      this.$http.get('/external/querySeeEightTopics', { params: { type } }).then(res => {
        if (res.code === 200) {
          if (type === 1) {
            res.data.find(item => item.id === this.themeId).active = true
            this.themes = res.data
            // this.activeTheme = res.data.find(item => item.id === this.themeId)
          } else if (type === 2) {
            res.data.find(item => item.id === this.cId).active = true
            this.types = res.data
            // const activeType = res.data.find(item => item.id === this.cId)
            this.activeTypeIndex = res.data.findIndex(item => item.id === this.cId)
            this.areaList = this.types[this.activeTypeIndex].childrenCourseType
            // this.area = activeType.childrenCourseType.find(item => item.id === this.areaId).typeName
            this.getCourses()
            this.getBanners()
          }
        }
      })
    },
    handleToPlay () {
      this.$router.push('/classesPreview')
    },
    // 菜单点击事件
    courseDetailClicked (item) {
      if (item.isFree !== 0) {
        this.$message.error('暂未解锁')
      } else {
        if (!item.courseUrl) {
          this.$message.warning('暂未上传课程')
        } else {
          const courseDataType = this.$xcUtils.matchFileType(item.courseUrl)
          console.log('courseDataType', courseDataType)
          if (courseDataType === 'pptx' || courseDataType === 'ppt') {
            const url = encodeURIComponent(item.courseUrl)
            this.$router.push({
              path: '/cebsbCourseManage/cebso_onlineBrowsingPDF',
              query: {
                // office
                fileUrl: 'http://view.officeapps.live.com/op/view.aspx?src=' + url
                // fileUrl: item.courseUrl
              }
            })
          } else if (courseDataType === 'video') {
            this.$router.push({
              path: '/cebsbCourseManage/cebso_onlineBrowsingVideo',
              query: {
                fileUrl: item.courseUrl
              }
            })
          } else {
            this.$router.push({
              path: '/cebsbCourseManage/cebso_onlineBrowsingPDF',
              query: {
                fileUrl: '/pdf/web/viewer.html?file=' + item.courseUrl
              }
            })
          }
        }
      }
    },

    handleToDownload () {
      this.$router.push('/download')
    },

    handleToChangeBanner(index) {
      this.setActiveItemFn(index)
    },

    setActiveItemFn(index) {
      this.$refs.carousel.setActiveItem(index)
    },
    bannerChange(index) {
      this.activeBannerIndex = index
    },
    handleCurrentChange (e) {
      this.pageNum = e
      this.getCourses()
    },
    getCourses() {
      const courseTypeId = this.areaId || this.cId
      const params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        courseTypeId,
        typeId: this.themeId,
        courseType: this.courseType
      }
      this.$http.get('external/queryCourseByType', { params }).then(res => {
        if (res.code === 200) {
          res.data.list.forEach(item => {
            item.durationStr = this.$xcUtils.formatSeconds(item.duration)
          })
          this.courseList = res.data.list
          this.total = res.data.total
        }
      })
    },
    getBanners() {
      const params = {
        typeId: this.themeId,
        courseTypeId: this.cId,
        isFree: 2 // 0免费 1付费 2特惠
      }
      this.$http.get('external/queryCourseList', { params }).then(res => {
        if (res.code === 200) {
          this.bannerData = res.data
        }
      })
    },
    changeThemeItem(index) {
      this.themes.find(item => item.active).active = false
      this.themes[index].active = true
      // this.activeTheme = this.themes[index]
      this.themeId = this.themes[index].id
      this.getCourses()
      this.getBanners()
    },
    changeTypeItem(index) {
      // this.area = ''
      this.areaId = ''
      this.types.find(item => item.active).active = false
      this.types[index].active = true
      // this.activeType = this.types[index]
      this.cId = this.types[index].id
      this.activeTypeIndex = index
      this.areaList = this.types[index].childrenCourseType
      this.getCourses()
      this.getBanners()
    }
  }
}
</script>
<style lang="less" scoped>
.theme{
  background-color: #b81b26;
  padding: 16px 0 0;
  .theme-flex{
    display: flex;
  }
  .theme-title{
    font-size: 24px;
    font-family: regular;
    color: #000;
    width: 120px;
    margin-top: 10px;
  }
  .theme-list{
    overflow: hidden;
    flex: 1;
  }
  .theme-item{
    float: left;
    width: 198px;
    height: 60px;
    margin-left: 20px;
    margin-bottom: 8px;
    position: relative;
    cursor: pointer;
    img{
      width: 198px;
      height: auto;
      position: relative;
      z-index: 1;
    }
    &.active{
      .white-bg{
        width: 100%;
        height: 39px;
        background-color: #fff;
        position: absolute;
        left: 0;
        top: 17px;
        z-index: 0;
      }
    }
  }
}

.type-bar{
  background-color: #dcdcdc;
  padding: 20px 0;
}
.type-flex{
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.type-item{
  width: 250px;
  height: 40px;
  background-image: url(https://cdn.spbcn.org/ghac/ghac_button_nomal.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  font-size: 24px;
  font-family: regular;
  color: #fff;
  text-align: center;
  line-height: 36px;
  cursor: pointer;
  &.active{
    background-image: url(https://cdn.spbcn.org/ghac/ghac_button_highlight.png);
    color: #000;
  }
}
.carousel{
  width: 100%;
  height: 400px;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
}
.page-banner-right{
  background-color: rgba(0, 0, 0, .6);
  padding: 20px 20px 20px 40px;
  border-radius: 10px;
  color: #fff;
  width: 400px;
  height: 340px;
  box-sizing: border-box;
  position: absolute;
  right: 40px;
  top: 30px;
  z-index: 1000;
  overflow-y: auto;
  .h1{
    font-size: 20px;
    margin-bottom: 14px;
  }
  .h2{
    font-size: 16px;
    margin-bottom: 14px;
    cursor: pointer;
    width: 350px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    &:hover, &.active{
      color: #b81b26;
    }
  }
}
.page-banner-course{
  width: 100%;
  height: 100%;
  // background-image: url(https://cdn.spbcn.org/ghac/pictures/ghac_banner2.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .page-banner-left{
    color: #fff;
    margin-left: 50px;
    .h2{
      font-size: 18px;
      text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    }
    .h1{
      width: 500px;
      font-size: 46px;
      font-family: hzgb;
      margin-top: 10px;
      margin-bottom: 30px;
      text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
      line-height: 66px;
    }
    .btn{
      font-size: 16px;
      color: #000;
      background-color: #fff;
      width: 120px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      border-radius: 10px;
      cursor: pointer;
      margin-top: 30px;
      &:hover{
        background-color: #b81b26;
        color: #fff;
      }
    }
  }
}

.course{
  margin-top: 20px;
  .search{
    font-size: 16px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .search-back{
      font-family: regular;
      font-size: 24px;
      color: #000;
      cursor: pointer;
      text-decoration: underline;
      &:hover{
        color: #b81b26;
      }
    }
  }
  .course-list{
    overflow: hidden;
    .course-item{
      width: 33.33%;
      float: left;
      .course-title{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .course-item-con{
        margin: 0 auto 20px;
        width: 96%;
        border-radius: 30px;
        cursor: pointer;
        &:hover{
          .course-title{
            color: #b81b26;
          }
          box-shadow: 2px 6px 6px rgba(80, 80, 80, 0.1);
        }
        .course-img{
          width: 100%;
          height: 160px;
          // background-image: url(https://cdn.spbcn.org/ghac/pictures/ghac_banner2.png);
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          border-radius: 30px 30px 0 0;
          position: relative;
          margin-top: 20px;
          .shikan{
            position: absolute;
            right: 10px;
            top: -12px;
            width: 60px;
            height: auto;
          }
          .lock{
            position: absolute;
            right: 20px;
            top: 12px;
            width: 20px;
            height: auto;
          }
          .type{
            background-color: rgba(255,255,255,0.5);
            // text-shadow: 2px 2px 2px rgba(255, 255, 255, 0.5);
            border: 2px solid #000;
            box-shadow: 0 0 4px rgba(255, 255, 255, 1);
            border-radius: 6px;
            color: #000;
            font-size: 14px;
            height: 24px;
            line-height: 26px;
            padding-left: 12px;
            padding-right: 12px;
            position: absolute;
            left: 12px;
            top: 20px;
          }
          .time{
            font-size: 12px;
            color: #fff;
            background-color: #000;
            height: 24px;
            line-height: 24px;
            width: 60px;
            text-align: center;
            position: absolute;
            right: 0;
            bottom: 0;
          }
        }
        .course-desc{
          background-color: #fff;
          border-radius: 0 0 30px 30px;
          height: 100px;
          color: #555;
          font-size: 16px;
          line-height: 26px;
          padding: 10px 20px;
          box-sizing: border-box;
        }
        .course-data{
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 14px;
          .course-data-item{
            display: flex;
            align-items: center;
          }
          img{
            width: 20px;
            height: auto;
            margin-right: 4px;
          }
        }
      }
    }
  }
}

.upload{
  background-image: url(https://cdn.spbcn.org/ghac/flag-border.png);
  background-size: 860px 80px;
  background-position: center;
  background-repeat: no-repeat;
  width: 900px;
  height: 100px;
  line-height: 96px;
  margin: 60px auto;
  background-color: #b81b26;
  border-radius: 14px;
  color: #fff;
  font-size: 40px;
  font-family: regular;
  text-align: center;
  cursor: pointer;
}

.page{
  text-align: center;
  /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active{
    background-color: #b81b26;
  }
  /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover{
    color: #b81b26;
  }
}
/deep/ .el-carousel__container {
  height: 400px;
}

</style>
<!-- https://cdn.spbcn.org/ghac/ghac_homepage_logo1.png -->
