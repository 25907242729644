<template>
  <div style="background-color: #333;">
    <img class="img" v-for="item in 15" :key="item" :src="'https://cdn.spbcn.org/IZONE/'+ (item-1) +'.png'" alt="">
  </div>
</template>
<script>
export default {
  data () {
    return {
    }
  },
  created () {
    document.title = 'IZON爱眼尊'
  }
}
</script>
<style>
.img{
  width: 100%;
  display: block;
}
</style>
