<template>
  <div>
    <div class="course-class-topback">
      <img
        class="course-left-backimg"
        src="../../../../../src/assets/images/homepage_left.png"
        @click="backClicked"
      />
      <div class="course-title">我的课程</div>
    </div>
    <div class="navbar-list">
      <div class="flex-imd" id="flexImd">
        <div class="navbar-item"
          v-for="(item, index) in courseTypeList"
          :key="index"
          @click="chooseNav(index)"
        >
          <div class="active" v-if="item.active"></div>
          <img
            :src="item.typeLogo"
            alt=""
            class="navbar-item-img"
            style="pointer-events: none"
          />
          <!-- {{ item.typeName }} -->
        </div>
      </div>
    </div>
    <!-- <el-form
        label-width="120px"
        style="width: 100%"
    >
      <el-row>
        <el-col :span="8">
          <el-form-item label="主题:" prop="typeId">
            <el-cascader
              style="width: 250px;"
              :options="courseTypeList"
              v-model="typeId"
              clearable
              :props="{
                label: 'typeName',
                value: 'id',
                children: 'childrenCourseType',
                checkStrictly: true
              }"
              @change="courseTypeArrayChange(1)"
            ></el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="区域分类" prop="courseTypeId">
            <el-cascader
              style="width: 250px;"
              :options="types"
              v-model="courseTypeId"
              clearable
              :props="{
                label: 'typeName',
                value: 'id',
                children: 'childrenCourseType',
                checkStrictly: true
              }"
              @change="courseTypeArrayChange(2)"
            ></el-cascader>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form> -->
    <div class="course">
      <template v-if="courseList.length">
        <div class="course-list">
          <div class="course-item" v-for="(item,index) of courseList" :key="index">
            <courseItem :item="item" :isOpen="true" @refresh="getCourses"></courseItem>
          </div>
        </div>
      </template>
      <div v-else class="default">
        <el-empty description="暂无课程"></el-empty>
      </div>
    </div>
  </div>
</template>

<script>
import { queryOpenCourseQueryList } from '@/http/api'
import courseItem from '../../../../components/courseItem.vue'
export default {
  components: { courseItem },
  data () {
    return {
      queryForm: {},
      courseNodataDialogVisible: false,
      currNavbar: 0,
      activeItem: 0,
      activeName: '用户管理',
      courseTypeList: [],
      // activeTheme: {},
      types: [],
      activeType: {},
      courseTypeId: [],
      courseList: [],
      courseType: '',
      themeId: '',
      cId: '',
      areaId: ''
    }
  },
  created () {
    // this.getSecondaryLinkageCourseQuery()
    this.getClasses(1)
    this.getClasses(2)
  },
  methods: {
    getClasses (type) {
      this.$http.get('/external/querySeeEightTopics', { params: { type } }).then(res => {
        if (res.code === 200) {
          if (type === 1) {
            res.data.forEach((item, i) => {
              item.active = i === 0
            })
            this.courseTypeList = res.data
            this.themeId = res.data[0].id

            this.getCourses()
          } else if (type === 2) {
            this.types = res.data
            this.activeType = res.data[0]
          }
        }
      })
    },
    // 获取课程分类二级菜单
    getSecondaryLinkageCourseQuery () {
      const schoolData = JSON.parse(localStorage.getItem('saveSchoolInfo'))
      this.queryForm = {
        schoolId: schoolData.roleId === '1' ? schoolData.list[0].schoolId : this.$route.query.schoolId,
        userId: schoolData.userId,
        activityType: this.$chnEngStatusCode.activityCourseType
      }
      queryOpenCourseQueryList(this.queryForm).then((res) => {
        console.log('res', res)
        this.courseTypeList = res.data
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    chooseNav (index) {
      this.currNavbar = index
      this.$nextTick(() => {
        const item = this.$el.querySelectorAll('.navbar-item')[index]
        const container = document.querySelector('#flexImd')
        console.log('container', container)
        const scrollLeft = item.offsetLeft - (container.offsetWidth - item.offsetWidth) / 2
        container.scrollTo({
          left: scrollLeft,
          behavior: 'smooth'
        })
      })
      this.courseTypeList.find(item => item.active).active = false
      this.courseTypeList[index].active = true
      // this.activeTheme = this.courseTypeList[index]
      this.themeId = this.courseTypeList[index].id
      this.getCourses()
    },
    backClicked () {
      this.$router.back()
    },
    getCourses() {
      const courseTypeId = this.areaId || this.cId
      const params = {
        courseTypeId,
        typeId: this.themeId,
        courseType: this.courseType
      }
      this.$http.get('course/queryOpenCourseListByUserId', { params }).then(res => {
        if (res.code === 200) {
          res.data.forEach(item => {
            item.durationStr = this.$xcUtils.formatSeconds(item.duration)
          })
          this.courseList = res.data
        }
      })
    }
    // 菜单点击事件
    // courseClassClicked (item) {
    //   if (item.childrenCourseType && item.childrenCourseType.length !== 0) {
    //     this.$router.push({
    //       path: '/aboutUserData/cebsbCommunityInfoView/cebsbCourseManage/cebsbInfo_community_courseDetail',
    //       query: {
    //         classData: JSON.stringify(item),
    //         schoolId: this.queryForm.schoolId
    //       }
    //     })
    //   } else {
    //     this.courseNodataDialogVisible = true
    //   }
    // }
  }
}
</script>
<style lang="less" scoped>
.course-class-topback {
  width: 100%;
  height: 60px;
  background-color: rgb(178, 178, 178);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.course-left-backimg {
  width: 30px;
  height: 30px;
  margin-left: 10px;
}
.course-title {
  margin-left: 10px;
  font-size: 18px;
  font-weight: 900;
}
.flex-imd {
  display: flex;
  overflow-x: scroll;
  padding: 0;
  // overflow: hidden;
}
.flex-imd::-webkit-scrollbar {
  display: none;
}
.navbar-list {
  width: 100%;
  // padding: 10px;
  margin: 30px 0;
  overflow-x: hidden;
}
.navbar-item {
  margin-bottom: 10px;
  width: 220px;
  height: 50px;
  cursor: pointer;
  position: relative;
  .active{
    width: 180px;
    height: 37px;
    background-color: #b81b26;
    position: absolute;
    left: 0;
    right: 0;
    top: 15px;
    z-index: 0;
  }
}
.navbar-item-img {
  width: 180px;
  height: auto;
  margin-right: 20px;
  position: relative;
  z-index: 10;
}
.navbar-list::-webkit-scrollbar {
  display: none;
}
// .navbar-item.act {
//   background: #c30f20;
// }
// .nol {
//   background: #999999;
// }

.course-type-back {
  width: 100%;
  margin: 10px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: row;
}
.course-type-content {
  width: 200px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.course-type-image {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  border: 4px solid #ffffff;
}
.course-tye-name {
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
  color: black;
  font-weight: 900;
}
.course{
  margin-top: 20px;
  .search{
    font-size: 16px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .search-back{
      font-family: regular;
      font-size: 24px;
      color: #000;
      cursor: pointer;
      text-decoration: underline;
      &:hover{
        color: #b81b26;
      }
    }
  }
  .course-list{
    overflow: hidden;
    .course-item{
      width: 25%;
      float: left;
    }
  }
}
</style>
