
<template>
  <div class="top-backview">
    <div class="contnet-backview">
      <!-- 顶部菜单 -->
      <!-- <Topbar class="topbar"></Topbar> -->
      <!-- banner图 -->
      <div class="bannerclass">
        <el-carousel
          :interval="3000"
          indicator-position="none"
          id="el-carousel"
          :height="dataHeight"
        >
          <el-carousel-item v-for="item in images" :key="item.index">
            <img :src="item.url" alt="" class="banner-img-class" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="bannerbottom">
        <div class="title-content">注册UIE·国际理解教育人文素养社团俱乐部</div>
        <div class="title-contenteng">
          SIGN UP FOR UIE·INTERNATIONAL UNDERSTANDING EDUCATION HUMANISTIC
          LITERACY CLUB
        </div>
        <div class="title-backview">JOIN US</div>
        <!--  @click="handleToReg" -->
      </div>
      <!-- banner红线 -->
      <div class="bottom-redline"></div>
      <!-- 中间部分内容 -->
      <div class="center-backview">
        <div class="center-contentback">
          <div class="centent-titleOne">大学</div>
          <div class="centent-titleTwo">UNIVERSITY</div>
          <div class="centent-titleThree">
            与我们建立大学本科生和研究生产学研基地，为大学生们提供优质的就业平台，为学校和社会提供优质的毕业生
          </div>
        </div>
        <div class="center-contentback1">
          <div class="centent-titleOne">教材教具合作</div>
          <div class="centent-titleTwo">
            TEACHING MATERIALS AND TEACHING AIDS
          </div>
          <div class="centent-titleThree">
            为国际理解教育社团俱乐部课程提供相应的教辅工具包材料
          </div>
        </div>
        <div class="center-contentback2">
          <div class="centent-titleOne">其他</div>
          <div class="centent-titleTwo">OTHER SPONSORS</div>
          <div class="centent-titleThree">
            我们欢迎向国际理解教育社团所提供的素拓课、研学、校园博物馆和非遗小使者大会部门的提供支持的合作方
          </div>
        </div>
      </div>
      <!-- 底部内容 -->
    </div>
  </div>
</template>

<script>
// import Topbar from '@/components/Topbar.vue'
// import Footer from '@/components/Footer.vue'
export default {
  props: {
    dataHeight: {
      type: String,
      default: '100%'
    }
  },
  data () {
    return {
      images: [{ url: 'https://cdn.spbcn.org/ghac/assets/cooperation_banner.png' }]
    }
  },
  components: {
    // Topbar,
    // Footer
  },
  methods: {
    // handleToReg () {
    //   this.$router.push('/registeredContent')
    // }
  }
}
</script>

<style lang="less" scoped>
.marginTop {
  margin-top: 30px;
}
// .topbar {
//   width: 1000px;
//   padding: 0;
//   margin: 0;
//   z-index: 1000;
// }
.top-backview {
  width: 100%;
  // padding-bottom: 10px;
  background-color: rgb(216, 216, 216);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.contnet-backview {
  width: 1000px;
  background-color: black;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.bannerclass {
  width: 1000px;
  height: 470px;
  background-color: rgb(0, 0, 0);
}
.bottom-redline {
  width: 1000px;
  height: 10px;
  background-color: red;
  // margin-top: 20px;
  // margin-bottom: 10px;
}
.center-backview {
  width: 1000px;
  /* height: 400px; */
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0px;
}

.footerclass {
  width: 1000px;
}
.banner-img-class {
  width: 1000px;
  height: 470px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.el-carousel {
  width: 1000px;
  height: 470px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}
.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.bannerbottom {
  width: 1000px;
  height: 100px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 150px;
  z-index: 100;
}
.title-backview {
  height: 60px;
  font-size: 34px;
  font-weight: 900;
  text-align: center;
  line-height: 60px;
  padding: 0px 30px;
  color: white;
  border: 2px solid rgb(255, 255, 255);
  cursor: pointer;
}
.title-content {
  font-size: 28px;
  font-weight: 900;
  text-align: center;
  color: white;
  text-align: center;
}
.title-contenteng {
  font-size: 18px;
  font-weight: 900;
  text-align: center;
  color: white;
  margin-bottom: 10px;
}
.center-contentback {
  width: 940px;
  height: 198px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(https://cdn.spbcn.org/ghac/assets/cooperation_button1.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.center-contentback1 {
  width: 940px;
  height: 198px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(https://cdn.spbcn.org/ghac/assets/cooperation_button2.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.center-contentback2 {
  width: 940px;
  height: 198px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
}
.centent-titleOne {
  width: 900px;
  font-size: 22px;
  text-align: center;
  color: white;
}
.centent-titleTwo {
  width: 900px;
  font-size: 22px;
  text-align: center;
  color: white;
}
.centent-titleThree {
  width: 900px;
  font-size: 14px;
  text-align: center;
  color: white;
}
</style>
