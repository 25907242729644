<template>
  <div>
    <div class="course-item-con" @click="courseDetailClicked(item)">
      <div class="course-img" :style="'background-image: url('+ item.courseThumbnail +');'">
        <!-- coursePay 1免费试看 2付费 3特惠 -->
        <!-- isFree 0免费试看 1付费 2特惠 -->
        <img class="shikan" v-if="item.isFree === 0 && !isOpen" src="https://cdn.spbcn.org/ghac/ghac_icon_shikan.png" alt="">
        <img class="lock" v-if="item.isFree !== 0 && !isOpen" src="https://cdn.spbcn.org/ghac/lock.png" alt="">
        <div class="type">{{item.courseType === 2 ? '视频课程' : '课件'}}</div>
        <!-- <div class="time" v-if="item.courseType === 2">{{item.durationStr}}</div> -->
      </div>
      <div class="course-desc">
        <div class="course-title1 course-title1-flex">
          <div>{{item.courseTypeStr}}</div>
          <div class="price" v-if="!isOpen">¥{{item.price}}</div>
        </div>
        <div class="course-title1">{{ item.courseName }}</div>
        <div class="course-data">
          <div class="course-data-item">
            <img src="https://cdn.spbcn.org/ghac/ghac_littleicon1.png" alt="">
            <span>{{item.playsNum}}</span>
          </div>
          <!-- <div class="course-data-item">
            <img src="https://cdn.spbcn.org/ghac/ghac_littleicon2.png" alt="">
            <span>{{item.downloadsNum}}</span>
          </div> -->
        </div>
      </div>
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      width="600px">
      <div slot="title" class="dialog-title">
        <i class="el-icon-loading"></i> 系统环境检测中...
      </div>
      <p class="mb-20">1、请允许浏览器读取剪贴板</p>
      <p class="mb-20">2、请确认本机已安装office2016版本及以上</p>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="dialogVisible2">
      <p class="mb-20">请确认已<b class="red">安装并启动</b>环球文遗小使者大会课件插件：</p>
      <p class="mb-20">1、如未下载，请<span class="click" @click="handleToDownload">去下载</span></p>
      <p class="mb-20">2、如已打开课件，请忽略此提示并<span class="click" @click="dialogVisible2 = false">关闭弹窗</span></p>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object
    },
    isOpen: {
      type: Boolean
    },
    outSide: {
      type: Boolean
    }
  },
  data () {
    return {
      loading: null,
      userId: '',
      dialogVisible: false,
      dialogVisible2: false,
      getContentTimes: 0
    }
  },
  methods: {
    // 菜单点击事件
    courseDetailClicked (item) {
      if (this.isOpen) {
        if (!item.courseUrl) {
          this.$message.warning('暂不可预览')
        } else {
          this.preview(item)
        }
      } else {
        if (item.isFree !== 0) {
          this.$message.error('暂未解锁')
        } else {
          if (!item.courseUrl) {
            this.$message.warning('暂未上传课程')
          } else {
            this.preview(item)
          }
        }
      }
    },
    getUserId (item) {
      this.$http.get('user/getUserInfoPPTId').then(res => {
        if (res.code === 200) {
          this.userId = res.data.userId
          this.previewPPT(item)
        }
      })
    },
    previewPPT (item) {
      /**
       * 复制json到剪贴板。判断是否安装exe：如果3s后剪贴板里无内容，说明已下载。有内容：跳转到引导下载页面。
       */
      console.log(item)
      const pptObj = {
        UserID: this.outSide ? '123456789' : this.userId,
        ProductID: item.productId,
        ProductName: item.courseName, // 课程名称
        OfficeImageUri: item.courseThumbnail, // ppt缩略图
        OfficePresentationUri: item.courseUrl // ppt文件地址
      }
      console.log(pptObj)
      this.copy(JSON.stringify(pptObj))
      this.showLoading()
      setTimeout(() => {
      // this.dialogVisible = false
        this.getContentTimes = 0
        this.getClipboardContent(item)
      // this.opentimes(item, 1)
      }, 5000)
    },
    preview (item) {
      const courseDataType = this.$xcUtils.matchFileType(item.courseUrl)
      console.log('courseDataType', courseDataType)
      if (courseDataType === 'pptx' || courseDataType === 'ppt') {
        // 1、浏览器预览
        // const url = encodeURIComponent(item.courseUrl)
        // this.$router.push({
        //   path: '/cebsbCourseManage/cebso_onlineBrowsingPDF',
        //   query: {
        //     // office
        //     fileUrl: 'http://view.officeapps.live.com/op/view.aspx?src=' + url
        //     // fileUrl: item.courseUrl
        //   }
        // })

        // 2、客户端预览
        if (this.userId || this.outSide) {
          this.previewPPT(item)
        } else {
          this.getUserId(item)
        }
      } else if (courseDataType === 'video') {
        this.opentimes(item, 2)
        this.$router.push({
          path: '/cebsbCourseManage/cebso_onlineBrowsingVideo',
          query: {
            fileUrl: item.courseUrl
          }
        })
      } else {
        this.opentimes(item, 2)
        this.$router.push({
          path: '/cebsbCourseManage/cebso_onlineBrowsingPDF',
          query: {
            fileUrl: '/pdf/web/viewer.html?file=' + item.courseUrl
          }
        })
      }
    },
    opentimes (item, type) {
      this.$http.post('/common/updateOpenCourseNums', { productId: item.productId }).then(res => {
        if (res.code === 200) {
          if (type === 1) {
            // 刷新列表
            this.$emit('refresh')
          }
          console.log(res)
        }
      })
    },
    copy (str) {
      // 创建一个隐藏的textarea元素
      const textarea = document.createElement('textarea')
      document.body.appendChild(textarea)
      // 设置需要复制的文本为Vue的内容
      textarea.value = str
      // 选中并复制文本
      textarea.select()
      document.execCommand('copy')
      // 移除临时的textarea元素
      document.body.removeChild(textarea)
    },
    getClipboardContent(item) {
      this.getContentTimes = this.getContentTimes + 1
      try {
        navigator.clipboard.readText().then(text => {
          // text为剪贴板中的文本内容
          console.log('剪贴板内容：', text)
          if (text) {
            // const prefix = process.env.NODE_ENV === 'production' ? '' : 'test.'
            // // registeredContent
            // const url = process.env.NODE_ENV === 'development' ? `http://${window.location.host}/#/download` : `http://${prefix}ghacedu.spbcn.org/#/download`
            // console.log(url)

            // window.open(url)
            this.dialogVisible = false
            this.dialogVisible2 = true
            // this.handleToDownload()
          } else {
            this.opentimes(item, 1)
            this.dialogVisible = false
            this.dialogVisible2 = false
          }
        }).catch(err => {
          console.error('无法从剪贴板中读取内容：' + this.getContentTimes, err)
          if (this.getContentTimes < 3) {
            this.getClipboardContent(item)
          }
          if (this.getContentTimes === 3) {
            this.dialogVisible = false
            this.dialogVisible2 = true
          }
        })
      } catch (error) {
        console.log(error)
        this.dialogVisible = false
        this.dialogVisible2 = true
      }
    },
    handleToDownload () {
      this.$router.push('/download')
    },
    showLoading() {
      // this.loading = this.$loading({
      //   lock: true,
      //   text: '请稍候...',
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(0, 0, 0, 0.7)'
      // })
      this.dialogVisible = true
    }
  }
}
</script>
<style scoped lang="less">
.course-title1{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.course-item-con{
  margin: 0 auto 20px;
  width: 96%;
  border-radius: 30px;
  cursor: pointer;
  &:hover{
    .course-title{
      color: #b81b26;
    }
    box-shadow: 2px 6px 6px rgba(80, 80, 80, 0.1);
  }
  .course-img{
    width: 100%;
    height: 160px;
    // background-image: url(https://cdn.spbcn.org/ghac/pictures/ghac_banner2.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 30px 30px 0 0;
    position: relative;
    margin-top: 20px;
    .shikan{
      position: absolute;
      right: 10px;
      top: -12px;
      width: 60px;
      height: auto;
    }
    .lock{
      position: absolute;
      right: 20px;
      top: 12px;
      width: 20px;
      height: auto;
    }
    .type{
      background-color: rgba(255,255,255,0.5);
      // text-shadow: 2px 2px 2px rgba(255, 255, 255, 0.5);
      border: 2px solid #000;
      box-shadow: 0 0 4px rgba(255, 255, 255, 1);
      border-radius: 6px;
      color: #000;
      font-size: 14px;
      height: 24px;
      line-height: 26px;
      padding-left: 12px;
      padding-right: 12px;
      position: absolute;
      left: 12px;
      top: 20px;
    }
    .time{
      font-size: 12px;
      color: #fff;
      background-color: #000;
      height: 24px;
      line-height: 24px;
      width: 60px;
      text-align: center;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  .course-desc{
    background-color: #fff;
    border-radius: 0 0 30px 30px;
    height: 100px;
    color: #555;
    font-size: 16px;
    line-height: 26px;
    padding: 10px 20px;
    box-sizing: border-box;
  }
  .course-data{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    .course-data-item{
      display: flex;
      align-items: center;
    }
    img{
      width: 20px;
      height: auto;
      margin-right: 4px;
    }
  }
}
.dialog-title{
  font-size: 22px;
  color: #b81b26;
}
.click{
  cursor: pointer;
  color: #b81b26;
  text-decoration: underline;
}
.red{
  color: #b81b26;
}
.mb-20{
  margin-bottom: 10px;
}
.course-title1-flex{
  display: flex;
  justify-content: space-between;
  align-items: center;
  .price{
    color: #b81b26;
    font-size: 20px;
    font-weight: bold;
  }
}
</style>
