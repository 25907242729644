<template>
  <div class="top-backview">
    <div class="contnet-backview">
      <!-- 顶部菜单 -->
      <!-- <Topbar class="topbar"></Topbar> -->
      <!-- banner -->
      <div class="dibannerclass">
        <el-carousel :interval="3000" :height="dataHeight">
          <el-carousel-item v-for="item in images" :key="item.index">
            <img :src="item.url" alt="" class="banner-img-class" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="bannerbottom">
        <div class="title-backview">人文素养课程学习</div>
      </div>
      <div class="red-backview">
        <div class="red-lineview"></div>
      </div>
      <!-- 中间内容 -->
      <div class="pblcenterclass">
        <div class="top-menu-backclass">
          <div class="child-menu-view" @click="menuOneClick">
            <div class="top-imgback">
              <img
                src="https://cdn.spbcn.org/ghac/assets/aboutus4_icon5_new.png"
                alt=""
                class="topimage-class"
              />
            </div>
            <div class="top-bottom-str">英语语言素养素质拓展课</div>
          </div>
          <div class="child-menu-view" @click="menuTwoClick">
            <div class="top-imgback">
              <img
                src="https://cdn.spbcn.org/ghac/assets/aboutus4_icon4_new.png"
                alt=""
                class="topimage-class"
              />
            </div>
            <div class="top-bottom-str">国际理解教育人文素养探究课</div>
          </div>
          <div class="child-menu-view" @click="menuThreeClick">
            <div class="top-imgback">
              <img
                src="https://cdn.spbcn.org/ghac/assets/aboutus4_icon3_new.png"
                alt=""
                class="topimage-class"
              />
            </div>
            <div class="top-bottom-str">汉语语言素养素拓课</div>
          </div>
        </div>
        <div>
          <img
            src="https://cdn.spbcn.org/ghac/assets/aboutus2_title.png"
            alt=""
            class="center-image2"
          />
        </div>
        <div class="center-titlestr">
          在中国教育部倡导“双减”政策下，同时响应“构建人类命运共同体”的前提下，并结合全球后疫情时代加强中国青少年“国际理解教育”能力提升，UIE国际理解教育人文素养社团俱乐部旨在通过以下几方面来进行课程的研发和落实。
        </div>
        <div class="center-list-backview">
          <img
            src="https://cdn.spbcn.org/ghac/assets/aboutus2_image1.png"
            alt=""
            class="center-list-images"
          />
          <div class="list-right-content">
            <div class="right-content-topview">
              <div class="right-content-lineview"></div>
              <div class="right-content-str">语言核心素养</div>
              <img
                src="https://cdn.spbcn.org/ghac/assets/aboutus2_icon.png"
                alt=""
                class="aboutus2-icon-img"
              />
            </div>
            <div class="right-content-bottomstr">
              UIE国际理解教育社团俱乐部课程内容之一是通过双语的学习来培养语言的优势，收获的不仅仅是语言的学习，而是语言习得过程中打造出来的勇敢、自信和自如。我们致力于以中英文双语学习为媒介，创造各种机会、搭建多样的舞台，融入中西文化，感受世界的发展，展示中国青少年的风采。
            </div>
          </div>
        </div>
        <div class="center-list-backview">
          <img
            src="https://cdn.spbcn.org/ghac/assets/aboutus2_image2.png"
            alt=""
            class="center-list-images"
          />
          <div class="list-right-content">
            <div class="right-content-topview">
              <div class="right-content-lineview"></div>
              <div class="right-content-str">文化自信与表达</div>
              <img
                src="https://cdn.spbcn.org/ghac/assets/aboutus2_icon.png"
                alt=""
                class="aboutus2-icon-img"
              />
            </div>
            <div class="right-content-bottomstr">
              党的十九大鲜明提出，文化是一个国家、一个民族的灵魂。文化兴国运兴，文化孩，民族强。没有高度的文化自信，没有文化的繁荣兴威，就没有中华民族伟大复兴。
              uIE
              国际理解教育社团俱乐部通过非遗文化学习，非遗博物馆或匠人馆体验以及非遗作品展示与交流等方式，一起品味中西文化，感受中国传统文化的独特韵味，认识传统手工艺的精妙，体验非遗的独特魅力，领略非遗之美。这加深了青少年们对中国传统文化的了解，增强了民族凝聚力和自豪感，更是进一步增孩了文化自信，助力中国文化走向世界，由中国青少年展现中国底气。
            </div>
          </div>
        </div>
        <div class="center-list-backview">
          <img
            src="https://cdn.spbcn.org/ghac/assets/aboutus2_image3.png"
            alt=""
            class="center-list-images"
          />
          <div class="list-right-content">
            <div class="right-content-topview">
              <div class="right-content-lineview"></div>
              <div class="right-content-str">学科渗透</div>
              <img
                src="https://cdn.spbcn.org/ghac/assets/aboutus2_icon.png"
                alt=""
                class="aboutus2-icon-img"
              />
            </div>
            <div class="right-content-bottomstr">
              UIE国际理解教育社团俱乐部课程，在“双减”背景下开展的力求会让课堂实现更多的可能性。其中，多学科渗透是常态化开展国际理解教育的主渠道，我们的国际理解教育正在这条路上走得更深远，更有特色；各门科学渗透国际理解教育之中，有着知识的客观性、方法的同一性、价值的统一性和科学文化的差异性。通过这四个方面在国际理解教育课程中渗透学科知识，培养学生用科学的方法学习科学知识，建设人类命运共同体。
            </div>
          </div>
        </div>
        <div class="center-list-backview">
          <img
            src="https://cdn.spbcn.org/ghac/assets/aboutus2_image4.png"
            alt=""
            class="center-list-images"
          />
          <div class="list-right-content">
            <div class="right-content-topview">
              <div class="right-content-lineview"></div>
              <div class="right-content-str">大学专业匹配以及职业发展启蒙</div>
              <img
                src="https://cdn.spbcn.org/ghac/assets/aboutus2_icon.png"
                alt=""
                class="aboutus2-icon-img"
              />
            </div>
            <div class="right-content-bottomstr">
              UIE国际理解教育社团俱乐部课程中不仅涉及跨学科内容，而且所涉及的学科内容与大学专业以及未来职业发展相挂钩，在课程中推动学生去进行大学专业以及未来职业探索，能够强化学生对于专业和相关职业的了解和自我认知方面的能力，促进学生的个性发展，为他们未来在各个职业岗位上发挥出更大的作用奠定坚实的基础。
            </div>
          </div>
        </div>
        <div class="center-list-backview">
          <img
            src="https://cdn.spbcn.org/ghac/assets/aboutus2_image5.png"
            alt=""
            class="center-list-images"
          />
          <div class="list-right-content">
            <div class="right-content-topview">
              <div class="right-content-lineview"></div>
              <div class="right-content-str">生活热点话题</div>
              <img
                src="https://cdn.spbcn.org/ghac/assets/aboutus2_icon.png"
                alt=""
                class="aboutus2-icon-img"
              />
            </div>
            <div class="right-content-bottomstr">
              UIE国际理解教育社团俱乐部课程不仅对于中西文化为主题，还收纳了一系列国际热点话题的为课程主题，例如冬奥会，战争与和平、保护环境等主题。把青少年们感兴趣的热点话题融入国际理解教育课堂，提升授课的吸引力和针对性，是国际理解教育走深走实的有效举措。
            </div>
          </div>
        </div>
        <div>
          <img
            src="https://cdn.spbcn.org/ghac/assets/aboutus_zhuangshi.png"
            alt=""
            class="center-spiritview-bottomimg"
          />
        </div>
      </div>
      <!-- 底部内容 -->
    </div>
  </div>
</template>

<script>
// import Topbar from '@/components/Topbar.vue'
// import Footer from '@/components/Footer.vue'
export default {
  props: {
    dataHeight: {
      type: String,
      default: '100%'
    }
  },
  data () {
    return {
      images: [{ url: 'https://cdn.spbcn.org/ghac/assets/feiyiprograms4_3_banner.png' }]
    }
  },
  methods: {
    menuOneClick () {
      this.$router.push({ path: '/languageCourses' })
    },
    menuTwoClick () {
      this.$router.push({ path: '/studiestoExplore' })
    },
    menuThreeClick () {
      this.$router.push({ path: '/chineseLanguageCourse' })
    }
  },
  components: {
    // Topbar,
    // Footer
  }
}
</script>

<style lang="less" scoped>
// .topbar {
//   z-index: 1000;
//   width: 1000px;
//   padding: 0;
//   margin: 0;
// }
.contnet-backview {
  width: 1000px;
  background-color: black;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.top-backview {
  width: 100%;
  // padding-bottom: 10px;
  background-color: rgb(216, 216, 216);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.top-imgback {
  width: 140px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  // background-image: url(https://cdn.spbcn.org/ghac/assets/aboutus3_photoback.png);
  padding: 0px;
  // padding-bottom: 0px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.topimage-class {
  width: 140px;
  height: 140px;
}
.top-bottom-str {
  width: 300px;
  height: 60px;
  margin-top: 10px;
  font-size: 22px;
  font-family: FZLBJW;
  font-weight: 900;
  text-align: center;
  line-height: 60px;
}
.child-menu-view {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dibannerclass {
  width: 1000px;
  height: 470px;
  z-index: 99;
  background-color: rgb(0, 0, 0);
}
.bannerbottom {
  width: 1000px;
  height: 100px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 150px;
  z-index: 100;
}
.title-backview {
  height: 60px;
  font-size: 32px;
  font-family: FZLBJW;
  font-weight: 900;
  text-align: center;
  line-height: 60px;
  padding: 0px 20px;
  color: white;
  border: 2px solid rgb(255, 255, 255);
}
.red-backview {
  width: 1000px;
  height: 25px;
}
.red-lineview {
  width: 1000px;
  height: 6px;
  margin-top: 15px;
  background-color: #e50211;
}
.top-menu-backclass {
  margin: 20px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 1000px;
}
.aboutus2-icon-img {
  width: 25px;
  height: 15px;
}
.banner-img-class {
  width: 1000px;
  height: 470px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.center-list-images {
  width: 150px;
  height: 93px;
}
.title-backview {
  height: 60px;
  font-size: 32px;
  font-family: FZLBJW;
  font-weight: 900;
  text-align: center;
  line-height: 60px;
  padding: 0 20px;
  color: white;
  border: 2px solid rgb(255, 255, 255);
}
.el-carousel {
  width: 1000px;
  height: 470px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.pblcenterclass {
  width: 1000px;
  // height: 1553px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(https://cdn.spbcn.org/ghac/assets/feiyi_background.png);
  padding: 0px;
  // padding-bottom: 0px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.center-spiritview-bottomimg {
  width: 1000px;
  height: 90px;
  margin-top: 20px;
  margin-bottom: -20px;
}
.center-titlestr {
  margin-top: 30px;
  width: 800px;
  text-indent: 2em;
  font-size: 18px;
  font-family: FZLBJW;
  color: rgb(41, 41, 41);
}

.center-image2 {
  width: 600px;
  height: 43px;
  margin-top: 20px;
}
.center-list-backview {
  width: 810px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 40px;
  background-color: white;
  border-radius: 20px;
}
.list-right-content {
  width: 600px;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.right-content-topview {
  width: 600px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.right-content-lineview {
  width: 2px;
  background-color: red;
  height: 20px;
  margin-right: 5px;
}
.right-content-str {
  font-size: 20px;
  font-family: FZLBJW;
  color: rgb(255, 0, 0);
  font-weight: 800;
  text-align: left;
}
.right-content-bottomstr {
  font-size: 16px;
  font-family: FZLBJW;
  color: rgb(57, 57, 57);
  width: 640px;
  text-align: left;
  margin-top: 5px;
}
.footerclass {
  width: 1000px;
}
</style>
