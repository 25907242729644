<template>
  <div>
    <div class="schoolinfo-banner">
      <el-image
        style="width: 100%; height: 100%"
        :src="schoolInfoModel.schoolBackPicUrl"
        fit="cover"
      ></el-image>
      <div class="membership-shadow">
        <el-image
          class="school-logo"
          :src="schoolInfoModel.schoolBackPicUrl"
          fit="cover"
        ></el-image>
        <div class="school-nameclass">{{ schoolInfoModel.schoolName }}</div>
      </div>
    </div>
    <el-card class="schoolInfo-contentback">
      <div class="third-title">学校信息</div>
      <!-- <el-tab-pane> -->
        <el-descriptions title="" :column="1">
          <el-descriptions-item label="学校名称">
            {{ schoolInfoModel.schoolName }}
          </el-descriptions-item>
          <el-descriptions-item label="学校类型">
            <span v-if="schoolInfoModel.schoolType + '' === '0'">公立学校</span>
            <span v-else-if="schoolInfoModel.schoolType + '' === '1'"
              >私立学校</span
            >
            <span v-else>外籍子女学校</span>
          </el-descriptions-item>
          <el-descriptions-item label="学校组别">
            {{ getLevelStr() }}
          </el-descriptions-item>
          <el-descriptions-item label="学校省市区">
            {{ schoolInfoModel.province }}{{ schoolInfoModel.city
            }}{{ schoolInfoModel.area }}
          </el-descriptions-item>
          <el-descriptions-item label="详细地址">
            {{
              schoolInfoModel.schoolAddress
                ? schoolInfoModel.schoolAddress
                : '暂无'
            }}
          </el-descriptions-item>
          <el-descriptions-item label="建校时间">
            {{
              schoolInfoModel.foundingYear
                ? schoolInfoModel.foundingYear
                : '暂无'
            }}
          </el-descriptions-item>
          <el-descriptions-item label="校训">
            {{
              schoolInfoModel.schoolMotto ? schoolInfoModel.schoolMotto : '暂无'
            }}
          </el-descriptions-item>
          <el-descriptions-item label="简介">
            {{
              schoolInfoModel.schoolProfile
                ? schoolInfoModel.schoolProfile
                : '暂无'
            }}
          </el-descriptions-item>
        </el-descriptions>
        <div class="bottom-Modifydata-view">
          <el-button class="btn" @click="changeContactDialogVisible = true"
            >修改信息</el-button
          >
        </div>
      <!-- </el-tab-pane> -->
    </el-card>
    <el-dialog
      title="修改学校信息"
      :visible.sync="changeContactDialogVisible"
      @close="changeContactDialogClose"
      width="40%"
    >
      <el-form
        :model="schoolInfoModel"
        ref="schoolInfoRuleFormRef"
        label-width="130px"
        style="width: 100%; margin-top: 6%"
      >
        <el-form-item label="建校时间" prop="foundingYear">
          <el-date-picker
            v-model="schoolInfoModel.foundingYear"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="校训" prop="schoolMotto">
          <el-input
            v-model="schoolInfoModel.schoolMotto"
            placeholder="请输入校训"
            style="width: 100%"
            maxlength="100"
          ></el-input>
        </el-form-item>
        <el-form-item label="简介" prop="schoolProfile">
          <el-input
            type="textarea"
            placeholder="请输入简介"
            maxlength="200"
            v-model="schoolInfoModel.schoolProfile"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="详细地址" prop="schoolAddress">
          <el-input
            type="textarea"
            placeholder="请输入详细地址"
            maxlength="200"
            v-model="schoolInfoModel.schoolAddress"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changeContactDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitChangeContactClicked()"
          >修 改</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import areaJson from '@/assets/area.json'
import { getSchoolList, onSendSms, getSchoolInfo, updateSchoolInfo } from '@/http/api'
export default {
  data () {
    return {
      timer: 0,
      sendDisabled: false,
      changeContactDialogVisible: false,
      areasData: areaJson.areas,
      dict_schoolType: this.$curUtils.dict_schoolType(),
      dict_schoolGroup: this.$curUtils.dict_schoolGroup(),
      provincesSchoolList: [],
      schoolInfoModel: {
        schoolBackPicUrl: 'https://cdn.spbcn.org/chineseWord/applets/images/homepage_banner.png',
        phone: '',
        schoolArea: [],
        schoolId: '',
        schoolNmae: '',
        schoolType: '',
        schoolTeacherName: '',
        schoolLevelArray: [],
        schoolMotto: '',
        foundingYear: '',
        schoolProfile: ''
      }
    }
  },
  created () {
    const lastSendTime = window.sessionStorage.getItem('last-send-changeSchoolTeacherTime')
    if (lastSendTime) {
      const timer = 60 - this.$moment(new Date()).diff(this.$moment(lastSendTime), 'seconds')
      if (timer > 0) this.sendDisabled = true
      this.timer = timer > 0 ? timer : 0
    }
    this.getSchoolInfo()
  },
  computed: {
    sendButtonText () {
      if (this.timer === 0) {
        return '发送验证码'
      } else {
        return `${this.timer}秒后重发`
      }
    }
  },
  watch: {
    timer (num) {
      if (num > 0) {
        setTimeout(() => {
          this.timer = --num
        }, 1000)
      } else {
        this.sendDisabled = false
      }
    }
  },
  methods: {
    // 获取学校信息
    getSchoolInfo () {
      const schoolData = JSON.parse(localStorage.getItem('saveSchoolInfo'))
      console.log('schoolData', schoolData)
      getSchoolInfo({ schoolId: schoolData.list[0].schoolId }).then((res) => {
        console.log('getSchoolInfo', res)
        this.schoolInfoModel = res.data
        this.schoolInfoModel.schoolBackPicUrl = 'https://cdn.spbcn.org/chineseWord/applets/images/homepage_banner.png'
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 学校省市区选择
    provincesHandleChange (value) {
      if (!value) return
      this.provincesSchoolList = []
      this.schoolInfoModel.schoolName = ''
      this.schoolInfoModel.schoolId = ''
      this.schoolInfoModel.provinceCode = value[0]
      this.schoolInfoModel.cityCode = value[1]
      this.schoolInfoModel.areaCode = value[2]
      const deliveryAreaName = this.$refs.provincesHandleChangeRef.getCheckedNodes()[0].pathLabels.join('/')
      const areaList = deliveryAreaName.split('/')
      this.schoolInfoModel.province = areaList[0]
      this.schoolInfoModel.city = areaList[1]
      this.schoolInfoModel.area = areaList[2]
      console.log('schoolInfoModel', this.schoolInfoModel)
      this.getSchoolListData(value)
    },
    // 根据省市区获取学校
    getSchoolListData (schoolArea) {
      getSchoolList({ provinceCode: schoolArea[0], cityCode: schoolArea[1], areaCode: schoolArea[2] }).then((res) => {
        console.log('getSchoolList', res)
        this.provincesSchoolList = res.data
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 学校选择
    schoolSelectChange () {
      for (let index = 0; index < this.provincesSchoolList.length; index++) {
        const element = this.provincesSchoolList[index]
        if (element.id === this.schoolInfoModel.schoolId) {
          this.schoolInfoModel.schoolType = element.schoolType + ''
        }
      }
    },
    // 修改学校负责人弹窗关闭
    changeContactDialogClose () {
      this.changeContactDialogVisible = false
    },
    // 发送验证码
    async SendSms () {
      if (!this.$xcUtils.checkPhone(this.schoolInfoModel.phone)) return this.$message.warning('请输入正确手机号')
      onSendSms({ phone: this.schoolInfoModel.phone, codeType: this.activeName === 'team' ? this.$chnEngStatusCode.TEAMSIGNUP : this.$chnEngStatusCode.REGISTRANTS }).then((res) => {
        console.log(res)
        this.$message.success('短信发送成功，请注意查收')
        window.sessionStorage.setItem('last-send-changeSchoolTeacherTime', new Date())
        this.timer = 60
        this.sendDisabled = true
      }).catch((err) => {
        console.log('err1111', err)
      })
    },
    // 确认修改学校信息
    submitChangeContactClicked () {
      updateSchoolInfo(this.schoolInfoModel).then((res) => {
        console.log(res)
        this.getSchoolInfo()
        this.changeContactDialogVisible = false
      }).catch((err) => {
        console.log('err1111', err)
      })
    },

    getLevelStr() {
      const level = this.schoolInfoModel.schoolLevel
      return this.$curUtils.dict_schoolGroup().find(item => item.dictValue === level).dictLabel
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-tabs__content {
  padding: 15px 40px;
}
::v-deep .el-descriptions-item__label {
  color: rgb(100, 100, 100);
  flex-shrink: 0;
  width: 80px;
}
::v-deep .el-descriptions-item__content {
  color: rgb(0, 0, 0);
}
/* el-tabs */
::v-deep .el-tabs__nav-scroll {
  background-color: #fff;
  padding: 20px 0;
}
::v-deep .el-tabs__nav {
  margin: 0 20px;
  /* 使用rpx没有效果 */
}
::v-deep .el-descriptions-item__cell {
  margin-bottom: 40px !important;
}
::v-deep .el-tabs__item {
  border-radius: 40px !important;
  margin-left: 20px !important;
}
::v-deep .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
  padding-left: 20px;
}
::v-deep .el-tabs--top .el-tabs__item.is-top:last-child {
  padding-right: 20px;
}
/*悬浮样式*/
::v-deep .el-tabs__item:hover {
  color: #e1251b !important;
}
/*选中样式*/
::v-deep .el-tabs__item.is-active {
  color: #fff !important;
  font-weight: bold !important;
  background-color: #e1251b !important;
}
/*隐藏tab下面的一横*/
::v-deep .el-tabs__active-bar {
  display: none;
}

.schoolinfo-banner {
  width: 100%;
  height: 240px;
  background-color: gray;
  position: relative;
}
.membership-shadow {
  width: 100%;
  height: 240px;
  position: absolute;
  background-image: linear-gradIEnt(
    to top,
    rgba(0, 0, 0, 0.8),
    rgba(255, 255, 255, 0.4)
  );
  display: flex;
  flex-direction: row;
  align-items: center;
  top: 0px;
  left: 0px;
  .school-logo {
    width: 100px;
    height: 100px;
    margin-left: 50px;
    border-radius: 50px;
  }
  .school-nameclass {
    font-size: 20px;
    font-weight: bolder;
    color: white;
    margin-left: 10px;
  }
}
.schoolInfo-contentback {
}
.bottom-Modifydata-view {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .btn{
    background-color: #b81c25;
    border-color: #b81c25;
    color: #fff;
  }
}
.Modifydata-button {
  width: 100px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  color: white;
  background-color: orange;
  border-radius: 10px;
  font-size: 12px;
  margin: 20px auto;
}
.schoolinfo-banner-image {
  width: 100%;
  height: 100%;
}
.third-title {
  width: 240px;
  height: 46px;
  padding-left: 46px;
  box-sizing: border-box;
  background-image: url(https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_01.png);
  background-size: auto 100%;
  background-repeat: no-repeat;
  font-family: hzgb;
  color: #fff;
  font-size: 14px;
  text-align: center;
  line-height: 46px;
  letter-spacing: 3px;
  margin-bottom: 20px;
}
</style>
