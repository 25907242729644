<template>
  <div>
    <div class="schoolinfo-banner">
      <el-image
        style="width: 100%; height: 100%"
        :src="userInfoModel.userBackPicUrl"
        fit="cover"
      ></el-image>
      <div class="membership-shadow">
        <el-image
          class="school-logo"
          :src="userInfoModel.userBackPicUrl"
          fit="cover"
        ></el-image>
        <div class="school-nameclass">{{ userInfoModel.userName }}</div>
      </div>
    </div>
    <el-card style="margin: 15px">
      <div class="third-title">个人资料</div>
      <el-descriptions :column="1">
        <el-descriptions-item label="姓名">{{
          userInfoModel.userName
        }}</el-descriptions-item>
        <el-descriptions-item label="性别">
          <span v-if="userInfoModel.sex + '' === '1'">男</span>
          <span v-else-if="userInfoModel.sex + '' === '0'">女</span>
          <span v-else>未设置</span>
        </el-descriptions-item>
        <el-descriptions-item label="联系方式">{{
          userInfoModel.phone
        }}</el-descriptions-item>
        <el-descriptions-item label="邮箱">{{
          userInfoModel.email
        }}</el-descriptions-item>
        <!-- <el-descriptions-item label="个人简介">{{
          userInfoModel.personalProfile
        }}</el-descriptions-item> -->
      </el-descriptions>
      <div class="bottom-Modifydata-view">
        <el-button class="btn" @click="changeContactDialogVisible = true"
          >修改信息</el-button
        >
      </div>
    </el-card>
    <el-dialog
      title="修改个人信息"
      :visible.sync="changeContactDialogVisible"
      @close="changeContactDialogClose"
      width="40%"
    >
      <el-form
        :model="userInfoModel"
        ref="userInfoRuleFormRef"
        label-width="130px"
        style="width: 100%; margin-top: 6%"
      >
        <el-form-item label="姓名" prop="userName">
          <el-input
            v-model="userInfoModel.userName"
            placeholder="请输入姓名"
            style="width: 100%"
            maxlength="100"
          ></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-select
            placeholder="请选择性别"
            style="width: 100%"
            v-model="userInfoModel.sex"
            clearable
          >
            <el-option
              v-for="item in dict_sex"
              :key="item.dictCode"
              :label="item.dictLabel"
              :value="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input
            type="textarea"
            placeholder="请输入邮箱"
            maxlength="200"
            v-model="userInfoModel.email"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changeContactDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitChangeContactClicked()"
          >修 改</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getSysUserInfo, saveSysUserInfo } from '@/http/api'
export default {
  data () {
    return {
      userInfoRuleFormRef: {
        userName: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        sex: [
          { required: true, message: '请选择性别', trigger: 'change' }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'change' }
        ]
      },
      dict_sex: this.$curUtils.dict_sex(),
      changeContactDialogVisible: false,
      sysUserInfoDto: {},
      userInfoModel: {
        userBackPicUrl: 'https://cdn.spbcn.org/chineseWord/applets/images/homepage_banner.png'
      }
    }
  },
  methods: {
    getUserInfo () {
      getSysUserInfo().then((res) => {
        console.log('res', res)
        this.sysUserInfoDto = res.data.sysUserInfo
        this.userInfoModel = res.data.sysUser
        this.userInfoModel.userBackPicUrl = 'https://cdn.spbcn.org/chineseWord/applets/images/homepage_banner.png'
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 确认修改学校信息
    submitChangeContactClicked () {
      const formData = {
        sysUserDto: this.userInfoModel,
        sysUserInfoDto: this.sysUserInfoDto
      }
      saveSysUserInfo(formData).then((res) => {
        console.log(res)
        this.getUserInfo()
        this.changeContactDialogVisible = false
      }).catch((err) => {
        console.log('err1111', err)
      })
    },
    // 修改学校负责人弹窗关闭
    changeContactDialogClose () {
      this.changeContactDialogVisible = false
    }
  },
  created () {
    this.getUserInfo()
  }
}
</script>

<style lang="less" scoped>
.schoolinfo-banner {
  width: 100%;
  height: 240px;
  background-color: gray;
  position: relative;
}
.bottom-Modifydata-view {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .btn{
    background-color: #b81c25;
    border-color: #b81c25;
    color: #fff;
  }
}
.membership-shadow {
  width: 100%;
  height: 240px;
  position: absolute;
  background-image: linear-gradIEnt(
    to top,
    rgba(0, 0, 0, 0.8),
    rgba(255, 255, 255, 0.4)
  );
  display: flex;
  flex-direction: row;
  align-items: center;
  top: 0px;
  left: 0px;
  .school-logo {
    width: 100px;
    height: 100px;
    margin-left: 50px;
    border-radius: 50px;
  }
  .school-nameclass {
    font-size: 20px;
    font-weight: bolder;
    color: white;
    margin-left: 10px;
  }
}
.third-title {
  width: 240px;
  height: 46px;
  padding-left: 46px;
  box-sizing: border-box;
  background-image: url(https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_01.png);
  background-size: auto 100%;
  background-repeat: no-repeat;
  font-family: hzgb;
  color: #fff;
  font-size: 14px;
  text-align: center;
  line-height: 46px;
  letter-spacing: 3px;
  margin-bottom: 20px;
}
</style>
