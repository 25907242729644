<template>
  <div class="backview">
    <div class="course-class-topback">
      <img
        class="course-left-backimg"
        src="../../../../src/assets/images/homepage_left.png"
        @click="backClicked"
      />
      <div class="course-title">社团管理</div>
    </div>
    <el-empty
      v-if="associationList.length === 0"
      description="暂无社团信息"
    ></el-empty>
    <div class="course-type-back" v-else>
      <div
        class="course-type-content"
        v-for="(item, index) in associationList"
        v-bind:key="index"
        @click="courseDetailClicked(item)"
      >
        <div class="school_backview">
          <div class="course-tye-name">{{ item.associationName }}</div>
        </div>
      </div>
    </div>
    <el-dialog
      title="新增社团信息"
      :visible.sync="createdAssociationdialog"
      @close="createdAssociationdialogClose"
      width="30%"
    >
      <el-form
        :model="associationModel"
        :rules="associationRules"
        label-width="130px"
        ref="associationFormRef"
        style="width: 100%"
      >
        <el-form-item label="社团名称" prop="associationName">
          <el-input
            v-model="associationModel.associationName"
            placeholder="请输入社团名"
            style="width: 100%"
            maxlength="100"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="createdAssociationdialog = false">取 消</el-button>
        <el-button type="primary" @click="submitChangeContactClicked()"
          >添 加</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getAssociationList, saveAssociation } from '@/http/api'
export default {
  data () {
    return {
      createdAssociationdialog: false,
      getAssDataForm: {
        pageNum: 1,
        pageSize: 1000,
        schoolId: ''
      },
      associationRules: {
        associationName: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      associationModel: {
      },
      associationList: []
    }
  },
  created () {
    this.getAssociationList()
  },
  methods: {
    backClicked () {
      this.$router.back()
    },
    getAssociationList () {
      const schoolData = JSON.parse(localStorage.getItem('saveSchoolInfo'))
      this.getAssDataForm.schoolId = schoolData.list[0].schoolId
      getAssociationList(this.getAssDataForm).then((res) => {
        this.associationList = res.data.list
      }).catch((err) => {
        console.log('err1111', err)
      })
    },
    courseDetailClicked (item) {
      console.log('item', item)
      this.$router.push({
        path: '/cebsbInfo_community_dtail',
        query: {
          associationIData: JSON.stringify(item)
        }
      })
    },
    createdAssociationdialogClose () {
      this.$refs.associationFormRef.resetFields()
    },
    submitChangeContactClicked () {
      const schoolData = JSON.parse(localStorage.getItem('saveSchoolInfo'))
      console.log('schoolData', schoolData)
      this.associationModel.schoolId = schoolData.list[0].schoolId
      this.associationModel.schoolName = schoolData.schoolName
      saveAssociation(this.associationModel).then((res) => {
        this.createdAssociationdialog = false
        this.getAssociationList()
      }).catch((err) => {
        console.log('err1111', err)
      })
    }
  }
}
</script>
<style lang="less" scoped>
.backview {
  height: 100%;
  width: 100%;
}
// .el-empty {
//   width: 100%;
//   height: 100%;
// }
// .course-class-topback {
//   width: 100%;
//   margin: 20px;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: flex-start;
// }
// .course-title {
//   margin-left: 10px;
//   font-size: 18px;
//   font-weight: 900;
// }
.course-type-back {
  width: 100%;
  margin: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: row;
}
.course-class-topback {
  width: 100%;
  height: 60px;
  background-color: rgb(178, 178, 178);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.course-left-backimg {
  width: 30px;
  height: 30px;
  margin-left: 10px;
}
.course-title {
  margin-left: 10px;
  font-size: 18px;
  font-weight: 900;
}
.course-type-content {
  width: 150px;
  margin-bottom: 20px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.school_backview {
  width: 140px;
  height: 175px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(https://cdn.spbcn.org/chineseWord/applets/images/association-background.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  // background-color: red;
}
.course-tye-name {
  font-size: 14px;
  width: 90%;
  text-align: center;
  color: white;
  font-weight: 900;
}
</style>
