<template>
  <div class="topBox">
    <!-- 左侧logo -->
    <div class="top-leftlogo">
      <img
        src="https://cdn.spbcn.org/ghac/ghac_homepage_logo1.png"
        alt=""
        class="top-leftlogo-image"
        @click="goTohome()"
      />
    </div>
    <!-- 顶部导航栏 -->
    <div class="top-tabview">
      <el-menu
        class="my-menu"
        :hidden="!isWidth"
        :default-active="activeIndex"
        mode="horizontal"
        background-color="#000000"
        text-color="#ffffff"
        active-text-color="#BC3134"
        @select="handleSelect"
        router
      >
        <template v-for="(item,i) of menuList" >
          <el-submenu v-if="item.childList" :index="item.id" :key="i">
            <template slot="title">{{item.title}}</template>
            <template v-for="(child, j) of item.childList">
              <template v-if="child.linkStr !== 'back'">
              <el-menu-item v-if="child.linkStr" :key="j" :index="child.linkStr">{{child.title}}</el-menu-item>
              <el-menu-item :key="j" v-else @click="handleToReg">{{child.title}}</el-menu-item>
            </template>
            </template>
          </el-submenu>
          <el-menu-item v-else :key="i" :index="item.linkStr">{{item.title}}</el-menu-item>
        </template>
      </el-menu>
      <el-dropdown
        trigger="click"
        :hidden="isWidth"
        @command="handleCommand"
        :hide-on-click="false"
      >
        <div
          :hidden="isWidth"
          class="menu-button-class"
          @click="menuClicked"
        ></div>
        <el-dropdown-menu slot="dropdown" :hidden="isWidth">
          <el-dropdown-item
            v-for="(item, index) in nowMenuList"
            :key="item.index"
            :command="index"
            >{{ item.title }}</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- 右侧登录 -->
    <div class="top-login" @click="handleToLog">
      <img src="https://cdn.spbcn.org/ghac/person.png" alt="">
      <div>{{ userName }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app',
  data () {
    return {
      userName: '',
      nowMenuList: [],
      lastMenuList: [],
      screenWidth: document.body.clientWidth,
      isWidth: true,
      activeIndex: '1',
      uniqueOpened: false,
      menuList: [
        {
          id: '1',
          title: '关于我们 | About us',
          linkStr: 'childMenu',
          childList: [
            { title: '返回', linkStr: 'back' },
            {
              title: '项目背景',
              linkStr: '/projectBackground'
            },
            { title: '项目概述', linkStr: '/descriptionOfProject' },
            { title: '研发专家团队', linkStr: '/rdExpertTeam' },
            { title: '学校服务', linkStr: '/schoolServiceView' }
          ]
        },
        {
          id: '5',
          title: '社团课程 | Class',
          linkStr: '/classesHome'
        },
        {
          id: '6',
          title: '华夏探究 | Exploration',
          linkStr: '/literacyStudies'
        },
        {
          id: '7',
          title: '海外出访 | Visit',
          linkStr: '/visit'
        },
        {
          id: '8',
          title: '环球文遗小使者大会 | GHAC',
          linkStr: 'childMenu',
          childList: [
            { title: '返回', linkStr: 'back' },
            // { title: '大会相关', linkStr: '/ghac' },
            { title: '展演流程', linkStr: '/ghacFlow' },
            { title: '展演主题', linkStr: '/ghacTheme' },
            { title: '评审&奖项', linkStr: '/ghacAwards' },
            { title: '展演报名', linkStr: '/ghacReg' }
          ]
        },
        // {
        //   id: 2,
        //   title: '项目介绍 | Programs',
        //   linkStr: 'childMenu',
        //   childList: [
        //     { title: '返回', linkStr: 'back' },
        //     {
        //       title: '人文素养课程学习',
        //       linkStr: '/literacyCourseLearning'
        //     },
        //     { title: '人文素养研学探究', linkStr: '/literacyStudies' },
        //     { title: '校园Micro-museum', linkStr: '/campusMicroMuseum' },
        //     {
        //       title: '环球文遗小使者大会',
        //       linkStr: '/IntangibleAngel'
        //       // childList: [
        //       //   { title: '返回', linkStr: 'back' },
        //       //   { title: '大会简介', linkStr: '/' },
        //       //   { title: '大会主旨', linkStr: '/' },
        //       //   { title: '大会形式', linkStr: '/' }
        //       // ]
        //     }
        //   ]
        // },
        // {
        //   id: 3,
        //   title: '注册 | Registration',
        //   linkStr: '/registeredContent'
        // },
        {
          id: '4',
          title: '合作 | Cooperation',
          linkStr: '/cooperationContent'
        }
      ]
    }
  },
  watch: {
    screenWidth: {
      handler: function (val) {
        if (val < 1050) {
          this.isWidth = false
        } else {
          this.isWidth = true
        }
      },
      immediate: true
    }
  },
  mounted () {
    const userInfoData = JSON.parse(localStorage.getItem('saveUserInfo'))
    this.userName = userInfoData ? userInfoData.userName : '游客'
    this.screenWidth = document.body.clientWidth
    this.nowMenuList = this.menuList
    window.onresize = () => {
      this.screenWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth
    }
  },
  methods: {
    handleSelect (key, keyPath) {
      console.log(key, keyPath)
    },
    goTohome () {
      this.$router.push('/')
    },
    LookOrderGrab () {
      window.open('http://t1.ink/f/xzacwe')
    },
    handleCommand (command) {
      console.log('nowMenuList', this.nowMenuList)
      const selectMenu = this.nowMenuList[command]
      if (selectMenu.linkStr === 'back') {
        this.nowMenuList = this.lastMenuList
      } else if (selectMenu.linkStr === 'childMenu') {
        // 只支持二级菜单，如果有三级菜单还需要完善lastMenuList逻辑
        this.lastMenuList = this.nowMenuList
        this.nowMenuList = selectMenu.childList
      } else {
        console.log('跳转链接', selectMenu.linkStr)
        this.$router.push(selectMenu.linkStr)
      }
    },
    menuClicked () {
      this.nowMenuList = this.menuList
    },
    handleToLog () {
      // const prefix = process.env.NODE_ENV === 'production' ? '' : 'test.'
      // // registeredContent
      // const url = process.env.NODE_ENV === 'development' ? `http://${window.location.host}/#/loginview` : `http://${prefix}ghacedu.com/#/loginview`

      // window.open(url)
      if (this.userName === '游客') {
        this.$router.push('/loginview')
      } else {
        this.$router.push('/userInfo_personalData')
      }
    },
    handleToReg () {
      const token = localStorage.getItem('HQAuthorization')
      if (token) {
        this.$router.push('/club/register?step=0')
      } else {
        this.$router.push('/loginview')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.topBox {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  background-color: black;
}

.top-leftlogo {
  z-index: 100;
  position: absolute; /*固定在顶部*/
  top: 8px;
  left: 0;
}
.top-leftlogo-image {
  width: auto;
  height: 56px;
}
/* .top-leftPlace {
  width: 400px;
  background-color: green;
  height: 70px;
} */
.top-tabview {
  width: 1200px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: rgb(0, 0, 0);
}
.menu-button-class {
  width: 60px;
  height: 65px !important;
  line-height: 65px !important;
  margin-right: 20px;
  background-image: url(https://cdn.spbcn.org/ghac/assets/right-menu.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}

/deep/ .el-menu--horizontal > .el-submenu .el-submenu__title {
  // height: 65px !important;
  // line-height: 65px !important;
  font-size: 12px;
  padding-left: 12px;
  padding-right: 12px;
}
.el-menu-item {
  padding-left: 12px;
  padding-right: 12px;
}
// .el-menu--horizontal > .el-menu-item {
//   height: 65px !important;
//   line-height: 65px !important;
// }
/* 将el-menu导航底部白线变成黑色 */
.el-menu.el-menu--horizontal {
  border-bottom: solid 1px #000000 !important;
}
/* 一级菜单鼠标划过 */
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  outline: 0 !important;
  color: #ff0000 !important;
  background: none !important;
}
/* 二级菜单鼠标划过 */
.el-menu .el-menu-item:hover {
  outline: 0 !important;
  color: #ff0000 !important;
  background: none !important;
}
.el-menu--horizontal .el-menu .el-menu-item:hover {
  color: #000000 !important;
  background: #ffffff !important;
}
/* 三级菜单鼠标划过 */
.el-menu .el-submenu .el-menu-item:hover {
  color: #000000 !important;
  background: #ffffff !important;
}
.my-menu{
  font-family: hzgb;
  .el-menu-item, .el-submenu__title{
    font-size: 12px;
  }
}
.top-login{
  position: absolute;
  right: 40px;
  top: 10px;
  font-size: 12px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  img{
    width: 30px;
    height: 30px;
  }
}
</style>
