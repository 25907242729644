<template>
  <div class="top-backview">
    <div class="contnet-backview">
      <!-- 顶部菜单 -->
      <!-- <Topbar class="topbar"></Topbar> -->
      <!-- banner -->
      <div class="dibannerclass">
        <el-carousel
          :interval="3000"
          indicator-position="none"
          id="el-carousel"
          :height="dataHeight"
        >
          <el-carousel-item v-for="item in images" :key="item.index">
            <img :src="item.url" alt="" class="banner-img-class" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="bannerbottom">
        <div class="title-backview">环球文遗小使者大会</div>
      </div>
      <div class="red-backview">
        <div class="red-lineview"></div>
      </div>
      <!-- 中间内容部分 -->
      <div class="centerclass">
        <div class="center-titleview">
          <div class="titleStr">大会主旨</div>
        </div>
        <div class="course-description-backview">
          <div course-list-view class="list-backview">
            <div class="left-lineview"></div>
            <div class="center-contentstr">
              构建全球青少年人类命运共同体 增进全球青少年国际理解教育实施
            </div>
            <img
              src="https://cdn.spbcn.org/ghac/assets/feiyiprograms4_2_icon.png"
              alt=""
              class="right-img"
            />
          </div>
          <div course-list-view class="list-backview">
            <div class="left-lineview"></div>
            <div class="center-contentstr">
              发现每一位青少年的天赋激发学生对学科和未来职业和生活创新
            </div>
            <img
              src="https://cdn.spbcn.org/ghac/assets/feiyiprograms4_2_icon.png"
              alt=""
              class="right-img"
            />
          </div>
          <div course-list-view class="list-backview">
            <div class="left-lineview"></div>
            <div class="center-contentstr">
              弘扬全球各国家文化 加强世界文化表达
            </div>
            <img
              src="https://cdn.spbcn.org/ghac/assets/feiyiprograms4_2_icon.png"
              alt=""
              class="right-img"
            />
          </div>
          <img
            src="https://cdn.spbcn.org/ghac/assets/feiyiprograms4_2_text.png"
            alt=""
            class="content-oneImg"
          />
        </div>
        <div class="center-titleview">
          <div class="titleStr">中国区大会选区</div>
        </div>
        <img
          src="https://cdn.spbcn.org/ghac/assets/feiyiprograms4_2_image.png"
          alt=""
          class="mapOfChina-img"
        />
      </div>
      <!-- 底部内容 -->
    </div>
  </div>
</template>

<script>
// import Topbar from '@/components/Topbar.vue'
// import Footer from '@/components/Footer.vue'
export default {
  props: {
    dataHeight: {
      type: String,
      default: '100%'
    }
  },
  data () {
    return {
      images: [{ url: 'https://cdn.spbcn.org/ghac/assets/feiyiprograms4_2_banner.png' }]
    }
  },
  components: {
    // Topbar,
    // Footer
  }
}
</script>

<style lang="less" scoped>
// .topbar {
//   z-index: 1000;
//   width: 1000px;
//   padding: 0;
//   margin: 0;
// }
.contnet-backview {
  width: 1000px;
  background-color: black;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.top-backview {
  width: 100%;
  // padding-bottom: 10px;
  background-color: rgb(216, 216, 216);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.dibannerclass {
  width: 1000px;
  height: 469px;
  z-index: 99;
  background-color: rgb(0, 0, 0);
}
.bannerbottom {
  width: 1000px;
  height: 100px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 150px;
  z-index: 100;
}
.title-backview {
  height: 60px;
  font-size: 32px;
  font-family: FZLBJW;
  font-weight: 900;
  text-align: center;
  line-height: 60px;
  padding: 0px 20px;
  color: white;
  border: 2px solid rgb(255, 255, 255);
}
.red-backview {
  width: 1000px;
  height: 25px;
}
.red-lineview {
  width: 1000px;
  height: 6px;
  margin-top: 15px;
  background-color: #e50211;
}
.centerclass {
  width: 1000px;
  height: 1153px;
  display: flex;
  flex-direction: column;
  background-image: url(https://cdn.spbcn.org/ghac/assets/feiyi_background_mix.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.center-titleview {
  width: 377px;
  height: 42px;
  margin-top: 30px;
  background: url(https://cdn.spbcn.org/ghac/assets/feiyi_homepage_title.png);
  background-size: 100%;
}

.titleStr {
  width: 280px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  font-size: 24px;
  font-family: FZLBJW;
  color: white;
  font-weight: bolder;
}

.course-description-backview {
  width: 800px;
  margin-left: 60px;
  margin-top: 30px;
  padding: 50px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 30px;
}
.list-backview {
  width: 800px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.left-lineview {
  width: 3px;
  height: 20px;
  background-color: #e50211;
}
.center-contentstr {
  padding: 5px 5px;
  font-size: 20px;
  font-weight: bolder;
  font-family: FZLBJW;
  color: #e50211;
}
.right-img {
  width: 25px;
  height: 25px;
}
.content-oneImg {
  margin-top: 30px;
  width: 600px;
  height: 207px;
}
.mapOfChina-img {
  width: 800px;
  height: 380px;
  margin-left: 100px;
  margin-top: 30px;
}
.footerclass {
  width: 1000px;
}
.banner-img-class {
  width: 1000px;
  height: 470px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.el-carousel {
  width: 1000px;
  height: 470px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
