<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="top-backview">
    <div class="contnet-backview">
      <div class="dibannerclass">
        <el-carousel
          :interval="3000"
          indicator-position="none"
          id="el-carousel"
          :height="dataHeight"
        >
          <el-carousel-item v-for="item in images" :key="item.index">
            <img :src="item.url" alt="" class="banner-img-class" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="bannerbottom">
        <div class="title-backview">
          <div>非遗小使者海外出访</div>
          <div class="en">Little Ambassadors Visiting Overseas</div>
        </div>
      </div>
      <div class="red-backview">
        <div class="red-lineview"></div>
      </div>
      <div class="centerclass">
        <div class="center-titleview">
          <div class="titleStr">出访荣誉 ｜ Honor of Visiting Overseas</div>
        </div>
        <div>
          <img class="page_img" src="https://cdn.spbcn.org/ghac/hwcf/HWCF_CFRY_banner.png" alt="">
          <div class="page_text">
            <div>每次的海外出访项目都会集结中方和出访国两国政府层面支持，为出访的小使者们提供最官方、最权威的资源。</div>
            <div>出访前，各目的国驻华大使馆会开通官方绿色通道，为每位小使者办理签证等相关资料。当小使者们光荣抵达的那一刻，将会受到我国驻当地大使馆官员、当地政府官员的热情迎接，他们将亲自前往机场，用最诚挚的微笑让小使者们感受到异国他乡的温暖友谊。在每次研学之旅的最后，当地政府官员也会在颁奖仪式上给予小使者们官方的肯定，为大家颁发奖杯及证书。</div>
            <div>此外，研学探究行程也是在目的国教育部、文化部等相关部门的指导下制定的，确保小使者们能够学习和接触到最地道、传统的当地非遗，同时也能够保证行程的顺畅和安全。</div>
          </div>
        </div>
      </div>
      <div class="centerclass">
        <div class="center-titleview">
          <div class="titleStr">出访详情 ｜ Details of Visiting Overseas</div>
        </div>
        <div class="page_text">
          <div>大会每年根据八大活动主题，精心策划海外出访线路及详细探究内容。出访的小使者通过实地探访研究，既能够参观当地独具风格的美术工艺、品尝特色美食、还可以探访特色建筑、了解特色民族舞蹈戏剧等等。同时在出访的过程中，小使者们不仅能够体验多姿多彩的风土人情和非遗，还可以将华夏优秀的非遗文明播撒到世界各地。相信通过海外出访的探究之旅，小使者们一定会有非常丰富的收获。</div>
        </div>
        <div class="sec-title">参与流程</div>
        <div class="flowpath">
          <div class="flowpath-item" v-for="(item, i) of flowpath" :key="i">
            <div class="title">{{ item.title }}</div>
            <div class="desc-item">
              <div class="desc">{{ item.desc1 }}</div>
              <div class="desc">{{ item.desc2 }}</div>
            </div>
            <img class="icon" v-if="i !== flowpath.length - 1" src="https://cdn.spbcn.org/ghac/hxtj/icon.png" alt="">
          </div>
        </div>
        <div class="sec-title">探究内容</div>
        <div class="page_text">
          <div>每年出访的线路和探究内容，都是大会根据八大主题精心筛选和设计的。出访的小使者在完成对国内不同文化区域对应项目的研究之后，带着自己的探究结果，和外国的非遗进行对比探究，在学习外国非遗知识的同时传播优秀的华夏传统非遗。</div>
        </div>
        <img class="page_img" src="https://cdn.spbcn.org/ghac/hwcf/HWCF_CFXQ_banner.png" alt="">
      </div>
      <div class="centerclass">
        <div class="center-titleview">
          <div class="titleStr">出访特色 ｜ Characteristics of Visiting Overseas</div>
        </div>
        <div class="special" v-for="(item,i) of special" :key="i">
          <div class="sec-title">{{ item.title }}</div>
          <div class="page_text"><div>{{ item.text }}</div></div>
          <img class="img" :src="item.img" alt="">
        </div>
      </div>
      <div class="centerclass">
        <div class="center-titleview">
          <div class="titleStr">出访地区 ｜ Areas of Visiting Overseas</div>
        </div>
        <div class="page_text">
          <div>大会每年都会精选来自五大洲的不同国家作为出访目的地。小使者们可以走出国门，去欧洲探寻文艺复兴的起源、去亚洲感受文化的灿烂多元、去北美洲了解兼收并蓄的气度、去南美洲感觉热情奔放的狂热、去非洲观察原始质朴的生机。</div>
        </div>
        <div class="area-1">
          <img class="area-1-img" src="https://cdn.spbcn.org/ghac/hwcf/HWCF_CFDQ_batten_01.png" alt="">
          <img class="area-1-img" src="https://cdn.spbcn.org/ghac/hwcf/HWCF_CFDQ_batten_02.png" alt="">
          <img class="area-1-img" src="https://cdn.spbcn.org/ghac/hwcf/HWCF_CFDQ_batten_03.png" alt="">
        </div>
        <img class="area-1-img-bottom" src="https://cdn.spbcn.org/ghac/hwcf/HWCF_CFDQ_batten_04.png" alt="">
        <div class="area-1 area-2">
          <img class="area-1-img" src="https://cdn.spbcn.org/ghac/hwcf/HWCF_CFDQ_batten_07.png" alt="">
          <img class="area-1-img" src="https://cdn.spbcn.org/ghac/hwcf/HWCF_CFDQ_batten_06.png" alt="">
        </div>
        <img class="area-1-img-bottom" src="https://cdn.spbcn.org/ghac/hwcf/HWCF_CFDQ_batten_05.png" alt="">
        <div class="area-1 area-2">
          <img class="area-1-img" src="https://cdn.spbcn.org/ghac/hwcf/HWCF_CFDQ_batten_08.png" alt="">
          <img class="area-1-img" src="https://cdn.spbcn.org/ghac/hwcf/HWCF_CFDQ_batten_10.png" alt="">
          <img class="area-1-img" src="https://cdn.spbcn.org/ghac/hwcf/HWCF_CFDQ_batten_09.png" alt="">
        </div>
      </div>
      <div class="centerclass">
        <div class="center-titleview">
          <div class="titleStr">出访意义 ｜ Significance of Visiting Overseas</div>
        </div>
        <div class="page_text">
          <div>在环球文遗小使者大会展演中表现优异的学生队伍，将有机会受邀代表华夏文明出访海外，为学生提供进行中外非遗对比的机会和平台。学生将化身华夏文明小使者，代表优秀的中国非遗，前往世界各地，与来自不同国家、不同文化背景下的青少年进行深入交流。</div>
          <div>这样的出访与交流不仅能为孩子们开启通往更广阔的世界的大门，激发他们探索未知世界的兴趣和热情，让他们看到不同地域、不同民族之间的文化差异，也对培养他们的综合素质，建立健全的世界观、人生观、价值观大有裨益，更是可以以此推动华夏文明的延续和传承，推动中华非遗在世界的传播力和影响力，推动人类文化多样性和人类命运共同体的构建和完善。</div>
        </div>
        <img class="page_img" src="https://cdn.spbcn.org/ghac/hwcf/HWCF_CFYY_banner.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
// import Topbar from '@/components/Topbar.vue'
// import Footer from '@/components/Footer.vue'
export default {
  props: {
    dataHeight: {
      type: String,
      default: '100%'
    }
  },
  data () {
    return {
      images: [{ url: 'https://cdn.spbcn.org/ghac/hwcf/HWCF_banner.png' }],
      flowpath: [
        {
          title: '组队准备阶段',
          desc1: '了解学生意愿',
          desc2: '校内队员筛选'
        },
        {
          title: '全国遴选阶段',
          desc1: '面试遴选队伍',
          desc2: '确认出访名单'
        },
        {
          title: '出访准备阶段',
          desc1: '相关手续办理',
          desc2: '社团课程学习'
        },
        {
          title: '海外出访阶段',
          desc1: '文化实地探访',
          desc2: '中外非遗交流'
        },
        {
          title: '回国总结阶段',
          desc1: '校内分享见闻',
          desc2: '手帐活动评比'
        }
      ],
      special: [
        {
          title: '中外文化深度对比',
          img: 'https://cdn.spbcn.org/ghac/hwcf/HWCF_CFTS_banner_01.png',
          text: '通过每次出访前，小使者们都会完成国内相关非遗主题的文化探究，在完成相关知识学习积累的基础之上出访海外，对外国的特定主题相关的非遗进行深度的学习和了解;同时还会将本民族的非遗和外国的非遗进行一一对比探究，从而了解中外非遗的不同，以及背后所蕴含的不同民族历史、风俗等方面的差异。'
        },
        {
          title: '中外青少年友好交流',
          img: 'https://cdn.spbcn.org/ghac/hwcf/HWCF_CFTS_banner_02.png',
          text: '文明因交流而多彩，文明因互鉴而丰富。除了对当地非遗进行学习了解、中外非遗对比探究外，大会还为中外青年学生搭建了高水平的文化交流的平台和友谊的桥梁，出访的小使者拥有大量的机会与当地的青少年进行全方位的交流，推动了华夏优秀非遗传播，助力了中外非遗相通。'
        }
      ]
    }
  },
  components: {
    // Topbar,
    // Footer
  }
}
</script>

<style lang="less" scoped>
// .topbar {
//   z-index: 1000;
//   width: 1000px;
//   padding: 0;
//   margin: 0;
// }
.contnet-backview {
  width: 1000px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-bottom: 80px;
}
.top-backview {
  width: 100%;
  // padding-bottom: 10px;
  background-color: rgb(216, 216, 216);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.dibannerclass {
  width: 1000px;
  height: 469px;
  z-index: 99;
}
.bannerbottom {
  width: 1000px;
  height: 100px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 150px;
  z-index: 100;
}
.title-backview {
  height: 60px;
  font-size: 32px;
  font-family: FZLBJW;
  font-weight: 900;
  text-align: center;
  line-height: 60px;
  padding: 0px 20px;
  color: white;
  border: 2px solid rgb(255, 255, 255);
  .en{
    font-size: 24px;
  }
}
.red-backview {
  width: 1000px;
  height: 25px;
}
.red-lineview {
  width: 1000px;
  height: 10px;
  // margin-top: 15px;
  background-color: #e50211;
}
.centerclass {
  width: 1000px;
}
.center-titleview {
  display: inline-block;
  padding-right: 100px;
  height: 42px;
  margin-top: 50px;
  background: url(https://cdn.spbcn.org/ghac/assets/feiyi_homepage_title.png);
  background-size: auto 100%;
  background-position: right;
  background-repeat: no-repeat;
}

.titleStr {
  // width: 600px;
  height: 42px;
  line-height: 42px;
  // text-align: center;
  font-size: 24px;
  font-family: FZLBJW;
  color: white;
  font-weight: bolder;
  padding-left: 30px;
  background-color: #b81b26;
}

.banner-img-class {
  width: 1000px;
  height: 470px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.el-carousel {
  width: 1000px;
  height: 470px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.area{
  width: 1000px;
  margin-top: 60px;
  .imgs{
    overflow-x: auto;
    display: flex;
    img{
      width: 230px;
      margin-right: 20px;
    }
  }
  .text{
    width: 1000px;
    background-color: #a32b26;
    border-radius: 12px;
    padding: 20px;
    color: #fff;
    font-size: 16px;
    margin-top: 24px;
    line-height: 30px;
  }
}
.special{
  margin-bottom: 20px;
  .img{
    width: 1000px;
    border-radius: 12px;
    margin-top: 20px;
  }
}
.sec-title{
  width: 260px;
  height: 38px;
  background-image: url(https://cdn.spbcn.org/ghac/ghac_button_highlight.png);
  background-size: 100% 100%;
  text-align: center;
  color: #fff;
  font-family: hzgb;
  font-size: 18px;
  line-height: 38px;
  margin: 40px auto 30px;
  letter-spacing: 2px;
}
.page_text{
  font-size: 20px;
  font-family: FZLBJW;
  line-height: 32px;
  margin-top: 20px;
  div{
    text-indent: 2em;
  }
}
.page_img{
  width: 1000px;
  // height: 186px;
  border-radius: 30px;
  margin-top: 40px;
}

.flowpath-item{
  .title{
    width: 240px;
    height: 32px;
    background-image: linear-gradIEnt(to bottom, #f9d291, #ed9028);
    border: 1px solid #904145;
    border-radius: 40px;
    font-family: hzgb;
    font-size: 18px;
    color: #000;
    line-height: 32px;
    text-align: center;
    margin: 0 auto 10px;
  }
  .desc-item{
    width: 1000px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .desc{
    width: 300px;
    height: 40px;
    background: url(https://cdn.spbcn.org/ghac/hwcf/HWCF_CFXQ_01.png);
    background-size: 100% 100%;
    margin: 0 20px;
    font-size: 18px;
    font-family: hzgb;
    color: #9c031d;
    line-height: 40px;
    text-align: center;
  }
  .icon{
    width: 24px;
    margin: 20px auto;
    display: block;
  }
}
.search-content-item{
  .title{
    color: #9e0200;
    font-family: hzgb;
    font-size: 24px;
    text-align: center;
    margin: 20px 0 20px;
  }
  .desc{
    width: 1000px;
    height: 320px;
    background-size: cover;
    background-position: center;
    border-radius: 16px;
    display: flex;
    align-items: center;
    .desc-text{
      width: 100%;
      height: 100px;
      background-color: rgba(0,0,0,.5);
      color: #fff;
      font-size: 16px;
      padding: 30px;
      box-sizing: border-box;
    }
  }
}
.area-1{
  width: 1000px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.area-1-img{
  width: 260px;
  margin: 0 16px;
}
.area-1-img-bottom{
  height: 30px;
  margin: -15px auto 0;
  display: block;
}
.area-2{
  margin-top: 40px;
}
</style>
