<template>
  <div>
    <div class="login-container">
      <img src="https://cdn.spbcn.org/ghac/login/HQWY_DL_logo_03.png" class="logo1" @click="handleToIndex" alt="">
      <img src="https://cdn.spbcn.org/ghac/login/HQWY_DL_logo_01.png" class="logo2" alt="">
      <div class="left">
        <img class="logo" src="https://cdn.spbcn.org/ghac/login/HQWY_DL_logo_02.png" alt="">
        <img src="https://cdn.spbcn.org/ghac/login/HQWY_DL_YS_02.png" alt="">
      </div>
      <div class="center">
        <div class="login-box" style="width: 350px">
          <div class="login-box-title" style="font-size: 18px">会员学校登录</div>
          <div class="login-title-line"></div>
          <el-form
            ref="loginFormRef"
            class="login_form"
            :rules="loginFormRules"
            :model="loginForm"
          >
            <el-form-item label="手机号" prop="phone" style="margin-top: 15px">
              <el-input
                v-model="loginForm.phone"
                placeholder="请输入手机号"
                clearable
                prefix-icon="el-icon-user-solid"
              ></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="code">
              <el-input
                v-model="loginForm.code"
                placeholder="请输入验证码"
                prefix-icon="el-icon-s-order"
                :maxlength="6"
              >
                <el-button
                  :loading="sending"
                  :disabled="sendDisabled"
                  size="small"
                  @click="getVerificationCode()"
                  slot="suffix"
                  >{{ sendButtonText }}</el-button
                >
              </el-input>
            </el-form-item>
            <el-form-item class="btns">
              <el-button
                :loading="isLogonState"
                type="primary"
                @click="mainLogin"
                round
                style="width: 140px; font-size: 13px"
                @keyup.enter="keyDown(e)"
                >登录</el-button
              >
            </el-form-item>
          </el-form>
          <div>
            <el-button type="text" @click="registClicked">立即注册</el-button>
          </div>
        </div>
      </div>
      <div class="right">
        <img src="https://cdn.spbcn.org/ghac/login/HQWY_DL_YS_01.png" alt="">
      </div>
      <div class="graphic-verification-code" v-show="isVerificationShow">
        <puzzleVerification
          v-model="isVerificationShow"
          :puzzleImgList="puzzleImgList"
          blockType="puzzle"
          :onSuccess="handleSuccess"
        />
      </div>
    </div>
    <div class="footer">
      <span>GLOBAL INTANGIBLE CULTURAL HERITAGE</span>
      <span>LITTLE AMBASSADORS' CONFERENCE</span>
    </div>
  </div>
</template>

<script>
// chnEngLoginRequest
import { onSendSms, getUserInformation, chnEngLoginRequest, getSchoolListWithNoPage } from '../../http/api'
export default {
  data () {
    return {
      turnUrl: this.$route.params.pushUrl,
      isLogonState: false,
      puzzleImgList: [
        'https://spbcn.oss-cn-zhangjiakou.aliyuncs.com/spbcnappneed/BABy_shengcheng.png',
        'https://spbcn.oss-cn-zhangjiakou.aliyuncs.com/spbcnappneed/BABy_shengcheng.png',
        'https://spbcn.oss-cn-zhangjiakou.aliyuncs.com/spbcnappneed/BABy_shengcheng.png'
      ],
      isVerificationShow: false,
      loginForm: {
        phone: '',
        code: ''
      },
      loginFormRules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入短信验证码', trigger: 'blur' }
        ]
      },
      sending: false,
      sendDisabled: false,
      loading: false,
      timer: 0
    }
  },
  created () {
    const lastSendTime = window.sessionStorage.getItem('loginLast-send-time')
    if (lastSendTime) {
      const timer = 60 - this.$moment(new Date()).diff(this.$moment(lastSendTime), 'seconds')
      if (timer > 0) this.sendDisabled = true
      this.timer = timer > 0 ? timer : 0
    }
  },
  mounted () {
    // 绑定监听事件
    window.addEventListener('keydown', this.keyDown)
  },
  destroyed () {
    // 销毁事件
    window.removeEventListener('keydown', this.keyDown, false)
  },
  methods: {
    handleToIndex () {
      this.$router.push('/homeView')
    },
    // 显示图形码
    getVerificationCode () {
      if (!this.$xcUtils.checkPhone(this.loginForm.phone)) return this.$message.warning('请输入正确手机号')
      // this.isVerificationShow = true
      this.sendSmsClicked()
    },
    // 图形码验证通过
    handleSuccess () {
      this.isVerificationShow = false
      this.sendSmsClicked()
    },
    // 获取短信
    sendSmsClicked () {
      onSendSms({ phone: this.loginForm.phone, codeType: this.$chnEngStatusCode.LOGINSMSTYPE }).then((res) => {
        this.$message.success('短信发送成功，请注意查收')
        window.sessionStorage.setItem('loginLast-send-time', new Date())
        this.timer = 60
        this.sendDisabled = true
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 点击回车键登录
    keyDown (e) {
      // 回车则执行登录方法 enter键的ASCII是13
      if (e.keyCode === 13) {
        this.mainLogin() // 定义的登录方法
      }
    },
    // 登录
    mainLogin () {
      const formData = new FormData()
      formData.append('phone', this.loginForm.phone)
      formData.append('code', this.loginForm.code)
      formData.append('codeType', this.$chnEngStatusCode.LOGINSMSTYPE)
      formData.append('type', this.$chnEngStatusCode.LOGINTYPE)
      this.$refs.loginFormRef.validate(async valid => {
        if (!valid) return
        this.isLogonState = true
        chnEngLoginRequest(formData).then((res) => {
          this.getUserInformation()
        }).catch(() => {
          this.isLogonState = false
        })
      })
    },
    // 获取学校信息并保存本地
    getUserInformation () {
      getUserInformation().then((res) => {
        console.log('success', res)
        localStorage.setItem('saveUserInfo', JSON.stringify(res.data))
        this.getSchoolName(res.data)
      }).catch((err) => {
        this.isLogonState = false
        console.log('返回的err', err)
      })
    },
    // 获取学校名称（别问，问就是后台没返，自己用别的接口再取一下）
    getSchoolName (schoolData) {
      getSchoolListWithNoPage({ userId: schoolData.userId, schoolId: schoolData.list[0].schoolId }).then((res) => {
        const schoolList = res.data
        if (schoolList.length > 0) {
          schoolData.schoolName = schoolList[0].schoolName
          localStorage.setItem('saveSchoolInfo', JSON.stringify(schoolData))
          this.isLogonState = false
          this.$message.success('登录成功！')
          console.log('turnUrl', this.turnUrl)
          if (this.turnUrl && this.turnUrl !== '') {
            this.$router.push(this.turnUrl)
          } else {
            this.$router.push('/cebsbInfoData')
          }
        } else {
          this.$message.success('登录信息有误！')
        }
      }).catch((err) => {
        console.log('err1111', err)
      })
    },
    // 注册按钮点击事件
    registClicked () {
      this.$router.push('/chnEngRegist')
    },
    resetLoginForm () {
      this.$refs.loginFormRef.resetFields()
    },
    backhomeClicked () {
      this.$router.push('/')
    }
  },
  computed: {
    sendButtonText () {
      if (this.timer === 0) {
        return '发送验证码'
      } else {
        return `${this.timer}秒后重发`
      }
    }
  },
  watch: {
    timer (num) {
      if (num > 0) {
        setTimeout(() => {
          this.timer = --num
        }, 1000)
      } else {
        this.sendDisabled = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.el-button--primary {
  background-color: #b81c25;
  border: 1px solid #b81c25;
  margin-bottom: 0px;
  &:hover{
    background-color: #b81c25;
    border-color: #b81c25;
  }
  span {
    font-family: Microsoft YaHei UI;
    color: #fff;
    font-size: 20px;
    letter-spacing: 1px;
  }
}
.el-button--text {
  color: #b81c25;
  font-size: 14px;
  text-decoration: underline;
}
.top-left-backhome {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 20px;
  top: 20px;
}
.top-left-backTitle {
  // margin-left: 0px;
  color: #b81c25;
  border: 0;
  background-color: white;
}
.graphic-verification-code {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(46, 46, 46, 0.6);
}
.login-container {
  background: url(https://cdn.spbcn.org/ghac/login/HQWY_DL_BJ_S.png);
  background-repeat: repeat;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  .left{
    width: 32%;
    box-sizing: border-box;
    padding-left: 120px;
    padding-top: 40px;
    position: relative;
    img{
      width: 360px;
    }
    .logo{
      position: absolute;
      width: 50px;
      bottom: -20px;
      left: 90px;
    }
  }
  .right{
    width: 30%;
    box-sizing: border-box;
    padding-left: 80px;
    padding-top: 80px;
    img{
      width: 220px;
    }
  }
  .logo1{
    position: absolute;
    top: 32px;
    left: 42px;
    height: 60px;
    cursor: pointer;
  }
  .logo2{
    position: absolute;
    top: 32px;
    right: 42px;
    height: 60px;
  }
  .center{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .login-box {
    background-color: #fff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
    .login-box-title {
      width: 86%;
      text-align: right;
      color: black;
      margin-top: 40px;
    }
    .login-title-line {
      width: 86%;
      margin-left: 7%;
      height: 3px;
      background-color: #b81c25;
      margin: 10px auto;
    }
    .login_form {
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;
      .btns {
        display: flex;
        justify-content: center;
      }
    }
  }
}
.footer{
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 20px;
  font-family: regular;
  font-size: 16px;
  span{
    margin: 0 40px;
  }
}
</style>
