export default {
  /**
   * 方法导航
   *
   * 校验手机号 checkPhone
   * 校验身份证 checkIdCard
   * 时间戳转时期 timestamp2Date
   */

  /**
   * 校验手机号
   * @param phone 手机号
   * @returns true-成功，false-失败
   */
  checkPhone: function (phone) {
    const reg = /^[1][3-9][0-9]{9}$/
    return reg.test(phone)
  },
  /**
   * 校验身份证
   * @param card 传入的证件号
   */
  checkIdCard: function (card) {
    const aIdentityCodeCity = { // 城市代码列表
      11: '北京',
      12: '天津',
      13: '河北',
      14: '山西',
      15: '内蒙古',
      21: '辽宁',
      22: '吉林',
      23: '黑龙江 ',
      31: '上海',
      32: '江苏',
      33: '浙江',
      34: '安徽',
      35: '福建',
      36: '江西',
      37: '山东',
      41: '河南',
      42: '湖北 ',
      43: '湖南',
      44: '广东',
      45: '广西',
      46: '海南',
      50: '重庆',
      51: '四川',
      52: '贵州',
      53: '云南',
      54: '西藏 ',
      61: '陕西',
      62: '甘肃',
      63: '青海',
      64: '宁夏',
      65: '新疆',
      71: '台湾',
      81: '香港',
      82: '澳门',
      91: '国外 '
    }
    let pass = true
    // 是否为空
    if (pass && card === '') pass = false
    // 校验长度，类型
    const reg = /(^\d{15}$)|(^\d{17}(\d|X)$)/ // 身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X
    if (reg.test(card) === false) {
      pass = false
    }
    // 检查省份,取身份证前两位，校验省份
    const province = card.substr(0, 2)
    if (aIdentityCodeCity[province] === undefined) {
      pass = false
    }
    // 校验生日,检查生日是否正确，15位以'19'年份来进行补齐。
    let len = card.length
    let arrData = []; let year = 0; let month = 0; let day = 0; let birthday = ''; let now = 0; let nowYear = 0; let time = 0
    // 身份证15位时，次序为省（3位）市（3位）年（2位）月（2位）日（2位）校验位（3位），皆为数字
    if (len === '15') {
      const reFifteen = /^(\d{6})(\d{2})(\d{2})(\d{2})(\d{3})$/
      arrData = card.match(reFifteen) // 正则取号码内所含出年月日数据
      year = arrData[2]
      month = arrData[3]
      day = arrData[4]
      birthday = new Date('19' + year + '/' + month + '/' + day)
      now = new Date()
      nowYear = now.getFullYear()
      // 年月日是否合理
      if (birthday.getFullYear() === '19' + year && (birthday.getMonth() + 1) === month && birthday.getDate() === day) {
        // 判断年份的范围（3岁到150岁之间)
        time = nowYear - '19' + year
        if (time >= 3 && time <= 150) {
          pass = true
        }
        pass = false
      }
      pass = false
    }
    // 身份证18位时，次序为省（3位）市（3位）年（4位）月（2位）日（2位）校验位（4位），校验位末尾可能为X
    if (len === '18') {
      const reEighteen = /^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/
      arrData = card.match(reEighteen) // 正则取号码内所含出年月日数据
      year = arrData[2]
      month = arrData[3]
      day = arrData[4]
      birthday = new Date(year + '/' + month + '/' + day)
      now = new Date()
      nowYear = now.getFullYear()
      // 年月日是否合理
      if (birthday.getFullYear() === year && (birthday.getMonth() + 1) === month && birthday.getDate() === day) {
        // 判断年份的范围（3岁到150岁之间)
        time = nowYear - year
        if (time >= 3 && time <= 150) {
          pass = true
        }
        pass = false
      }
      pass = false
    }
    // 检验位的检测
    let arrInt = []; let arrCh = []; let cardTemp = 0; let i = 0
    if (card.length === '15') { // 15位转18位
      arrInt = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
      arrCh = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2']
      cardTemp = 0
      card = card.substr(0, 6) + '19' + card.substr(6, card.length - 6)
      for (i = 0; i < 17; i++) {
        cardTemp += card.substr(i, 1) * arrInt[i]
      }
      card += arrCh[cardTemp % 11]
    }
    len = card.length
    if (len === '18') {
      arrInt = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
      arrCh = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2']
      cardTemp = 0
      let valnum = 0
      for (i = 0; i < 17; i++) {
        cardTemp += card.substr(i, 1) * arrInt[i]
      }
      valnum = arrCh[cardTemp % 11]
      if (valnum === card.substr(17, 1)) {
        pass = true
      }
      pass = false
    }
    if (pass) {
      return true
    } else {
      return false
    }
  },
  /**
   * 时间戳转日期
   * @param {Number} timeStamp 传入的时间戳(毫秒级)
   * @param {Number} type 返回的时间类型(默认0)
   *                      0: ‘YYYY-MM-DD hh:mm:ss’
   *                      1: ‘YYYY-MM-DD’
   */
  timestamp2Date: function (timeStamp, type) {
    if (timeStamp === undefined || timeStamp === '') {
      return ''
    }
    const date = new Date(parseInt(timeStamp))
    const YY = date.getFullYear()
    const MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
    const DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate())
    const hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours())
    const mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())
    const ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
    if (type === 1) {
      return YY + '-' + MM + '-' + DD
    }
    return YY + '-' + MM + '-' + DD + ' ' + hh + ':' + mm + ':' + ss
  },
  /**
   * 金额保留两位小数并补足0
   */
  toFixedPrice (num) {
    return (Math.round(num * 100) / 100).toFixed(2)
  },
  /**
   * 根据出生日期获取年龄性别
   */
  getInfoFormIdCard (idCard) {
    let sex = null
    let birth = null
    const myDate = new Date()
    const month = myDate.getMonth() + 1
    const day = myDate.getDate()
    let age = 0

    if (idCard.length === 18) {
      age = myDate.getFullYear() - idCard.substring(6, 10) - 1
      sex = idCard.substring(16, 17)
      birth = idCard.substring(6, 10) + '-' + idCard.substring(10, 12) + '-' + idCard.substring(12, 14)
      if (idCard.substring(10, 12) < month || (idCard.substring(10, 12) === month && idCard.substring(12, 14) <= day)) {
        age++
      }
    }
    if (idCard.length === 15) {
      age = myDate.getFullYear() - idCard.substring(6, 8) - 1901
      sex = idCard.substring(13, 14)
      birth = '19' + idCard.substring(6, 8) + '-' + idCard.substring(8, 10) + '-' + idCard.substring(10, 12)
      if (idCard.substring(8, 10) < month || (idCard.substring(8, 10) === month && idCard.substring(10, 12) <= day)) {
        age++
      }
    }
    if (sex % 2 === 0) {
      sex = '0' // 性别代码 1代表男，0代表女，暂时不涉及其他类型性别
    } else {
      sex = '1'
    }
    return {
      age,
      sex,
      birth
    }
  },
  /**
   */
  checkEmail (value) {
    const emailReg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
    if (value === '' || value === undefined || value == null) {
      return false
    }
    if (emailReg.test(value)) {
      return true
    } else {
      return false
    }
  },
  /**
 * 根据文件名后缀区分 文件类型
 *
 * @param: fileName - 文件名称
 * @param: 数据返回 1) 无后缀匹配 - false
 * @param: 数据返回 2) 匹配图片 - image
 * @param: 数据返回 3) 匹配 txt - txt
 * @param: 数据返回 4) 匹配 excel - excel
 * @param: 数据返回 5) 匹配 word - word
 * @param: 数据返回 6) 匹配 pdf - pdf
 * @param: 数据返回 7) 匹配 ppt - ppt
 * @param: 数据返回 8) 匹配 视频 - video
 * @param: 数据返回 9) 匹配 音频 - radio
 * @param: 数据返回 10) 其他匹配项 - other
 */
  matchFileType (fileName) {
  // 后缀获取
    let suffix = ''
    // 获取类型结果
    let result = ''
    if (!fileName) return false
    try {
    // 截取文件后缀
      suffix = fileName.substr(fileName.lastIndexOf('.') + 1, fileName.length)
      // 文件后缀转小写，方便匹配
      suffix = suffix.toLowerCase()
    } catch (err) {
      suffix = ''
    }
    // fileName无后缀返回 false
    if (!suffix) {
      result = false
      return result
    }

    const fileTypeList = [
    // 图片类型
      { typeName: 'image', types: ['png', 'jpg', 'jpeg', 'bmp', 'gif'] },
      // 文本类型
      { typeName: 'txt', types: ['txt'] },
      // excel类型
      { typeName: 'excel', types: ['xls', 'xlsx'] },
      { typeName: 'word', types: ['doc', 'docx'] },
      { typeName: 'pdf', types: ['pdf'] },
      { typeName: 'ppt', types: ['ppt'] },
      { typeName: 'pptx', types: ['pptx'] },
      // 视频类型
      { typeName: 'video', types: ['mp4', 'm2v', 'mkv'] },
      // 音频
      { typeName: 'radio', types: ['mp3', 'wav', 'wmv'] }
    ]
    // let fileTypeList = ['image', 'txt', 'excel', 'word', 'pdf', 'video', 'radio']
    for (let i = 0; i < fileTypeList.length; i++) {
      const fileTypeItem = fileTypeList[i]
      const typeName = fileTypeItem.typeName
      const types = fileTypeItem.types
      // console.log(fileTypeItem)
      result = types.some(function (item) {
        return item === suffix
      })
      if (result) {
        return typeName
      }
    }
    return 'other'
  },

  // 秒数转化为时分秒
  formatSeconds (value) {
    let secondTime = parseInt(value) // 秒
    let minuteTime = 0 // 分
    let hourTime = 0 // 小时
    if (secondTime > 60) { // 如果秒数大于60，将秒数转换成整数
      // 获取分钟，除以60取整数，得到整数分钟
      minuteTime = parseInt(secondTime / 60)
      // 获取秒数，秒数取余，得到整数秒数
      secondTime = parseInt(secondTime % 60)
      // 如果分钟大于60，将分钟转换成小时
      if (minuteTime > 60) {
        // 获取小时，获取分钟除以60，得到整数小时
        hourTime = parseInt(minuteTime / 60)
        // 获取小时后取余的分，获取分钟除以60取余的分
        minuteTime = parseInt(minuteTime % 60)
      }
    }
    let result = '' + this.addZero(parseInt(secondTime))

    if (minuteTime > 0) {
      result = '' + this.addZero(parseInt(minuteTime)) + ':' + result
    }
    if (hourTime > 0) {
      result = '' + this.addZero(parseInt(hourTime)) + ':' + result
    }
    // console.log('result', result)
    return result
  },
  addZero (num) {
    const len = String(num).length
    // console.log(len)
    return len === 1 ? '0' + String(num) : len === 2 ? '' + String(num) : String(num)
  }

}
