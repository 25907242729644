<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="top-backview">
    <div class="contnet-backview">
      <ghacBanner></ghacBanner>
      <ghacTab :activeIndex="1"></ghacTab>
      <div class="centerclass">
        <div class="center-titleview">
          <div class="titleStr">大会活动主题分类 ｜ Themes</div>
        </div>
        <div class="page_text">
          <div>在联合国教科文组织《保护非物质文化遗产公约》的指导下，结合我国国家级非物质文化遗产代表性项目名录的分类标准和方式，大会基于全球文化关联、中外文化对比、世界遗产共享的原则，选取设定了八大活动主题。学生可以结合自身所在的文化区域的非遗特色，选取感兴趣的主题对本地区文化进行挖掘探究，培养自身解决问题和创新实践能力的同时，为弘扬地区文化做出应有贡献。</div>
        </div>
        <div class="theme-title">
          <img :src="currentTitle" alt="">
          <div class="bg"></div>
        </div>
        <div class="themes">
          <el-carousel type="card" class="carousel" :autoplay="false" indicator-position="none" :interval="5000" arrow="never" @change="carouselChange">
            <el-carousel-item v-for="(item,i) of themes" :key="i">
              <img :src="item.banner" class="theme-banner" alt="">
            </el-carousel-item>
          </el-carousel>
          <div class="video" v-if="currentVideoUrl">
            <video :poster="poster" :src="currentVideoUrl" controls :autoplay="false" width="580" height="328"></video>
            <div class="video-name">{{ currentVideoName }}</div>
          </div>
          <!--  v-for="(item,i) of themes" :key="i" -->
          <div class="theme-item" style="display: none;">
            <!-- <img :src="item.banner" class="theme-banner" alt=""> -->
            <div class="circle-bg-title"><span>跨</span><span>学</span><span>科</span><span>探</span><span>究</span></div>
            <div class="sub-item" v-for="(subRow, m) of themeItem.subject" :key="m">
              <div class="sub-row" v-for="(subCol, n) of subRow" :key="n">
                <div class="sub-col"><div class="block" :class="subCol.checked ? 'checked' : ''"></div><div>{{ subCol.name }}</div></div>
              </div>
            </div>
            <div class="circle-bg-title"><span>专</span><span>业</span><span>方</span><span>向</span></div>
            <div class="theme-desc">{{ themeItem.major }}</div>
            <div class="circle-bg-title"><span>就</span><span>业</span><span>方</span><span>向</span></div>
            <div class="theme-desc">{{ themeItem.job }}</div>
            <el-row class="secondary">
              <el-col :span="8" v-for="(img,j) of themeItem.secondary" :key="j">
                <img class="secondary-img" :src="img" alt="">
              </el-col>
            </el-row>
          </div>
          <div class="center-titleview">
            <div class="titleStr">大会活动文化选区 ｜ Cultural Areas</div>
          </div>
          <div class="area-flex">
            <div class="page_text">
              <div>中国作为一个幅员辽阔的泱泱大国，各地的自然、地理、人文条件千差万别。中国文化在漫长的发展历程中，因其腹地开阔，南北东西各路相激相荡，燕赵、齐鲁与荆楚、岭南相互吸纳，三秦、三晋与巴蜀、吴越互为应援，呈现区域文化多样化发展的局面。</div>
              <div>基于中国传统文化区域的划分，大会以八大文化区域为探究范畴，鼓励青少年去探究异彩纷呈、生机盎然的华夏文化，去感受不同区域和而不同、刚健自强的文化机制。在每个地区，大会特别甄选了部分重点城市，作为不同文化区域的代表城市，并将在这些城市着重推动国际理解教育的开展和非遗教育的传承。</div>
            </div>
            <img class="page_img2" src="https://cdn.spbcn.org/ghac/pictures/map.png" alt="">
          </div>

          <!-- <div class="sec-title">2024年度展演主题</div> -->
          <div class="center-titleview">
            <div class="titleStr">2024年度展演主题 ｜ 2024 Annual Exhibition Theme</div>
          </div>
          <div class="page_text">
            <div> 2024年度环球文遗小使者大会·世界非遗青少年展演活动发布了<span style="color: #b51c25;">美术工艺类、建筑工程类、美食文化类和服饰纺织类</span>四大主题作为此次展演内容。参加展演的队伍需从以上四大主题中，任选一个主题进行相关的非遗探究并完成相应的展演内容，同时回应联合国17个可持续发展目标，并解决相应的问题。</div>
          </div>
          <div class="image-flex">
            <div class="imgs">
              <img src="https://cdn.spbcn.org/ghac/theme/1.png" class="page_img4" alt="">
              <img src="https://cdn.spbcn.org/ghac/theme/2.png" class="page_img4" alt="">
              <img src="https://cdn.spbcn.org/ghac/theme/3.png" class="page_img4" alt="">
              <img src="https://cdn.spbcn.org/ghac/theme/4.png" class="page_img4" alt="">
            </div>
            <img class="page_img1-2" src="https://cdn.spbcn.org/ghac/theme/2.jpg" alt="">
          </div>
          <!-- <div class="tip" @click="showMore = !showMore">点击{{ showMore ? '隐藏' : '查看' }}主题解读</div> -->
           <!--  v-if="showMore" -->
          <div>
            <div class="sec-title">主题解读</div>
            <img class="page_img border-r0" src="https://cdn.spbcn.org/ghac/theme/theme2.jpg" alt="">
            <div class="page_text">
              <div>如参演团队有其他非遗探究成果，可以自行从八大主题中进行匹配、探究。</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ghacTab from '../../components/ghacTab.vue'
import ghacBanner from '../../components/ghacBanner2.vue'

export default {
  components: {
    ghacTab,
    ghacBanner
  },
  data () {
    return {
      showMore: false,
      // images: [
      //   { url: 'https://cdn.spbcn.org/ghac/page/1/HQWY_HQDH_banne_01.png' },
      //   { url: 'https://cdn.spbcn.org/ghac/assets/feiyiprograms4_1_banner.png' }
      // ],
      themeItem: {},
      currentVideoUrl: 'https://cdn.spbcn.org/ghac/video/msgy01.mp4',
      poster: 'https://cdn.spbcn.org/ghac/page/2/HQWY_HQDH_WHZT_ZTFL_MSGY_banner.png',
      currentVideoName: '《纸下的时间旅行者》',
      currentTitle: 'https://cdn.spbcn.org/ghac/1.png',
      themes: [
        {
          id: 3,
          name: '美术工艺类',
          title: 'https://cdn.spbcn.org/ghac/1.png',
          banner: 'https://cdn.spbcn.org/ghac/page/2/HQWY_HQDH_WHZT_ZTFL_MSGY_banner.png',
          video: 'https://cdn.spbcn.org/ghac/video/msgy01.mp4',
          videoName: '《纸下的时间旅行者》',
          subject: [
            [
              { name: '语文', checked: true },
              { name: '数学', checked: false },
              { name: '英语', checked: true },
              { name: '物理', checked: false },
              { name: '化学', checked: false }
            ],
            [
              { name: '生物', checked: false },
              { name: '地理', checked: false },
              { name: '历史', checked: true },
              { name: '政治', checked: false },
              { name: '信息技术', checked: false }
            ],
            [
              { name: '体育', checked: false },
              { name: '美术', checked: true },
              { name: '音乐', checked: false }
            ]
          ],
          major: '美术学、绘画、雕塑、工艺美术、文物与博物馆学、艺术史论等。',
          job: '美术教育、美术研究、文博艺术管理、工艺美术设计、新闻出版等。',
          secondary: [
            'https://cdn.spbcn.org/ghac/page/2/3-1.png',
            'https://cdn.spbcn.org/ghac/page/2/3-2.png',
            'https://cdn.spbcn.org/ghac/page/2/3-3.png',
            'https://cdn.spbcn.org/ghac/page/2/3-4.png',
            'https://cdn.spbcn.org/ghac/page/2/3-5.png'
          ]
        },
        {
          id: 1,
          name: '建筑工程类',
          title: 'https://cdn.spbcn.org/ghac/2.png',
          banner: 'https://cdn.spbcn.org/ghac/page/2/HQWY_HQDH_WHZT_ZTFL_JZGC_banner.png',
          video: 'https://cdn.spbcn.org/ghac/video/jzgc01.mp4',
          videoName: '《翻开历史“桥”天津》',
          subject: [
            [
              { name: '语文', checked: false },
              { name: '数学', checked: true },
              { name: '英语', checked: true },
              { name: '物理', checked: true },
              { name: '化学', checked: false }
            ],
            [
              { name: '生物', checked: false },
              { name: '地理', checked: true },
              { name: '历史', checked: true },
              { name: '政治', checked: false },
              { name: '信息技术', checked: false }
            ],
            [
              { name: '体育', checked: false },
              { name: '美术', checked: false },
              { name: '音乐', checked: false }
            ]
          ],
          major: '建筑环境与能源应用工程、建筑学、城乡规划、农业建筑环境与能源工程、建筑电气与智能化等。',
          job: '建筑设计、建筑策划、室内装饰设计、市政工程等。',
          secondary: [
            'https://cdn.spbcn.org/ghac/page/2/1-1.png',
            'https://cdn.spbcn.org/ghac/page/2/1-2.png',
            'https://cdn.spbcn.org/ghac/page/2/1-3.png',
            'https://cdn.spbcn.org/ghac/page/2/1-4.png',
            'https://cdn.spbcn.org/ghac/page/2/1-5.png',
            'https://cdn.spbcn.org/ghac/page/2/1-6.png'
          ]
        },
        {
          id: 2,
          name: '服饰纺织类',
          title: 'https://cdn.spbcn.org/ghac/5.png',
          banner: 'https://cdn.spbcn.org/ghac/page/2/HQWY_HQDH_WHZT_ZTFL_FSFZ_banner.png',
          video: 'https://cdn.spbcn.org/ghac/video/fsfz01.mp4',
          videoName: '《遇见非遗之美，弘扬传统文化》',
          subject: [
            [
              { name: '语文', checked: true },
              { name: '数学', checked: true },
              { name: '英语', checked: true },
              { name: '物理', checked: false },
              { name: '化学', checked: true }
            ],
            [
              { name: '生物', checked: false },
              { name: '地理', checked: false },
              { name: '历史', checked: true },
              { name: '政治', checked: false },
              { name: '信息技术', checked: false }
            ],
            [
              { name: '体育', checked: false },
              { name: '美术', checked: true },
              { name: '音乐', checked: false }
            ]
          ],
          major: '美术学、绘画、纺织工艺、艺术设计学、视觉传达设计、服饰与服装设计、公共艺术、工艺美术等。',
          job: '美术教育、美术研究、文博艺术管理、工艺美术设计、服装设计等。',
          secondary: [
            'https://cdn.spbcn.org/ghac/page/2/2-1.png',
            'https://cdn.spbcn.org/ghac/page/2/2-2.png',
            'https://cdn.spbcn.org/ghac/page/2/2-3.png',
            'https://cdn.spbcn.org/ghac/page/2/2-4.png',
            'https://cdn.spbcn.org/ghac/page/2/2-5.png'
          ]
        },
        {
          id: 5,
          name: '美食文化类',
          title: 'https://cdn.spbcn.org/ghac/4.png',
          banner: 'https://cdn.spbcn.org/ghac/page/2/HQWY_HQDH_WHZT_ZTFL_MSWH_banner.png',
          video: 'https://cdn.spbcn.org/ghac/video/mswh01.mp4',
          videoName: '《德意“香”传》',
          subject: [
            [
              { name: '语文', checked: true },
              { name: '数学', checked: false },
              { name: '英语', checked: true },
              { name: '物理', checked: false },
              { name: '化学', checked: true }
            ],
            [
              { name: '生物', checked: true },
              { name: '地理', checked: false },
              { name: '历史', checked: true },
              { name: '政治', checked: false },
              { name: '信息技术', checked: false }
            ],
            [
              { name: '体育', checked: false },
              { name: '美术', checked: false },
              { name: '音乐', checked: false }
            ]
          ],
          major: '食品质量与安全、粮食工程、乳品工程、酿酒工程、食品卫生与营养学等。',
          job: '食品生产企业的生产工艺设计、新产品开发、质量检测、经营管理等工作;食品的科学研究和成果推广工作;食品质量监督、海关、商检、卫生防疫、进出口等部门的产品分析、检测工作;相关的国家机关、大专院校、科研院所的工作等。',
          secondary: [
            'https://cdn.spbcn.org/ghac/page/2/5-1.png',
            'https://cdn.spbcn.org/ghac/page/2/5-2.png',
            'https://cdn.spbcn.org/ghac/page/2/5-3.png'
          ]
        },
        {
          id: 6,
          name: '曲艺戏剧类',
          title: 'https://cdn.spbcn.org/ghac/7.png',
          banner: 'https://cdn.spbcn.org/ghac/page/2/HQWY_HQDH_WHZT_ZTFL_QYXJ_banner.png',
          video: 'https://cdn.spbcn.org/ghac/video/qyxj01.mp4',
          videoName: '《指尖上的艺术，心目中的英雄》',
          subject: [
            [
              { name: '语文', checked: true },
              { name: '数学', checked: false },
              { name: '英语', checked: true },
              { name: '物理', checked: false },
              { name: '化学', checked: false }
            ],
            [
              { name: '生物', checked: false },
              { name: '地理', checked: false },
              { name: '历史', checked: true },
              { name: '政治', checked: true },
              { name: '信息技术', checked: false }
            ],
            [
              { name: '体育', checked: false },
              { name: '美术', checked: true },
              { name: '音乐', checked: true }
            ]
          ],
          major: '音乐表演、舞蹈表演、戏剧学、电影学、戏剧影视文学、戏剧影视美术设计、录音艺术等。',
          job: '主要就职于各类艺术院团、电视台、培训机构、群众文化活动部门、休闲娱乐场所等单位，从事音乐剧表演、舞蹈表演、影视表演、声乐演唱、群众文化活动教学与辅导等工作。',
          secondary: [
            'https://cdn.spbcn.org/ghac/page/2/6-1.png',
            'https://cdn.spbcn.org/ghac/page/2/6-2.png'
          ]
        },
        {
          id: 7,
          name: '医药文化类',
          title: 'https://cdn.spbcn.org/ghac/8.png',
          banner: 'https://cdn.spbcn.org/ghac/page/2/HQWY_HQDH_WHZT_ZTFL_YYWH_banner.png',
          video: 'https://cdn.spbcn.org/ghac/video/yywh01.mp4',
          videoName: '《大医精诚》',
          subject: [
            [
              { name: '语文', checked: true },
              { name: '数学', checked: true },
              { name: '英语', checked: true },
              { name: '物理', checked: false },
              { name: '化学', checked: true }
            ],
            [
              { name: '生物', checked: true },
              { name: '地理', checked: false },
              { name: '历史', checked: true },
              { name: '政治', checked: false },
              { name: '信息技术', checked: true }
            ],
            [
              { name: '体育', checked: false },
              { name: '美术', checked: false },
              { name: '音乐', checked: false }
            ]
          ],
          major: '基础医学，临床医学、中医学、针灸推拿学，中西医临床医学、药学、中药学等。',
          job: '科研人员--在大学、研究所、药厂的研究部门，从事药物的研发工作;医院药剂师--在医院药剂科、药房、药厂等从事制剂、质检、临床药学等工作;药检人员--在药检所从事药物的质量鉴定和制定相应的质量标准;公司职员、医药销售人员--在医药贸易公司或制药企业从事药品生产、流通及销售等工作。',
          secondary: [
            'https://cdn.spbcn.org/ghac/page/2/7-1.png',
            'https://cdn.spbcn.org/ghac/page/2/7-2.png'
          ]
        },
        {
          id: 4,
          name: '节令民俗类',
          title: 'https://cdn.spbcn.org/ghac/3.png',
          banner: 'https://cdn.spbcn.org/ghac/page/2/HQWY_HQDH_WHZT_ZTFL_JLMS_banner.png',
          subject: [
            [
              { name: '语文', checked: true },
              { name: '数学', checked: false },
              { name: '英语', checked: true },
              { name: '物理', checked: false },
              { name: '化学', checked: false }
            ],
            [
              { name: '生物', checked: false },
              { name: '地理', checked: false },
              { name: '历史', checked: true },
              { name: '政治', checked: false },
              { name: '信息技术', checked: false }
            ],
            [
              { name: '体育', checked: true },
              { name: '美术', checked: true },
              { name: '音乐', checked: true }
            ]
          ],
          major: '哲学、宗教学、国际政治、社会学、社会工作、民族学、教育学、人文教育等。',
          job: '教育研究、党政工作、新闻出版、文教事业等。',
          secondary: [
            'https://cdn.spbcn.org/ghac/page/2/4-1.png',
            'https://cdn.spbcn.org/ghac/page/2/4-2.png',
            'https://cdn.spbcn.org/ghac/page/2/4-3.png'
          ]
        },
        {
          id: 8,
          name: '体育杂技类',
          title: 'https://cdn.spbcn.org/ghac/6.png',
          banner: 'https://cdn.spbcn.org/ghac/page/2/HQWY_HQDH_WHZT_ZTFL_TYZJ_banner.png',
          subject: [
            [
              { name: '语文', checked: true },
              { name: '数学', checked: false },
              { name: '英语', checked: true },
              { name: '物理', checked: false },
              { name: '化学', checked: false }
            ],
            [
              { name: '生物', checked: false },
              { name: '地理', checked: false },
              { name: '历史', checked: true },
              { name: '政治', checked: false },
              { name: '信息技术', checked: false }
            ],
            [
              { name: '体育', checked: true },
              { name: '美术', checked: true },
              { name: '音乐', checked: true }
            ]
          ],
          major: '体育教育、运动训练、社会体育指导与管理、武术与民族传统体育、运动人体科学等。',
          job: '主要就职于各级社会体育管理机构、各类体育协会、体育俱乐部、体育企业和体育场馆部门高等院校等。',
          secondary: [
            'https://cdn.spbcn.org/ghac/page/2/8-1.png',
            'https://cdn.spbcn.org/ghac/page/2/8-2.png'
          ]
        }
      ]
    }
  },
  created () {
    this.themeItem = this.themes[0]
  },
  methods: {
    fn (path) {
      if (path) {
        this.$router.push(path)
      } else {
        this.handleToReg()
      }
    },
    carouselChange (i) {
      this.themeItem = this.themes[i]
      this.currentVideoUrl = this.themes[i].video
      this.currentVideoName = this.themes[i].videoName
      this.poster = this.themes[i].banner
      this.currentTitle = this.themes[i].title
    },
    handleToReg () {
      const token = localStorage.getItem('HQAuthorization')
      if (token) {
        this.$router.push('/club/register?step=0')
      } else {
        this.$router.push('/loginview')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.contnet-backview {
  // width: 1000px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-bottom: 80px;
}
.top-backview {
  width: 100%;
  // padding-bottom: 10px;
  background-color: rgb(216, 216, 216);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.dibannerclass {
  width: 1000px;
  height: 469px;
  z-index: 99;
}
.bannerbottom {
  width: 1000px;
  height: 100px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 150px;
  z-index: 100;
}
.title-backview {
  height: 80px;
  font-size: 32px;
  font-family: FZLBJW;
  font-weight: 900;
  text-align: center;
  line-height: 40px;
  padding: 0px 20px;
  color: white;
  border: 2px solid rgb(255, 255, 255);
  .en{
    font-size: 24px;
    margin-top: 10px;
  }
}
.red-backview {
  width: 1000px;
  height: 25px;
}
.red-lineview {
  width: 1000px;
  height: 10px;
  // margin-top: 15px;
  background-color: #e50211;
}
.centerclass {
  width: 1000px;
}
.center-titleview {
  display: inline-block;
  padding-right: 100px;
  height: 42px;
  margin-top: 50px;
  background: url(https://cdn.spbcn.org/ghac/assets/feiyi_homepage_title.png);
  background-size: auto 100%;
  background-position: right;
  background-repeat: no-repeat;
}

.titleStr {
  // width: 600px;
  height: 42px;
  line-height: 42px;
  // text-align: center;
  font-size: 24px;
  font-family: FZLBJW;
  color: white;
  font-weight: bolder;
  padding-left: 30px;
  background-color: #b81b26;
}

.banner-img-class {
  width: 1000px;
  height: 470px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.el-carousel {
  width: 1000px;
  height: 470px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.sec-title{
  width: 260px;
  height: 38px;
  background-image: url(https://cdn.spbcn.org/ghac/ghac_button_highlight.png);
  background-size: 100% 100%;
  text-align: center;
  color: #fff;
  font-family: hzgb;
  font-size: 18px;
  line-height: 38px;
  margin: 40px auto 30px;
  letter-spacing: 2px;
}
.area-flex{
  display: flex;
  align-items: center;
}
.page_text{
  font-size: 20px;
  font-family: FZLBJW;
  line-height: 32px;
  margin-top: 20px;
  div{
    text-indent: 2em;
  }
}
.page_img{
  width: 1000px;
  // height: 186px;
  border-radius: 30px;
  margin-top: 40px;
}
.border-r0{
  border-radius: 0;
}
.page_img2{
  width: 420px;
  margin-left: 40px;
}
.topMenuBackview{
  width: 1000px;
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.menu-backview{
  color: #fff;
  font-size: 28px;
  font-family: FZLBJW;
  background-color: #000;
  height: 42px;
  line-height: 42px;
  padding: 0 40px;
  border-radius: 42px;
  cursor: pointer;
}
.selectColor {
  background-color: #b71b26;
}
.carousel{
  height: 200px;
}
/deep/ .el-carousel__container{
  height: 200px;
}
.themes{
  margin-top: 40px;
  .theme-banner{
    width: 100%;
    height: auto;
  }
  .circle-bg-title{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 40px;
    span{
      display: block;
      width: 44px;
      height: 44px;
      background-color: #b81c25;
      color: #fff;
      font-family: regular;
      font-size: 28px;
      text-align: center;
      line-height: 40px;
      margin-right: 6px;
      border-radius: 44px;
    }
  }
  .theme-desc{
    font-family: regular;
    font-size: 18px;
    color: #000;
  }
  .sub-item{
    display: flex;
    align-items: center;
    .sub-row{
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .sub-col{
        // width: 100px;
        font-family: regular;
        font-size: 18px;
        color: #000;
        display: flex;
        align-items: center;
        margin-right: 20px;
        .block{
          width: 18px;
          height: 18px;
          box-sizing: border-box;
          border: 2px solid #b81c25;
          margin-right: 6px;
          &.checked{
            background-color: #b81c25;
          }
        }
      }
    }
  }
  .secondary{
    margin-top: 20px;
    text-align: center;
    margin-bottom: 40px;
    .secondary-img{
      height: 160px;
      margin-top: 20px;
    }
  }
}

/deep/ .el-carousel__item{
  background-color: transparent !important;
}

.sec-title {
  width: 260px;
  height: 38px;
  background-image: url(https://cdn.spbcn.org/ghac/ghac_button_highlight.png);
  background-size: 100% 100%;
  text-align: center;
  color: #fff;
  font-family: hzgb;
  font-size: 18px;
  line-height: 38px;
  margin: 40px auto 30px;
  letter-spacing: 2px;
}
.imgs{
  width: 100%;
  margin-top: 20px;
  .page_img4{
    width: 24%;
    margin: 0 0.5%;
  }
}
// .page_img1-1{
//   height: 258px;
//   margin-right: 10px;
//   margin-top: 20px;
// }
.page_img1-2{
  height: 202px;
  margin-left: 10px;
  margin-top: 20px;
}
.image-flex{
  display: flex;
  align-items: center;
}
::v-deep .el-carousel__button{
  width: 10px;
}
.video{
  border: 10px solid #b71b26;
  background-color: #b71b26;
  border-radius: 10px;
  width: 580px;
  height: 354px;
  margin: 0 auto;
  video{
    width: 580px;
    height: 328px;
  }
  .video-name{
    text-align: center;
    font-size: 14px;
    color: #fff;
  }
}
.tip{
  color: #b81b26;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  margin: 40px 0;
  &:hover{
    text-decoration: underline;
  }
}
.theme-title{
  width: 198px;
  height: 60px;
  position: relative;
  margin-top: 40px;
  img{
    width: 198px;
    height: auto;
    position: relative;
    z-index: 1;
  }
  .bg{
    width: 100%;
    height: 40px;
    background-color: #b81b26;
    position: absolute;
    left: 0;
    top: 17px;
    z-index: 0;
  }
}
</style>
