<template>
  <div class="backview">
    <div class="username">HI!{{ userName }} Teacher</div>
    <div class="welcome">welcome !</div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      userName: ''
    }
  },
  created () {
    // const schoolData = JSON.parse(localStorage.getItem('saveSchoolInfo'))
    // this.userName = schoolData.
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
.backview {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.username {
  width: 100%;
  font-size: 36px;
  text-align: center;
  color: black;
  font-weight: 900;
}
.welcome {
  width: 100%;
  font-size: 46px;
  text-align: center;
  color: red;
  font-weight: 900;
}
</style>
