<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="top-backview">
    <div class="contnet-backview">
      <div class="dibannerclass">
        <el-carousel
          :interval="3000"
          indicator-position="none"
          id="el-carousel"
          :height="dataHeight"
        >
          <el-carousel-item v-for="item in images" :key="item.index">
            <img :src="item.url" alt="" class="banner-img-class" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="bannerbottom">
        <div class="title-backview">
          <div>环球文遗小使者大会<br>2024年度展演</div>
          <div class="en">Global Intangible Cultural Heritage Little Ambassadors' Conference</div>
        </div>
      </div>
      <div class="red-backview">
        <div class="red-lineview"></div>
      </div>
      <div class="topMenuBackview">
        <div
          class="menu-backview"
          v-for="item in menuList"
          :class="{ selectColor: item.active }"
          @click="fn(item.path)"
          :key="item.idnex"
        >
          {{ item.title }}
        </div>
      </div>
      <div class="centerclass">
        <div class="center-titleview">
          <div class="titleStr">大会起源 ｜ Origin</div>
        </div>
        <div>
          <img class="page_img" src="https://cdn.spbcn.org/ghac/page/1/HQWY_HQDH_DHXG_DHQY_banner.png" alt="">
          <div class="page_text">
            <div>环球文遗小使者大会(以下简称“大会”)起源于拥有5000年璀璨历史文化的中国。在后疫情时代，大会为响应联合国17个可持续发展目标的战略规划、遵照构建人类命运共同体的理念、为推进联合国教科文组织在全球青少年范围内国际理解教育的实施，提升全球青少年人文素养能力，特别甄选世界非物质文化遗产作为大会文化探究的基础，开设8大类型挑战活动，设置近百个主题500多个项目。通过博古、通今、创未来的大会思想，让青少年在通晓本国传统文化的基础上实现地域和全球国际比较教育理解，并且通过校园科目学习的渗透，去探究自己未来感兴趣的大学专业和职业，将传统的历史文化和现实的生活场景进行融合探究，并激发学生对于未来科学和生活的创新。</div>
            <div>环球文遗小使者大会的研发智库包含联合国教科文组织相关专家学者，也包含各国教育相关部门、非物质文化遗产相关部门专家，并联合全球和地区各高校的教授和学者共同设计和进行评定工作。大会将通过学校社团甄选参加环球文遗小使者大会的团队，并将为组织参与的学校提供相关的课程体系和研学探究方案，让学生能够有充足的知识储备，来代表学校和团队参与其中。</div>
            <div>大会发起方UIE聚知优国际教育集团，作为“SpellingBeeofChina”中国地区组委会已经成功在中国地区的60多个城市举办此项活动。SpellingBee英语素质教育活动1925年起源于美国，作为全球范围内影响力最大，参与人数最多的青少年活动，已经成功在全球5大洲100多个国家开展将近百年的历史，每年在全球范围拥有近5000万的学生参与。SpellingBee凭借其简单和公平的英语拼词活动，深受各国青少年和家庭的喜爱。学好英文向世界表达中国璀璨传统文化，将是中国地区青少年的使命。大会希望每一位参与学生能够提升科学和人文素养的同时，真正以兴趣为导向的去了解学科知识的魅力，去探究自己未来大学的专业和未来人生的职业。</div>
          </div>
        </div>
      </div>
      <div class="centerclass">
        <div class="center-titleview">
          <div class="titleStr">大会理论背景 ｜ Theoretical Background</div>
        </div>
        <div class="page_text">
          <div>历史呼唤着人类文明同放异彩，不同文明应该和谐共生、相得益彰，共同为人类发展提供精神力量。“求同”是客观现实的要求。随着人类社会的发展，各国相互交流日益频繁。科学技术的迅猛发展、经济全球化的日益深入，形成了事实上的地球村，使得各国形成了你中有我、我中有你的命运共同体。我们生活的世界充满希望，也充满挑战。面对全球的重大问题、重大挑战，尽管世界各国有这样那样的分歧矛盾，也免不了产生这样那样的磕磕碰碰，但我们都生活在同一片蓝天下、拥有同一个家园，需要相互理解、相互包容，共同解决人类发展的难题，共享人类文明进步的成果。</div>
        </div>
        <div class="sec-title">构建人类命运共同体</div>
        <div class="page_text">
          <div>“构建人类命运共同体”是习近平总书记于2015年9月在纽约联合国总部出席第七十届联合国大会一般性辩论时发表重要讲话中提出的治国理政方针理论。“当今世界，各国相互依存、休戚与共。我们要继承和弘扬联合国宪章的宗旨和原则，构建以合作共赢为核心的新型国际关系，打造人类命运共同体。”</div>
        </div>
        <img class="page_img" src="https://cdn.spbcn.org/ghac/page/1/HQWY_HQDH_DHXG_DHLLBJ_banne_01.png" alt="">
        <div class="page_text">
          <div>青少年是国家的未来和希望，大会充分挖掘和发挥人类命运共同体理念在新时代青少年教育中的价值引领作用，希望通过“社团课程-文化探究-成果展演-海外交流”四位一体的活动顶层设计，帮助青少年不断提升自己的科学思维能力，更好地为新时代中国特色社会主义伟大事业发展和人类社会进步贡献自己的力量。</div>
        </div>
        <div class="sec-title">国际理解教育</div>
        <div class="page_text">
          <div>“国际理解教育”理念是1946年联合国教科文组织第一次大会上正式提出的，是一个促进和平文化的全面和多层面的教育倡议。它倡导参与式民主、人权和尊严，社会和经济公平，生态可持续性以及和平与公正的冲突和解。人们希望通过教育达成各国、各文化之间的理解，以实现和平。1993年以雅克·德洛尔(JacquesDelors)为主席的国际21世纪教育委员会成立，历时3年的广泛调查和深入研究，于1996年向UNESCO提交了著名报告--《learning:thetreasurewithin(教育:财富蕴藏其中)(DelorsReport)》。该报告系统回答了面对信息社会和知识经济时代挑战的教育使命，提出面向21世纪人人必须掌握四种基本学习:学会认识，学会做事，学会生存，学会共同生活。</div>
          <div>在日益全球化的今天，当代青少年面对着不同种族和文化的冲击，他们面对的机遇、竞争和挑战更是立足于整个国际社会。面对新时代、新需求，为了帮助青少年更好地适应未来、把握未来，大会以“国际理解教育”为理念，设计四位一体活动，为肩负起培养兼具本土情怀和国际素养人才的重要使命做出自己的努力。</div>
        </div>
        <div class="page_4_img">
          <img v-for="item of 4" :key="item" :src="'https://cdn.spbcn.org/ghac/page/1/HQWY_HQDH_DHXG_DHLLBJ_batten_0'+ item +'.png'" alt="">
        </div>
        <img class="page_img" src="https://cdn.spbcn.org/ghac/page/1/HQWY_HQDH_DHXG_DHLLBJ_banne_02.png" alt="">
        <div class="sec-title">联合国十七项可持续发展目标</div>
        <div class="page_text">
          <div>联合国可持续发展目标(SustainableDevelopmentGoals，简称“SDGs”)，是联合国在2015年通过的一项重要议程，旨在全球范围内推动可持续发展。该议程是在联合国发展峰会上通过的《2030年可持续发展议程》的核心内容，共包括17个具体的目标，涵盖了经济、社会和环境等多个方面。</div>
          <div>教育被认为是可持续发展道路最重要的转型变革驱动因素之一。2015年，联合国相关会议报告指出，“教育被认为是推动可持续发展所必需的转型变革的最有力手段之一，但要实现这一潜力，教育系统需要具有灵活性、文化敏感性、相关性和适用性，能够改变人们的价值观和行为”。</div>
          <div>为了促进可持续发展目标的实现，将这些目标融入教育体系中变得愈发重要。大会将可持续发展目标融入活动设计理念，有助于提高学生的综合能力，激发他们的积极性和责任感，让他们更好地了解和参与可持续发展，乃至未来世界发展的进程。</div>
        </div>
        <img class="page_img_circle" src="https://cdn.spbcn.org/ghac/page/1/HQWY_HQDH_DHXG_DHLLBJ_banner_03.png" alt="">
      </div>
      <div class="sec-title">PBL项目式学习</div>
      <div class="red-bg">
        <div class="centerclass">
          <div class="page_text page_text2">
            <div>PBL全称为Project-BasedLearning，即基于项目的学习方式，也就是项目教学法。最早起源于20世纪50年代的医学教育，PBL教学法主要是指在教师的指导下，通过一个完整项目的选题、设计、执行、评估全过程，对于某个产品、服务项目进行全方位的学习理解。项目式学习为的是培养孩子/学生的批判性思维和解决问题的能力，过程中要培养团队合作能力，创新创造能力，自我管理能力，公众演讲和表达能力等等。</div>
            <div>大会设计的活动以及需要学生完成的任务，都是建立在项目的基础之上。学生需要以小组合作的形式进行，同时需要在关键问题驱动下进行自主学习和探究，最终呈现出本组的探究结果。</div>
          </div>
        </div>
      </div>
      <img class="page_img_circle" src="https://cdn.spbcn.org/ghac/page/1/HQWY_HQDH_DHXG_DHLLBJ_banner_04.png" alt="">

      <div class="sec-title">5C核心素养</div>
      <div class="red-bg">
        <div class="centerclass">
          <div class="page_text page_text2">
            <div>国际理解教育人文素养课程是在《21世纪核心素养5C模型研究报告(中文版)》成果的基础上实施的。这份报告吸纳了中国学者在相关领域的研究成果，并基于我国社会、经济、科技、教育发展需求，进一步追问“打下中国根基、兼具国际视野”的人应该具有哪些素养，提出了“21世纪核心素养5C模型”并搭建框架、阐述内涵。</div>
            <div>大会深知优秀传统文化的重要意义，这与5C核心素养中以“文化理解与传承”为中心不谋而合。大会以非物质文化遗产为依托，旨在帮助青少年感悟华夏文明在世界历史中的重要地位，培养青少年对中华民族的文化生命力及其发展前景具有坚定信念和情感认同。</div>
          </div>
        </div>
      </div>
      <img class="page_img_circle" src="https://cdn.spbcn.org/ghac/page/1/HQWY_HQDH_DHXG_DHLLBJ_banner_05.png" alt="">

      <div class="centerclass">
        <div class="item" v-for="(item,i) of list" :key="i">
          <div class="sec-title">{{item.title}}</div>
          <div class="page_text">
            <div>{{ item.desc1 }}</div>
            <div>{{ item.desc2 }}</div>
          </div>
          <img class="page_img" :src="item.img" alt="">
        </div>
        <div class="centerclass">
          <div class="center-titleview">
            <div class="titleStr">大会实践意义 ｜ Practical Significance</div>
          </div>
        </div>
        <div class="item" v-for="(item,i) of list2" :key="i">
          <div class="sec-title">{{item.title}}</div>
          <div class="page_text">
            <div>{{ item.desc }}</div>
          </div>
          <img class="page_img" :src="item.img" alt="">
        </div>
      </div>

    </div>
  </div>
</template>

<script>
// import Topbar from '@/components/Topbar.vue'
// import Footer from '@/components/Footer.vue'
export default {
  props: {
    dataHeight: {
      type: String,
      default: '100%'
    }
  },
  data () {
    return {
      images: [{ url: 'https://cdn.spbcn.org/ghac/page/1/HQWY_HQDH_banne_01.png' }],
      list: [
        {
          title: '全球胜任力',
          desc1: '“全球胜任力”一词最早可追溯至20世纪70年代“国际理解”一词的提出。此后，在1988年，美国国际教育交流学会在《为全球胜任力而教》报告中率先提出“全球胜任力”这一概念，强调青少年应具备能够分析当地、全球和跨文化的问题，理解和欣赏他人的观点和世界观，与不同文化背景的人进行开放、得体和有效的互动，以及为集体福祉和可持续发展采取行动的能力。总体来说，全球胜任力就是一种更好地胜任全球范围内多种职务，适应全球生存和发展的能力或素养，强调用全球的知识、技能态度来分析问题和解决问题。',
          desc2: '大会始终强调青少年需要了解本地区、国内各地区的优秀传统文化，更要明白国内外传统文化的异同。在分析比较不同文化的过程中，青少年能够提高解决未来世界问题的能力，更好地适应未来世界发展的趋势。',
          img: 'https://cdn.spbcn.org/ghac/page/1/HQWY_HQDH_DHXG_DHLLBJ_banner_06.png'
        },
        {
          title: '思政教育新要求',
          desc1: '2021年，党中央、国务院印发了《关于新时代加强和改进思想政治工作的意见》，明确提出加快构建学校思想政治工作体系。党的二十大报告明确提出，“育人的根本在于立德”“用社会主义核心价值观铸魂育人，完善思想政治工作体系，推进大中小学思想政治教育一体化建设”。教育部门和中小学校要紧密联系实际，切实推进贯彻落实。少年强则国强，中小学阶段是学生世界观、人生观、价值观形成的关键时期。',
          desc2: '大会以课程思政为抓手、以传统非遗为工具，向青少年传递文化自信、审美情趣和工匠精神等价值取向，培养青少年爱国主义精神的具体实践，将文化传承与匠心打造相融合，以此探索实践知行合一的目标。',
          img: 'https://cdn.spbcn.org/ghac/page/1/HQWY_HQDH_DHXG_DHLLBJ_banner_07.png'
        },
        {
          title: '劳动技能教育',
          desc1: '日前，教育部印发《义务教育劳动课程标准(2022年版)》，明确将劳动、信息科技从综合实践活动课程中完全独立出来，这意味着劳动教育将成为义务教育阶的必修课。劳动教育是中国特色社会主义教育制度的重要内容，直接决定社会主义建设者和接班人的劳动精神面貌、劳动价值取向和劳动技能水平。',
          desc2: '大会将培养青少年劳动意识、增强劳动观念、发挥劳动育人的独特作用纳入顶层设计理念，为青少年提供亲手体验非遗制作等主题劳动教育实践活动，使青少年在活动中提高劳动能力、感知认同非遗，推动非物质文化遗产的传承与发展。',
          img: 'https://cdn.spbcn.org/ghac/page/1/HQWY_HQDH_DHXG_DHLLBJ_banner_08.png'
        }
      ],
      list2: [
        {
          title: '天赋兴趣赋能',
          desc: '大会发布多种主题、多种类别的活动，参与队伍学生可以根据所在文化区域、个人兴趣等进行选择。同时，参与队伍各成员都有着不同角色分工，根据自身条件与专长进行选择，担任不同职责，充分激发学生的天赋与兴趣。',
          img: 'https://cdn.spbcn.org/ghac/page/1/HQWY_HQDH_DHXG_DHSJYY_banne_01.png'
        },
        {
          title: '学科能力赋能',
          desc: '大会以物质、非物质文化遗产为切入点，通过项目式学习(Project-BasedLea-rning)手段，以中小学学科及大学学科为研究方向，让学生在活动中充分运用和探究不同学科融合能力，巩固和激发学科学习效果，探索未来专业与研究方向的兴趣培养。',
          img: 'https://cdn.spbcn.org/ghac/page/1/HQWY_HQDH_DHXG_DHSJYY_banne_02.png'
        },
        {
          title: '专业职业赋能',
          desc: '参与的成员按照角色进行不同分工:队长、小匠人、小演说家、小外交官、小作家、小艺人。每位成员各司其职，同时又相互协作，在保证学生有个性、有特色发展的前提下，结合探究主题，提升学生与专业、与职业相关的知识储备与实践经验，为学生未来专业与职业规划打下基础，提升专业与职业和每位学生匹配度，让学生以兴趣为学习动力、为职业发展方向。',
          img: 'https://cdn.spbcn.org/ghac/page/1/HQWY_HQDH_DHXG_DHSJYY_banne_03.png'
        },
        {
          title: '核心素养赋能',
          desc: '从参与条件到参与形式，以及最终的评分标准，无一不紧紧围绕着全面提升学生核心素养能力的最终目标。大会结合5C教育理念与PBL项目式学习方式，旨在全面提升学生沟通、合作、跨区域比较能力、发现与解决问题能力、审辩思维、创新、理解欣赏他人观点和世界观、文化理解与传承等全面核心素养能力。',
          img: 'https://cdn.spbcn.org/ghac/page/1/HQWY_HQDH_DHXG_DHSJYY_banne_04.png'
        }
      ]
    }
  },
  components: {
    // Topbar,
    // Footer
  },
  methods: {
    fn (path) {
      if (path) {
        this.$router.push(path)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.contnet-backview {
  // width: 1000px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-bottom: 80px;
}
.top-backview {
  width: 100%;
  // padding-bottom: 10px;
  background-color: rgb(216, 216, 216);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.dibannerclass {
  width: 1000px;
  height: 469px;
  z-index: 99;
}
.bannerbottom {
  width: 1000px;
  height: 100px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 150px;
  z-index: 100;
}
.title-backview {
  height: 80px;
  font-size: 32px;
  font-family: FZLBJW;
  font-weight: 900;
  text-align: center;
  line-height: 40px;
  padding: 0px 20px;
  color: white;
  border: 2px solid rgb(255, 255, 255);
  .en{
    font-size: 24px;
    margin-top: 10px;
  }
}
.red-backview {
  width: 1000px;
  height: 25px;
}
.red-lineview {
  width: 1000px;
  height: 10px;
  // margin-top: 15px;
  background-color: #e50211;
}
.centerclass {
  width: 1000px;
  margin: 0 auto;
}
.center-titleview {
  display: inline-block;
  padding-right: 100px;
  height: 42px;
  margin-top: 50px;
  background: url(https://cdn.spbcn.org/ghac/assets/feiyi_homepage_title.png);
  background-size: auto 100%;
  background-position: right;
  background-repeat: no-repeat;
}

.titleStr {
  // width: 600px;
  height: 42px;
  line-height: 42px;
  // text-align: center;
  font-size: 24px;
  font-family: FZLBJW;
  color: white;
  font-weight: bolder;
  padding-left: 30px;
  background-color: #b81b26;
}

.banner-img-class {
  width: 1000px;
  height: 470px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.el-carousel {
  width: 1000px;
  height: 470px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.sec-title{
  width: 350px;
  height: 38px;
  background-image: url(https://cdn.spbcn.org/ghac/ghac_button_highlight.png);
  background-size: 100% 100%;
  text-align: center;
  color: #fff;
  font-family: hzgb;
  font-size: 18px;
  line-height: 38px;
  margin: 60px auto 30px;
  letter-spacing: 2px;
}
.page_text{
  font-size: 20px;
  font-family: FZLBJW;
  line-height: 32px;
  margin-top: 20px;
  div{
    text-indent: 2em;
  }
}
.page_img{
  width: 1000px;
  // height: 186px;
  border-radius: 30px;
  margin-top: 40px;
}
.topMenuBackview{
  width: 1000px;
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.menu-backview{
  color: #fff;
  font-size: 28px;
  font-family: FZLBJW;
  background-color: #000;
  height: 42px;
  line-height: 42px;
  padding: 0 40px;
  border-radius: 42px;
  cursor: pointer;
}
.selectColor {
  background-color: #b71b26;
}
.page_4_img{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 40px;
  img{
    width: 22%;
    height: auto;
  }
}
.page_img_circle{
  width: 400px;
  margin: 40px auto 0;
  display: block;
}
.red-bg{
  background-color: #a32c25;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
}
.page_text2{
  margin-top: 0;
  color: #fff;
  font-family: '微软雅黑';
  font-size: 14px;
  line-height: 28px;
}
</style>
