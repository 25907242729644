import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  // 存放基础数据
  state: {
    count: 0
  },
  // 不要在这里写异步代码
  mutations: {
    add (state) {
      state.count++
    },
    addN (state, step) {
      state.count += step
    },
    sub (state) {
      state.count--
    },
    subN (state, step) {
      state.count -= step
    }
  },
  // 专门处理异步操作
  actions: {
    // 在actions 中不能直接修改state中的数据，必须要使用commit触发mutations中的操作
    // addAsync(store) {
    //   setTimeout(() => {
    //     store.commit('add')
    //   }, 1000)
    // }
    addAsyncN(store, step) {
      setTimeout(() => {
        store.commit('addN', step)
      }, 1000)
    },
    subAsyncN(store, step) {
      setTimeout(() => {
        store.commit('subN', 5)
      }, 1000)
    }
  },
  getters: {
    showNumber: state => {
      return state.count
    }
  }
})
