import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/global.css' // 引入全局样式
import './plugins/element.js'
import 'amfe-flexible'
import moment from 'moment'
import http from './http/request'
import VueRouter from 'vue-router'
import cebsbLoading from '@/components/cebsbLoading/index.js'
import store from './store'
import puzzleVerification from 'vue-puzzle-verification'
import xcUtils from './utils/xcUtils'
import curUtils from './utils/curUtils'
import chnEngStatusCode from './utils/chnEngStatusCode'
Vue.use(cebsbLoading)
Vue.use(puzzleVerification)
Vue.prototype.$xcUtils = xcUtils
Vue.prototype.$curUtils = curUtils
Vue.prototype.$chnEngStatusCode = chnEngStatusCode
Vue.prototype.$http = http
Vue.prototype.$moment = moment
Vue.config.productionTip = false
moment.locale('zh-cn')

Vue.prototype.ismobil = function () {
  return Vue.ismobil
}
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
