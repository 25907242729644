<template>
  <div>
    <div class="course-class-topback">
      <el-row>
        <el-col :span="8">
          <div class="course-left-backview">
            <img
              class="course-left-backimg"
              src="../../../../../src/assets/images/homepage_left.png"
              @click="backClicked"
            />
            <el-image
              class="course-left-title"
              :src="courseData.typeLogo"
              fit="cover"
            ></el-image>
            <div class="course-title">{{ courseData.typeName }}</div>
          </div>
        </el-col>
        <el-col :span="16">
          <div class="navbar-list">
            <ul class="flex-imd">
              <li
                v-for="(item, index) in courseData.childrenCourseType"
                :key="index"
                class="navbar-item"
                :class="currNavbar === index ? 'act' : 'nol'"
                @click="courseClassClicked(index)"
                :data-navid="index"
              >
                {{ item.typeName }}
              </li>
            </ul>
          </div>
        </el-col>
      </el-row>
      <el-empty
        v-if="courseListData && courseListData.length === 0"
        description="暂无课程信息"
      ></el-empty>
      <div class="course-type-back" v-else>
        <div
          class="course-type-content"
          v-for="(item, index) in courseListData"
          v-bind:key="index"
          @click="courseDetailClicked(item)"
        >
          <el-image
            :class="
              item.lock === 0
                ? 'course-cover-lockimage'
                : 'course-cover-nolockimage'
            "
            :src="item.courseThumbnail"
            fit="cover"
          >
          </el-image>
          <el-image
            v-if="item.lock === 0"
            class="lock-image-class"
            :src="lockImage"
          ></el-image>
          <div class="course-tye-name">{{ item.courseName }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { queryOpenCourseList, haveBeenOpenedCourse } from '@/http/api'
export default {
  data () {
    return {
      lockImage: require('@/assets/images/lock-image.png'),
      currNavbar: 0,
      courseListData: null,
      courseNodataDialogVisible: false,
      courseData: JSON.parse(this.$route.query.classData),
      queryFormdata: {
        activityType: this.$chnEngStatusCode.activityCourseType,
        pageNum: 1,
        pageSize: 1000000
      }
    }
  },
  created () {
    this.courseClassClicked(this.currNavbar)
  },
  methods: {
    backClicked () {
      this.$router.back()
    },
    // 菜单点击事件获取课程列表
    courseClassClicked (selectIndex) {
      this.queryFormdata.typeId = this.courseData.childrenCourseType[selectIndex].id
      const schoolData = JSON.parse(localStorage.getItem('saveSchoolInfo'))
      this.queryFormdata.userId = schoolData.userId
      console.log('schoolData', schoolData)
      if (!this.$route.query.schoolId) {
        haveBeenOpenedCourse(this.queryFormdata).then((res) => {
          this.courseListData = res.data.list
        }).catch((err) => {
          console.log('err1111', err)
        })
      } else {
        this.queryFormdata.schoolId = this.$route.query.schoolId
        queryOpenCourseList(this.queryFormdata).then((res) => {
          this.courseListData = res.data
        }).catch((err) => {
          console.log('err1111', err)
        })
      }
    },
    // 菜单点击事件
    courseDetailClicked (item) {
      console.log('item', item)
      if (item.lock === 0) {
        this.$message.error('暂未开通该课程！')
      } else {
        const courseDataType = this.$xcUtils.matchFileType(item.courseUrl)
        console.log('courseDataType', courseDataType)
        if (courseDataType === 'pptx' || courseDataType === 'ppt') {
          const url = encodeURIComponent(item.courseUrl)
          this.$router.push({
            path: '/cebsbCourseManage/cebso_onlineBrowsingPDF',
            query: {
              fileUrl: 'http://view.officeapps.live.com/op/view.aspx?src=' + url
              // fileUrl: item.courseUrl
            }
          })
        } else if (courseDataType === 'video') {
          this.$router.push({
            path: '/cebsbCourseManage/cebso_onlineBrowsingVideo',
            query: {
              fileUrl: item.courseUrl
            }
          })
        } else {
          this.$router.push({
            path: '/cebsbCourseManage/cebso_onlineBrowsingPDF',
            query: {
              fileUrl: '/pdf/web/viewer.html?file=' + item.courseUrl
            }
          })
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.course-class-topback {
  width: 100%;
  height: 60px;
  background-color: rgb(178, 178, 178);
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  // justify-content: flex-start;
}
.course-left-backview {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.course-left-backimg {
  width: 30px;
  height: 30px;
  margin-left: 10px;
}
.course-left-title {
  width: 40px;
  height: 40px;
  margin-left: 10px;
}
.navbar-list {
  width: 100%;
  height: 60px;
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
}
.navbar-list > ul {
  position: absolute;
  height: 40px;
  margin: 10px 0px;
  left: 0;
  display: flex;
  flex-flow: row nowrap;
}
.navbar-item {
  width: max-content;
  padding: 0px 18px;
  margin: 0px 5px;
  box-sizing: border-box;
  height: 40px;
  display: flex;
  border-radius: 20px;
  color: white;
  font-weight: 900;
  font-size: 12px;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-right: 10px; */
}
.flex-imd {
  padding: 0;
}
.navbar-list::-webkit-scrollbar {
  display: none;
}
.navbar-item.act {
  background: #c30f20;
  // background-clip: text;
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;  -webkit-text-fill-color: transparent;
  // font-weight: bold;
}
.nol {
  background: #999999;
}
.course-type-back {
  width: 100%;
  margin: 30px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: row;
}
.course-title {
  margin-left: 10px;
  max-width: 200px;
  font-size: 16px;
  font-weight: 900;
}
.course-tye-classname {
  font-size: 12px;
}
.course-type-content {
  width: 200px;
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.course-cover-lockimage {
  width: 160px;
  height: 90px;
  border-radius: 10px;
  filter: grayscale(100%);
}
.lock-image-class {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
}
.course-cover-nolockimage {
  width: 160px;
  height: 90px;
  border-radius: 10px;
}
.course-type-image {
  width: 100px;
  height: 100px;
}
.course-tye-name {
  width: 200px;
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
  color: black;
  font-weight: 900;
}
</style>
