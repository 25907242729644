import axios from 'axios'// 引入axios
import env from '../http/env'
import router from '../router'
import Vue from 'vue'
import { Promise } from 'core-js'
import qs from 'qs'
// application/json;charset=UTF-8;
// 创建axios实例
const service = axios.create({
  // 这里拿线上接口测试
  baseURL: env.baseIP,
  headers: { // 请求头
    'Content-Type': 'application/json;charset=UTF-8;'
  },
  settimeout: 10000 // 超时时间
})
// 请求拦截器
service.interceptors.request.use(
  config => {
    Vue.$showLoading.show()
    if (localStorage.getItem('HQAuthorization')) {
      config.headers.Authorization = localStorage.getItem('HQAuthorization')
    }
    if (config.method === 'get') {
      config.paramsSerializer = function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
    return config // 记得一定要 返回config
  },
  error => {
    Vue.$showLoading.hide()
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use((res) => {
  Vue.$showLoading.hide()
  if (res.status && res.status === 200) {
    if (res.headers && res.headers.authorization) {
      const authorization = res.headers.authorization
      localStorage.setItem('HQAuthorization', authorization)
    }
    if (res.data.code === 200) {
      return res.data
    } else if (res.data.code === 2014) { // 有未完善的资料
      Vue.prototype.$confirm('您有未完善的信息，是否现在去完善?', '提示', {
        confirmButtonText: '去完善',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        router.push({
          path: '/cebsbUserInfo?pushUrl=/speRegisterFirCompetition'
        })
      }).catch(() => {
      })
    } else if (res.data.code === 401) {
      Vue.prototype.$message.error('登录已过期，请重新登录！')
      localStorage.removeItem('HQAuthorization') // 移除token，跳转至登录
      router.push({
        path: '/loginView',
        params: {
          pushUrl: ''
        }
      })
    } else {
      Vue.prototype.$message.error(res.data.msg)
    }
    return Promise.reject(res)
  }
}, err => {
  Vue.$showLoading.hide()
  console.log('err', err)
  if (err.code === 'ERR_BAD_RESPONSE') {
    if (err.response.data.code === 401) {
      Vue.prototype.$message.error('登录已过期，请重新登录！')
      localStorage.removeItem('HQAuthorization') // 移除token，跳转至登录
      router.push({
        path: '/loginView',
        params: {
          pushUrl: ''
        }
      })
    } else {
      Vue.prototype.$message.error('无服务，请稍后重试！')
    }
  } else {
    if (err.status === 401) {
      Vue.prototype.$message.error('登录已过期，请重新登录！')
      localStorage.removeItem('HQAuthorization') // 移除token，跳转至登录
      router.push({
        path: '/loginView',
        params: {
          pushUrl: ''
        }
      })
    } else {
      Vue.prototype.$message.error('无服务，请稍后重试！')
    }
  }
  return Promise.reject(err)
})
export default service
