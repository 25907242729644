
export default {
  /**
   * 获取字典数据
   * @param {*} that 拿到vue对象
   * @param {*} dictType 字典类型（查看字典管理）
   * @returns Array 字典数据
   */
  getDataList: async function (that, dictType) {
    const { data: res } = await that.$http.get('/dict/queryDictList', {
      params: {
        dictType: dictType
      }
    })
    return res
  },
  /**
   * 获取赛程信息
   * @param {*} that 拿到vue 对象
   * @param {*} formData 传参
   * @returns 赛程数组
   */
  querySeasonRaceSchedule: async function (that, formData) {
    const { data: res } = await that.$http.get('/raceSchedule/querySeasonRaceSchedule', {
      params: formData
    })
    return res
  },
  // ====================  ====================
  /**
   * 开启加载
   * @param {*} that 拿到vue对象
   */
  openLoading (that) {
    that.loading = that.$loading({
      lock: true,
      text: '拼命加载中……',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
  },
  // ====================  ====================
  /**
   * 关闭加载
   * @param {*} that 拿到vue对象
   */
  closeLoading (that) {
    that.loading && that.loading.close()
  },
  /**
   * 年级数据
   */
  getGradeData: function () {
    const gradeList = [
      {
        dictCode: 1,
        dictLabel: '一年级',
        dictValue: '1'
      },
      {
        dictCode: 2,
        dictLabel: '二年级',
        dictValue: '2'
      },
      {
        dictCode: 3,
        dictLabel: '三年级',
        dictValue: '3'
      },
      {
        dictCode: 4,
        dictLabel: '四年级',
        dictValue: '4'
      },
      {
        dictCode: 5,
        dictLabel: '五年级',
        dictValue: '5'
      },
      {
        dictCode: 6,
        dictLabel: '六年级',
        dictValue: '6'
      },
      {
        dictCode: 7,
        dictLabel: '七年级',
        dictValue: '7'
      },
      {
        dictCode: 8,
        dictLabel: '八年级',
        dictValue: '8'
      },
      {
        dictCode: 9,
        dictLabel: '九年级',
        dictValue: '9'
      },
      {
        dictCode: 10,
        dictLabel: '高一',
        dictValue: '10'
      },
      {
        dictCode: 11,
        dictLabel: '高二',
        dictValue: '11'
      },
      {
        dictCode: 12,
        dictLabel: '高三',
        dictValue: '12'
      }
    ]
    return gradeList
  },
  /**
   * 身份类型
   */
  dict_idCardType () {
    const idCardType = [
      {
        dictCode: 1,
        dictLabel: '身份证',
        dictValue: '1'
      },
      {
        dictCode: 2,
        dictLabel: '护照',
        dictValue: '2'
      }
    ]
    return idCardType
  },
  dict_sex () {
    const dictSex = [
      {
        dictCode: 1,
        dictLabel: '男',
        dictValue: '1'
      },
      {
        dictCode: 0,
        dictLabel: '女',
        dictValue: '0'
      }
    ]
    return dictSex
  },
  dict_schoolType () {
    const schoolType = [
      {
        dictCode: 1,
        dictLabel: '公立学校',
        dictValue: '0'
      },
      {
        dictCode: 2,
        dictLabel: '私立学校',
        dictValue: '1'
      }, {
        dictCode: 3,
        dictLabel: '外籍子女学校',
        dictValue: '2'
      }
    ]
    return schoolType
  },
  dict_schoolGroup () {
    const schoolGroup = [
      {
        dictCode: 1,
        dictLabel: '小学',
        dictValue: '1'
      },
      {
        dictCode: 2,
        dictLabel: '初中',
        dictValue: '2'
      },
      {
        dictCode: 3,
        dictLabel: '高中',
        dictValue: '3'
      }
    ]
    return schoolGroup
  },
  dict_cebsbSeason () {
    const cebsbSeason = [
      {
        dictCode: 1,
        dictLabel: '2022~2023',
        dictValue: '1'
      },
      {
        dictCode: 2,
        dictLabel: '2023~2024',
        dictValue: '2'
      },
      {
        dictCode: 3,
        dictLabel: '2024~2025',
        dictValue: '3'
      }
    ]
    return cebsbSeason
  },
  dict_considerations () {
    const considerations = [
      {
        dictCode: 1,
        dictLabel: '100-200',
        dictValue: '100-200'
      },
      {
        dictCode: 2,
        dictLabel: '200-400',
        dictValue: '200-400'
      },
      {
        dictCode: 3,
        dictLabel: '400-600',
        dictValue: '400-600'
      },
      {
        dictCode: 4,
        dictLabel: '600-800',
        dictValue: '600-800'
      },
      {
        dictCode: 5,
        dictLabel: '800-1000',
        dictValue: '800-1000'
      },
      {
        dictCode: 6,
        dictLabel: '1000-1200',
        dictValue: '1000-1200'
      },
      {
        dictCode: 7,
        dictLabel: '1200-1500',
        dictValue: '1200-1500'
      },
      {
        dictCode: 8,
        dictLabel: '1500-2000',
        dictValue: '1500-2000'
      }
    ]
    return considerations
  },
  dict_group () {
    const group = [
      {
        dictCode: 1,
        dictLabel: '小初组（1-2年级）',
        dictValue: '1'
      },
      {
        dictCode: 2,
        dictLabel: '小中组（3-4年级）',
        dictValue: '2'
      },
      {
        dictCode: 3,
        dictLabel: '小高组（5-6年级）',
        dictValue: '3'
      },
      {
        dictCode: 4,
        dictLabel: '初中组（7-9年级）',
        dictValue: '4'
      },
      {
        dictCode: 5,
        dictLabel: '高中组（10-12年级）',
        dictValue: '5'
      }
    ]
    return group
  },
  /**
 * 根据审核类型返回审核结果
 * * 审核类型  0 申请 1 通过/待寄出 2 未通过  3 已寄出  4 已寄回  5 成绩统计中/已收回 6成绩统计完毕/公布成绩
 * @param {*} applyStatus // 审核类型
 * @returns
 */
  getApplyStatusWithNum (applyStatus) {
    let applyStatusStr = ''
    switch (applyStatus + '') {
      case '0':
        applyStatusStr = '申请中'
        break
      case '1':
        applyStatusStr = '通过/待寄出'
        break
      case '2':
        applyStatusStr = '未通过'
        break
      case '3':
        applyStatusStr = '已寄出'
        break
      case '4':
        applyStatusStr = '已寄回'
        break
      case '5':
        applyStatusStr = '成绩统计中'
        break
      case '6':
        applyStatusStr = '成绩待公布'
        break
      case '7':
        applyStatusStr = '成绩已公布'
        break
      default:
        applyStatusStr = ''
    }
    return applyStatusStr
  },
  /**
   * 根据年级返回组别信息
   * @param {*} userGrade 年级数据
   * @returns 组别
   */
  getUserGroupWithGrade (userGrade) {
    let userGradeStr = ''
    let groupId = ''
    switch (userGrade + '') {
      case '1':
      case '2':
        userGradeStr = '小初组'
        groupId = '1'
        break
      case '3':
      case '4':
        userGradeStr = '小中组'
        groupId = '2'
        break
      case '5':
      case '6':
        userGradeStr = '小高组'
        groupId = '3'
        break
      case '7':
      case '8':
      case '9':
        userGradeStr = '初中组'
        groupId = '4'
        break
      case '10':
      case '11':
      case '12':
        userGradeStr = '高中组'
        groupId = '5'
        break
      default:
        userGradeStr = '未设置'
        groupId = '0'
    }
    const gradeData = {}
    gradeData.userGradeStr = userGradeStr
    gradeData.groupId = groupId
    return gradeData
  },
  /**
   * 试卷申请考量状态
   * 0 申请 1 通过/待寄出 2 未通过 3 已寄出 4 已寄回 5 成绩统计中/已收回 6成绩统计完毕/公布成绩
   * @returns
   */
  dict_considerationsState () {
    const considerationsState = [
      {
        dictCode: 0,
        dictLabel: '申请中',
        dictValue: '0'
      },
      {
        dictCode: 1,
        dictLabel: '待寄出',
        dictValue: '1'
      },
      {
        dictCode: 2,
        dictLabel: '未通过',
        dictValue: '2'
      },
      {
        dictCode: 3,
        dictLabel: '已寄出',
        dictValue: '3'
      },
      {
        dictCode: 4,
        dictLabel: '已寄回',
        dictValue: '4'
      },
      {
        dictCode: 5,
        dictLabel: '成绩统计中',
        dictValue: '5'
      },
      {
        dictCode: 6,
        dictLabel: '成绩待公布',
        dictValue: '6'
      },
      {
        dictCode: 7,
        dictLabel: '成绩已公布',
        dictValue: '7'
      }
    ]
    return considerationsState
  },
  dict_courseType () {
    const dictCourseType = [
      {
        dictCode: 1,
        dictLabel: '图文课程',
        dictValue: '1'
      },
      {
        dictCode: 2,
        dictLabel: '视频课程',
        dictValue: '2'
      }
    ]
    return dictCourseType
  }
}
