<template>
  <div class="top-backview">
    <div class="contnet-backview">
      <!-- 顶部菜单 -->
      <!-- <Topbar class="topbar"></Topbar> -->
      <!-- banner -->
      <div class="dibannerclass">
        <el-carousel
          :interval="3000"
          indicator-position="none"
          id="el-carousel"
          :height="dataHeight"
        >
          <el-carousel-item v-for="item in images" :key="item.index">
            <img :src="item.url" alt="" class="banner-img-class" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="bannerbottom">
        <div class="title-backview">环球文遗小使者大会</div>
      </div>
      <div class="red-backview">
        <div class="red-lineview"></div>
      </div>
      <!-- 中间内容部分 -->
      <div class="centerclass">
        <div class="center-titleview">
          <div class="titleStr">大会形式</div>
        </div>
        <div class="course-list-backview">
          <img
            src="https://cdn.spbcn.org/ghac/assets/feiyiprograms4_3_theme1.png"
            alt=""
            class="list-left-imgs"
          />
          <img
            src="https://cdn.spbcn.org/ghac/assets/feiyiprograms4_3_image1.png"
            alt=""
            class="list-right-imgs"
          />
          <img
            src="https://cdn.spbcn.org/ghac/assets/feiyiprograms4_3_icon1.png"
            alt=""
            class="list-left-number"
          />
        </div>
        <div class="course-list-backview">
          <img
            src="https://cdn.spbcn.org/ghac/assets/feiyiprograms4_3_theme2.png"
            alt=""
            class="list-left-imgs"
          />
          <img
            src="https://cdn.spbcn.org/ghac/assets/feiyiprograms4_3_image2.png"
            alt=""
            class="list-right-imgs2"
          />
          <img
            src="https://cdn.spbcn.org/ghac/assets/feiyiprograms4_3_icon2.png"
            alt=""
            class="list-left-number"
          />
        </div>
        <div class="course-list-backview">
          <img
            src="https://cdn.spbcn.org/ghac/assets/feiyiprograms4_3_theme3.png"
            alt=""
            class="list-left-imgs"
          />
          <div class="lest-right-contentStr">
            人文素养研学探究活动主要分为三种形式：博物馆研学,大自然研学和非遗传承人工匠馆。具体研学类型和目的地会根据课堂定制内容进行相关研学探究。习近平总书记多次强调，要让收藏在博物馆里的文物、陈列在广阔大地上的遗产、书写在古籍里的文字都活起来，丰富全社会历史文化滋养。具有历史、艺术、科学价值的文物是人类宝贵的历史文化遗产，能让人们更好认识源远流长、博大精深的中华文明，为弘扬中华优秀传统文化、增强文化自信提供坚强支撑。因此，博物馆研学尤其重要。大自然研学是是将“自然+教育”深度融合、弘扬传统文化，依托大自然天然景观或者千年古遗址等场所，深度挖掘大自然中涉及到的非物质遗产、历史文化等的精髓，研发丰富多彩的优质旅行课程和多种寓教于乐的研学产品，调动眼耳口鼻脑等多感官和动手能力，使“行万里路”与“读万卷书”完美结合。非遗传承人工匠馆给孩子们提供了一个走进匠人，体会匠心，深入体验非遗的一个机会。把小猪佩奇的皮影涂上自己喜欢的色彩，几根小木棍一支，灯光摇曳下，佩奇一家的戏怎么演由自己决定；选几支色彩鲜艳的绒条，在老师指导下勾条、打尖、传花，影视剧里“各种角色”就在自己手中新鲜出炉…
          </div>
          <img
            src="https://cdn.spbcn.org/ghac/assets/feiyiprograms4_3_icon3.png"
            alt=""
            class="list-left-number"
          />
        </div>
        <div class="course-list-backview">
          <img
            src="https://cdn.spbcn.org/ghac/assets/feiyiprograms4_3_theme4.png"
            alt=""
            class="list-left-imgs"
          />
          <div class="lest-right-contentStr">
            《环球文遗小使者大会》的研发智库包含联合国教科文组织相关专家学者，也包含各个国家教育相关部门、非物质文化遗产相关部门并且联合全球和地区各高校的教授和学者共同和设计和进行评定工作。大会将通过学校机构和家庭社区两个区域甄选参加《环球文遗小使者大会》全球挑战赛的战队。并且将为组织参赛的学校和社区提供，相关的课程体系和研学探究方案，让备赛的代表队能够有充足的知识贮备和准备参赛作品的场景和工具。
          </div>
          <img
            src="https://cdn.spbcn.org/ghac/assets/feiyiprograms4_3_icon4.png"
            alt=""
            class="list-left-number"
          />
        </div>
        <div class="course-list-backview">
          <img
            src="https://cdn.spbcn.org/ghac/assets/feiyiprograms4_3_theme5.png"
            alt=""
            class="list-left-imgs5"
          />
          <div class="lest-right-contentStr">
            该项大会发起方UIE聚知优国际教育集团，作为《SPELLING BEE of
            CHINA》中国地区组委会已经成功在中国地区的60多个城市举办此项活动，SPELLING
            BEE英语素质教育活动1925年起源于美国，作为全球范围内影响力最大，参与人数最多的青少年活动，已经成功在全球5大洲100多个国家开展将近百年的历史，每年在全球范围拥有近5000万的学生参与。SPELLING
            BEE凭借其简单和公平的英语拼词活动，深受各国青少年和家庭的爱戴。学好英文向世界表达中国璀璨传统文化，将是中国地区青少年的使命，并且通过大会让每一位参与者增强学生的科学和人文素养的同时，真正以学生兴趣为导向的去了解学科知识的魅力，去探究自己未来大学的专业和未来人生的职业，
          </div>
          <img
            src="https://cdn.spbcn.org/ghac/assets/feiyiprograms4_3_icon5.png"
            alt=""
            class="list-left-number"
          />
        </div>
      </div>
      <!-- 底部内容 -->
    </div>
  </div>
</template>

<script>
// import Topbar from '@/components/Topbar.vue'
// import Footer from '@/components/Footer.vue'
export default {
  props: {
    dataHeight: {
      type: String,
      default: '100%'
    }
  },
  data () {
    return {
      images: [{ url: 'https://cdn.spbcn.org/ghac/assets/feiyiprograms4_3_banner.png' }]
    }
  },
  components: {
    // Topbar,
    // Footer
  }
}
</script>

<style lang="less" scoped>
// .topbar {
//   z-index: 1000;
//   width: 1000px;
//   padding: 0;
//   margin: 0;
// }
.contnet-backview {
  width: 1000px;
  background-color: black;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.top-backview {
  width: 100%;
  // padding-bottom: 10px;
  background-color: rgb(216, 216, 216);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.dibannerclass {
  width: 1000px;
  height: 469px;
  z-index: 99;
  background-color: rgb(0, 0, 0);
}
.bannerbottom {
  width: 1000px;
  height: 100px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 150px;
  z-index: 100;
}
.title-backview {
  height: 60px;
  font-size: 32px;
  font-family: FZLBJW;
  font-weight: 900;
  text-align: center;
  line-height: 60px;
  padding: 0px 20px;
  color: white;
  border: 2px solid rgb(255, 255, 255);
}
.red-backview {
  width: 1000px;
  height: 25px;
}
.red-lineview {
  width: 1000px;
  height: 6px;
  margin-top: 15px;
  background-color: #e50211;
}
.centerclass {
  width: 1000px;
  height: 1153px;
  display: flex;
  flex-direction: column;
  background-image: url(https://cdn.spbcn.org/ghac/assets/feiyi_background_mix.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.center-titleview {
  width: 377px;
  height: 42px;
  margin-top: 30px;
  background: url(https://cdn.spbcn.org/ghac/assets/feiyi_homepage_title.png);
  background-size: 100%;
}

.titleStr {
  width: 280px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  font-size: 24px;
  font-family: FZLBJW;
  color: white;
  font-weight: bolder;
}

.course-list-backview {
  width: 800px;
  margin-left: 60px;
  margin-top: 20px;
  padding: 20px 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 30px;
  position: relative;
}
.list-left-number {
  position: absolute;
  width: 55px;
  height: 45px;
  left: 10px;
  top: 10px;
}
.list-left-number5 {
  position: absolute;
  width: 55px;
  height: 45px;
  left: 10px;
  top: 10px;
}
.list-left-imgs {
  width: 120px;
  height: 94px;
}
.list-left-imgs5 {
  width: 99px;
  height: 94px;
}
.list-right-imgs {
  width: 600px;
  height: 175px;
  margin-left: 20px;
}
.list-right-imgs2 {
  width: 600px;
  height: 160px;
  margin-left: 20px;
}
.lest-right-contentStr {
  width: 600px;
  font-size: 14px;
  font-family: FZLBJW;
  margin-left: 20px;
  color: black;
  text-indent: 2em;
}
.footerclass {
  width: 1000px;
}
.banner-img-class {
  width: 1000px;
  height: 470px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.el-carousel {
  width: 1000px;
  height: 470px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
