<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="top-backview">
    <div class="contnet-backview">
      <ghacBanner></ghacBanner>
      <ghacTab :activeIndex="0"></ghacTab>
      <div class="centerclass">
        <!-- <div class="upload" @click="handleToReg">2024年度展演报名</div> -->
        <div class="center-titleview">
          <div class="titleStr">大会概述 ｜ Overview of GHAC</div>
        </div>
        <div class="page_text">
          <div>
            环球文遗小使者大会是一项年度性活动，每年启动季大会组委会在官网www.ghacedu.com发布活动通知，从八大主题中选择发布本年度的活动主题。学校基于大会发布的本年度主题选择参与的项目，并以团队形式在官网完成报名注册。团队需根据所选主题进行相关的非遗探究，在探究过程中需与联合国17个可持续发展目标中的一个或多个目标相呼应，并完成探究报告、广告展板、PPT演示、舞台剧展示和工程作品5项任务。
          </div>
          <div>(示例:某小学所选主题为美术工艺类，具体探究的非遗项目为杨柳青木板年画，展演主题为“重塑心中的英雄”，呼应了联合国17个可持续发展目标中的第16项。)</div>
        </div>
        <!-- <div class="sec-title">注册时间</div>
        <div class="page_text">
          <div>2024年度参赛队伍注册<b style="color: #b51c25;">截止日期为2024年6月30日</b>。参赛队伍在此之前在官网提交探究主题、参赛选手信息，开题报告、展演视频，并完成缴费。</div>
        </div>
        <div>
          <img class="page_img" src="https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_banner_01.png" alt="">
        </div> -->
        <!-- <div class="sec-title">整体流程</div> -->
        <div class="center-titleview">
          <div class="titleStr">整体流程 ｜ Overall Process</div>
        </div>
        <div class="flow">
          <div class="line"></div>
          <div v-for="(item, i) of flow" :key="i">
            <div class="flow-item" :class="item.type === 2 ? 'reverse' : ''">
              <div class="flow-title-con">
                <div class="flow-title">{{ item.title }}</div>
                <img class="arrow1" v-if="item.type === 1"
                  src="https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_ZTLC_icon_03.png" alt="">
                <img class="arrow2" v-if="item.type === 2"
                  src="https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_ZTLC_icon_04.png" alt="">
              </div>
              <div class="flow-item-list">
                <div class="flow-sec-item" v-for="(sec, j) of item.list" :key="j">
                  <div class="flow-sec-title">{{ sec.title }}</div>
                  <div class="flow-sec-date-con">
                    <!-- <i class="el-icon-time"></i>  -->
                    <div class="flow-sec-date" @click="showMoreFn(sec, i)" :class="sec.clickable ? 'clickable' : ''">{{ sec.date }}<i v-if="sec.clickable" class="el-icon-thumb"></i></div>
                    <div class="click-more" @click="showMoreFn(sec, i)" v-if="sec.clickable">（点击查看详情）</div>
                  </div>
                  <div class="flow-sec-desc">{{ sec.desc }}</div>
                </div>
              </div>
            </div>
            <div>
              <div  v-if="item.showMore" class="more">
                <div class="sec-title">组别划分</div>
                <el-row class="col-3">
                  <el-col :offset="i===0 ? 3 : 0" :span="6" v-for="(item, i) of levels" :key="i">
                    <img class="icon" :src="item.img" alt="">
                    <div class="h1">{{ item.title }}</div>
                    <div class="h2">{{ item.age }}</div>
                  </el-col>
                </el-row>
                <div class="sec-title">组队形式</div>
                <el-row class="col-3">
                  <el-col :offset="i===0 ? 3 : 0" :span="6" v-for="(item, i) of list" :key="i">
                    <img class="icon" :src="item.img" alt="">
                    <div class="h2">{{ item.desc }}</div>
                  </el-col>
                </el-row>
                <div class="page_tip">组委会将在全国八大文化区域遴选参赛队伍，每个文化区域遴选20支，每个学校限报名2支队伍。</div>
                <div class="page_tip">学生需有一定的中、英双语基础和非遗文化知识储备</div>
                <div class="sec-title">成员分工</div>
                <el-row class="col-3">
                  <el-col :offset="i===0 || i===3 ? 3 : 0" :span="6" v-for="(item, i) of list2" :key="i">
                    <img class="icon icon2" :src="item.img" alt="">
                    <div class="btn">{{ item.role }}</div>
                    <div class="h2">{{ item.desc }}</div>
                  </el-col>
                </el-row>
                <!-- <div class="hide-more" @click="hideMoreFn(item)">收起 <i class="el-icon-caret-top"></i></div> -->
              </div>
            </div>
          </div>
        </div>
        <div v-if="showMore3" class="more more2">
          <!-- 开幕式 -->
          <div class="sec-title">展演活动项目</div>
                  <div class="third-title">开幕式</div>
                  <!-- <div class="third-title">活动说明</div> -->
                  <div class="page_text">
                    <div>
                      全国展演大会将为来自全国各地的参展学生举行盛大的开幕式。这不仅仅是大会的传统礼仪环节，更是展示华夏大地各个不同区域文化底蕴和非遗精神的舞台。开幕式为本年度最盛大的展演活动拉开帷幕。同时，每支队伍应在开幕式开始前两周内，向组委会提交本组的探究报告。专业评审将对每个提交的探究报告进行评分。
                    </div>
                  </div>
                  <div>
                    <img class="page_img mb-40" src="https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_KMS_banner_01.png" alt="">
                  </div>

            <div style="display: none;">
                    <div class="third-title">内容需求</div>
                    <div class="page_text mb-40">
                      <div>探究报告应包含题目、摘要、目录、正文、参考文献五部分。其中，摘要部分应描述选择主题及具体项目的原因，并概述整体探究情况;正文部分应描述本队进行理论学习和探究实践的过程、本队的成果及收获、以及小结。</div>
                    </div>
                    <div class="third-title">语言需求</div>
                    <div class="page_text mb-40">
                      <div>探究报告整体中文完成即可，摘要部分需包含中、英双语。</div>
                    </div>
                    <div class="third-title">格式需求</div>
                    <div class="page_text mb-40">
                      <div>队伍提交的探究报告应符合一般正式文体的格式、字体、字号要求。</div>
                    </div>
                    <div class="third-title">探究报告评分规则</div>
                    <div class="rules">
                      <!--  -->
                      <div class="rules3-1">
                        <div class="rules3-1-text">选题明确、符合发布主题限定的探究范畴</div>
                        <img class="img" src="https://cdn.spbcn.org/ghac/page/3/3.png" alt="">
                        <div class="rules3-1-text">选题能够呼应联合国17个可持续发展目标中的一个或多个</div>
                      </div>
                      <div class="rules-center mb-60">
                        <div>选题能够体现所选主题要解决的问题</div>
                      </div>
                      <!--  -->
                      <div class="rules-center">
                        <div>报告各部分齐全、格式正确、语句通顺流畅、语法标点正确</div>
                      </div>
                      <div class="rules3-1">
                        <div class="rules3-1-text">
                          <div class="l1">思路层次清晰，主题明确突出，概括全面准确，具备一定的广度和深度</div>
                          <div class="l2">能够体现跨地区、跨文化的对比与探究</div>
                        </div>
                        <img class="img" src="https://cdn.spbcn.org/ghac/page/3/1.png" alt="">
                        <div class="rules3-1-text">
                          <div class="l1">引用文献的真实性、权威性、规范性</div>
                          <div class="l2">探究方法科学，具备一定的挑战性;探究过程合理，具备一定的渐进性</div>
                        </div>
                      </div>
                      <div class="rules-center mb-60">
                        <div>能够体现跨学科知识的融合，以及对未来大学专业的初步探索</div>
                      </div>
                      <!--  -->
                      <div class="rules3-1 mb-40">
                        <div class="rules3-1-text">能够体现小组对探究主题的新收获和新见解</div>
                        <img class="img" src="https://cdn.spbcn.org/ghac/page/3/2.png" alt="">
                        <div class="rules3-1-text">选题具备一定的科学性、创新性和可行性</div>
                      </div>
                      <el-row class="mb-60">
                        <el-col :span="8" v-for="(item, i) of list4" :key="i">
                          <div class="score">{{ item }}</div>
                        </el-col>
                      </el-row>

                    </div>
            </div>
          <!-- 环球大舞台 -->
                  <div class="third-title">环球大舞台</div>
                  <!-- <div class="third-title">活动说明</div> -->
                  <div class="page_text">
                    <div>
                      参演队伍需从发布的年度主题中任选一个主题进行非遗相关探究，在限定的12-15分钟内，通过角色扮演等形式，将选题研究的分析过程和解答内容设置成情节或者情境，通过语言、动作、舞蹈、音乐等表现形式进行表演。舞台剧表演过程中需要使用到制作的PPT和工程作品。专业评审将对每个展演作品进行评分。
                    </div>
                  </div>
                  <div>
                    <img class="page_img mb-40" src="https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_KMS_banner_02.png" alt="">
                  </div>
            <div style="display: none;">
                    <div class="third-title">时间需求</div>
                    <div class="page_text mb-40">
                      <div>表演时长不超过15分钟，表演计时从开场白(选手需要在开场白中说明展演队伍的编号、成员姓名、作品名称三项信息)开始。表演时段的前、后各有3分钟的时间用来布场和撤场。</div>
                    </div>
                    <div class="third-title">语言需求</div>
                    <div class="page_text mb-40">
                      <div>表演语言需包含中、英双语。</div>
                    </div>
                    <div class="third-title">道具需求</div>
                    <div class="page_text mb-40">
                      <div>除背景板、道具外，在表演中允许使用多媒体设备，但需自备。组织方只提供桌椅、电源、投影设备，不提供其它任何设备和器材。</div>
                    </div>
                    <div class="third-title">场外援助</div>
                    <div class="page_text mb-40">
                      <div>非参演成员可在此期间提供必要的协助，如物料的搬运，设施、设备的安装、调试、拆装等。完成协助工作后，非参演成员需要离开表演区域。</div>
                    </div>
                    <div class="third-title">PPT演示评分规则</div>
                    <div class="rules">
                      <!--  -->
                      <div class="rules3-1 mb-60">
                        <div class="rules3-1-text">选题明确、符合发布主题限定的探究范畴</div>
                        <img class="img" src="https://cdn.spbcn.org/ghac/page/3/5.png" alt="">
                        <div class="rules3-1-text">选题能够呼应联合国17个可持续发展目标中的一个或多个</div>
                      </div>
                      <!--  -->
                      <div class="rules3-1 mb-60">
                        <div class="rules3-1-text">
                          <div class="l1">思路层次清晰，主题明确突出，概括全面准确，具备一定的广度和深度</div>
                          <div class="l2">能够体现跨地区、跨文化的对比与探究</div>
                        </div>
                        <img class="img" src="https://cdn.spbcn.org/ghac/page/3/4.png" alt="">
                        <div class="rules3-1-text">
                          <div class="l1">结构合理、逻辑顺畅，内容之间具有层次性和连贯性</div>
                          <div class="l2">能够恰当运用文本、图片、表格、图表、图形、动画、音视频等表现工具</div>
                        </div>
                      </div>
                      <!--  -->
                      <div class="rules3-1">
                        <div class="rules3-1-text">版式设计生动活泼，富有新意，视觉效果好，有较强的表现力和感染力</div>
                        <img class="img" src="https://cdn.spbcn.org/ghac/page/3/6.png" alt="">
                        <div class="rules3-1-text">语言表达得体、流利，讲述具有感染力</div>
                      </div>
                      <div class="rules-center mb-60">
                        <div>与舞台剧配合得当，相得益彰</div>
                      </div>
                      <el-row class="mb-60">
                        <el-col :span="8" v-for="(item, i) of list4" :key="i">
                          <div class="score">{{ item }}</div>
                        </el-col>
                      </el-row>

                    </div>
                    <div class="third-title">舞台剧展示评分规则</div>
                    <div class="rules">
                      <!--  -->
                      <div class="rules3-1">
                        <div class="rules3-1-text">展演内容能够体现所选探究主题</div>
                        <img class="img" src="https://cdn.spbcn.org/ghac/page/3/10.png" alt="">
                        <div class="rules3-1-text">展演内容能够呼应联合国17个可持续发展目标中的一个或多个</div>
                      </div>
                      <div class="rules-center mb-60">
                        <div>内容详实准确，富有创意，扣人心弦</div>
                      </div>
                      <!--  -->
                      <div class="rules3-1">
                        <div class="rules3-1-text">语言结构严谨、构思巧妙、简练流畅</div>
                        <img class="img" src="https://cdn.spbcn.org/ghac/page/3/9.png" alt="">
                        <div class="rules3-1-text">发音标准、口齿清晰、声音洪亮、语速恰当</div>
                      </div>
                      <div class="rules-center mb-60">
                        <div>语音语调、节奏语气富于变化，与内容搭配得当</div>
                      </div>
                      <!--  -->
                      <div class="rules3-1 mb-60">
                        <div class="rules3-1-text">
                          <div class="l1">叙事合理、表演自然、人物角色鲜明</div>
                          <div class="l2">有效运用姿态、动作、手势、表情，富有感染力</div>
                        </div>
                        <img class="img" src="https://cdn.spbcn.org/ghac/page/3/7.png" alt="">
                        <div class="rules3-1-text">
                          <div class="l1">舞台表现力强，具有艺术观赏性</div>
                          <div class="l2">团队之间配合默契、成员与道具、音乐之间相得益彰</div>
                        </div>
                      </div>
                      <!--  -->
                      <div class="rules3-1 mb-60">
                        <div class="rules3-1-text">道具具有美感、造型设计优美，色彩搭配恰当，制作精良</div>
                        <img class="img" src="https://cdn.spbcn.org/ghac/page/3/8.png" alt="">
                        <div class="rules3-1-text">作品贴合探究主题，富有创意</div>
                      </div>
                      <el-row class="mb-60">
                        <el-col :span="8" v-for="(item, i) of list4" :key="i">
                          <div class="score">{{ item }}</div>
                        </el-col>
                      </el-row>
                    </div>
            </div>
          <!-- 环球博览会 -->
                  <div class="third-title">环球博览会</div>
                  <!-- <div class="third-title">活动说明</div> -->
                  <div class="page_text">
                    <div>这是一场环球非遗盛宴!学生们根据本队所选非遗主题，在进行调研和探究后，完成广告展板的内容创作和工程作品的手工制作。两项任务作品将在环球博览会进行展出，专业评审和大众评审将对每个参展作品进行评分。</div>
                  </div>
                  <div>
                    <img class="page_img mb-40" src="https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_KMS_banner_03.png" alt="">
                  </div>
            <div style="display: none;">
                  <div class="third-title">空间要求</div>
                  <div class="page_text mb-40">
                    <div>在此环节进行展示的广告展板和工程作品，应控制在长75厘米、宽100厘米、高180厘米的空间范围内。</div>
                  </div>
                  <div class="third-title">材质要求</div>
                  <div class="page_text mb-40">
                    <div>参展作品禁止使用KT板，鼓励符合3R(Reduce减少、Reuse再利用、Recycle再循环)可持续发展理念材料的替代使用。</div>
                  </div>
                  <div class="third-title">语言要求</div>
                  <div class="page_text mb-40">
                    <div>参展作品中所有的文本内容需包含中、英双语。</div>
                  </div>
                  <div class="third-title">道具要求</div>
                  <div class="page_text mb-40">
                    <div>多媒体设备(如笔记本电脑、PAD、便携式DVD播放器、显示器、投影仪等)可在展览使用，但需自备。所有和展示内容有关的设备、道具的固定式放置区域。</div>
                  </div>
                  <div class="third-title">广告展板评分规则</div>
                  <div class="rules">
                    <!--  -->
                    <div class="rules3-1">
                      <div class="rules3-1-text">选题明确、符合发布主题限定的探究范畴</div>
                      <img class="img" src="https://cdn.spbcn.org/ghac/page/3/3.png" alt="">
                      <div class="rules3-1-text">选题能够呼应联合国17个可持续发展目标中的一个或多个</div>
                    </div>
                    <div class="rules-center mb-60">
                      <div>设计理念突出，具有一定的深度</div>
                    </div>
                    <!--  -->
                    <div class="rules-center">
                      <div>所占空间、所用材质符合要求</div>
                    </div>
                    <div class="rules3-1">
                      <div class="rules3-1-text">
                        <div class="l1">条理清晰、符合逻辑、表达完整</div>
                        <div class="l2">内容准确、有效使用可靠的参考资料</div>
                      </div>
                      <img class="img" src="https://cdn.spbcn.org/ghac/page/3/1.png" alt="">
                      <div class="rules3-1-text">
                        <div class="l1">素材选取恰当、符合所选主题</div>
                        <div class="l2">整体色彩搭配和谐，具有美感</div>
                      </div>
                    </div>
                    <div class="rules-center mb-60">
                      <div>排版布局合理、书写正确、字迹美观</div>
                    </div>
                    <!--  -->
                    <div class="rules3-1 mb-60">
                      <div class="rules3-1-text">版式设计生动活泼，富有新意</div>
                      <img class="img" src="https://cdn.spbcn.org/ghac/page/3/12.png" alt="">
                      <div class="rules3-1-text">表现手法创新和视觉冲击强</div>
                    </div>
                    <el-row class="mb-60">
                      <el-col :span="8" v-for="(item, i) of list4" :key="i">
                        <div class="score">{{ item }}</div>
                      </el-col>
                    </el-row>
                  </div>
                  <div class="third-title">工程作品评分规则</div>
                  <div class="rules">
                    <!--  -->
                    <div class="rules3-1 mb-60">
                      <div class="rules3-1-text">选题明确、符合发布主题限定的探究范畴</div>
                      <img class="img" src="https://cdn.spbcn.org/ghac/page/3/5.png" alt="">
                      <div class="rules3-1-text">选题能够呼应联合国17个可持续发展目标中的一个或多个</div>
                    </div>
                    <!--  -->
                    <div class="rules3-1">
                      <div class="rules3-1-text">创意新颖、 形式活泼</div>
                      <img class="img" src="https://cdn.spbcn.org/ghac/page/3/16.png" alt="">
                      <div class="rules3-1-text">完全由团队手工制作而非组装，符合原创性</div>
                    </div>
                    <div class="rules-center mb-60">
                      <div>所占空间、所用材质符合要求</div>
                    </div>
                    <!--  -->
                    <div class="rules3-1 mb-60">
                      <div class="rules3-1-text">外形别致、制作精良</div>
                      <img class="img" src="https://cdn.spbcn.org/ghac/page/3/15.png" alt="">
                      <div class="rules3-1-text">色彩搭配得当、具有美感</div>
                    </div>
                    <el-row class="mb-60">
                      <el-col :span="8" v-for="(item, i) of list4" :key="i">
                        <div class="score">{{ item }}</div>
                      </el-col>
                    </el-row>
                  </div>
            </div>
          <!-- 环球嘉年华 -->
                  <div class="third-title">环球嘉年华</div>
                  <div class="page_text">
                    <div>在参与展演之余，参演师生可以在环球嘉年华里将感受到来自世界各国的非遗。环球嘉年华分为非遗匠人工作坊，小匠人集市区和环球非遗LiveShow三部分。</div>
                    <div>非遗匠人工作坊汇聚了五湖四海的非遗传承人，他们将实地展示自己扎实的非遗功底、现场制作自己传承的非遗作品。好吃、好玩、好看的非遗匠人工作坊区，将为参演师生提供一个学习非遗、体验非遗、品尝非遗的机会。</div>
                    <div>小匠人集市区是参演学生的交换天地。来自不同文化区域的小匠人，可以带着自己通过探究完成制作的非遗产品，来到这里与其他的小匠人进行产品交换或售卖。这是一个大家用劳动换取成果的天地。</div>
                    <div>LiveShow是与会观众的观演区。在这里，所有参会的家长、老师、学生都可以欣赏各位学生的精彩表情，为他们加油打气。</div>
                    <div>环球嘉年华为参演师生搭建了一个交流学习的平台，让所有来到环球文遗小使者大会的人们都能够体会非遗的魅力，感受优秀传统文化的精彩。</div>
                  </div>
                  <img class="page_img mb-40" src="https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_KMS_banner_04.png" alt="">
          <!-- 环球之夜社交晚宴 -->
                  <div class="third-title2">
                    <div>环球未来外交官之夜·社交晚宴</div>
                  </div>
                  <div class="page_text">
                    <div>环球未来外交官之夜·社交晚宴作为组委会为参展学生精心策划的重头戏，无疑为这场环球非遗盛宴增添了更为浓墨重彩的一笔。这一夜，来自全国各地的参展学生汇聚一堂，他们带着各自家乡的非遗瑰宝，共同见证并参与了这场别开生面的社交晚宴。</div>
                    <div>晚宴现场，各国驻华大使馆的官员代表纷纷携带着各自国家的非遗展演而来，为这场盛宴注入了国际化的元素。他们的到来，不仅为学生们提供了了解不同国家非遗文化的宝贵机会，更为整个晚宴增添了浓厚的外交氛围。</div>
                  </div>
                  <img class="page_img mb-40" src="https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_KMS_banner_05.png" alt="">
                  <!-- <div class="hide-more" @click="hideMoreFn2()">收起 <i class="el-icon-caret-top"></i></div> -->
                  <div class="sec-title">展演大会日程</div>
                  <img class="page_img border-r0" src="https://cdn.spbcn.org/ghac/theme/theme1_0703.png" alt="">

        </div>
        <!-- <div class="center-titleview">
          <div class="titleStr">大会项目类别及评分规则 ｜ Exhibition Projects and Evaluation System</div>
        </div> -->
        <!-- <div class="sec-title">流程细节与评选维度</div>
        <div class="third-title">流程细节</div>
        <div class="page_text">
          <div>
            大会活动分为开、闭幕式、环球大舞台、环球博览会、环球嘉年华、环球之夜社交晚宴6个项目板块。每支队伍所完成的探究报告、广告展板、PPT演示、舞台剧展示和工程作品共5项任务，将在以上6个项目板块中进行展示，并参与评审。组委会专业评审组及大众评审团，将根据不同的评分规则，对参演队伍提交完成的每一项任务进行打分。
          </div>
        </div> -->
        <!-- <div class="flow2">
          <div class="flow2-item" v-for="(item, i) of flow2" :key="i">
            <img :src="item.img" alt="">
            <div class="title">{{ item.title }}</div>
          </div>
        </div> -->
        <!-- <div class="flow3">
          <div class="flow3-item" v-for="(item, i) of flow3" :key="i">
            <div class="title">{{ item.title }}</div>
            <el-row class="flow3-list">
              <el-col :span="8" class="flow3-list-item" v-for="(sec, j) of item.list" :key="j">
                <div class="btn">{{ sec.btn }}</div>
                <div class="desc">{{ sec.desc }}</div>
              </el-col>
            </el-row>
          </div>
        </div> -->
        <!-- <div class="third-title">评价维度</div>
        <div class="pjwd" v-for="(item, i) of list3" :key="i">
          <div class="title">{{ item.title }}<br />能力</div>
          <el-row class="con">
            <el-col :span="8" v-for="(sec, j) of item.list" :key="j">
              <div class="item">{{ sec }}</div>
            </el-col>
          </el-row>
        </div> -->

      </div>
    </div>
    <!-- <div class="centerclass">
      <div class="center-titleview">
        <div class="titleStr">展演大会日程｜Exhibition Conference Schedule</div>
      </div>
      <img class="page_img border-r0" src="https://cdn.spbcn.org/ghac/theme/theme1_0703.png" alt="">
    </div> -->
  </div>
</template>

<script>
// import Topbar from '@/components/Topbar.vue'
// import Footer from '@/components/Footer.vue'
import ghacTab from '../../components/ghacTab.vue'
import ghacBanner from '../../components/ghacBanner2.vue'

export default {
  props: {
    dataHeight: {
      type: String,
      default: '100%'
    }
  },
  data() {
    return {
      // images: [
      //   { url: 'https://cdn.spbcn.org/ghac/banner/gczp/1.jpg', title: '工程作品' },
      //   { url: 'https://cdn.spbcn.org/ghac/banner/gczp/2.jpg', title: '工程作品' },
      //   { url: 'https://cdn.spbcn.org/ghac/banner/gczp/3.jpg', title: '工程作品' },
      //   { url: 'https://cdn.spbcn.org/ghac/banner/gczp/4.jpg', title: '工程作品' },
      //   { url: 'https://cdn.spbcn.org/ghac/banner/gczp/5.jpg', title: '工程作品' },
      //   { url: 'https://cdn.spbcn.org/ghac/banner/hqdwt/1.jpg', title: '环球大舞台' },
      //   { url: 'https://cdn.spbcn.org/ghac/banner/hqdwt/2.jpg', title: '环球大舞台' },
      //   { url: 'https://cdn.spbcn.org/ghac/banner/hqdwt/3.jpg', title: '环球大舞台' },
      //   { url: 'https://cdn.spbcn.org/ghac/banner/hqdwt/4.jpg', title: '环球大舞台' },
      //   { url: 'https://cdn.spbcn.org/ghac/banner/hqdwt/5.jpg', title: '环球大舞台' },
      //   { url: 'https://cdn.spbcn.org/ghac/banner/hqjnh/1.jpg', title: '环球嘉年华' },
      //   { url: 'https://cdn.spbcn.org/ghac/banner/hqjnh/2.jpg', title: '环球嘉年华' },
      //   { url: 'https://cdn.spbcn.org/ghac/banner/hqjnh/3.jpg', title: '环球嘉年华' },
      //   { url: 'https://cdn.spbcn.org/ghac/banner/hqjnh/4.jpg', title: '环球嘉年华' },
      //   { url: 'https://cdn.spbcn.org/ghac/banner/hqjnh/5.jpg', title: '环球嘉年华' },
      //   { url: 'https://cdn.spbcn.org/ghac/banner/hq.mp4', type: 'video' }
      // ],
      // 组别划分
      levels: [
        {
          title: '小学组别 (EL)',
          age: '(年级G1-G6 年龄6-12岁)',
          img: 'https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_icon_01.png'
        },
        {
          title: '初中组别 (ML)',
          age: '(年级G7-G9 年龄12-15岁)',
          img: 'https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_icon_02.png'
        },
        {
          title: '高中组别 (SL)',
          age: '(年级G10-G12 年龄15-18岁)',
          img: 'https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_icon_03.png'
        }
      ],
      // 组队形式
      list: [
        {
          img: 'https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_icon_04.png',
          desc: `学校按照要求组织每个
组别参与的队伍，每支队伍
应包含5-7名学生`
        },
        {
          img: 'https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_icon_05.png',
          desc: `每支队伍应包含
1名领队老师`
        },
        {
          img: 'https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_icon_01.png',
          desc: `每支队伍可包含
多名指导老师`
        }
      ],
      // 成员分工
      list2: [
        {
          img: 'https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_icon_06.png',
          role: '队长&副队长',
          desc: `负责协助老师进行
团队内部的统筹协调`
        },
        {
          img: 'https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_icon_07.png',
          role: '小匠人',
          desc: `负责展演相关作品的制作
(如广告展板、工程作品等)`
        },
        {
          img: 'https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_icon_08.png',
          role: '小演说家',
          desc: `负责向观众及评委讲述
团队作品理念与探究结果`
        },
        {
          img: 'https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_icon_09.png',
          role: '小作家',
          desc: `负责双语撰写作品
介绍等纸质材料`
        },
        {
          img: 'https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_icon_10.png',
          role: '小艺人',
          desc: `负责环球大舞台环
节的剧本创作、导
演、演员排演等工作`
        },
        {
          img: 'https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_icon_11.png',
          role: '小外交家',
          desc: `负责外部资源搜集
与对接`
        }
      ],
      // 整体流程
      flow: [
        {
          title: `组队
准备阶段`,
          type: 1,
          showMore: false,
          list: [
            {
              // title: '注册GHAC团队',
              // date: '每年9-10月',
              date: '学校注册认证',
              desc: '在www.ghacedu.com官网完成学校注册认证'
            },
            {
              // title: '选择探究主题及对应项目',
              // date: '每年9-10月',
              date: '选择探究主题及对应项目',
              desc: '根据组委会发布的本年度活动主题，选择团队要探究的主题及对应项目'
            },
            {
              date: '组建参展团队',
              clickable: true
            }
          ]
        },
        {
          title: `学习
探究阶段`,
          type: 2,
          list: [
            {
              // title: '校园社团学习',
              // date: '每年9月-次年5月',
              date: '校园社团学习',
              desc: '学生在校内进行非遗相关人文素养社团课程的学习'
            },
            {
              // title: '华夏文化探究',
              // date: '每年寒暑假',
              date: '华夏文化探究',
              desc: '学生在华夏不同区域进行当地文化的探究与学习'
            }
          ]
        },
        {
          title: `海外
荣耀时刻`,
          type: 1,
          list: [
            {
              // title: '海外出访交流',
              // date: '每年寒暑假',
              date: '海外出访交流',
              desc: '每年寒暑假，在全国展演活动中表现突出的队伍，将有机会代表华夏文化出访海外，学习交流不同国家的非遗'
            }
          ]
        },
        {
          title: `展演
交流阶段`,
          type: 2,
          list: [
            {
              // title: '省市展演交流',
              // date: '每年2-4月',
              date: '省市展演交流',
              desc: '团队参加省市级别的展演交流活动，优秀队伍将有机会代表本地区参加全国展演'
            },
            {
              // title: '全国展演大会',
              // date: '每年11月',
              date: '全国展演大会',
              desc: '来自全国各省市的优秀队伍将汇聚于此，参加全国的展演交流活动',
              clickable: true
            }
          ]
        }
      ],
      // 流程细节
      flow2: [
        {
          img: 'https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_LCXJ_ICON_01.png',
          title: '探究报告'
        },
        {
          img: 'https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_LCXJ_ICON_02.png',
          title: '广告展板'
        },
        {
          img: 'https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_LCXJ_ICON_03.png',
          title: 'PPT演示'
        },
        {
          img: 'https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_LCXJ_ICON_04.png',
          title: '舞台剧展示'
        },
        {
          img: 'https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_LCXJ_ICON_05.png',
          title: '工程作品'
        }
      ],
      // 流程
      flow3: [
        {
          title: '开幕式',
          list: [
            {
              btn: '参评任务',
              desc: '探究报告'
            },
            {
              btn: '参评时间',
              desc: `活动开始前两周
内提交至组委会`
            },
            {
              btn: '评价方式',
              desc: '专业评审'
            }
          ]
        },
        {
          title: '环球大舞台',
          list: [
            {
              btn: '参评任务',
              desc: `PPT演示
舞台剧展示
(含工程作品)`
            },
            {
              btn: '参评时间',
              desc: '活动当天'
            },
            {
              btn: '评价方式',
              desc: '专业评审'
            }
          ]
        },
        {
          title: '环球博览会',
          list: [
            {
              btn: '参评任务',
              desc: `广告展板
工程作品`
            },
            {
              btn: '参评时间',
              desc: '活动当天'
            },
            {
              btn: '评价方式',
              desc: `专业评审
及大众评审`
            }
          ]
        }
      ],
      // 评价维度
      list3: [
        {
          title: '核心素养',
          list: ['国际理解教育', '全球胜任力', '团队合作能力', '问题解决能力', '批判性思维能力', '创新创造力']
        },
        {
          title: '学科探究',
          list: ['大学专业探索', '中小学学科启发']
        },
        {
          title: '专业领域',
          list: ['语言技能', '写作技能', '演讲技能', '手工技能', '表演能力']
        }
      ],
      //
      list4: ['A-优秀: 10-9分', 'B-良好: 8-6分', 'C-提升: 5-4分'],
      showMore3: false

    }
  },
  components: {
    ghacTab,
    ghacBanner
    // Topbar,
    // Footer
  },
  methods: {
    fn(path) {
      if (path) {
        this.$router.push(path)
      } else {
        this.handleToReg()
      }
    },
    handleToReg () {
      const token = localStorage.getItem('HQAuthorization')
      if (token) {
        this.$router.push('/club/register?step=0')
      } else {
        this.$router.push('/loginview')
      }
    },
    showMoreFn (sec, i) {
      if (sec.clickable) {
        if (i === 0) {
          this.flow[i].showMore = !this.flow[i].showMore
        } else if (i === 3) {
          this.showMore3 = !this.showMore3
        }
      }
    },
    hideMoreFn (item) {
      item.showMore = false
    },
    hideMoreFn2 () {
      this.showMore3 = false
    }
  }
}
</script>

<style lang="less" scoped>
.contnet-backview {
  // width: 1000px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-bottom: 80px;
}

.top-backview {
  width: 100%;
  // padding-bottom: 10px;
  background-color: rgb(216, 216, 216);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.dibannerclass {
  width: 1000px;
  height: 469px;
  z-index: 99;
}

.bannerbottom {
  width: 1000px;
  height: 100px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 150px;
  z-index: 100;
}

.title-backview {
  height: 60px;
  font-size: 32px;
  font-family: FZLBJW;
  font-weight: 900;
  text-align: center;
  line-height: 60px;
  padding: 0px 20px;
  color: white;
  border: 2px solid rgb(255, 255, 255);
  .en{
    font-size: 24px;
    margin-top: 10px;
  }
}

.red-backview {
  width: 1000px;
  height: 25px;
}

.red-lineview {
  width: 1000px;
  height: 10px;
  // margin-top: 15px;
  background-color: #e50211;
}

.centerclass {
  width: 1000px;
}

.center-titleview {
  display: inline-block;
  padding-right: 100px;
  height: 42px;
  margin-top: 50px;
  background: url(https://cdn.spbcn.org/ghac/assets/feiyi_homepage_title.png);
  background-size: auto 100%;
  background-position: right;
  background-repeat: no-repeat;
}

.titleStr {
  // width: 600px;
  height: 42px;
  line-height: 42px;
  // text-align: center;
  font-size: 24px;
  font-family: FZLBJW;
  color: white;
  font-weight: bolder;
  padding-left: 30px;
  background-color: #b81b26;
}

.banner-img-class {
  width: 1000px;
  height: 470px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.video{
  width: 1000px;
  height: 470px;
}

.el-carousel {
  width: 1000px;
  height: 470px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.sec-title {
  width: 260px;
  height: 38px;
  background-image: url(https://cdn.spbcn.org/ghac/ghac_button_highlight.png);
  background-size: 100% 100%;
  text-align: center;
  color: #fff;
  font-family: hzgb;
  font-size: 18px;
  line-height: 38px;
  margin: 40px auto 30px;
  letter-spacing: 2px;
}

.page_text {
  font-size: 20px;
  font-family: FZLBJW;
  line-height: 32px;
  margin-top: 20px;

  div {
    text-indent: 2em;
  }
}
.page_tip{
  color: #666;
  font-size: 14px;
  text-align: center;
}

.page_img {
  width: 100%;
  // height: 186px;
  border-radius: 30px;
  margin-top: 40px;
}

.border-r0{
  border-radius: 0;
}

.topMenuBackview {
  width: 1000px;
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.menu-backview {
  color: #fff;
  font-size: 28px;
  font-family: FZLBJW;
  background-color: #000;
  height: 42px;
  line-height: 42px;
  padding: 0 40px;
  border-radius: 42px;
  cursor: pointer;
}

.selectColor {
  background-color: #b71b26;
}

.col-3 {
  text-align: center;
  margin-bottom: 30px;

  .icon {
    height: 120px;
    margin-bottom: 10px;
  }

  .icon2 {
    margin-bottom: -4px;
  }

  .h1 {
    color: #333;
    font-size: 18px;
    margin-bottom: 10px;
  }

  .h2 {
    font-size: 14px;
    white-space: pre;
    height: 70px;
  }

  .btn {
    width: 120px;
    height: 26px;
    border-radius: 26px;
    background-color: #b81c25;
    text-align: center;
    line-height: 26px;
    color: #fff;
    font-family: hzgb;
    font-size: 16px;
    margin: 0 auto 10px;
  }
}
.more{
  position: relative;
  z-index: 1000;
  background-color: #f4f4f4;
  padding: 40px 0;
  .hide-more{
    color: #b71b26;
    font-size: 16px;
    float: right;
    margin-right: 20px;
    cursor: pointer;
  }
}
.more2{
  padding: 40px;
}
.flow {
  position: relative;
  margin-top: 60px;

  .line {
    width: 20px;
    height: 100%;
    border: 2px solid #b81c25;
    background-color: #fff;
    position: absolute;
    left: 490px;
    top: 0;
  }

  .flow-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0;
  }

  .flow-title-con {
    width: 460px;
    display: flex;
    justify-content: flex-end;
    position: relative;

    .arrow1 {
      width: 46px;
      position: absolute;
      right: -53px;
      top: 40px;
    }

    .arrow2 {
      width: 45px;
      position: absolute;
      left: -49px;
      top: 40px;
    }
  }

  .flow-title {
    width: 250px;
    height: 250px;
    background-image: url(https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_ZTLC_icon_02.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    white-space: pre;
    color: #fff;
    font-size: 36px;
    font-family: hzgb;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 44px;
  }

  .flow-item-list {
    width: 460px;
  }

  .flow-sec-title {
    font-family: regular;
    color: #b81c25;
    font-size: 20px;
    margin-bottom: 10px;
  }

  .flow-sec-date-con {
    border-bottom: 2px solid #b81c25;
    margin-bottom: 10px;
  }

  .flow-sec-date {
    color: #fff;
    font-family: regular;
    font-size: 18px;
    background-color: #b81c25;
    padding: 2px 10px 4px;
    // border-radius: 20px;
    display: inline-block;
  }
  .click-more{
    color: #b71b26;
    font-size: 12px;
    cursor: pointer;
    display: inline-block;
    margin-left: 12px;
    cursor: pointer;
  }

  .flow-sec-desc {
    font-family: regular;
    font-size: 14px;
    color: #000;
    margin-bottom: 16px;
    margin-top: 16px;
  }

  .reverse {
    flex-direction: row-reverse;

    .flow-title {
      background-image: url(https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_ZTLC_icon_05.png);
    }

    .flow-title-con {
      justify-content: flex-start;
    }
  }
}

.third-title {
  width: 240px;
  height: 46px;
  padding-left: 46px;
  box-sizing: border-box;
  background-image: url(https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_01.png);
  background-size: auto 100%;
  background-repeat: no-repeat;
  font-family: hzgb;
  color: #fff;
  font-size: 14px;
  text-align: center;
  line-height: 46px;
  letter-spacing: 3px;
}
.third-title2 {
  width: 340px;
  height: 46px;
  padding-left: 46px;
  box-sizing: border-box;
  background-image: url(https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_01.png);
  background-size: auto 100%;
  background-repeat: no-repeat;
  font-family: hzgb;
  color: #fff;
  font-size: 14px;
  text-align: center;
  line-height: 46px;
  letter-spacing: 3px;
  div{
    height: 46px;
    background-image: url(https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_01_right.png);
    background-position: top right;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }
}

.flow2 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .flow2-item {
    text-align: center;
    position: relative;

    &:after {
      content: '';
      display: block;
      width: 100px;
      height: 2px;
      background-color: #000;
      position: absolute;
      right: -100px;
      top: 60px;
    }

    &:last-child {
      &::after {
        display: none;
      }
    }

    img {
      width: 120px;
      margin-bottom: 10px;
    }

    .title {
      font-size: 22px;
      font-family: regular;
      color: #000;
    }
  }
}

.flow3 {
  margin-bottom: 60px;

  .flow3-item {
    .title {
      width: 200px;
      height: 102px;
      background-image: url(https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_LCXJ_01.png);
      background-size: contain;
      background-position: top;
      background-repeat: no-repeat;
      margin: 40px auto 20px;
      text-align: center;
      line-height: 136px;
      font-size: 24px;
      font-family: hzgb;
      color: #fff;
    }
  }

  .flow3-list-item {
    text-align: center;

    .btn {
      width: 140px;
      height: 32px;
      border-radius: 32px;
      background-color: #b81c25;
      text-align: center;
      line-height: 32px;
      color: #fff;
      font-family: hzgb;
      font-size: 18px;
      margin: 0 auto 10px;
    }

    .desc {
      font-size: 16px;
      white-space: pre;
    }
  }
}

.pjwd {
  .title {
    width: 100px;
    height: 100px;
    background-image: url(https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_LCXJ_02.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    font-family: regular;
    font-size: 16px;
    color: #fff;
    margin: 40px auto 20px;
    text-align: center;
    padding-top: 24px;
    box-sizing: border-box;
  }

  .con {
    .item {
      margin: 10px auto;
      width: 200px;
      text-align: center;
      border: 2px solid #b81c25;
      border-radius: 40px;
      height: 30px;
      line-height: 30px;
      font-size: 14px;
    }
  }
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-60 {
  margin-bottom: 60px;
}

.rules {
  margin: 40px 0;

  .rules3-1 {
    display: flex;
    align-items: center;
    justify-content: center;

    .img {
      width: 300px;
    }
  }

  .rules3-1-text {
    width: 250px;
    margin: 0 20px;
    font-size: 16px;

    .l1 {
      margin-bottom: 100px;
    }
  }

  .rules-center {
    div {
      width: 250px;
      margin: 20px auto;
      font-size: 16px;
      text-align: left;
    }
  }
}

.score {
  width: 200px;
  color: #fff;
  height: 26px;
  line-height: 26px;
  text-align: center;
  border-radius: 26px;
  background-color: #b81c25;
  margin: 0 auto;
  font-size: 14px;
}
.red-bg-con{
  text-align: center;
}
.red-bg{
  height: 50px;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 50px;
  line-height: 50px;
  color: #fff;
  font-family: regular;
  font-size: 18px;
  background-color: #b81c25;
  display: inline-block;
  margin: 40px auto 20px;
}
.honor{
  text-align: center;
}
.honor-title{
  width: 260px;
  height: 42px;
  background-image: url(https://cdn.spbcn.org/ghac/page/3/HQWY_HQDH_DHLC_DHJX_00.png);
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
  line-height: 42px;
  font-family: regular;
  font-size: 18px;
  color: #fff;
  text-align: center;
  margin: 10px auto 40px;
}
.mt-60{
  margin-top: 60px;
}
.honor-p{
  width: 300px;
}
.honor-bg{
  width: 100%;
  height: 64px;
  background: #eadfc7;
  margin-top: 20px;
  margin-bottom: 40px;
}
.honor-title1{
  text-align: center;
  width: 200px;
  margin: 0 auto;
  font-family: regular;
  font-size: 18px;
  color: #000;
  padding-top: 10px;
}
.upload{
  background-image: url(https://cdn.spbcn.org/ghac/flag-border.png);
  background-size: 860px 80px;
  background-position: center;
  background-repeat: no-repeat;
  width: 900px;
  height: 100px;
  line-height: 96px;
  margin: 60px auto 0;
  background-color: #b81b26;
  border-radius: 14px;
  color: #fff;
  font-size: 40px;
  font-family: regular;
  text-align: center;
  cursor: pointer;
}

::v-deep .el-carousel__button{
  width: 10px;
}
.clickable{
  cursor: pointer;
}
</style>
