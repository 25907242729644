<template>
  <div>
    <div class="circle" @click="scrollToTop">
      <i class="el-icon-arrow-up"></i>
      <div>TOP</div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
    }
  },
  methods: {
    scrollToTop () {
      this.$emit('toTop')
    }
  }
}
</script>
<style lang="less" scoped>
.circle{
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background: #fff;
  color: #b81b26;
  border: 2px solid #b81b26;
  position: fixed;
  z-index: 10000;
  right: 40px;
  bottom: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 14px;
  font-family: hzgb;
  padding-bottom: 10px;
  cursor: pointer;
  i{
    font-size: 24px;
  }
  &:hover{
    background-color: #b81b26;
    color: #fff;
  }
}
</style>
