<template>
  <div class="h1">
    <img src="../assets/images/default.png" alt="">
  </div>
</template>
<script>
export default {
  data () {
    return {}
  },
  methods: {

  }
}
</script>
<style scoped lang="less">
.h1{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    width: 200px;
  }
}
</style>
