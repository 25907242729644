<template>
  <div>权限管理</div>
</template>

<script>
export default {

}
</script>

<style>

</style>
