<template>
  <div class="top-backview">
    <div class="contnet-backview">
      <!-- 顶部菜单 -->
      <!-- <Topbar class="topbar"></Topbar> -->
      <!-- banner -->
      <div class="dibannerclass">
        <el-carousel
          :interval="3000"
          indicator-position="none"
          id="el-carousel"
          :height="dataHeight"
        >
          <el-carousel-item v-for="item in images" :key="item.index">
            <img :src="item.url" alt="" class="banner-img-class" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="bannerbottom">
        <div class="title-backview">研发专家团队</div>
      </div>
      <!-- 中间内容部分 -->
      <div class="centerclass">
        <div class="expert-backview">
          <div v-for="item in expertList" :key="item.index">
            <div
              class="expert-Content"
              v-bind:style="{ 'background-image': 'url(' + item.backImg + ')' }"
            >
              <img :src="item.url" alt="" class="userhead-image" />
              <div
                class="userName-backview"
                v-bind:style="{
                  'background-image': 'url(' + item.usernameBack + ')'
                }"
              >
                {{ item.username }}
              </div>
              <div class="user-introduce">
                <span v-html="item.introduce"></span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <img
            src="https://cdn.spbcn.org/ghac/assets/aboutus_zhuangshi.png"
            alt=""
            class="center-spiritview-bottomimg"
          />
        </div>
      </div>
      <!-- 底部内容 -->
    </div>
  </div>
</template>

<script>
// import Topbar from '@/components/Topbar.vue'
// import Footer from '@/components/Footer.vue'
export default {
  props: {
    dataHeight: {
      type: String,
      default: '100%'
    }
  },
  data () {
    return {
      images: [{ url: 'https://cdn.spbcn.org/ghac/assets/aboutus1_1_banner.png' }],
      expertList: [
        {
          url: 'https://cdn.spbcn.org/ghac/assets/aboutus3_photo1.png',
          backImg: 'https://cdn.spbcn.org/ghac/assets/aboutus3_back_red.png',
          username: '鲁子问',
          usernameBack: 'https://cdn.spbcn.org/ghac/assets/aboutus3_nameback_white.png',
          introduce:
            '教育部教师教育资源<br>专家委员会成员<br>教育部首批国培专家<br>博士、教授、博士生导师'
        },
        {
          url: 'https://cdn.spbcn.org/ghac/assets/aboutus3_photo2.png',
          backImg: 'https://cdn.spbcn.org/ghac/assets/aboutus3_back_red.png',
          username: '秦琳',
          usernameBack: 'https://cdn.spbcn.org/ghac/assets/aboutus3_nameback_white.png',
          introduce: '中交教育科学研究院<br>国际与比较教育研究所副研究员'
        },
        {
          url: 'https://cdn.spbcn.org/ghac/assets/aboutus3_photo3.png',
          backImg: 'https://cdn.spbcn.org/ghac/assets/aboutus3_back_red.png',
          username: '梁彦民',
          usernameBack: 'https://cdn.spbcn.org/ghac/assets/aboutus3_nameback_white.png',
          introduce:
            '北京语言大学汉语国际教育<br>研究院党总支书记<br>硕士、研究生导师'
        },
        {
          url: 'https://cdn.spbcn.org/ghac/assets/aboutus3_photo4.png',
          backImg: 'https://cdn.spbcn.org/ghac/assets/aboutus3_back_red.png',
          username: '张大庆',
          usernameBack: 'https://cdn.spbcn.org/ghac/assets/aboutus3_nameback_white.png',
          introduce: '中国少年宫协会 主题活动专委会<br>秘书长'
        },
        {
          url: 'https://cdn.spbcn.org/ghac/assets/aboutus3_photo5.png',
          backImg: 'https://cdn.spbcn.org/ghac/assets/aboutus3_back_red.png',
          username: '刘旭',
          usernameBack: 'https://cdn.spbcn.org/ghac/assets/aboutus3_nameback_white.png',
          introduce: '成都市教育科学研究所'
        },
        {
          url: 'https://cdn.spbcn.org/ghac/assets/aboutus3_photo6.png',
          backImg: 'https://cdn.spbcn.org/ghac/assets/aboutus3_back_red.png',
          username: '陈骅',
          usernameBack: 'https://cdn.spbcn.org/ghac/assets/aboutus3_nameback_white.png',
          introduce:
            '曾任培生朗义大中华区英语教学业务和出版总监<br>牛津大学出版社中国英语教学出版主任'
        },
        {
          url: 'https://cdn.spbcn.org/ghac/assets/aboutus3_photo7.png',
          backImg: 'https://cdn.spbcn.org/ghac/assets/aboutus3_back_red.png',
          username: '郑彩华',
          usernameBack: 'https://cdn.spbcn.org/ghac/assets/aboutus3_nameback_white.png',
          introduce: '天津市教育科学研究院研究员'
        },
        {
          url: 'https://cdn.spbcn.org/ghac/assets/aboutus3_photo8.png',
          backImg: 'https://cdn.spbcn.org/ghac/assets/aboutus3_back_red.png',
          username: '张冠群',
          usernameBack: 'https://cdn.spbcn.org/ghac/assets/aboutus3_nameback_white.png',
          introduce: '深圳市福田区教科院教研员'
        },
        {
          url: 'https://cdn.spbcn.org/ghac/assets/aboutus3_photo9.png',
          backImg: 'https://cdn.spbcn.org/ghac/assets/aboutus3_back_white.png',
          username: '王丽',
          usernameBack: 'https://cdn.spbcn.org/ghac/assets/aboutus3_nameback_red.png',
          introduce:
            'SPBCN国际英文拼词大会<br>国家组委会学术研究中心主任<br>UIE国际理解教育人文素养社团项目组组长'
        },
        {
          url: 'https://cdn.spbcn.org/ghac/assets/aboutus3_photo10.png',
          backImg: 'https://cdn.spbcn.org/ghac/assets/aboutus3_back_white.png',
          username: '胡盼',
          usernameBack: 'https://cdn.spbcn.org/ghac/assets/aboutus3_nameback_red.png',
          introduce:
            'SPBCN国际英文拼词大会 <br>国家组委会 学术老师<br>清华大学访问学者'
        },
        {
          url: 'https://cdn.spbcn.org/ghac/assets/aboutus3_photo11.png',
          backImg: 'https://cdn.spbcn.org/ghac/assets/aboutus3_back_white.png',
          username: '冯赫毅',
          usernameBack: 'https://cdn.spbcn.org/ghac/assets/aboutus3_nameback_red.png',
          introduce: 'SPBCN国际英文拼词大会 <br>国家组委会 学术老师'
        },
        {
          url: 'https://cdn.spbcn.org/ghac/assets/aboutus3_photo12.png',
          backImg: 'https://cdn.spbcn.org/ghac/assets/aboutus3_back_white.png',
          username: '李嘉瑄',
          usernameBack: 'https://cdn.spbcn.org/ghac/assets/aboutus3_nameback_red.png',
          introduce:
            'SPBCN国际英文拼词大会 <br>国家组委会 学术老师<br>毕业于加拿大IVC Master University'
        }
      ]
    }
  },
  components: {
    // Topbar,
    // Footer
  }
}
</script>

<style lang="less" scoped>
// .topbar {
//   z-index: 1000;
//   width: 1000px;
//   padding: 0;
//   margin: 0;
// }
.contnet-backview {
  width: 1000px;
  // background-color: black;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.top-backview {
  width: 100%;
  // padding-bottom: 10px;
  background-color: rgb(216, 216, 216);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.dibannerclass {
  width: 1000px;
  height: 260px;
  z-index: 99;
  background-color: rgb(0, 0, 0);
}
.bannerbottom {
  width: 1000px;
  height: 100px;
  display: flex;
  background-color: black;
  align-items: center;
  justify-content: center;
}
.title-backview {
  height: 60px;
  font-size: 32px;
  font-family: FZLBJW;
  font-weight: 900;
  text-align: center;
  line-height: 60px;
  padding: 0px 20px;
  color: white;
  border: 2px solid rgb(255, 255, 255);
}
.centerclass {
  width: 1000px;
  // height: 1553px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // background-image: url(https://cdn.spbcn.org/ghac/assets/feiyi_background.png);
  background-color: rgba(0, 0, 0, 0.15);
  padding: 0px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}

.footerclass {
  width: 1000px;
}
.el-carousel {
  width: 1000px;
  height: 260px;
}
.banner-img-class {
  width: 1000px;
  height: 260px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.expert-backview {
  padding: 30px 0px;
  width: 1000px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}
.expert-Content {
  width: 280px;
  height: 351px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.userhead-image {
  margin-top: 50px;
  width: 100px;
  height: 100px;
}
.userName-backview {
  width: 240px;
  height: 38px;
  margin-top: 30px;
  font-size: 18px;
  font-family: FZLBJW;
  line-height: 38px;
  text-align: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.user-introduce {
  width: 260px;
  padding: 10px;
  font-size: 14px;
  font-family: FZLBJW;
  text-align: center;
  color: black;
}
.center-spiritview-bottomimg {
  width: 1000px;
  height: 90px;
  margin-top: 20px;
  margin-bottom: -20px;
}
</style>
