<template>
  <div class="bottom-contentview">
    <div class="bottom-redview" @click="showFn"></div>
    <div class="bottom-contactview">
      <div class="bottom-colview">
        <div class="contact-leftviewbackview">
          <div class="contact-leftview">
            <img
              src="https://cdn.spbcn.org/ghac/assets/feiyi_logo_down.png"
              alt=""
              class="contact-leftview-image"
            />
            <div class="leftview-thirdback">
              <div class="view-imgback">
                <img
                  src="https://cdn.spbcn.org/ghac/assets/feiyi_red_icon1.png"
                  alt=""
                  class="view-img-class"
                />
              </div>
              <div class="view-imgback">
                <img
                  src="https://cdn.spbcn.org/ghac/assets/feiyi_red_icon2.png"
                  alt=""
                  class="view-img-class"
                />
              </div>
              <div class="view-imgback">
                <img
                  src="https://cdn.spbcn.org/ghac/assets/feiyi_red_icon3.png"
                  alt=""
                  class="view-img-class"
                />
              </div>
              <div class="view-imgback">
                <img
                  src="https://cdn.spbcn.org/ghac/assets/feiyi_red_icon4.png"
                  alt=""
                  class="view-img-class"
                />
              </div>
              <div class="view-imgback">
                <img
                  src="https://cdn.spbcn.org/ghac/assets/feiyi_red_icon5.png"
                  alt=""
                  class="view-img-class"
                />
              </div>
              <div class="view-imgback">
                <img
                  src="https://cdn.spbcn.org/ghac/assets/feiyi_red_icon6.png"
                  alt=""
                  class="view-img-class"
                />
              </div>
              <div class="view-imgback">
                <img
                  src="https://cdn.spbcn.org/ghac/assets/feiyi_red_icon7.png"
                  alt=""
                  class="view-img-class"
                />
              </div>
            </div>
          </div>
          <div class="right-lineview"></div>
        </div>
        <!-- <div class="contact-centerview">
          <div class="center-contactTitle">Contact us</div>
          <div class="center-contactStr">
            Room 603. Tower C, Oriental Media Center, Chaoyang District,
            Beijing, China PR
          </div>
          <div class="center-contactStr">4008-365-587</div>
          <div class="center-contactStr">www.spbcn.org</div>
        </div> -->
        <div class="contact-centerbackview">
          <div class="center-contactTitleStr">Contact us</div>
          <div class="contact-centerback">
            <div
              class="contact-content"
              v-for="(item, i) in contactList"
              :key="i"
            >
              <img :src="item.imgUrl" alt="" class="contactImg" />
              <div class="contactStr">{{ item.titleStr }}</div>
            </div>
          </div>
        </div>
        <div class="contact-rightview">
          <div class="right-lineview"></div>
          <div class="right-contview">
            <div class="center-contactTitle">
              <router-link to="/homeView">Home </router-link>
            </div>
            <div class="center-contactStr">
              About us
              <!-- <router-link to="/homeView">About us</router-link> -->
            </div>
            <div class="center-contactStr">
              Registration
              <!-- <router-link to="/homeView">Registration</router-link> -->
            </div>
            <div class="center-contactStr">
              Introduction
              <!-- <router-link to="/homeView">Introduction</router-link> -->
            </div>
            <div class="center-contactStr">
              Updates
              <!-- <router-link to="/homeView">Updates</router-link> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="visible"
      width="600px">
        <div class="flex">
          <div class="item">
            <img class="img1" src="../assets/images/gzh.png" alt="">
            <div>环球文遗小使者官方微信公众号</div>
          </div>
          <div class="item">
            <img src="../assets/images/kfh.jpg" alt="">
            <div>环球文遗小使者官方客服微信号</div>
          </div>
        </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'app',
  data() {
    return {
      visible: false,
      contactList: [{
        imgUrl: 'https://cdn.spbcn.org/ghac/assets/lianxi_logo1.png',
        titleStr: '北京办公室'
      }, {
        imgUrl: 'https://cdn.spbcn.org/ghac/assets/lianxi_logo2.png',
        titleStr: '天津办公室'
      }, {
        imgUrl: 'https://cdn.spbcn.org/ghac/assets/lianxi_logo3.png',
        titleStr: '上海办公室'
      }, {
        imgUrl: 'https://cdn.spbcn.org/ghac/assets/lianxi_logo4.png',
        titleStr: '深圳办公室'
      }, {
        imgUrl: 'https://cdn.spbcn.org/ghac/assets/lianxi_logo5.png',
        titleStr: '成都办公室'
      }, {
        imgUrl: 'https://cdn.spbcn.org/ghac/assets/lianxi_logo6.png',
        titleStr: '大连办公室'
      }]
    }
  },
  methods: {
    showFn () {
      this.visible = true
    }
  }
}
</script>

<style>
.bottom-contentview {
  /* width: 1000px; */
  width: 100%;
}

.bottom-contactview {
  /* width: 1000px; */
  width: 100%;
  height: 320px;
  background: url(https://cdn.spbcn.org/ghac/assets/feiyi_down_back.png);
  background-size: 100%;
  background-position: 50% 58%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.bottom-redview {
  /* width: 1000px; */
  width: 100%;
  height: 126px;
  background: url(https://cdn.spbcn.org/ghac/assets/feiyi_contectus.png);
  background-size: cover;
  background-position: center;
}
.bottom-colview {
  /* width: 1000px; */
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.contact-leftviewbackview {
  width: 240px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.contact-leftview {
  width: 230px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.leftview-thirdback {
  margin-top: 10px;
  width: 170px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.view-imgback {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: #f2f2f2;
}
.contact-centerview {
  width: 420px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contact-centerbackview{
  width: 420px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.contact-centerback {
  width: 420px;
  margin-top: 20px;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.center-contactTitle {
  width: 220px;
  padding: 0 30px;
  font-size: 16px;
  font-weight: bolder;
  text-align: left;
  color: #5e5e5e;
}
.center-contactTitleStr{
  width: 220px;
  font-size: 16px;
  margin-left: 10px;
  text-align: left;
  font-weight: bolder;
  color: #5e5e5e;
}
.center-contactStr {
  width: 220px;
  padding: 3px 30px;
  font-size: 12px;
  text-align: left;
  color: #5e5e5e;
}

.contact-rightview {
  width: 240px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.right-contview {
  width: 230px;
  display: flex;
  flex-direction: column;
}
.right-lineview {
  width: 2px;
  height: 110px;
  /* margin-right: 10px; */
  background-color: #5e5e5e;
}
.contact-leftview-image {
  width: 80px;
  height: 86px;
}
.view-img-class {
  width: 15px;
  height: 15px;
}
.contact-content {
  width: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contactStr {
  width: 70px;
  font-size: 10px;
  margin-top: 5px;
  color: #5e5e5e;
  text-align: center;
}
.contactImg {
  width: 48px;
  height: 48px;
}
a {
  text-decoration: none;
  color: #515151;
}

/* .router-link-active {
  text-decoration: none;
} */
.flex {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  font-size: 16px;
  font-family: FZLBJW;
  color: #000;
  text-align: center;
  padding-bottom: 20px;
}
.flex img{
  width: auto;
  height: 120px;
  margin-bottom: 10px;
  border: 10px solid #b81b26;
  border-radius: 10px;
}
</style>
