<template>
  <div class="top-backview">
    <div class="contnet-backview">
      <!-- 顶部菜单 -->
      <!-- <Topbar class="topbar"></Topbar> -->
      <!-- banner -->
      <div class="dibannerclass">
        <el-carousel
          :interval="3000"
          indicator-position="none"
          id="el-carousel"
          :height="dataHeight"
        >
          <el-carousel-item v-for="item in images" :key="item.index">
            <img :src="item.url" alt="" class="banner-img-class" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="bannerbottom">
        <div class="title-backview">环球文遗小使者大会</div>
      </div>
      <div class="red-backview">
        <div class="red-lineview"></div>
      </div>
      <!-- 中间内容部分 -->
      <div class="centerclass">
        <div class="center-titleview">
          <div class="titleStr">大会简介</div>
        </div>
        <div class="course-description-backview">
          <div class="description-contentStr">
            《环球文遗小使者大会》起源于2021年拥有5000年璀璨历史文化的中国，在后疫情时代大会为响应联合国17个可持续发展目标的战略规划，遵照联合国构建人类命运共同体的理念。为推进联合国教科文组织在全球青少年范围内国际理解教育的实施，提升全球青少年人文素养能力，特别甄选世界非物质文化遗产作为大会文化探究的基础，开设8大类型挑战赛，设置近百个主题500多个项目.通过博古、通今、创未来的大会挑战赛思路，让青少年在通晓本国传统文化的基础上实现地域和全球国际比较教育理解，并且通过学校体制内的学科的参透去探究自己未来感兴趣的大学专业和职业。在挑战赛中把传统的历史文化和现实生活的场景进行融合探究，并激发学生对于未来科学和生活的创新。
          </div>
          <div class="description-contentStr">
            《环球文遗小使者大会》的研发智库包含联合国教科文组织相关专家学者，也包含各个国家教育相关部门、非物质文化遗产相关部门并且联合全球和地区各高校的教授和学者共同和设计和进行评定工作。大会将通过学校机构和家庭社区两个区域甄选参加《环球文遗小使者大会》全球挑战赛的战队。并且将为组织参赛的学校和社区提供，相关的课程体系和研学探究方案，让备赛的代表队能够有充足的知识贮备和准备参赛作品的场景和工具。
          </div>
          <div class="description-contentStr">
            该项大会发起方UIE聚知优国际教育集团，作为《SPELLING BEE of
            CHINA》中国地区组委会已经成功在中国地区的60多个城市举办此项活动，SPELLING
            BEE英语素质教育活动1925年起源于美国，作为全球范围内影响力最大，参与人数最多的青少年活动，已经成功在全球5大洲100多个国家开展将近百年的历史，每年在全球范围拥有近5000万的学生参与。SPELLING
            BEE凭借其简单和公平的英语拼词活动，深受各国青少年和家庭的爱戴。学好英文向世界表达中国璀璨传统文化，将是中国地区青少年的使命，并且通过大会让每一位参与者增强学生的科学和人文素养的同时，真正以学生兴趣为导向的去了解学科知识的魅力，去探究自己未来大学的专业和未来人生的职业.
          </div>
        </div>
        <img
          src="https://cdn.spbcn.org/ghac/assets/feiyiprograms1_2_icon.png"
          alt=""
          class="content-image"
        />
      </div>
      <!-- 底部内容 -->
    </div>
  </div>
</template>

<script>
// import Topbar from '@/components/Topbar.vue'
// import Footer from '@/components/Footer.vue'
export default {
  props: {
    dataHeight: {
      type: String,
      default: '100%'
    }
  },
  data () {
    return {
      images: [{ url: 'https://cdn.spbcn.org/ghac/assets/feiyiprograms4_1_banner.png' }]
    }
  },
  components: {
    // Topbar,
    // Footer
  }
}
</script>

<style lang="less" scoped>
// .topbar {
//   z-index: 1000;
//   width: 1000px;
//   padding: 0;
//   margin: 0;
// }
.contnet-backview {
  width: 1000px;
  background-color: black;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.top-backview {
  width: 100%;
  // padding-bottom: 10px;
  background-color: rgb(216, 216, 216);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.dibannerclass {
  width: 1000px;
  height: 469px;
  z-index: 99;
  background-color: rgb(0, 0, 0);
}
.bannerbottom {
  width: 1000px;
  height: 100px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 150px;
  z-index: 100;
}
.title-backview {
  height: 60px;
  font-size: 32px;
  font-family: FZLBJW;
  font-weight: 900;
  text-align: center;
  line-height: 60px;
  padding: 0px 20px;
  color: white;
  border: 2px solid rgb(255, 255, 255);
}
.red-backview {
  width: 1000px;
  height: 25px;
}
.red-lineview {
  width: 1000px;
  height: 6px;
  margin-top: 15px;
  background-color: #e50211;
}
.centerclass {
  width: 1000px;
  height: 1153px;
  display: flex;
  flex-direction: column;
  background-image: url(https://cdn.spbcn.org/ghac/assets/feiyi_background_mix.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.center-titleview {
  width: 377px;
  height: 42px;
  margin-top: 30px;
  background: url(https://cdn.spbcn.org/ghac/assets/feiyi_homepage_title.png);
  background-size: 100%;
}

.titleStr {
  width: 280px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  font-size: 24px;
  font-family: FZLBJW;
  color: white;
  font-weight: bolder;
}

.course-description-backview {
  width: 800px;
  margin-left: 60px;
  margin-top: 30px;
  padding: 50px 40px;
  background-color: #ffffff;
  border-radius: 30px;
}
.description-contentStr {
  text-indent: 2em;
  margin-top: 10px;
  font-family: FZLBJW;
  font-size: 20px;
}
.content-image {
  width: 800px;
  height: 310px;
  margin-left: 100px;
  margin-top: 40px;
}
.footerclass {
  width: 1000px;
}
.banner-img-class {
  width: 1000px;
  height: 470px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.el-carousel {
  width: 1000px;
  height: 470px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
