<template>
  <div class="top-backview">
    <div class="contnet-backview">
      <!-- 顶部菜单 -->
      <!-- <Topbar class="topbar"></Topbar> -->
      <!-- banner -->
      <div class="dibannerclass">
        <el-carousel
          :interval="3000"
          indicator-position="none"
          id="el-carousel"
          :height="dataHeight"
        >
          <el-carousel-item v-for="item in images" :key="item.index">
            <img :src="item.url" alt="" class="banner-img-class" />
          </el-carousel-item>
        </el-carousel>
        <div class="bannerbottom">
          <a
            class="title-backview"
            :href="currentUrl + 'loginview'"
            target="_blank"
            >JOIN US</a
          >
        </div>
      </div>
      <div class="red-backview">
        <div class="red-lineview"></div>
      </div>
      <!-- 中间内容部分 -->
      <div class="centerclass">
        <div class="center-titleview">
          <div class="titleStr">加入我们</div>
        </div>
        <div class="top-title-backview">
          聚知优国际教育-做为SpellingBee全球最大的英语活动中国地区组委会，为中国全日制学校提供基于校本课程延时社团活动：国际理解教育人文素养素拓课。我们将针对中国民族非物质文化遗产和国际热门话题作为核心内容，为学校提供基于双语的国际理解教育素质拓展活动。从四个方面提供：人文素养课程学习、人文素养研学探究、校园Micro-museum和环球文遗小使者大会。为了更好地为学校服务，请您完成以下信息注册。
        </div>
        <img
          src="https://cdn.spbcn.org/ghac/assets/feiyiregister_text.png"
          alt=""
          class="english-titleimg"
        />
        <div class="menu-content-backview">
          <div class="menu-backview" @click="chineseCourseClick">
            <img
              src="https://cdn.spbcn.org/ghac/assets/feiyiregister_icon1.png"
              alt=""
              class="menu-logo-image"
            />
            <div class="menu-title-str">汉语语言素养素拓课</div>
            <div class="menu-content-str">
              俱乐部的设计基于让孩子们在汉语学科领域学术能力提升，在此过程中也会帮他们认知世界，培养个人核心竞争力>详情
            </div>
          </div>
          <div class="menu-backview redColor" @click="engOrderGrab">
            <img
              src="https://cdn.spbcn.org/ghac/assets/aboutus4_icon5.png"
              alt=""
              class="menu-logo-image"
            />
            <div class="menu-title-str blackColor">英语语言素养素拓课</div>
            <div class="menu-content-str">
              UIE·SPBCN英语语言素养素拓课致力于培养具有全球视野的“世界小公民”。>详情
            </div>
          </div>
          <div class="menu-backview redColor" @click="humanitiesTag">
            <img
              src="https://cdn.spbcn.org/ghac/assets/aboutus4_icon4.png"
              alt=""
              class="menu-logo-image"
            />
            <div class="menu-title-str blackColor">
              国际理解教育人文素养素拓课
            </div>
            <div class="menu-content-str">
              针对中国民族非物质文化遗产和国际热门话题做为核心内容，为学校提供基于双语的国际理解教育素拓活动。>详情
            </div>
          </div>
        </div>
      </div>
      <!-- 底部内容 -->
    </div>
  </div>
</template>

<script>
// import Topbar from '@/components/Topbar.vue'
// import Footer from '@/components/Footer.vue'
export default {
  props: {
    dataHeight: {
      type: String,
      default: '100%'
    }
  },
  data () {
    return {
      images: [{ url: 'https://cdn.spbcn.org/ghac/assets/feiyiprograms202305104_2_banner.png' }]
    }
  },
  computed: {
    currentUrl () {
      const prefix = process.env.NODE_ENV === 'production' ? 'www.' : process.env.NODE_ENV === 'test' ? 'test.' : ''
      return process.env.NODE_ENV === 'development' ? `http://${window.location.host}/#/` : `http://${prefix}ghacedu.com/#/`
    }
  },
  methods: {
    engOrderGrab () {
      window.open('http://t1.ink/f/z4ojp1')
    },
    humanitiesTag () {
      window.open('http://t1.ink/f/xzacwe')
    },
    chineseCourseClick () {
      window.open('https://biaodan100.com/web/formview/6257b7b775a03c1b1704f619')
    },
    turnClicked () {
      window.open('http://www.ghacedu.com/#/loginview')
    }
  },
  components: {
    // Topbar,
    // Footer
  }
}
</script>

<style lang="less" scoped>
// .topbar {
//   z-index: 1000;
//   width: 1000px;
//   padding: 0;
//   margin: 0;
// }
.contnet-backview {
  width: 1000px;
  background-color: black;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.top-backview {
  width: 100%;
  // padding-bottom: 10px;
  background-color: rgb(216, 216, 216);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.dibannerclass {
  width: 1000px;
  height: 469px;
  z-index: 99;
  background-color: rgb(0, 0, 0);
  position: relative;
}
.bannerbottom {
  width: 1000px;
  // height: 100px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  bottom: 4px;
}
.title-backview {
  height: 40px;
  font-size: 20px;
  // font-weight: 900;
  text-align: center;
  line-height: 40px;
  padding: 0px 60px;
  border-radius: 40px;
  color: white;
  background-color: #000;
  border: 2px solid #000;
  cursor: pointer;
  &:hover {
    border: 2px solid #fff;
  }
}
.blackColor {
  color: black !important;
}
.redColor {
  background-color: #b81b26 !important;
}
.red-backview {
  width: 1000px;
  height: 25px;
}
.red-lineview {
  width: 1000px;
  height: 6px;
  margin-top: 15px;
  background-color: #e50211;
}
.centerclass {
  width: 1000px;
  display: flex;
  flex-direction: column;
  background-image: url(https://cdn.spbcn.org/ghac/assets/feiyi_background.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.center-titleview {
  width: 377px;
  height: 42px;
  margin-top: 30px;
  background: url(https://cdn.spbcn.org/ghac/assets/feiyi_homepage_title.png);
  background-size: 100%;
}

.titleStr {
  width: 280px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  font-size: 24px;
  font-family: FZLBJW;
  color: white;
  font-weight: bolder;
}

.footerclass {
  width: 1000px;
}
.banner-img-class {
  width: 1000px;
  height: 470px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.top-title-backview {
  margin: 20px 100px;
  width: 750px;
  padding: 10px 25px;
  background-color: white;
  font-size: 18px;
  font-family: FZLBJW;
  border-radius: 20px;
  text-indent: 2em;
}
.english-titleimg {
  width: 500px;
  height: 42px;
  margin: 10px 250px;
}
.menu-content-backview {
  width: 1000px;
  margin: 30px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.menu-backview {
  width: 260px;
  padding-bottom: 20px;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.menu-logo-image {
  width: 100px;
  height: 100px;
  margin-top: 40px;
  margin-bottom: 10px;
}
.menu-title-str {
  width: 260px;
  margin: 10px 0px;
  font-size: 18px;
  text-align: center;
  font-weight: 900;
  color: #b81b26;
}
.menu-content-str {
  width: 220px;
  font-size: 12px;
  color: white;
}
.el-carousel {
  width: 1000px;
  height: 470px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
