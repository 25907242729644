<template>
  <div class="top-backview">
    <div class="contnet-backview">
      <!-- 顶部菜单 -->
      <!-- <Topbar class="topbar"></Topbar> -->
      <!-- banner -->
      <div class="dibannerclass">
        <el-carousel
          :interval="3000"
          indicator-position="none"
          id="el-carousel"
          :height="dataHeight"
        >
          <el-carousel-item v-for="item in images" :key="item.index">
            <img :src="item.url" alt="" class="banner-img-class" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="bannerbottom">
        <div class="title-backview">PBL项目式学习与5C核心素养</div>
      </div>
      <!-- 中间内容 -->
      <div class="pblcenterclass">
        <div class="center-titlestr">
          PBL全称为Project-based
          learning即基于项目的学习方式，也就是项目教学法。最早起源于20世纪50年代的医学教育，PBL教学法主要是指在教师的指导下，通过一个完整项目的选题、设计、执行、评估全过程，对于某个产品、服务项目进行全方位的学习理解。项目式学习为的是培养孩子/学生的批判性思维和解决问题的能力，过程中要培养团队合作能力，创新创造能力，自我管理能力，公众演讲和表达能力等等。为了实现这些目标，项目的设计有七大关键核心要素。
        </div>
        <div>
          <img
            src="https://cdn.spbcn.org/ghac/assets/aboutus1_3_image1.png"
            alt=""
            class="center-image1"
          />
        </div>
        <div class="center-titlestr">
          国际理解教育人文素养课程是在《21世纪核心素养5C模型研究报告（中文版）》成果的基础上实施的。这份报告吸纳了中国学者在相关领域的研究成果，并基于我国社会、经济、科技、教育发展需求，进一步追问“打下中国根基、兼具国际视野”的人应该具有哪些素养，提出了“21世纪核心素养5C模型”并搭建框架、阐述内涵。并在这个模式下，产生专家、导师、教练和学生四个角色。
        </div>
        <div>
          <img
            src="https://cdn.spbcn.org/ghac/assets/aboutus1_3_image2.png"
            alt=""
            class="center-image2"
          />
        </div>
        <div>
          <img
            src="https://cdn.spbcn.org/ghac/assets/aboutus_zhuangshi.png"
            alt=""
            class="center-spiritview-bottomimg"
          />
        </div>
      </div>
      <!-- 底部内容 -->
    </div>
  </div>
</template>

<script>
// import Topbar from '@/components/Topbar.vue'
// import Footer from '@/components/Footer.vue'
export default {
  props: {
    dataHeight: {
      type: String,
      default: '100%'
    }
  },
  data () {
    return {
      images: [{ url: 'https://cdn.spbcn.org/ghac/assets/aboutus1_2_banner.png' }]
    }
  },
  components: {
    // Topbar,
    // Footer
  }
}
</script>

<style lang="less" scoped>
// .topbar {
//   z-index: 1000;
//   width: 1000px;
//   padding: 0;
//   margin: 0;
// }
.contnet-backview {
  width: 1000px;
  background-color: black;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.top-backview {
  width: 100%;
  // padding-bottom: 10px;
  background-color: rgb(216, 216, 216);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.dibannerclass {
  width: 1000px;
  height: 260px;
  z-index: 99;
  background-color: rgb(0, 0, 0);
}
.bannerbottom {
  width: 1000px;
  height: 100px;
  display: flex;
  background-color: black;
  align-items: center;
  justify-content: center;
}
.title-backview {
  height: 60px;
  font-size: 32px;
  font-family: FZLBJW;
  font-weight: 900;
  text-align: center;
  line-height: 60px;
  padding: 0 20px;
  color: white;
  border: 2px solid rgb(255, 255, 255);
}
.el-carousel {
  width: 1000px;
  height: 260px;
}
.banner-img-class {
  width: 1000px;
  height: 260px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.pblcenterclass {
  width: 1000px;
  // height: 1553px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(https://cdn.spbcn.org/ghac/assets/feiyi_background.png);
  padding: 0px;
  // padding-bottom: 0px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.center-titlestr {
  margin-top: 30px;
  width: 800px;
  text-indent: 2em;
  font-size: 18px;
  font-family: FZLBJW;
  color: rgb(41, 41, 41);
}
.center-spiritview-bottomimg {
  width: 1000px;
  height: 90px;
  margin-top: 20px;
  margin-bottom: -20px;
}
.center-image1 {
  width: 880px;
  height: 279px;
  margin-top: 20px;
}
.center-image2 {
  margin-top: 30px;
  width: 880px;
  height: 320px;
}
.center-spiritview-bottomimg {
  width: 1000px;
  height: 90px;
  margin-top: 20px;
  margin-bottom: -20px;
}
.footerclass {
  width: 1000px;
}
</style>
