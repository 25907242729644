<template>
  <div class="mobile-page">
    <div class="img">
      <ghacBanner v-if="type === 'banner'" :isMobile="true"></ghacBanner>
      <video v-if="type === 'video'" class="vid" controls src="https://cdn.spbcn.org/ghac/video/shdxx.mp4"></video>
    </div>
  </div>
</template>
<script>
import ghacBanner from '@/components/ghacBanner.vue'
export default {
  components: {
    ghacBanner
  },
  data () {
    return {
      type: ''
    }
  },
  created () {
    document.title = '环球文遗小使者大会'
    this.type = this.$route.query.type
    console.log(this.type)
  }
}
</script>
<style lang="less" scoped>
.mobile-page{
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(../assets/images/bg.jpg);
  background-size: cover;
  background-position: center;
}
.img{
  width: 100%;
  margin-bottom: 150px;
}
.vid{
  width: 100%;
  height: 760px;
}
</style>
