<template>
  <div class="login-container">
    <div class="regist-form-backview">
      <div class="regist-title-backview">
        <!-- ../../assets/images/ghac_login_choose_icon.png -->
        <img
          src="https://cdn.spbcn.org/ghac/login/HQWY_DL_logo_03.png"
          alt=""
          class="regist-title-rightImg"
          @click="backtoHomeClicked"
        />
        <div class="regist-form-title">| 会员学校注册</div>
      </div>
      <el-tabs
        v-if="false"
        v-model="activeName"
        @tab-click="handleClick"
        style="width: 100%"
        :header-cell-style="{ 'text-align': 'center' }"
      >
        <!-- 选手信息注册 -->
        <!-- <el-tab-pane name="player">
          <span slot="label"
            ><i class="el-icon-s-custom"></i> 选手账户注册</span
          >
          <el-form
            :model="registModel"
            :rules="registFormRules"
            ref="registRuleFormRef"
            label-width="120px"
            style="width: 70%; margin-left: 15%; margin-top: 6%"
          >
            <el-form-item label="学生姓名" prop="userName">
              <el-input
                v-model="registModel.userName"
                placeholder="请输入学生姓名"
              ></el-input>
            </el-form-item>
            <el-form-item label="证件类型" prop="cardType">
              <el-select
                placeholder="请选择证件类型"
                style="width: 100%"
                v-model="registModel.cardType"
                clearable
              >
                <el-option
                  v-for="item in dict_idCardType"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="证件号" prop="idCard">
              <el-input
                v-model="registModel.idCard"
                placeholder="请输入证件号"
                maxlength="18"
                @input="checkInput($event)"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="出生日期" prop="dateBirth">
              <el-date-picker
                v-model="registModel.dateBirth"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="性别" prop="sex">
              <el-radio-group v-model="registModel.sex">
                <el-radio label="1">男</el-radio>
                <el-radio label="0">女</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="邮箱" prop="email">
              <el-input
                v-model="registModel.email"
                placeholder="请输入邮箱"
                style="width: 100%"
              ></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="phone">
              <el-input
                v-model="registModel.phone"
                placeholder="请输入手机号"
                style="width: 100%"
              ></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="code">
              <el-input
                v-model="registModel.code"
                type="code"
                placeholder="请输入短信验证码"
              >
                <el-button
                  :loading="sending"
                  :disabled="sendDisabled"
                  size="small"
                  @click="SendSms('player')"
                  slot="suffix"
                  >{{ sendButtonText }}</el-button
                >
              </el-input>
            </el-form-item>
          </el-form>
        </el-tab-pane> -->
        <!-- 学校信息注册 -->
        <el-tab-pane name="school">

          <!-- <span slot="label"><i class="el-icon-school"></i> 学校账户注册</span> -->
        </el-tab-pane>
      </el-tabs>
          <el-form
            :model="registModel"
            :rules="schoolRegistFormRules"
            ref="schoolRegistRuleFormRef"
            label-width="130px"
            style="width: 50%; margin-top: 50px"
          >
            <el-form-item label="学校省市区" prop="schoolArea">
              <el-cascader
                ref="provincesHandleChangeRef"
                placeholder="请选择省市区"
                style="width: 100%"
                v-model="registModel.schoolArea"
                :options="areasData"
                :props="{
                  expandTrigger: 'hover',
                  label: 'name',
                  value: 'id',
                  children: 'areas'
                }"
                @change="provincesHandleChange"
                clearable
              ></el-cascader>
            </el-form-item>
            <el-form-item label="学校名称" prop="schoolId">
              <el-select
                ref="mailingInfoModelSchoolSelectRef"
                placeholder="请选择"
                style="width: 100%"
                v-model="registModel.schoolId"
                @change="schoolSelectChange"
                clearable
                filterable
              >
                <el-option
                  v-for="item in provincesSchoolList"
                  :key="item.id"
                  :label="item.schoolName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="文化区域">
              <el-input disabled v-model="garea"></el-input>
            </el-form-item>
            <el-form-item label="学校类型" prop="schoolType">
              <el-select
                placeholder="请选择学校类型"
                style="width: 100%"
                v-model="registModel.schoolType"
                clearable
              >
                <el-option
                  v-for="item in dict_schoolType"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="学校组别" prop="schoolLevelArray">
              <el-select
                placeholder="请选择学校组别"
                style="width: 100%"
                v-model="registModel.schoolLevelArray"
              >
                <el-option
                  v-for="item in dict_schoolGroup"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="负责老师" prop="schoolTeacherName">
              <el-input
                v-model="registModel.schoolTeacherName"
                placeholder="请输入姓名"
                maxlength="20"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="phone">
              <el-input
                v-model="registModel.phone"
                placeholder="请输入手机号"
                style="width: 100%"
              ></el-input>
            </el-form-item>
            <el-form-item label="邮箱" prop="email">
              <el-input
                v-model="registModel.email"
                placeholder="请输入邮箱"
                style="width: 100%"
              ></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="code">
              <el-input
                v-model="registModel.code"
                type="code"
                placeholder="请输入短信验证码"
              >
                <el-button
                  :loading="sending"
                  :disabled="sendDisabled"
                  size="small"
                  @click="SendSms('teacher')"
                  slot="suffix"
                  >{{ sendButtonText }}</el-button
                >
              </el-input>
            </el-form-item>
          </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="registClicked"
          style="width: 240px; margin-top: 15%; background-color: #b81c25;border-color: #b81c25;"
          >注 册</el-button
        >
      </span>
    </div>
  </div>
</template>

<script>
import areaJson from '../../assets/area.json'
import { onSendSms, registeredUser, getSchoolList, registeredSchool } from '../../http/api'
// import preventBack from 'vue-prevent-browser-back' // 禁止返回上一页
export default {
  // mixins: [preventBack],
  data () {
    return {
      areasData: areaJson.areas,
      provincesSchoolList: [],
      upLoadPicUrl: this.$http.defaults.baseURL + '/spbcnapp/userpic',
      sending: false,
      timer: 0,
      sendDisabled: false,
      registModel: {
        avatar: 'https://cdn.spbcn.org/spbcnappneed/userHeadPlaceImage.png',
        userName: '',
        email: '',
        sex: '',
        cardType: '',
        idCard: '',
        phone: '',
        code: '',
        dateBirth: '',
        schoolArea: [],
        schoolId: '',
        schoolName: '',
        schoolType: '',
        schoolTeacherName: '',
        schoolLevelArray: ''
      },
      garea: '',
      activeName: 'school',
      registFormRules: {
        userName: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        sex: [
          { required: true, message: '请选择性别', trigger: 'change' }
        ],
        cardType: [
          { required: true, message: '请选择证件类型', trigger: 'change' }
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' }
        ],
        idCard: [
          { required: true, message: '请输入证件号', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入短信验证码', trigger: 'blur' }
        ],
        dateBirth: [
          { required: true, message: '请选择出生日期', trigger: 'change' }
        ]
      },
      schoolRegistFormRules: {
        schoolArea: [
          { required: true, message: '请选择省市区', trigger: 'change' }
        ],
        schoolId: [
          { required: true, message: '请选择学校', trigger: 'change' }
        ],
        schoolType: [
          { required: true, message: '请选择学校类型', trigger: 'change' }
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
        ],
        schoolTeacherName: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入短信验证码', trigger: 'blur' }
        ],
        schoolLevelArray: [
          { required: true, message: '请选择学校组别', trigger: 'change' }
        ]
      },
      dict_idCardType: this.$curUtils.dict_idCardType(),
      dict_schoolType: this.$curUtils.dict_schoolType(),
      dict_schoolGroup: this.$curUtils.dict_schoolGroup()
    }
  },
  created () {
    const lastSendTime = window.sessionStorage.getItem('registLast-send-time')
    if (lastSendTime) {
      const timer = 60 - this.$moment(new Date()).diff(this.$moment(lastSendTime), 'seconds')
      if (timer > 0) this.sendDisabled = true
      this.timer = timer > 0 ? timer : 0
    }
  },
  methods: {
    backtoHomeClicked () {
      this.$router.push('/')
    },
    // 顶部菜单切换
    handleClick (tab, event) {
      this.$refs.schoolRegistRuleFormRef.resetFields()
      this.$refs.registRuleFormRef.resetFields()
    },
    // 学校省市区选择
    provincesHandleChange (value) {
      if (!value) return
      this.provincesSchoolList = []
      this.registModel.schoolName = ''
      this.registModel.schoolId = ''
      this.registModel.provinceCode = value[0]
      this.registModel.cityCode = value[1]
      this.registModel.areaCode = value[2]
      const deliveryAreaName = this.$refs.provincesHandleChangeRef.getCheckedNodes()[0].pathLabels.join('/')
      const areaList = deliveryAreaName.split('/')
      this.registModel.province = areaList[0]
      this.registModel.city = areaList[1]
      this.registModel.area = areaList[2]
      console.log('registModel', this.registModel)
      this.getSchoolListData(value)
    },
    // 根据省市区获取学校
    getSchoolListData (schoolArea) {
      getSchoolList({ provinceCode: schoolArea[0], cityCode: schoolArea[1], areaCode: schoolArea[2] }).then((res) => {
        console.log('getSchoolList', res)
        this.provincesSchoolList = res.data
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 学校选择
    schoolSelectChange () {
      for (let index = 0; index < this.provincesSchoolList.length; index++) {
        const element = this.provincesSchoolList[index]
        if (element.id === this.registModel.schoolId) {
          this.registModel.schoolType = element.schoolType + ''
          this.garea = element.gareaStr
        }
      }
    },
    // 发送短信
    async SendSms (type) {
      if (type === 'player') {
        if (!this.$xcUtils.checkPhone(this.registModel.phone)) return this.$message.warning('请输入正确手机号')
      }
      if (type === 'teacher') {
        if (!this.$xcUtils.checkPhone(this.registModel.phone)) return this.$message.warning('请输入正确手机号')
      }
      this.sending = true
      onSendSms({ phone: type === 'player' ? this.registModel.phone : this.registModel.phone, codeType: type === 'player' ? this.$chnEngStatusCode.INDIVIDUALREGISTTYPE : this.$chnEngStatusCode.SCHOOLREGISTTYPE }).then((res) => {
        console.log(res)
        this.$message.success('短信发送成功，请注意查收')
        window.sessionStorage.setItem('registLast-send-time', new Date())
        this.timer = 60
        this.sendDisabled = true
        this.sending = false
      }).catch((err) => {
        this.sending = false
        console.log('err1111', err)
      })
    },
    // 注册按钮点击
    registClicked () {
      console.log(this.activeName)
      if (this.activeName === 'player') { // 个人注册
        if (this.registModel.idCardType === '1' && !this.$xcUtils.checkIdCard(this.registModel.idCardNum)) return this.$message.warning('请输入正确的身份证号')
        this.$refs.registRuleFormRef.validate(async valid => {
          if (!valid) return
          this.registModel.codeType = this.$chnEngStatusCode.INDIVIDUALREGISTTYPE
          console.log('registModel', this.registModel)
          registeredUser(this.registModel).then((res) => {
            console.log('res', res)
            this.$confirm('恭喜您，注册成功！', '提示', {
              confirmButtonText: '确定',
              type: 'success',
              showCancelButton: false,
              center: true,
              closeOnClickModal: false,
              closeOnPressEscape: false,
              closeOnHashChange: false,
              showClose: false
            }).then(() => {
              window.sessionStorage.removeItem('registLast-send-time')
              this.$router.push({
                path: '/loginView',
                params: {
                  pushUrl: ''
                }
              })
            })
          }).catch((err) => {
            console.log('err', err)
          })
        })
      } else { // 学校注册
        this.$refs.schoolRegistRuleFormRef.validate(async valid => {
          if (!valid) return
          if (this.$xcUtils.checkEmail(this.registModel.email) === false) {
            return this.$message.error('请填写正确的邮箱格式')
          }
          this.registModel.codeType = this.$chnEngStatusCode.SCHOOLREGISTTYPE
          this.registModel.schoolName = this.$refs.mailingInfoModelSchoolSelectRef.selectedLabel
          this.registModel.schoolLevel = String(this.registModel.schoolLevelArray)
          this.registModel.activityType = this.$chnEngStatusCode.activityType
          console.log('registModel', this.registModel)
          registeredSchool(this.registModel).then((res) => {
            console.log('res', res)
            this.$confirm('注册信息提交成功！请等待后台审核，审核通过后即可登录！', '提示', {
              confirmButtonText: '确定',
              type: 'success',
              showCancelButton: false,
              center: true,
              closeOnClickModal: false,
              closeOnPressEscape: false,
              closeOnHashChange: false,
              showClose: false
            }).then(() => {
              // 注册成功删除短信发送倒计时
              window.sessionStorage.removeItem('registLast-send-time')
              this.$router.push('/loginView')
            })
          }).catch((err) => {
            console.log('err', err)
          })
        })
      }
    },
    // 头像上传成功
    handleAvatarSuccess (res, file) {
      if (res.code !== 0) {
        return this.$message.error(res.msg)
      }
      this.registModel.avatar = res.data.url
    },
    // 头像上传之前判断图片是否符合
    beforeAvatarUpload (file) {
      console.log('file', file)
      const isJPGPNG = (file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/jpeg')
      const isLt2M = file.size / 1024 / 200
      if (!isJPGPNG) {
        this.$message.error('上传头像图片只能是 JPG/PNG/JPEG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 200KB!')
      }
      return isJPGPNG && isLt2M
    },
    // 身份证自动计算性别生日年纪
    checkInput (val) {
      if (this.registModel.cardType === '1' && val.length === 18 && this.$xcUtils.checkIdCard(val)) {
        const getUserCardInfo = this.$xcUtils.getInfoFormIdCard(val)
        console.log('getUserCardInfo', getUserCardInfo)
        this.registModel.sex = getUserCardInfo.sex
        this.registModel.dateBirth = getUserCardInfo.birth
        this.registModel.age = getUserCardInfo.age
      }
      console.log('val', val)
    }
  },
  computed: {
    sendButtonText () {
      if (this.timer === 0) {
        return '发送验证码'
      } else {
        return `${this.timer}秒后重发`
      }
    }
  },
  watch: {
    timer (num) {
      if (num > 0) {
        setTimeout(() => {
          this.timer = --num
        }, 1000)
      } else {
        this.sendDisabled = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-tabs__nav-scroll {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
::v-deep .el-tabs__item {
  font-size: 16px;
}
::v-deep .el-date-editor.el-input {
  width: 100% !important;
}
::v-deep .el-form-item__label {
  // font-size: 12px;
}
.login-container {
  background-color: #2b5b6b;
  height: 100vh;
  .regist-form-backview {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-direction: column;
    align-items: center;
    .personal-regist {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .regist-title-backview {
      margin-top: 20px;
      width: 90%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      .regist-title-rightImg {
        width: auto;
        height: 60px;
      }
      .regist-form-title {
        font-size: 32px;
        color: #333;
        font-family: regular;
        margin-left: 10px;
      }
    }
    .form {
      width: 100%;
    }
  }
}
.avatar {
  width: 80px;
  height: 80px;
  border-radius: 40px;
  margin-bottom: 10px;
  display: block;
}
</style>
