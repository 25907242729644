import Vue from 'vue'
import VueRouter from 'vue-router'
import homeIndex from '../views/homeIndex.vue'
import homeView from '../views/homeView.vue'
import diplomaticThoughts from '../views/aboutUs/diplomaticThoughts.vue'
import understandingEducation from '../views/aboutUs/understandingEducation.vue'
import pblProgramCoreLiteracy from '../views/aboutUs/pblProgramCoreLiteracy.vue'
import descriptionOfProject from '../views/aboutUs/descriptionOfProject.vue'
import rdExpertTeam from '../views/aboutUs/rdExpertTeam.vue'
import schoolServiceView from '../views/aboutUs/schoolServiceView.vue'
import languageCourses from '../views/projectIntroduction/languageCourses.vue'
import studiestoExplore from '../views/projectIntroduction/studiestoExplore.vue'
import supervisionandServices from '../views/projectIntroduction/supervisionandServices.vue'
import literacyStudies from '../views/projectIntroduction/literacyStudies.vue'
import visit from '../views/projectIntroduction/visit.vue'
import campusMicroMuseum from '../views/projectIntroduction/campusMicroMuseum.vue'
import chineseLanguageCourse from '../views/projectIntroduction/chineseLanguageCourse.vue'
import conferenceProfile from '../views/projectIntroduction/conferenceProfile.vue'
import conferenceTheme from '../views/projectIntroduction/conferenceTheme.vue'
import conferenceForm from '../views/projectIntroduction/conferenceForm.vue'
import projectBackground from '../views/aboutUs/projectBackground.vue'
import IntangibleAngel from '../views/projectIntroduction/IntangibleAngel.vue'
import ghacPage from '../views/projectIntroduction/ghac.vue'
import ghacPage2 from '../views/projectIntroduction/ghac2.vue'
import ghacPage3 from '../views/projectIntroduction/ghac3.vue'
import ghacAwards from '../views/projectIntroduction/ghacAwards.vue'
import ghacPageReg from '../views/projectIntroduction/ghacReg.vue'
import literacyCourseLearning from '../views/projectIntroduction/literacyCourseLearning.vue'
import cooperationContent from '../views/cooperation/cooperationContent.vue'
import registeredContent from '../views/registered/registeredContent.vue'
import loginView from '../views/login/loginView.vue'
import chnEngRegist from '../views/login/chnEngRegist'
import cebsbInfoData from '../views/aboutUserData/cebsbInfoData.vue'
// 404
import errorpage from '../views/error/errorPage.vue'
// 信息管理-欢迎界面
import userInfoWelcome from '../views/aboutUserData/userInfoWelcome'
// 暂未开放
import notOpen from '../views/not_open.vue'
// 信息管理-学校信息管理-学校信息
import userInfoSchoolData from '../views/aboutUserData/cebsbSchoolInfoView/userInfo_schoolData'
// 信息管理-学校信息管理-权限管理
import userInfoRightsManagementData from '../views/aboutUserData/cebsbSchoolInfoView/userInfo_rightsManagementData'
// 信息管理-学校信息管理-个人信息
import userInfoPersonalData from '../views/aboutUserData/cebsbSchoolInfoView/userInfo_personalData'
// 信息管理-学校选拔-初选
import userInfoIndividualHome from '../views/aboutUserData/cebsbIndividualInfoView/userInfo_individualHome'
// 信息管理-学校选拔-初选-个人赛考量邮寄
import userInfoIndividualPapers from '../views/aboutUserData/cebsbIndividualInfoView/userInfo_IndividualPapers'
// 信息管理-学校选拔-初选-考量详情
import userInfoIndividualPapersDetail from '../views/aboutUserData/cebsbIndividualInfoView/userInfo_IndividualPapersDetail'
// 信息管理-学校选拔-初选-个人赛成绩
import userInfoIndividualScore from '../views/aboutUserData/cebsbIndividualInfoView/userInfo_individualScore'
// 信息管理-学校选拔-初选-个人赛历史成绩
import userInfoIndividualHistory from '../views/aboutUserData/cebsbIndividualInfoView/userInfo_individualHistory'
// 信息管理-大众展演-团体赛队伍管理
import userInfoTeamManagement from '../views/aboutUserData/cebsbTeamInfoView/userInfo_teamManagement'
// 信息管理-大众展演-战队进程管理
import userInfoTeamProcessMembers from '../views/aboutUserData/cebsbTeamInfoView/userInfo_teamProcessMembers'
// 信息管理-大众展演-团体赛成绩查询
import userInfoTeamScoreSearch from '../views/aboutUserData/cebsbTeamInfoView/userInfo_teamScoreSearch'
// 信息管理-大众展演-团体赛历史成绩查询
import userInfoTeamHistoryScoreSearch from '../views/aboutUserData/cebsbTeamInfoView/userInfo_teamHistoryScoreSearch'
// 信息管理-社团管理-社团管理
import cebsbInfoCommunityMember from '../views/aboutUserData/cebsbCommunityInfoView/cebsbInfo_communityMember'
// 信息管理-社团管理-社团详情
import cebsbInfoCommunityDetail from '../views/aboutUserData/cebsbCommunityInfoView/cebsbInfo_community_detail'
// 信息管理-社团管理-社团课内容管理
import cebsbInfoCommunityCourseMember from '../views/aboutUserData/cebsbCommunityInfoView/cebsbCourseManage/cebsbInfo_community_courseMember'
// 信息管理-社团管理-社团课课程列表
import cebsbInfoCommunityCourseList from '../views/aboutUserData/cebsbCommunityInfoView/cebsbCourseManage/cebsbInfo_community_course_list'
// 信息管理-社团管理-社团课二级分类
import cebsoInfoCourseClass from '../views/aboutUserData/cebsbCommunityInfoView/cebsbCourseManage/cebsbInfo_community_courseClass'
// 信息管理-社团管理-社团课二级分类
import cebsoInfoCourseDetail from '../views/aboutUserData/cebsbCommunityInfoView/cebsbCourseManage/cebsbInfo_community_courseDetail'
// 信息管理-社团管理-社团内容管理-在线浏览pdf文件
import cebsoOnlineBrowsingPDF from '../views/aboutUserData/cebsbCommunityInfoView/cebsbCourseManage/cebso_onlineBrowsingPDF'
// 信息管理-社团管理-社团内容管理-在线浏览视频
import cebsoOnlineBrowsingVideo from '../views/aboutUserData/cebsbCommunityInfoView/cebsbCourseManage/cebso_onlineBrowsingVideo'
// 社团-我的课件
import cebsbInfoUserCourseMember from '../views/aboutUserData/cebsbCommunityInfoView/cebsbCourseManage/cebsbInfo_user_courseMember'
// 社团-我的学校
import cebsbInfoUserBindSchoolData from '../views/aboutUserData/cebsbCommunityInfoView/cebsbCourseManage/cebsbInfo_user_bindSchoolData'

// 社团课程-首页
import classesHome from '../views/clubClasses/course-home.vue'
// 社团课程-课程列表
import classesList from '../views/clubClasses/course-list.vue'
// 下载ppt预览客户端页面
import downloadPPTExe from '../views/clubClasses/downloadPPTExe.vue'

// 俱乐部
import clubHome from '../views/club/home.vue'
// 报名缴费
import clubReg from '../views/club/register.vue'
// 爱眼尊，不知道放哪，暂时搁这
import izonPage from '../views/izonPage'

import mobilePlay from '../views/mobilePlay'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: '404',
    component: errorpage
  },
  {
    path: '/',
    component: homeIndex,
    redirect: '/homeView',
    children: [
      { path: '/homeView', component: homeView },
      { path: '/diplomaticThoughts', component: diplomaticThoughts },
      { path: '/understandingEducation', component: understandingEducation },
      { path: '/pblProgramCoreLiteracy', component: pblProgramCoreLiteracy },
      { path: '/descriptionOfProject', component: descriptionOfProject },
      { path: '/rdExpertTeam', component: rdExpertTeam },
      { path: '/schoolServiceView', component: schoolServiceView },
      { path: '/languageCourses', component: languageCourses },
      { path: '/studiestoExplore', component: studiestoExplore },
      { path: '/supervisionandServices', component: supervisionandServices },
      { path: '/literacyStudies', component: literacyStudies },
      { path: '/visit', component: visit },
      { path: '/campusMicroMuseum', component: campusMicroMuseum },
      { path: '/conferenceProfile', component: conferenceProfile },
      { path: '/conferenceTheme', component: conferenceTheme },
      { path: '/conferenceForm', component: conferenceForm },
      { path: '/projectBackground', component: projectBackground },
      { path: '/IntangibleAngel', component: IntangibleAngel },
      { path: '/ghac', component: ghacPage },
      { path: '/ghacTheme', component: ghacPage2 },
      { path: '/ghacFlow', component: ghacPage3 },
      { path: '/ghacAwards', component: ghacAwards },
      { path: '/ghacReg', component: ghacPageReg },
      { path: '/literacyCourseLearning', component: literacyCourseLearning },
      { path: '/cooperationContent', component: cooperationContent },
      { path: '/registeredContent', component: registeredContent },
      { path: '/chineseLanguageCourse', component: chineseLanguageCourse },
      { path: '/userInfo_IndividualPapersDetail', component: userInfoIndividualPapersDetail },
      { path: '/classesList', component: classesList },
      { path: '/classesHome', component: classesHome }
    ]
  },
  {
    path: '/cebsbInfoData',
    component: cebsbInfoData,
    redirect: '/userInfo_welcome',
    children: [
      { path: '/userInfo_welcome', component: userInfoWelcome },
      { path: '/not_open', component: notOpen },
      { path: '/userInfo_schoolData', component: userInfoSchoolData },
      { path: '/userInfo_personalData', component: userInfoPersonalData },
      { path: '/userInfo_individualHome', component: userInfoIndividualHome },
      { path: '/userInfo_IndividualPapers', component: userInfoIndividualPapers },
      { path: '/userInfo_individualScore', component: userInfoIndividualScore },
      { path: '/userInfo_individualHistory', component: userInfoIndividualHistory },
      { path: '/userInfo_teamManagement', component: userInfoTeamManagement },
      { path: '/userInfo_teamProcessMembers', component: userInfoTeamProcessMembers },
      { path: '/userInfo_teamScoreSearch', component: userInfoTeamScoreSearch },
      { path: '/userInfo_teamHistoryScoreSearch', component: userInfoTeamHistoryScoreSearch },
      { path: '/userInfo_rightsManagementData', component: userInfoRightsManagementData },
      { path: '/cebsbInfo_communityMember', component: cebsbInfoCommunityMember },
      { path: '/cebsbInfo_community_dtail', component: cebsbInfoCommunityDetail },
      { path: '/cebsbInfo_community_courseMember', component: cebsbInfoCommunityCourseMember },
      { path: '/aboutUserData/cebsbCommunityInfoView/cebsbCourseManage/cebsbInfo_community_courseMember', component: cebsbInfoCommunityCourseMember },
      { path: '/aboutUserData/cebsbCommunityInfoView/cebsbCourseManage/cebsbInfo_community_course_list', component: cebsbInfoCommunityCourseList },
      { path: '/aboutUserData/cebsbCommunityInfoView/cebsbCourseManage/cebsbInfo_community_courseClass', component: cebsoInfoCourseClass },
      { path: '/aboutUserData/cebsbCommunityInfoView/cebsbCourseManage/cebsbInfo_community_courseDetail', component: cebsoInfoCourseDetail },
      { path: '/aboutUserData/cebsbCommunityInfoView/cebsbCourseManage/cebsbInfo_user_courseMember', component: cebsbInfoUserCourseMember },
      { path: '/aboutUserData/cebsbCommunityInfoView/cebsbCourseManage/cebsbInfo_user_bindSchoolData', component: cebsbInfoUserBindSchoolData },
      { path: '/club/home', component: clubHome }
    ]
  },
  { path: '/club/register', component: clubReg },
  { path: '/download', component: downloadPPTExe },
  {
    path: '/cebsbCourseManage/cebso_onlineBrowsingPDF', component: cebsoOnlineBrowsingPDF
  },
  {
    path: '/cebsbCourseManage/cebso_onlineBrowsingVideo', component: cebsoOnlineBrowsingVideo
  },
  { path: '/loginView', component: loginView },
  { path: '/chnEngRegist', component: chnEngRegist },
  { path: '/izon', component: izonPage },
  { path: '/mobilePlay', component: mobilePlay }
]

const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    // 期望滚动到哪个位置
    return {
      x: 0,
      y: 0
    }
  }
})
router.afterEach(() => {
  const main = document.querySelector('#main')
  if (main) {
    main.scrollTop = 0
  }
})
export default router
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
