<template>
  <div>
    <div class="pdfbackview">
      <iframe
        id="ppt-iframe"
        :src="fileUrl"
        frameborder="0"
        style="width: 100%; height: 100%"
      ></iframe>
    </div>
  </div>
</template>

<script>
document.addEventListener('keydown', function (event) {
  if (event.metaKey && event.keyCode === 83) {
    event.preventDefault()
    alert('保存文件被禁止')
  }
})
export default {
  data () {
    return {
      fileUrl: this.$route.query.fileUrl
    }
  },
  methods: {
    disableSaveShortcut (event) {
      if (event.metaKey && event.keyCode === 83) {
        event.preventDefault()
      }
    }
  },
  mounted () {
    document.addEventListener('keydown', this.disableSaveShortcut)
    // const iframe = document.getElementById('ppt-iframe')
    const iframe = this.$el.querySelector('iframe')
    iframe.contentWindow.document.addEventListener('contextmenu', this.disableDownload)
    iframe.contentWindow.document.addEventListener('keydown', this.disableDownload)
  },
  beforeDestroy () {
    // const iframe = document.getElementById('ppt-iframe')
    const iframe = this.$el.querySelector('iframe')
    iframe.contentWindow.document.removeEventListener('contextmenu', this.disableDownload)
    iframe.contentWindow.document.removeEventListener('keydown', this.disableDownload)
  }
}
</script>
<style>
.pdfbackview {
  width: 100%;
  height: 100vh;
}
</style>
