<template>
  <div>
    <div class="dibannerclass">
      <el-carousel
        :interval="3000"
        id="el-carousel"
        height="100%"
      >
        <el-carousel-item v-for="item in images" :key="item.index">
          <img :src="item.url" alt="" class="banner-img-class" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="bannerbottom">
      <div class="title-backview">
        <div>环球文遗小使者大会<br>2024年度展演</div>
        <div class="en">Global Intangible Cultural Heritage Little Ambassadors' Conference</div>
      </div>
    </div>
    <div class="red-backview">
      <div class="red-lineview"></div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      images: [
        { url: 'https://cdn.spbcn.org/ghac/page/1/HQWY_HQDH_banne_01.png' },
        { url: 'https://cdn.spbcn.org/ghac/assets/feiyiprograms4_1_banner.png' }
      ]
    }
  }
}
</script>
<style lang="less">
.dibannerclass {
  width: 1000px;
  height: 469px;
  z-index: 99;
}
.title-backview {
  height: 60px;
  font-size: 32px;
  font-family: FZLBJW;
  font-weight: 900;
  text-align: center;
  line-height: 60px;
  padding: 0px 20px;
  color: white;
  border: 2px solid rgb(255, 255, 255);
  .en{
    font-size: 24px;
    margin-top: 10px;
  }
}
.banner-img-class {
  width: 1000px;
  height: 470px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.video{
  width: 1000px;
  height: 470px;
}

.el-carousel {
  width: 1000px;
  height: 470px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
::v-deep .el-carousel__button{
  width: 10px;
}
.red-backview {
  width: 1000px;
  height: 25px;
}

.red-lineview {
  width: 1000px;
  height: 10px;
  // margin-top: 15px;
  background-color: #e50211;
}
.bannerbottom {
  width: 1000px;
  height: 100px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 150px;
  z-index: 100;
}
.title-backview {
  height: 80px;
  font-size: 32px;
  font-family: FZLBJW;
  font-weight: 900;
  text-align: center;
  line-height: 40px;
  padding: 0px 20px;
  color: white;
  border: 2px solid rgb(255, 255, 255);
  .en{
    font-size: 24px;
    margin-top: 10px;
  }
}
</style>
