import request from '../http/request'
// 发送短信验证码
export function onSendSms (data) {
  return request({
    url: '/web/smsVerificationCode', // 请求地址
    method: 'POST', // 请求方式
    data // 参数
  })
}
// 个人用户信息注册
export function registeredUser (data) {
  return request({
    url: '/login/registeredSchool',
    method: 'POST',
    data
  })
}
// 登录
export function chnEngLoginRequest (data) {
  return request({
    url: '/mobileLoginWeb',
    method: 'POST',
    data
  })
}
// 退出登录
export function loginOutRequest (params) {
  return request({
    url: '/loginOut',
    method: 'GET',
    params
  })
}

// 获取用户信息
export function getSysUserInfo (params) {
  return request({
    url: '/user/getSysUserInfo',
    method: 'GET',
    params
  })
}
// 获取学校信息
export function getUserInformation (params) {
  return request({
    url: '/user/getUserInformation',
    method: 'GET',
    params
  })
}
// 修改学校信息
export function updateSchoolInfo (data) {
  return request({
    url: '/school/updateSchoolInfo',
    method: 'POST',
    data
  })
}
// 查询用户收货地址
export function getUserAddressDefault (params) {
  return request({
    url: '/userAddress/getUserAddressDefault',
    method: 'GET',
    params
  })
}
// 查询学校列表
export function getSchoolList (params) {
  return request({
    url: '/web/getSchoolList',
    method: 'GET',
    params
  })
}
// 用户资料保存信息接口
export function saveSysUserInfo (data) {
  return request({
    url: '/user/saveSysUserAll',
    method: 'POST',
    data
  })
}
// 个人报名订单界面确认用户信息保存
export function saveSysUserInfoAddress (data) {
  return request({
    url: '/user/saveSysUserInfoAddress',
    method: 'POST',
    data
  })
}
// 个人赛试卷考量申请
export function savePersonalPaperApply (data) {
  return request({
    url: '/paper/savePersonalPaperApply',
    method: 'POST',
    data
  })
}
// 学校注册
export function registeredSchool (data) {
  return request({
    url: '/web/registeredSchool',
    method: 'POST',
    data
  })
}
// 校验用户信息是否完善
export function checkSysUserInfo (params) {
  return request({
    url: '/user/checkSysUserInfo',
    method: 'GET',
    params
  })
}
// 获取俱乐部列表
export function getClubList (params) {
  return request({
    url: '/club/getClubList',
    method: 'GET',
    params
  })
}
// 获取战队列表
export function getTeamListByActivityType (params) {
  return request({
    url: '/team/getTeamListByActivityType',
    method: 'GET',
    params
  })
}
// 获取赛季列表
export function getSeasonActivityList (params) {
  return request({
    url: '/season/getSeasonActivityList/' + '1',
    method: 'GET',
    params
  })
}
// 获取赛季信息
export function getSeasonActivityIng (params) {
  return request({
    url: '/season/getSeasonActivityIng/' + '1',
    method: 'GET',
    params
  })
}
// 团体赛学校报名
export function saveTeamClub (data) {
  return request({
    url: '/team/saveTeamClub',
    method: 'POST',
    data
  })
}

// 个人参赛校报名
export function savePersonalSchool (data) {
  return request({
    url: '/enrollment/enrollmentPersonalSchool',
    method: 'POST',
    data
  })
}
// 订单界面获取战队信息
export function getTeamInfo (params) {
  return request({
    url: '/team/getTeamInfo',
    method: 'GET',
    params
  })
}
// 查询学校是否参加个人赛
export function getIsSchoolPersonexport (params) {
  return request({
    url: '/enrollment/getIsSchoolPerson',
    method: 'GET',
    params
  })
}
// 获取个人参赛校详细信息
export function getEnrollmentInfo (params) {
  return request({
    url: '/enrollment/getEnrollmentInfo',
    method: 'GET',
    params
  })
}
// 获取绑定学校信息
export function getSchoolInfo (params) {
  return request({
    url: '/school/getSchoolInfo',
    method: 'GET',
    params
  })
}
// 获取申请考量进度
export function queryPreliminaryProcessInfo (params) {
  return request({
    url: '/process/queryPreliminaryProcessInfo',
    method: 'GET',
    params
  })
}
// 保存用户收货地址
export function saveUserAddress (data) {
  return request({
    url: '/userAddress/saveUserAddress',
    method: 'POST',
    data
  })
}
//  获取要支付的产品内容
export function getTbProductSingle (params) {
  return request({
    url: '/product/getTbProductSingle',
    method: 'GET',
    params
  })
}
// 获取要支付的产品内容
// export function getTbProductSingle (params) {
//   return request({
//     url: '/product/getTbProductSingle/SpecsInfo',
//     method: 'GET',
//     params
//   })
// }
// 获取微信支付内容
export function creatTbProductOrder (data) {
  return request({
    url: '/order/creatTbProductOrder',
    method: 'POST',
    data
  })
}
// 点击完成支付按钮事件
export function getTbProductOrderIsFinish (data) {
  return request({
    url: '/order/getTbProductOrderIsFinish',
    method: 'POST',
    data
  })
}
// 获取学校参加个人赛考量申报订单
export function getPersonalPaperApplyList (params) {
  return request({
    url: '/paper/getPersonalPaperApplyList',
    method: 'GET',
    params
  })
}
// 获取学校申请考卷详情
export function getPersonalPaperApplyInfo (params) {
  return request({
    url: '/paper/getPersonalPaperApplyInfo',
    method: 'GET',
    params
  })
}

// 审核学校试卷申请
export function confirmToSendBack (data) {
  return request({
    url: '/paper/confirmToSendBack',
    method: 'POST',
    data
  })
}

// 线下成绩信息查询
export function queryPaperResultScore (params) {
  return request({
    // url: '/paper/queryPaperResultScore',
    url: '/paper/queryAnswerResultsPage',
    method: 'GET',
    params
  })
}

// 获取课程二级联动类型
export function secondaryLinkageCourseQuery (data) {
  console.log(data)
  return request({
    url: '/course/secondaryLinkageCourseQuery',
    method: 'POST',
    data
  })
}
// 获取已开通课程接口
export function queryOpenCourseList (params) {
  return request({
    url: '/course/queryOpenCourseList',
    method: 'GET',
    params
  })
}
// 获取课程产品列表
export function haveBeenOpenedCourse (params) {
  return request({
    url: '/course/haveBeenOpenedCourse',
    method: 'GET',
    params
  })
}

// 根据typeid 获取课程
export function queryCourseQueryList (params) {
  return request({
    url: '/course/queryCourseQueryList',
    method: 'GET',
    params
  })
}
// 获取用户绑定的学校
export function getUserSchoolList (params) {
  return request({
    url: '/user/getUserSchoolList',
    method: 'GET',
    params
  })
}

// 获取用户开通课程
export function queryOpenCourseQueryList (params) {
  return request({
    url: '/course/queryOpenCourseQueryList',
    method: 'GET',
    params
  })
}

// 获取社团列表
export function getAssociationList (params) {
  return request({
    url: '/association/getAssociationList',
    method: 'GET',
    params
  })
}
// 查看社团成员列表
export function queryAssociationUserPage (params) {
  return request({
    url: '/association/queryAssociationUserPage',
    method: 'GET',
    params
  })
}
// 删除社团
export function deleteAssociationInfo (params) {
  return request({
    url: '/association/deleteAssociationInfo',
    method: 'GET',
    params
  })
}
// 添加社团
export function saveAssociation (data) {
  return request({
    url: '/association/saveAssociation',
    method: 'POST',
    data
  })
}
// 获取无分页学校数据
export function getSchoolListWithNoPage (params) {
  return request({
    url: '/school/getSchoolList',
    method: 'GET',
    params
  })
}
// 删除社团成员
export function deleteAssociationUser (params, url) {
  return request({
    url: url,
    method: 'DELETE',
    params
  })
}
// 添加社团成员
export function addAssociationUser (data) {
  return request({
    url: '/association/addAssociationUser',
    method: 'POST',
    data
  })
}
