<template>
  <div>
    <el-breadcrumb>
      <el-breadcrumb-item>社团管理</el-breadcrumb-item>
      <el-breadcrumb-item>社团详情</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <el-card style="margin: 15px">
      <el-row class="title_row">
        <el-col>
          <span>社团信息</span>
        </el-col>
      </el-row>
      <el-descriptions>
        <!-- <el-descriptions-item label="学校">{{
          associationIData.schoolName
        }}</el-descriptions-item>
        <el-descriptions-item label="社团名称：">{{
          associationIData.associationName
        }}</el-descriptions-item> -->
        <el-descriptions-item label="学校">{{
          associationData.schoolName
        }}</el-descriptions-item>
        <el-descriptions-item label="社团名称：">{{
          associationData.associationName
        }}</el-descriptions-item>
      </el-descriptions>
      <el-row :gutter="20" type="flex" justify="end">
        <!-- <el-col :span="3">
          <el-button
            type="primary"
            style="width: 100%"
            @click="createdAssociationdialog = true"
            >修改信息</el-button
          >
        </el-col> -->
        <el-col :span="3">
          <el-button
            type="danger"
            style="width: 100%"
            @click="deleteAssociationClicked"
            >删除社团</el-button
          >
        </el-col>
      </el-row>
    </el-card>
    <el-card style="margin: 15px">
      <el-row class="title_row">
        <el-col>
          <span>信息筛选</span>
        </el-col>
      </el-row>
      <el-form
        :model="queryForm"
        class="filter_Form"
        label-width="100px"
        ref="queryFormRef"
      >
        <el-row>
          <el-col :span="6">
            <el-form-item label="学生姓名" prop="userName">
              <el-input
                v-model="queryForm.userName"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="手机号" prop="phone">
              <el-input
                v-model="queryForm.phone"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="6">
            <el-form-item label="年级" prop="grade">
              <el-select
                v-model="queryForm.grade"
                placeholder="请选择"
                style="width: 100%"
              >
                <el-option
                  v-for="item in dict_grade"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                >
                </el-option>
              </el-select> </el-form-item
          ></el-col>
          <el-col :span="6"
            ><el-form-item label="班级" prop="class">
              <el-select
                v-model="queryForm.class"
                placeholder="请选择"
                style="width: 100%"
              >
                <el-option
                  v-for="item in classListData"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                >
                </el-option>
              </el-select> </el-form-item
          ></el-col> -->
        </el-row>
      </el-form>
      <el-row :gutter="20" type="flex" justify="end">
        <el-col :span="3">
          <el-button
            type="primary"
            style="width: 100%"
            @click="getSelectStudentsList('search')"
            >查询</el-button
          >
        </el-col>
        <el-col :span="3">
          <el-button type="primary" style="width: 100%" @click="reset"
            >重置</el-button
          >
        </el-col>
      </el-row>
    </el-card>
    <el-card style="margin: 15px">
      <el-row class="title_row" align="middle" type="flex">
        <el-col :span="9">
          <span>成员信息</span>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="start">
        <el-col :span="3">
          <el-button
            type="primary"
            style="width: 100%; margin: 15px"
            @click="addAssociationclicked"
            >添加成员</el-button
          >
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="dataList"
        border
        stripe
      >
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column label="姓名" prop="userName"></el-table-column>
        <!-- <el-table-column label="性别" prop="scoreSex"></el-table-column>
        <el-table-column
          label="年级"
          prop="scoreGrade"
          :formatter="gradeFormatter"
        ></el-table-column>
        <el-table-column label="班级" prop="class"></el-table-column>
        <el-table-column label="成绩" prop="scores"></el-table-column>
        <el-table-column label="国籍" prop="nationality"></el-table-column> -->
        <el-table-column label="联系方式" prop="phone"></el-table-column>
        <el-table-column label="操作" width="140px">
          <template scope="scope">
            <el-button
              type="text"
              size="small"
              @click="deleteMemberClicked(scope.row)"
              >删除成员</el-button
            >
            <!-- <el-button
              type="text"
              size="small"
              @click="moveTeamClicked(scope.row)"
              >移入参赛队</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.pageNum"
        :page-sizes="[10, 20, 40, 80]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <el-dialog
      title="修改社团信息"
      :visible.sync="createdAssociationdialog"
      @close="createdAssociationdialogClose"
      width="30%"
    >
      <el-form
        :model="associationData"
        :rules="associationRules"
        ref="associationFormRef"
        label-width="130px"
        style="width: 100%"
      >
        <el-form-item label="社团名称" prop="associationName">
          <el-input
            v-model="associationData.associationName"
            placeholder="请输入社团名"
            style="width: 100%"
            maxlength="100"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="createdAssociationdialog = false">取 消</el-button>
        <el-button type="primary" @click="submitChangeContactClicked()"
          >修 改</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="添加社团成员"
      :visible.sync="addAssociationDialog"
      width="60%"
    >
      <el-card>
        <el-form
          :model="selectQueryForm"
          class="filter_Form"
          label-width="100px"
          ref="selectQueryFormRef"
        >
          <el-row>
            <el-col :span="10">
              <el-form-item label="赛季" prop="season">
                <el-select
                  placeholder="请选择赛季"
                  style="width: 100%"
                  v-model="selectQueryForm.season"
                >
                  <el-option
                    v-for="item in dict_season"
                    :key="item.dictCode"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="赛程" prop="raceSchedule">
                <el-select
                  placeholder="请选择"
                  style="width: 100%"
                  v-model="selectQueryForm.raceSchedule"
                  clearable
                >
                  <el-option
                    v-for="item in raceScheduleList"
                    :key="item.id"
                    :label="item.scheduleName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item label="学生姓名" prop="userName">
                <el-input
                  v-model="selectQueryForm.userName"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="手机号" prop="phone">
                <el-input
                  v-model="selectQueryForm.phone"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-row :gutter="20" type="flex" justify="end">
          <el-col :span="3">
            <el-button
              type="primary"
              style="width: 100%"
              @click="getSelectStudentsList('search')"
              >查询</el-button
            >
          </el-col>
          <el-col :span="3">
            <el-button
              type="primary"
              style="width: 100%"
              @click="searchStudentsReset"
              >重置</el-button
            >
          </el-col>
        </el-row>
      </el-card>

      <el-card style="margin: 15px 0px">
        <el-row class="title_row" align="middle" type="flex">
          <el-col :span="9">
            <span>成绩信息</span>
          </el-col>
          <el-col :span="15" style="margin-bottom: 10px">
            <div class="div_algin_right">
              <el-button
                :disabled="studentsDataList.length === 0 ? true : false"
                type="primary"
                @click="addStudentsInfoDetail"
                >确认添加</el-button
              >
            </div>
          </el-col>
        </el-row>
        <el-table
          header-cell-class-name="tableHeaderStyle"
          :data="studentsDataList"
          @selection-change="handleSelectionChange"
          :row-key="getRowKey"
          border
          stripe
        >
          <el-table-column
            type="selection"
            width="55"
            :reserve-selection="true"
          ></el-table-column>
          <el-table-column label="姓名" prop="userName"></el-table-column>
          <!-- <el-table-column label="性别" prop="scoreSex"></el-table-column> -->
          <el-table-column
            label="年级"
            prop="grade"
            :formatter="gradeFormatter"
          ></el-table-column>
          <el-table-column label="成绩" prop="score"></el-table-column>
          <!-- <el-table-column
            label="成绩查询码"
            prop="scoreCode"
          ></el-table-column> -->
          <!-- <el-table-column label="国籍" prop="nationality"></el-table-column> -->
          <el-table-column label="联系方式" prop="phone"></el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          @size-change="selecthandleSizeChange"
          @current-change="selecthandleCurrentChange"
          :current-page="selectQueryForm.pageNum"
          :page-sizes="[10, 20, 40, 80]"
          :page-size="selectQueryForm.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="studentsDataTotal"
        >
        </el-pagination>
      </el-card>
    </el-dialog>
  </div>
</template>

<script>
import { queryAssociationUserPage, deleteAssociationInfo, saveAssociation, queryPaperResultScore, deleteAssociationUser, addAssociationUser } from '@/http/api'
export default {
  data () {
    return {
      addAssociationDialog: false,
      total: 0,
      studentsDataTotal: 0,
      createdAssociationdialog: false,
      associationData: JSON.parse(this.$route.query.associationIData),
      dataList: [],
      classListData: [],
      dict_grade: this.$curUtils.getGradeData().slice(0, 9),
      dict_season: this.$curUtils.getDataList(this, 't_season').then(value => { this.dict_season = value }),
      raceScheduleList: this.$curUtils.querySeasonRaceSchedule(this, {}).then(value => {
        this.raceScheduleList = [value[0]]
      }),
      associationRules: {
        associationName: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      selectQueryForm: {
        pageNum: 1,
        pageSize: 10,
        season: '2022~2023',
        raceSchedule: '1',
        activityType: 10,
        associationId: '',
        phone: '',
        userName: '',
        userId: []
      },
      studentsDataList: [],
      queryForm: {
        pageNum: 1,
        pageSize: 10
      }
    }
  },
  created () {
    for (let index = 0; index < 50; index++) {
      const item = {}
      item.dictCode = (index + 1) + ''
      item.dictLabel = (index + 1) + ''
      item.dictValue = (index + 1) + ''
      this.classListData.push(item)
    }
    this.getAssociationUserList()
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    reset () {
      this.$refs.queryFormRef.resetFields()
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getAssociationUserList()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getAssociationUserList()
    },
    // 获取成员列表信息
    getAssociationUserList () {
      this.queryForm.associationId = this.associationData.id
      queryAssociationUserPage(this.queryForm).then((res) => {
        this.dataList = res.data.list
      }).catch((err) => {
        console.log('err1111', err)
      })
    },
    // 删除社团成员点击事件
    deleteMemberClicked (rowData) {
      this.$confirm('是否确认删除该社团成员', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delecteTeamStudent(rowData)
      }).catch(() => { })
    },
    // 确认删除事件
    delecteTeamStudent (rowData) {
      const requestUrl = '/association/deleteAssociationUser/' + rowData.id
      deleteAssociationUser(rowData.id, requestUrl).then((res) => {
        console.log('deleteAssociationUser', res)
        this.$message.success('删除成功！')
        this.getAssociationUserList()
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 移入参赛队点击事件
    moveTeamClicked () {
      this.$message.success('后续接口未实现')
    },
    // 删除社团点击事件
    deleteAssociationClicked () {
      this.$confirm('是否确认删除该社团', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delecteAssociation()
      }).catch(() => { })
    },
    // 确定删除社团
    delecteAssociation () {
      deleteAssociationInfo({ id: this.associationData.id }).then((res) => {
        this.$message.success('删除成功')
        this.goBack()
      }).catch((err) => {
        console.log('err1111', err)
      })
    },
    // 修改信息弹窗关闭
    createdAssociationdialogClose () {
      this.$refs.associationFormRef.resetFields()
    },
    // 提交修改社团信息
    submitChangeContactClicked () {
      saveAssociation(this.associationData).then((res) => {
        this.createdAssociationdialog = false
      }).catch((err) => {
        console.log('err1111', err)
      })
    },
    // 点击添加成员事件，并获取成绩信息
    addAssociationclicked () {
      this.addAssociationDialog = true
      this.getSelectStudentsList()
    },
    selecthandleSizeChange (newSize) {
      this.selectQueryForm.pageSize = newSize
      this.getSelectStudentsList()
    },
    selecthandleCurrentChange (newCurPage) {
      this.selectQueryForm.pageNum = newCurPage
      this.getSelectStudentsList()
    },
    // 获取学生列表
    getSelectStudentsList (searchType) {
      if (searchType === 'search') {
        this.selectQueryForm.pageNum = 1
        this.selectQueryForm.pageSize = 10
      }
      const schoolData = JSON.parse(localStorage.getItem('saveSchoolInfo'))
      this.selectQueryForm.schoolId = schoolData.list[0].schoolId
      console.log(schoolData)
      queryPaperResultScore(this.selectQueryForm).then((res) => {
        console.log('getSchoolList', res)
        this.studentsDataList = res.data.list
        this.studentsDataTotal = res.data.total
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    getRowKey (row) {
      return row.id
    },
    // 课程多选回调
    handleSelectionChange (val) {
      console.log('val', val)
      this.selectQueryForm.userId = val.map(item => item.userId)
    },
    searchStudentsReset () {
      this.$refs.selectQueryFormRef.resetFields()
    },
    // 确认添加
    addStudentsInfoDetail () {
      if (this.selectQueryForm.userId.length === 0) {
        return this.$message.warning('请先选择要添加的学生')
      }
      this.selectQueryForm.associationId = this.associationData.id
      addAssociationUser(this.selectQueryForm).then((res) => {
        this.getAssociationUserList()
        this.addAssociationDialog = false
        return this.$message.success('添加成功！')
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    gradeFormatter (data) {
      let temp = '未知'
      for (const key in this.dict_grade) {
        if (Object.hasOwnProperty.call(this.dict_grade, key)) {
          const element = this.dict_grade[key]
          if (element.dictValue === data.grade + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    }
  }
}
</script>

<style lang="less" scoped>
</style>
