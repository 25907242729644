
<template>
  <div class="top-backview">
    <div class="contnet-backview">
      <!-- banner图 -->
      <div class="bannerclass">
        <el-carousel
          :interval="3000"
          indicator-position="none"
          id="el-carousel"
          :height="dataHeight"
          style="width: 100%;"
        >
          <el-carousel-item v-for="item in images" :key="item.index">
            <div class="banner-img-class" :style="'background-image: url(' + item.url + ');'"></div>
            <!-- <img :src="item.url" alt="" class="banner-img-class" /> -->
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- banner红线 -->
      <div class="bottom-blackbg">
        <div class="bottom-redline"></div>
      </div>
      <!-- 中间部分内容 -->
      <div class="center-backview">
        <div class="center-container">
          <!-- 标题 -->
          <div class="center-titleview">
            <div class="titleStr">项目介绍 | programs</div>
          </div>
          <!-- 四大项目介绍 -->
          <div class="center-menuview">
            <div class="menuview-backview" @click="homeMenuClicked(i)" v-for="(item, i) of list" :key="i">
              <img
                class="menuimage"
                :src="'https://cdn.spbcn.org/ghac/assets/feiyi_homepage_bigicon'+ (i+1) +'.png'"
                alt=""
              />
              <div class="menuview-title">{{item.title}}</div>
              <div class="menuview-title-en">{{item.enTitle}}</div>
            </div>
          </div>
          <!-- 四大项目介绍list -->
          <div class="center-menulist">
            <div v-for="(item, i) of list" :key="i"
              class="list-content-backview"
              :style="'background-image: url(https://cdn.spbcn.org/ghac/index/HQWY_SY_benner_00' + (i+1) +'.png)'"
              @click="homeMenuClicked(i)"
            >
              <img class="icon icon1" :src="'https://cdn.spbcn.org/ghac/index/HQWY_SY_icon_00'+ (i+1) +'.png'" alt="">
              <div class="txt">
                <div class="cn">{{ item.title }}</div>
                <div class="en">{{ item.enTitle }}</div>
              </div>
              <img class="icon icon2" src="https://cdn.spbcn.org/ghac/index/HQWY_SY_icon_00.png" alt="">
            </div>
          </div>
          <!-- 标题 -->
          <div class="center-titleview marginTop">
            <div class="titleStr">八+1 挑战 | challenges</div>
          </div>
          <!-- 八大挑战 -->
          <div class="theme">
            <img v-for="item in 9" :key="item" :src="'https://cdn.spbcn.org/ghac/index/theme' + item + '.png'" alt="">
          </div>
        </div>
      </div>
      <!-- 底部内容 -->
    </div>
  </div>
</template>

<script>
// import Footer from '@/components/Footer.vue'
export default {
  props: {
    dataHeight: {
      type: String,
      default: '100%'
    }
  },
  data () {
    return {
      images: [{ url: 'https://cdn.spbcn.org/ghac/pictures/HQWY_SY_banner.png' }],
      list: [
        {
          title: '人文素养社团课程',
          enTitle: 'Humanistic Literacy Courses'
        },
        {
          title: '华夏非遗探究项目',
          enTitle: 'Domestic Research and Exploration'
        },
        {
          title: '非遗小使者海外出访',
          enTitle: 'Little Ambassadors Visiting Overseas'
        },
        {
          title: '环球文遗小使者大会',
          enTitle: "Global Intangible Cultural Heritage Little Ambassadors' Conference"
        }
      ]
    }
  },
  components: {
    // Topbar,
    // Footer
  },
  methods: {
    homeMenuClicked (i) {
      if (i === 0) {
        this.$router.push('/classesHome')
      } else if (i === 1) {
        this.$router.push('/literacyStudies')
      } else if (i === 2) {
        this.$router.push('/visit')
      } else if (i === 3) {
        this.$router.push('/IntangibleAngel')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.marginTop {
  margin-top: 30px;
}
// .topbar {
//   width: 1000px;
//   padding: 0;
//   margin: 0;
//   z-index: 1000;
// }
.top-backview {
  width: 100%;
  height: auto;
  // padding-bottom: 10px;
  // background-color: rgb(216, 216, 216);
  background-image: url(https://cdn.spbcn.org/ghac/assets/feiyi_background.png);
  // background-size: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.contnet-backview {
  // width: 1000px;
  width: 100%;
  // height: auto;
  // background-color: black;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.bannerclass {
  width: 1000px;
  height: 470px;
  background-color: rgb(0, 0, 0);
  z-index: 99;
}
.bottom-blackbg{
  background-color: #000;
}
.bottom-redline {
  width: 1000px;
  height: 10px;
  background-color: red;
  margin-top: 20px;
  margin-bottom: 10px;
}
.center-backview {
  // width: 1000px;
  width: 100%;
  /* height: 400px; */
  // background-color: #fff;
  padding: 20px 0px;
  // background-image: url(https://cdn.spbcn.org/ghac/assets/feiyi_background.png);
  // background-size: 100%;
}

.center-titleview {
  width: 377px;
  height: 42px;
  background: url(https://cdn.spbcn.org/ghac/assets/feiyi_homepage_title.png);
  background-size: 100%;
}

.titleStr {
  width: 280px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  font-size: 24px;
  color: white;
  font-weight: bolder;
  font-family: FZLBJW;
}
.center-menuview {
  width: 1000rpx;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 20px;
}
.menuview-backview {
  width: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.menuimage {
  width: 100px;
  height: 100px;
}
.menuview-title{
  font-size: 20px;
  font-family: regular;
  margin-top: 10px;
}
.menuview-title-en{
  font-size: 14px;
  font-family: regular;
  margin-top: 10px;
  text-align: center;
}
.menutitleimage {
  width: 130px;
  height: 16px;
  margin-left: 10px;
  margin-top: 10px;
}
.center-menulist {
  width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.list-content-backview {
  width: 100%;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  .txt{
    font-size: 32px;
    font-family: regular;
    color: #fff;
    text-align: center;
    .en{
      font-size: 20px;
    }
  }
  .icon{
    height: 42px;
    margin: 0 40px;
  }
}
.list-content-titleimage1 {
  width: 350px;
  height: 42px;
}
.list-content-titleimage2 {
  width: 350px;
  height: 41px;
}
.list-content-titleimage3 {
  width: 350px;
  height: 36px;
}
.list-content-titleimage4 {
  width: 350px;
  height: 38px;
}
.center-challengesbackOne {
  // width: 908px;
  width: 1000px;
  height: 410px;
  // margin-left: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 2px solid black;
  margin-top: 20px;
  box-sizing: border-box;
}
.center-challengesbackTwo {
  width: 990px;
  height: 400px;
  background-color: white;
  border: 2px solid rgb(255, 0, 0);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.challeng-listback {
  width: 160px;
  height: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.challeng-menuback {
  width: 160px;
  height: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.challengmenuImage {
  width: 90px;
  height: 90px;
  background-size: 100%;
}
.challengTitleImage {
  margin-top: 10px;
  width: 110px;
  height: 20px;
}
.footerclass {
  width: 1000px;
}
.banner-img-class {
  width: 1000px;
  height: 480px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.el-carousel {
  width: 1000px;
  height: 480px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}
.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.theme{
  overflow: hidden;
  margin-top: 40px;
  img{
    width: 48%;
    border-radius: 20px;
    margin: 1%;
  }
}
</style>
