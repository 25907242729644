<template>
  <div class="backview">
    <div class="course-class-topback">
      <img
        class="course-left-backimg"
        src="../../assets/images/homepage_left.png"
        @click="backClicked"
      />
      <div class="course-title">展演报名</div>
    </div>

    <div class="content">
      <div class="upload" @click="handleToReg(0)" v-if="!list.length">2024年度展演报名</div>
      <div v-else>
        <el-button class="download" type="text" @click="downloadFn">点击下载学生展演指导手册</el-button>
        <el-table :data="list" border style="width: 100%; margin-top: 20px;">
          <!-- <el-table-column prop="clubName" label="俱乐部"></el-table-column> -->
          <!-- <el-table-column prop="category" label="展演类目"></el-table-column> -->
          <el-table-column type="expand">
            <template slot-scope="scope">
              <el-table :data="scope.row.list" style="width: 98%; margin: 0 auto;" border>
                <el-table-column label="#" type="index"></el-table-column>
                <el-table-column prop="teamName" label="展演名称"></el-table-column>
                <el-table-column prop="teamCategory" label="展演类目"></el-table-column>
                <el-table-column prop="teamForm" label="展演形式"></el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <el-table-column prop="groupLevel" label="组别" :formatter="levelFormatter"></el-table-column>
          <el-table-column prop="teacherName1" label="指导教师"></el-table-column>
          <el-table-column prop="teacherPhone1" label="联系方式"></el-table-column>
          <el-table-column prop="teamNum" label="参赛队数量"></el-table-column>
          <el-table-column prop="status" label="状态">
            <template slot-scope="scope">
              <el-tag size="mini" :type="formatType(scope.row.status)">{{ formatTypeStr(scope.row.status) }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="date" label="操作">
            <template slot-scope="scope">
              <el-button type="text" v-if="scope.row.status === 0 || scope.row.status === 1" @click="handleToReg(1)">去缴费</el-button>
              <!-- <el-button type="text" style="color: #F56C6C;" v-if="scope.row.status === 0 || scope.row.status === 1">撤回</el-button> -->
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      list: [],
      dict_schoolGroup: this.$curUtils.dict_schoolGroup(),
      statusArr: [
        // 0-已提交，1-审核通过/待缴费，2-审核未通过，3-已缴费 4-已退费
        { code: 0, label: '待缴费', type: 'warning' },
        { code: 1, label: '待缴费', type: 'warning' },
        { code: 2, label: '审核未通过', type: 'danger' },
        { code: 3, label: '已缴费', type: 'success' },
        { code: 4, label: '已退费', type: 'info' }
      ]
    }
  },
  created () {
    this.getList()
  },
  methods: {
    handleToReg(step) {
      this.$router.push({
        path: '/club/register',
        query: {
          step
        }
      })
    },
    downloadFn () {
      window.open('https://cdn.spbcn.org/ghac/file/学生展演指导手册.pdf')
      // this.fileLinkToStreamDownload('https://cdn.spbcn.org/ghac/file/学生展演指导手册.pdf', '学生展演指导手册')
    },
    fileLinkToStreamDownload(url, fileName) {
      const that = this
      const xhr = new XMLHttpRequest()
      xhr.open('get', url, true)
      xhr.setRequestHeader('Content-Type', 'application/octet-stream;charset=utf-8')
      xhr.responseType = 'blob'
      xhr.onload = function () {
        if (this.status === 200) {
          // 接受二进制文件流
          const blob = this.response
          that.downloadExportFile(blob, fileName)
        }
      }
      xhr.send()
    },
    downloadExportFile(blob, fileName) {
      const downloadElement = document.createElement('a')
      let href = blob
      if (typeof blob === 'string') {
        downloadElement.target = '_blank'
      } else {
        href = window.URL.createObjectURL(blob) // 创建下载的链接
      }
      downloadElement.href = href
      downloadElement.download = fileName
      document.body.appendChild(downloadElement)
      downloadElement.click() // 点击下载
      document.body.removeChild(downloadElement) // 下载完成移除元素
      if (typeof blob !== 'string') {
        window.URL.revokeObjectURL(href) // 释放掉blob对象
      }
    },
    backClicked () {
      this.$router.back()
    },
    getList () {
      this.$http.get('itsoRegister/itsoRegisterList', { params: { pageNum: 1, pageSize: 10 } }).then(res => {
        if (res.code === 200) {
          this.list = res.data.list
          this.list.forEach(item => {
            item.list = JSON.parse(item.list)
          })
          console.log(this.list)
        }
      })
    },
    formatType (status) {
      return this.statusArr.find(item => item.code === status).type
    },
    formatTypeStr (status) {
      return this.statusArr.find(item => item.code === status).label
    },
    levelFormatter (item) {
      return this.dict_schoolGroup.find(sch => sch.dictValue === String(item.groupLevel)).dictLabel
    }
  }
}
</script>
<style scoped lang="less">
.download{
  text-decoration: underline;
  margin-top: 20px;
}
.backview {
  height: 100%;
  width: 100%;
}
.course-class-topback {
  width: 100%;
  height: 60px;
  background-color: rgb(178, 178, 178);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.course-left-backimg {
  width: 30px;
  height: 30px;
  margin-left: 10px;
}
.course-title {
  margin-left: 10px;
  font-size: 18px;
  font-weight: 900;
}
.sec-title {
  width: 260px;
  height: 38px;
  background-image: url(https://cdn.spbcn.org/ghac/ghac_button_highlight.png);
  background-size: 100% 100%;
  text-align: center;
  color: #fff;
  font-family: hzgb;
  font-size: 18px;
  line-height: 38px;
  margin: 40px auto 30px;
  letter-spacing: 2px;
  cursor: pointer;
}
.upload{
  background-image: url(https://cdn.spbcn.org/ghac/flag-border.png);
  background-size: 560px 60px;
  background-position: center;
  background-repeat: no-repeat;
  width: 600px;
  height: 80px;
  line-height: 80px;
  margin: 20px auto;
  background-color: #b81b26;
  border-radius: 14px;
  color: #fff;
  font-size: 32px;
  font-family: regular;
  text-align: center;
  cursor: pointer;
}
</style>
