<template>
  <div>
    <el-breadcrumb>
      <el-breadcrumb-item>初选</el-breadcrumb-item>
      <el-breadcrumb-item>成绩查询</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <el-card style="margin: 15px">
      <el-row class="title_row">
        <el-col>
          <span>成绩信息筛选</span>
        </el-col>
      </el-row>
      <el-form
        :model="queryForm"
        class="filter_Form"
        label-width="100px"
        ref="queryFormRef"
      >
        <el-row :gutter="10">
          <el-col :span="10">
            <el-form-item label="赛季" prop="season">
              <el-select
                placeholder="请选择赛季"
                style="width: 100%"
                v-model="queryForm.season"
              >
                <el-option
                  v-for="item in dict_season"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="赛程" prop="raceSchedule">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                v-model="queryForm.raceSchedule"
                clearable
              >
                <el-option
                  v-for="item in raceScheduleList"
                  :key="item.id"
                  :label="item.scheduleName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="10">
            <el-form-item label="学生姓名" prop="userName">
              <el-input
                v-model="queryForm.userName"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="手机号" prop="phone">
              <el-input
                v-model="queryForm.phone"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row :gutter="20" type="flex" justify="end">
        <el-col :span="3">
          <el-button
            type="primary"
            style="width: 100%"
            @click="queryPaperResultScore('search')"
            >查询</el-button
          >
        </el-col>
        <el-col :span="3">
          <el-button type="primary" style="width: 100%" @click="reset"
            >重置</el-button
          >
        </el-col>
      </el-row>
    </el-card>
    <el-card style="margin: 15px">
      <el-row class="title_row" align="middle" type="flex">
        <el-col :span="9">
          <span>成绩信息</span>
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="dataList"
        border
        stripe
      >
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column label="姓名" prop="userName"></el-table-column>
        <!-- <el-table-column label="性别" prop="scoreSex"></el-table-column> -->
        <el-table-column
          label="年级"
          prop="grade"
          :formatter="gradeFormatter"
        ></el-table-column>
        <el-table-column label="成绩" prop="score"></el-table-column>
        <!-- <el-table-column label="成绩查询码" prop="scoreCode"></el-table-column> -->
        <!-- <el-table-column label="国籍" prop="nationality"></el-table-column> -->
        <el-table-column label="联系方式" prop="phone"></el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.pageNum"
        :page-sizes="[10, 20, 40, 80]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import areasData from '../../../assets/area.json'
import { getSchoolList, queryPaperResultScore } from '../../../http/api'
export default {
  data () {
    return {
      total: 0,
      dict_gender: this.$curUtils.dict_sex(),
      dict_grade: this.$curUtils.getGradeData(),
      dict_season: this.$curUtils.getDataList(this, 't_season').then(value => { this.dict_season = value }),
      raceScheduleList: this.$curUtils.querySeasonRaceSchedule(this, {}).then(value => {
        this.raceScheduleList = [value[0]]
      }),
      areasData: areasData.areas,
      dataList: [],
      provincesSchoolList: [],
      queryForm: {
        pageNum: 1,
        pageSize: 10,
        season: '2022~2023',
        raceSchedule: '1',
        activityType: 10
      }
    }
  },
  created () {
    this.queryPaperResultScore()
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    // 学校省市区选择
    provincesHandleChange (value) {
      this.provincesSchoolList = []
      this.queryForm.provinceCode = this.queryForm.schoolArea[0]
      this.queryForm.cityCode = this.queryForm.schoolArea[1]
      this.queryForm.areaCode = this.queryForm.schoolArea[2]
      const deliveryAreaName = this.$refs.schoolAreaRef.getCheckedNodes()[0].pathLabels.join('/')
      const areaList = deliveryAreaName.split('/')
      this.queryForm.province = areaList[0]
      this.queryForm.city = areaList[1]
      this.queryForm.area = areaList[2]
      this.getSchoolListData(value)
    },
    // 获取学校列表信息
    getSchoolListData (schoolArea) {
      getSchoolList({ provinceCode: schoolArea[0], cityCode: schoolArea[1], areaCode: schoolArea[2] }).then((res) => {
        this.provincesSchoolList = res.data
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 获取成绩信息
    queryPaperResultScore (searchType) {
      if (searchType === 'search') {
        this.queryForm.pageNum = 1
        this.queryForm.pageSize = 10
      }
      const schoolData = JSON.parse(localStorage.getItem('saveSchoolInfo'))
      this.queryForm.schoolId = schoolData.list[0].schoolId
      queryPaperResultScore(this.queryForm).then((res) => {
        console.log('getSchoolList', res)
        this.dataList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 重置搜索条件
    reset () {
      this.$refs.queryFormRef.resetFields()
      this.queryPaperResultScore()
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.queryPaperResultScore()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.queryPaperResultScore()
    },
    genderFormatter (data) {
      let temp = '未知'
      for (const key in this.dict_gender) {
        if (Object.hasOwnProperty.call(this.dict_gender, key)) {
          const element = this.dict_gender[key]
          if (element.dictValue === data.scoreSex + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    gradeFormatter (data) {
      let temp = '未知'
      for (const key in this.dict_grade) {
        if (Object.hasOwnProperty.call(this.dict_grade, key)) {
          const element = this.dict_grade[key]
          if (element.dictValue === data.grade + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    }
  }
}
</script>

<style>
</style>
