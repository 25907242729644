<template>
  <div class="top-backview">
    <div class="contnet-backview">
      <!-- 顶部菜单 -->
      <!-- <Topbar class="topbar"></Topbar> -->
      <!-- banner -->
      <div class="dibannerclass">
        <el-carousel
          :interval="3000"
          indicator-position="none"
          id="el-carousel"
          :height="dataHeight"
        >
          <el-carousel-item v-for="item in images" :key="item.index">
            <img :src="item.url" alt="" class="banner-img-class" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="bannerbottom">
        <div class="title-backview">课程监管与服务</div>
      </div>
      <div class="red-backview">
        <div class="red-lineview"></div>
      </div>
      <!-- 中间内容部分 -->
      <div class="centerclass">
        <div class="center-titleview">
          <div class="titleStr">课程监管与服务</div>
        </div>
        <div class="course-content-backview">
          <div
            class="course-list-backview"
            v-for="item in contentList"
            :key="item.index"
          >
            <img :src="item.numImg" alt="" class="num-img-class" />
            <div class="oneline-view"></div>
            <img :src="item.conImg" alt="" class="content-image" />
            <div class="twoline-view"></div>
            <div class="right-content-view">
              <div class="right-top-str">{{ item.conTitleStr }}</div>
              <div class="right-bottom-str">{{ item.contentStr }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 底部内容 -->
    </div>
  </div>
</template>

<script>
// import Topbar from '@/components/Topbar.vue'
// import Footer from '@/components/Footer.vue'
export default {
  props: {
    dataHeight: {
      type: String,
      default: '100%'
    }
  },
  data () {
    return {
      images: [{ url: 'https://cdn.spbcn.org/ghac/assets/feiyiprograms1_3_banner.png' }],
      contentList: [
        {
          numImg: 'https://cdn.spbcn.org/ghac/assets/feiyiprograms1_3_icon1.png',
          conImg: 'https://cdn.spbcn.org/ghac/assets/feiyiprograms1_3_image1.png',
          conTitleStr: '第一阶段：课件研究',
          contentStr: '由聚知优国际理解教育研发专家团队研发'
        },
        {
          numImg: 'https://cdn.spbcn.org/ghac/assets/feiyiprograms1_3_icon2.png',
          conImg: 'https://cdn.spbcn.org/ghac/assets/feiyiprograms1_3_image2.png',
          conTitleStr: '第二阶段：教师选拔',
          contentStr: '来自于产研学基地在校优秀硕士研究生'
        },
        {
          numImg: 'https://cdn.spbcn.org/ghac/assets/feiyiprograms1_3_icon3.png',
          conImg: 'https://cdn.spbcn.org/ghac/assets/feiyiprograms1_3_image3.png',
          conTitleStr: '第三阶段：师资团队搭建',
          contentStr: '经过层层面试、试讲最终确认进校教师'
        },
        {
          numImg: 'https://cdn.spbcn.org/ghac/assets/feiyiprograms1_3_icon4.png',
          conImg: 'https://cdn.spbcn.org/ghac/assets/feiyiprograms1_3_image4.png',
          conTitleStr: '第四阶段：师资培训',
          contentStr:
            '进校教师们在入校前要进过5-10次试讲并根据老师们上课的风格对课件进行进一步加工'
        },
        {
          numImg: 'https://cdn.spbcn.org/ghac/assets/feiyiprograms1_3_icon5.png',
          conImg: 'https://cdn.spbcn.org/ghac/assets/feiyiprograms1_3_image5.png',
          conTitleStr: '第五阶段：进校授课与跟课监督',
          contentStr:
            '教师进校上课必须由赛区总负责人带着研发团队课程监督员一起进校督课，并对课堂进行评价'
        },
        {
          numImg: 'https://cdn.spbcn.org/ghac/assets/feiyiprograms1_3_icon6.png',
          conImg: 'https://cdn.spbcn.org/ghac/assets/feiyiprograms1_3_image6.png',
          conTitleStr: '第六阶段：向学校提供反馈并根据校方要求进行调整',
          contentStr: ''
        },
        {
          numImg: 'https://cdn.spbcn.org/ghac/assets/feiyiprograms1_3_icon7.png',
          conImg: 'https://cdn.spbcn.org/ghac/assets/feiyiprograms1_3_image7.png',
          conTitleStr:
            '第七阶段：配合学校做好课程总结、研学、校园微博物馆建设以及参加环球文遗小使者大会的准备工作',
          contentStr: ''
        }
      ]
    }
  },
  components: {
    // Topbar,
    // Footer
  }
}
</script>

<style lang="less" scoped>
// .topbar {
//   z-index: 1000;
//   width: 1000px;
//   padding: 0;
//   margin: 0;
// }
.contnet-backview {
  width: 1000px;
  background-color: black;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.top-backview {
  width: 100%;
  // padding-bottom: 10px;
  background-color: rgb(216, 216, 216);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.dibannerclass {
  width: 1000px;
  height: 469px;
  z-index: 99;
  background-color: rgb(0, 0, 0);
}
.bannerbottom {
  width: 1000px;
  height: 100px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 150px;
  z-index: 100;
}
.title-backview {
  height: 60px;
  font-size: 32px;
  font-family: FZLBJW;
  font-weight: 900;
  text-align: center;
  line-height: 60px;
  padding: 0px 20px;
  color: white;
  border: 2px solid rgb(255, 255, 255);
}
.red-backview {
  width: 1000px;
  height: 25px;
}
.red-lineview {
  width: 1000px;
  height: 6px;
  margin-top: 15px;
  background-color: #e50211;
}
.centerclass {
  width: 1000px;
  height: 1300px;
  display: flex;
  flex-direction: column;
  background-image: url(https://cdn.spbcn.org/ghac/assets/feiyi_background_mix.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.center-titleview {
  width: 377px;
  height: 42px;
  margin-top: 30px;
  background: url(https://cdn.spbcn.org/ghac/assets/feiyi_homepage_title.png);
  background-size: 100%;
}

.titleStr {
  width: 240px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  font-size: 24px;
  font-family: FZLBJW;
  color: white;
  font-weight: bolder;
}

.course-content-backview {
  width: 800px;
  margin-left: 60px;
  margin-top: 30px;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.course-list-backview {
  width: 800px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 0px;
}
.num-img-class {
  width: 80px;
  height: 80px;
}

.oneline-view {
  width: 2px;
  height: 100px;
  background-color: rgb(114, 114, 114);
  margin: 0 10px;
}
.content-image {
  width: 240px;
  height: 103px;
}
.twoline-view {
  width: 4px;
  height: 50px;
  margin: 0 10px;
  background-color: #b81926;
}
.right-content-view {
  width: 350px;
  display: flex;
  flex-direction: column;
}
.right-top-str {
  width: 350px;
  font-size: 18px;
  font-family: FZLBJW;
  color: black;
  font-weight: 900;
}
.right-bottom-str {
  width: 350px;
  font-size: 16px;
  font-family: FZLBJW;
  color: rgb(48, 48, 48);
}
.footerclass {
  width: 1000px;
}
.banner-img-class {
  width: 1000px;
  height: 470px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.el-carousel {
  width: 1000px;
  height: 470px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
