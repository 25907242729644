<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="top-backview">
    <div class="contnet-backview">
      <ghacBanner></ghacBanner>
      <ghacTab :activeIndex="3"></ghacTab>
      <div class="centerclass">
        <div class="upload" @click="handleToReg">2024年度展演报名</div>
        <div class="tips">
          <!-- <div class="tip-item">一、注册报名时间：2024年2月1日-6月30日</div>
          <div class="tip-item">二、注册服务费：980元/队</div>
          <div class="tip-item">三、注册服务明细：</div>
          <div class="tip-item2">队伍注册费：80元</div>
          <div class="tip-item2">国家组委会一对一线下或线上指导一次（价值800元）</div>
          <div class="tip-item2">指导手册一本（价值100元）</div>
          <div class="tip-item">四、展演时间：2024年11月1日-3日（三天两晚）</div>
          <div class="tip-item">五、展演地点：北京•国家会展中心</div>
          <div class="tip-item">六、展演价格：</div>
          <div class="tip-item2">A 套餐价格：4980元/人（包食宿）</div>
          <div class="tip-item2">B 套餐价格：2980 元/人（不包食宿）附赠住宿官方协议指导价</div>
          <div class="tip-item">七、展演项目提报时间：即日起至2024年6月30日截止</div>
          <div class="tip-item2">注：提报展演项目需要接受组委会审核，每个区域遴选队伍控制在40支以内，每校不超过2支队。</div>
          <div class="tip-item">八、退费说明：</div>
          <div class="tip-item2">如因校方原因无故缺席展演，注册费一律不退。</div>
          <div class="tip-item2">如因组委会原因未能展演成功，按实际已消耗费用扣除相应费用后退费。</div> -->
          <!-- <div class="tip-item">附件：<a @click="downloadFn">2024年度环球文遗小使者大会展演报名表</a></div> -->
          <div class="table">
            <div class="tr">
              <div class="td th">报名阶段</div>
              <div class="td th">完成时限</div>
              <div class="td th col3">权益明细</div>
              <div class="td th col4">组织费用</div>
              <div class="td th col5">备注</div>
            </div>
            <div class="tr tr1">
              <div class="td font">组队准备阶段</div>
              <div class="td">2024.9.30前</div>
              <div class="td star col3">
                <div class="item1"><img src="../../assets/images/star.png" alt="">学校主体注册认证服务</div>
                <div class="item1"><img src="../../assets/images/star.png" alt="">国家组委会专项指导服务</div>
                <div class="item1"><img src="../../assets/images/star.png" alt="">人文素养社团课资料包</div>
                <div class="item1"><img src="../../assets/images/star.png" alt="">参展指导手册及相关资料</div>
              </div>
              <div class="td price col4">980元/队</div>
              <div class="td col5 lh">提报展演项目需要接受组委会审核，每个区域遴选队伍控制名额，每校每期不超过2支队。</div>
            </div>
            <div class="tr tr2">
              <div class="td font">展演交流阶段</div>
              <div class="td">2024.10.10前</div>
              <div class="td star col3">
                <div class="item2 item1"><img src="../../assets/images/star1.png" alt=""><b>北京3天2晚</b>活动沉浸体验</div>
                <div class="item2 item1"><img src="../../assets/images/star1.png" alt="">开闭幕式、环球大舞台、环球博览会、环球嘉年华、环球未来外交官之夜<b>6个项目版块的成果展示</b></div>
                <div class="item2 item1"><img src="../../assets/images/star1.png" alt="">探究报告、广告展板、PPT演示、舞台剧展示和工程作品<b>5项任务的专家团评审指导</b></div>
                <div class="item2 item1"><img src="../../assets/images/star1.png" alt="">现场<b>影像直播</b>资料</div>
                <div class="item2 item1"><img src="../../assets/images/star1.png" alt="">团队及校级成果<b>评奖荣誉</b></div>
                <div class="item2 item1"><img src="../../assets/images/star1.png" alt="">环球未来外交官<b>晚宴</b></div>
              </div>
              <div class="td col4 lh">
                <!-- <div class="taocan">A：<span class="s1">3980元/人</span><div class="s2">含住宿<br/>单房差补价600元/人</div></div> -->
                <div class="taocan"><span class="s1">2980元/人</span><div class="s2">不含住宿</div></div>
              </div>
              <div class="td col5 lh">按队收取，统一提交至组委会。</div>
            </div>
            <div class="tr3">
              <div>【退费说明】</div>
              <div>如因校方原因无故缺席展演，注册费一律不退。</div>
              <div>如因组委会原因未能展演成功，按实际已消耗费用扣除相应费用后退费。</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ghacBanner from '../../components/ghacBanner2.vue'
import ghacTab from '../../components/ghacTab.vue'
export default {
  components: {
    ghacTab,
    ghacBanner
  },
  data () {
    return {
      images: [{ url: 'https://cdn.spbcn.org/ghac/page/1/HQWY_HQDH_banne_01.png' }]
    }
  },
  methods: {
    fn (path) {
      if (path) {
        this.$router.push(path)
      }
    },
    handleToReg () {
      const token = localStorage.getItem('HQAuthorization')
      if (token) {
        this.$router.push('/club/register?step=0')
      } else {
        this.$router.push('/loginview')
      }
    },
    downloadFn () {
      window.open('https://cdn.spbcn.org/ghac/file/2024年度环球文遗小使者大会展演报名表.docx')
    }
  }
}
</script>

<style lang="less" scoped>
.contnet-backview {
  // width: 1000px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-bottom: 80px;
}
.top-backview {
  width: 100%;
  // padding-bottom: 10px;
  background-color: rgb(216, 216, 216);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.dibannerclass {
  width: 1000px;
  height: 469px;
  z-index: 99;
}
.bannerbottom {
  width: 1000px;
  height: 100px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 150px;
  z-index: 100;
}
.title-backview {
  height: 80px;
  font-size: 32px;
  font-family: FZLBJW;
  font-weight: 900;
  text-align: center;
  line-height: 40px;
  padding: 0px 20px;
  color: white;
  border: 2px solid rgb(255, 255, 255);
  .en{
    font-size: 24px;
    margin-top: 10px;
  }
}
.red-backview {
  width: 1000px;
  height: 25px;
}
.red-lineview {
  width: 1000px;
  height: 10px;
  // margin-top: 15px;
  background-color: #e50211;
}
.centerclass {
  width: 1000px;
  margin: 0 auto;
}
.center-titleview {
  display: inline-block;
  padding-right: 100px;
  height: 42px;
  margin-top: 50px;
  background: url(https://cdn.spbcn.org/ghac/assets/feiyi_homepage_title.png);
  background-size: auto 100%;
  background-position: right;
  background-repeat: no-repeat;
}

.titleStr {
  // width: 600px;
  height: 42px;
  line-height: 42px;
  // text-align: center;
  font-size: 24px;
  font-family: FZLBJW;
  color: white;
  font-weight: bolder;
  padding-left: 30px;
  background-color: #b81b26;
}

.banner-img-class {
  width: 1000px;
  height: 470px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.el-carousel {
  width: 1000px;
  height: 470px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.sec-title{
  width: 350px;
  height: 38px;
  background-image: url(https://cdn.spbcn.org/ghac/ghac_button_highlight.png);
  background-size: 100% 100%;
  text-align: center;
  color: #fff;
  font-family: hzgb;
  font-size: 18px;
  line-height: 38px;
  margin: 60px auto 30px;
  letter-spacing: 2px;
}
.page_text{
  font-size: 20px;
  font-family: FZLBJW;
  line-height: 32px;
  margin-top: 20px;
  div{
    text-indent: 2em;
  }
}
.page_img{
  width: 1000px;
  // height: 186px;
  border-radius: 30px;
  margin-top: 40px;
}
.topMenuBackview{
  width: 1000px;
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.menu-backview{
  color: #fff;
  font-size: 28px;
  font-family: FZLBJW;
  background-color: #000;
  height: 42px;
  line-height: 42px;
  padding: 0 40px;
  border-radius: 42px;
  cursor: pointer;
}
.selectColor {
  background-color: #b71b26;
}
.page_4_img{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 40px;
  img{
    width: 22%;
    height: auto;
  }
}
.page_img_circle{
  width: 400px;
  margin: 40px auto 0;
  display: block;
}
.red-bg{
  background-color: #a32c25;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
}
.page_text2{
  margin-top: 0;
  color: #fff;
  font-family: '微软雅黑';
  font-size: 14px;
  line-height: 28px;
}
.upload{
  background-image: url(https://cdn.spbcn.org/ghac/flag-border.png);
  background-size: 860px 80px;
  background-position: center;
  background-repeat: no-repeat;
  width: 900px;
  height: 100px;
  line-height: 96px;
  margin: 60px auto 40px;
  background-color: #b81b26;
  border-radius: 14px;
  color: #fff;
  font-size: 40px;
  font-family: regular;
  text-align: center;
  cursor: pointer;
}

.tips{
  color: #958363;
  font-size: 14px;
  margin-top: 20px;
  .tip-item{
    margin-bottom: 4px;
    margin-top: 16px;
    a{
      color: #b81c25;
      cursor: pointer;
      text-decoration: underline;
      font-size: 18px;
    }
  }
  .tip-item2{
    margin-bottom: 4px;
    margin-left: 28px;
  }
}
::v-deep .el-carousel__button{
  width: 10px;
}

.table{
  width: 100%;
  border-left: 1px solid #a28d67;
  border-top: 1px solid #a28d67;
  font-size: 14px;
  color: #a28d67;
  .th{
    font-size: 18px;
    font-family: regular;
    color: #b81c25;
    text-align: center;
    height: 40px;
    line-height: 40px;
  }
  .tr{
    display: flex;
    align-items: center;
  }
  .tr1{
    height: 180px;
  }
  .tr2{
    height: 320px;
  }
  .td{
    height: 100%;
    border-right: 1px solid #a28d67;
    border-bottom: 1px solid #a28d67;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .col3{
    flex: 160px 1 1;
  }
  .col3, .col4, .col5{
    padding-left: 20px;
    padding-right: 20px;
  }
  .star{
    align-items: flex-start;
  }
  .item1{
    margin: 8px 0;
    img{
      width: 12px;
      height: 12px;
      margin-right: 4px;
    }
  }
  .item2{
    img{
      width: 16px;
      height: 16px;
    }
  }
  .price{
    font-family: regular;
    font-size: 18px;
    color: #b81c25;
  }
  .font{
    font-family: FZLBJW;
    font-size: 18px;
  }
  .lh{
    line-height: 26px;
  }
  .taocan{
    margin: 12px 0;
    .s1{
      font-size: 18px;
      font-family: regular;
      color: #b81c25;
    }
    .s2{
      font-size: 12px;
    }
  }
  .tr3{
    padding: 20px 30px;
    border-right: 1px solid #a28d67;
    border-bottom: 1px solid #a28d67;
    line-height: 26px;
  }
}
</style>
