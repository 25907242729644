import { render, staticRenderFns } from "./languageCourses.vue?vue&type=template&id=484a7aae&scoped=true&"
import script from "./languageCourses.vue?vue&type=script&lang=js&"
export * from "./languageCourses.vue?vue&type=script&lang=js&"
import style0 from "./languageCourses.vue?vue&type=style&index=0&id=484a7aae&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "484a7aae",
  null
  
)

export default component.exports