const env = process.env.NODE_ENV
// 开发环境运行命令：npm run serve
// 生产环境打包命令：npm run pro
// 测试环境打包命令：npm run build
// 网站本地开发环境：9777
// 网站本地测试环境：9888
// 本地开发、测试
// baseIP: 'http://192.168.2.15:9966/web_dev/'
// 本地正式
// baseIP: 'http://192.168.2.15:9966/web/'
// 线上测试
// baseIP: 'https://hy.spbcn.org/web_test'
// 线上正式
// baseIP: 'https://hy.spbcn.org/web'
const IP = {
  development: { // 本地开发环境
    // baseIP: 'http://192.168.2.34:9965/web_hq_dev/' // local
    // baseIP: 'https://hq.spbcn.org/web_hq_test/' // test
    baseIP: 'https://hq.spbcn.org/web_hq/' // pro
  },
  production: { // 生产环境
    baseIP: 'https://hq.spbcn.org/web_hq/'
    // baseIP: 'https://hy.spbcn.org/web_test/'
  },
  test: { // 测试环境
    baseIP: 'https://hq.spbcn.org/web_hq_test/'
  }
}
export default IP[env]
