<template>
  <div class="top-backview">
    <div class="contnet-backview">
      <!-- 顶部菜单 -->
      <!-- <Topbar class="topbar"></Topbar> -->
      <!-- banner -->
      <div class="dibannerclass">
        <el-carousel
          :interval="3000"
          indicator-position="none"
          id="el-carousel"
          :height="dataHeight"
        >
          <el-carousel-item v-for="item in images" :key="item.index">
            <img :src="item.url" alt="" class="banner-img-class" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="bannerbottom">
        <div class="title-backview">校园Micro-museum</div>
      </div>
      <div class="red-backview">
        <div class="red-lineview"></div>
      </div>
      <!-- 中间内容部分 -->
      <div class="centerclass">
        <div class="center-titleview">
          <div class="titleStr">校园Micro-museum</div>
        </div>
        <img
          src="https://cdn.spbcn.org/ghac/assets/feiyiprograms3_text.png"
          alt=""
          class="title-image"
        />
        <img
          src="https://cdn.spbcn.org/ghac/assets/feiyiprograms3_image1.png"
          alt=""
          class="type-image"
        />
        <div class="center-titleview">
          <div class="titleStr">校园微博物馆的管理</div>
        </div>
        <div class="museum-management-backview">
          <img
            src="https://cdn.spbcn.org/ghac/assets/feiyiprograms3_image2.png"
            alt=""
            class="management-image"
          />
          <div class="management-titStr">
            校园博物馆可以根据国际理解教育人文素养社团俱乐部的课堂引导课程和校外研学课程的四个主题建立四个主题的博物馆，在课程开始总结课结束后，孩子们可以通过<span
              style="font-weight: bolder"
              >“校内博物馆招聘会”</span
            >加入自己感兴趣的博物馆，成为馆内的工作人员，参与馆内日常运营管理工作。在课堂引导课过程中，各馆的小馆员们大致接受了相关主题的理论与实践的培训，当然，在实践中学校会根据各馆不同情况调整课程的具体内容。这样，学生作为各馆工作人员，与老师们共同完成校园博物馆的日常运营管理工作。
          </div>
        </div>
        <div class="center-titleview">
          <div class="titleStr">校园微博物馆的意义</div>
        </div>
        <div class="meaning-backview">
          近年来，博物馆青少年教育工作得到了国家及社会公众的高度关注。2020年10月，教育部、国家文物局联合印发《关于利用博物馆资源开展中小学教育教学的意见》（以下简称《意见》），对中小学利用博物馆资源开展教育教学提出明确指导意见，要求进一步健全博物馆与中小学校合作机制，促进博物馆资源融入教育体系，提升中小学生利用博物馆学习效果。与以往颁布的政策相比，《意见》首次明确了教育部门和学校在利用博物馆资源开展教育教学工作中的主体地位和主导作用。创造性地开展非遗校本课程并在校园建设非遗博物馆，<span
            style="font-weight: bolder"
            >让非遗在校园里“活”起来</span
          >，对学校、教师和学生的影响非常大。
        </div>
        <img
          src="https://cdn.spbcn.org/ghac/assets/feiyiprograms3_image3.png"
          alt=""
          class="meaning-imgclass"
        />
      </div>
      <!-- 底部内容 -->
    </div>
  </div>
</template>

<script>
// import Topbar from '@/components/Topbar.vue'
// import Footer from '@/components/Footer.vue'
export default {
  props: {
    dataHeight: {
      type: String,
      default: '100%'
    }
  },
  data () {
    return {
      images: [{ url: 'https://cdn.spbcn.org/ghac/assets/feiyiprograms3_banner.png' }]
    }
  },
  components: {
    // Topbar,
    // Footer
  }
}
</script>

<style lang="less" scoped>
// .topbar {
//   z-index: 1000;
//   width: 1000px;
//   padding: 0;
//   margin: 0;
// }
.contnet-backview {
  width: 1000px;
  background-color: black;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.top-backview {
  width: 100%;
  // padding-bottom: 10px;
  background-color: rgb(216, 216, 216);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.dibannerclass {
  width: 1000px;
  height: 469px;
  z-index: 99;
  background-color: rgb(0, 0, 0);
}
.bannerbottom {
  width: 1000px;
  height: 100px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 150px;
  z-index: 100;
}
.title-backview {
  height: 60px;
  font-size: 32px;
  font-family: FZLBJW;
  font-weight: 900;
  text-align: center;
  line-height: 60px;
  padding: 0px 20px;
  color: white;
  border: 2px solid rgb(255, 255, 255);
}
.red-backview {
  width: 1000px;
  height: 25px;
}
.red-lineview {
  width: 1000px;
  height: 6px;
  margin-top: 15px;
  background-color: #e50211;
}
.centerclass {
  width: 1000px;
  height: 1453px;
  display: flex;
  flex-direction: column;
  background-image: url(https://cdn.spbcn.org/ghac/assets/feiyi_background_mix.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.center-titleview {
  width: 377px;
  height: 42px;
  margin-top: 30px;
  background: url(https://cdn.spbcn.org/ghac/assets/feiyi_homepage_title.png);
  background-size: 100%;
}

.titleStr {
  width: 280px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  font-size: 24px;
  font-family: FZLBJW;
  color: white;
  font-weight: bolder;
}
.title-image {
  width: 800px;
  height: 85px;
  margin: 20px 100px;
}
.type-image {
  width: 800px;
  height: 331px;
  margin-left: 100px;
}

.museum-management-backview {
  width: 800px;
  margin-left: 100px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.management-image {
  width: 260px;
  height: 170px;
}
.management-titStr {
  width: 520px;
  font-size: 18px;
  font-family: FZLBJW;
  color: rgb(58, 58, 58);
  text-indent: 1em;
  font-family: FZLBJW;
}
.meaning-backview {
  width: 740px;
  padding: 30px;
  font-size: 18px;
  font-family: FZLBJW;
  text-indent: 2em;
  margin: 20px 100px;
  color: white;
  background-image: url(https://cdn.spbcn.org/ghac/assets/feiyiprograms3_textback.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.meaning-imgclass {
  width: 800px;
  height: 231px;
  margin-left: 100px;
}
.footerclass {
  width: 1000px;
}
.banner-img-class {
  width: 1000px;
  height: 470px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.el-carousel {
  width: 1000px;
  height: 470px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  font-family: FZLBJW;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
