<template>
  <div>
    <div class="topMenuBackview">
        <div
          class="menu-backview"
          v-for="item,index in menuList"
          :class="{ selectColor: activeIndex === index }"
          @click="fn(item.path)"
          :key="index"
        >
          {{ item.title }}
        </div>
      </div>
  </div>
</template>
<script>
export default {
  props: {
    activeIndex: {
      type: Number
    }
  },
  data () {
    return {
      menuList: [
        { title: '展演流程', path: '/ghacFlow' },
        { title: '展演主题', path: '/ghacTheme' },
        { title: '评审&奖项', path: '/ghacAwards' },
        { title: '展演报名', path: '/ghacReg' }
      ]
    }
  },
  methods: {
    fn (path) {
      if (path) {
        this.$router.push(path)
      } else {
        this.handleToReg()
      }
    },
    handleToReg () {
      const token = localStorage.getItem('HQAuthorization')
      if (token) {
        this.$router.push('/club/register?step=0')
      } else {
        this.$router.push('/loginview')
      }
    }
  }
}
</script>
<style lang="less">
.topMenuBackview{
  width: 1000px;
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.menu-backview{
  color: #fff;
  font-size: 28px;
  font-family: FZLBJW;
  background-color: #000;
  height: 42px;
  line-height: 42px;
  padding: 0 40px;
  border-radius: 42px;
  cursor: pointer;
}
.selectColor {
  background-color: #b71b26;
}
</style>
