<template>
  <div>
    <div class="course-class-topback">
      <img
        class="course-left-backimg"
        src="../../../../../src/assets/images/homepage_left.png"
        @click="backClicked"
      />
      <div class="course-title">社团课程</div>
    </div>
    <!-- 八大主题 -->
    <!-- <div class="course-type-back">
      <el-row>
        <el-col :span="4" v-for="(item, index) in courseTypeList" v-bind:key="index" >
          <div class="course-type-content" @click="courseClassClicked(index)">
            <div class="active" v-if="item.active"></div>
            <img class="course-type-image" :src="item.typeLogo" />
          </div>
        </el-col>
      </el-row>
    </div> -->
    <!-- 国内海外 -->
    <!-- <div class="type-bar">
      <div class="center-container type-flex">
        <div class="type-item" :class="item.active ? 'active' : ''" v-for="item,index of types" :key="item.id" @click="changeTypeItem(index)">{{item.typeName}}</div>
      </div>
    </div> -->

    <!-- 二级分类 -->
    <!-- <div class="book">
      <el-row>
        <el-col :span="4" v-for="item of activeType.childrenCourseType" :key="item.id">
          <div class="book-item">
            <img @click="handleToDetail(item)" class="book-img" :src="item.typeLogo" alt="">
          </div>
        </el-col>
      </el-row>
    </div> -->
    <div class="course">
      <el-form
        label-width="120px"
        style="width: 100%"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="主题:" prop="typeId">
              <el-cascader
                style="width: 250px;"
                :options="courseTypeList"
                v-model="typeId"
                clearable
                :props="{
                  label: 'typeName',
                  value: 'id',
                  children: 'childrenCourseType',
                  checkStrictly: true
                }"
                @change="courseTypeArrayChange(1)"
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="区域分类" prop="courseTypeId">
              <el-cascader
                style="width: 250px;"
                :options="types"
                v-model="courseTypeId"
                clearable
                :props="{
                  label: 'typeName',
                  value: 'id',
                  children: 'childrenCourseType',
                  checkStrictly: true
                }"
                @change="courseTypeArrayChange(2)"
              ></el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template v-if="courseList.length">
        <div class="course-list">
          <div class="course-item" v-for="(item,index) of courseList" :key="index">
            <courseItem :item="item" :isOpen="item.lock === 0" @refresh="getCourses"></courseItem>
          </div>
        </div>
        <div class="page">
          <el-pagination
            background
            layout="prev, pager, next"
            :current-page="pageNum"
            :page-size="pageSize"
            :total="total"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </template>
      <div v-else class="default">
        <el-empty description="暂未上传课程"></el-empty>
      </div>
    </div>
  </div>
</template>

<script>
import courseItem from '../../../../components/courseItem.vue'
export default {
  components: {
    courseItem
  },
  data () {
    return {
      courseList: [],
      total: 0,
      courseTypeList: [],
      types: [], // 华夏、海外
      activeType: {}, // 选择的区域
      activeTypeIndex: 0, //
      activeTheme: {}, // 选中的主题
      areaList: [],
      courseType: '',
      pageNum: 1,
      pageSize: 12,
      typeId: [],
      courseTypeId: [],
      courseTypeIdStr: ''
    }
  },
  created () {
    this.themeId = this.$route.query.themeId
    this.cId = this.$route.query.cId
    this.areaId = this.$route.query.areaId

    this.typeId.push(this.themeId)
    this.courseTypeId.push(this.cId)
    this.courseTypeId.push(this.areaId)
    this.courseTypeIdStr = this.areaId || this.cId

    this.getClasses(1) // 8主题
    this.getClasses(2)
  },
  methods: {
    backClicked () {
      this.$router.back()
    },
    // 分类点击事件
    courseClassClicked (index) {
      this.courseTypeList.find(item => item.active).active = false
      this.courseTypeList[index].active = true
      this.activeTheme = this.courseTypeList[index]
    },
    changeTypeItem(index) {
      this.types.find(item => item.active).active = false
      this.types[index].active = true
      this.activeType = this.types[index]
      this.activeTypeIndex = index
    },
    getClasses (type) {
      this.$http.get('/external/querySeeEightTopics', { params: { type } }).then(res => {
        if (res.code === 200) {
          res.data.forEach((item, i) => {
            item.active = i === 0
          })
          if (type === 1) {
            console.log(this.themeId)
            res.data.find(item => item.id === this.themeId).active = true
            this.courseTypeList = res.data
            // this.activeTheme = res.data[0]
          } else if (type === 2) {
            res.data.find(item => item.id === this.cId).active = true
            this.types = res.data
            // this.activeType = res.data[0]
            this.activeTypeIndex = res.data.findIndex(item => item.id === this.cId)
            this.areaList = this.types[this.activeTypeIndex].childrenCourseType

            this.getCourses()
          }
        }
      })
    },
    getCourses() {
      const params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        courseTypeId: this.courseTypeIdStr,
        typeId: this.themeId,
        courseType: this.courseType
      }
      this.$http.get('course/haveBeenOpenedCourse', { params }).then(res => {
        if (res.code === 200) {
          res.data.list.forEach(item => {
            item.durationStr = this.$xcUtils.formatSeconds(item.duration)
          })
          this.courseList = res.data.list
          this.total = res.data.total
        }
      })
    },
    courseTypeArrayChange (type) {
      console.log(this.typeId)
      if (type === 1) {
        this.themeId = this.typeId[this.typeId.length - 1]
      } else if (type === 2) {
        this.courseTypeIdStr = this.courseTypeId[this.courseTypeId.length - 1]
      }
      this.getCourses()
    },
    handleCurrentChange (e) {
      this.pageNum = e
      this.getCourses()
    }
  }
}
</script>

<style lang="less" scoped>
.course-type-back {
  width: 100%;
  margin: 30px 0px 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: row;
}
.course-type-content {
  width: 200px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  cursor: pointer;
  .active{
    width: 180px;
    height: 37px;
    background-color: #b81b26;
    position: absolute;
    left: 10px;
    right: 0;
    top: 15px;
    z-index: 0;
  }
}
.course-type-image {
  width: 180px;
  // height: 80px;
  position: relative;
  z-index: 10;
}
.course-tye-name {
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
  color: black;
  font-weight: 900;
}
.course-cover-image {
  width: 150px;
  height: 100px;
}
.table-backview {
  width: 100%;
}
.table-column-backview {
  width: 100%;
  height: 85px;
  margin: 20px 0px;
  display: flex;
  flex-direction: row;
  // align-items: center;
}
.left-image {
  width: 150px;
  height: 85px;
}
.right-backview {
  display: flex;
  margin-left: 20px;
  flex-direction: column;
}
.right-title {
  font-size: 16px;
  color: black;
}
.right-content {
  font-size: 12px;
  color: rgb(58, 58, 58);
}
.course-class-topback {
  width: 100%;
  height: 60px;
  background-color: rgb(178, 178, 178);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.course-left-backimg {
  width: 30px;
  height: 30px;
  margin-left: 10px;
}
.course-title {
  margin-left: 10px;
  font-size: 18px;
  font-weight: 900;
}

.type-bar{
  background-color: #dcdcdc;
  padding: 10px 0;
}
.type-flex{
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.type-item{
  width: 250px;
  height: 40px;
  background-image: url(https://cdn.spbcn.org/ghac/ghac_button_nomal.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  font-size: 24px;
  font-family: regular;
  color: #fff;
  text-align: center;
  line-height: 36px;
  cursor: pointer;
  &.active{
    background-image: url(https://cdn.spbcn.org/ghac/ghac_button_highlight.png);
    color: #000;
  }
}

.book{

}
.book-item{
  margin: 20px;
  // border: 3px solid ;
  img{
    height: 220px;
    cursor: pointer;
  }
}

.course{
  margin-top: 20px;
  .search{
    font-size: 16px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .search-back{
      font-family: regular;
      font-size: 24px;
      color: #000;
      cursor: pointer;
      text-decoration: underline;
      &:hover{
        color: #b81b26;
      }
    }
  }
  .course-list{
    overflow: hidden;
    .course-item{
      width: 25%;
      float: left;
      .course-title1{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .course-item-con{
        margin: 0 auto 20px;
        width: 96%;
        border-radius: 30px;
        cursor: pointer;
        &:hover{
          .course-title{
            color: #b81b26;
          }
          box-shadow: 2px 6px 6px rgba(80, 80, 80, 0.1);
        }
        .course-img{
          width: 100%;
          height: 160px;
          // background-image: url(https://cdn.spbcn.org/ghac/pictures/ghac_banner2.png);
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          border-radius: 30px 30px 0 0;
          position: relative;
          margin-top: 20px;
          .shikan{
            position: absolute;
            right: 10px;
            top: -12px;
            width: 60px;
            height: auto;
          }
          .lock{
            position: absolute;
            right: 20px;
            top: 12px;
            width: 20px;
            height: auto;
          }
          .type{
            background-color: rgba(255,255,255,0.5);
            // text-shadow: 2px 2px 2px rgba(255, 255, 255, 0.5);
            border: 2px solid #000;
            box-shadow: 0 0 4px rgba(255, 255, 255, 1);
            border-radius: 6px;
            color: #000;
            font-size: 14px;
            height: 24px;
            line-height: 26px;
            padding-left: 12px;
            padding-right: 12px;
            position: absolute;
            left: 12px;
            top: 20px;
          }
          .time{
            font-size: 12px;
            color: #fff;
            background-color: #000;
            height: 24px;
            line-height: 24px;
            width: 60px;
            text-align: center;
            position: absolute;
            right: 0;
            bottom: 0;
          }
        }
        .course-desc{
          background-color: #fff;
          border-radius: 0 0 30px 30px;
          height: 100px;
          color: #555;
          font-size: 16px;
          line-height: 26px;
          padding: 10px 20px;
          box-sizing: border-box;
        }
        .course-data{
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 14px;
          .course-data-item{
            display: flex;
            align-items: center;
          }
          img{
            width: 20px;
            height: auto;
            margin-right: 4px;
          }
        }
      }
    }
  }
}
.page{
  text-align: center;
  /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active{
    background-color: #b81b26;
  }
  /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover{
    color: #b81b26;
  }
}
</style>
