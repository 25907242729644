<template>
    <el-container>
        <el-header class="headview">
            <Topbar class="topbar"></Topbar>
            <!-- logo图片 -->
        </el-header>
        <el-container>
            <el-main id="main">
                <router-view :key="$router.path"></router-view>
                <div class="foot-class">
                    <Footer class="footerclass"></Footer>
                </div>
                <toTop @toTop="handleToTop"></toTop>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
import Topbar from '@/components/Topbar.vue'
import Footer from '@/components/Footer.vue'
import toTop from '@/components/toTop.vue'
export default {
  data () {
    return {
    }
  },
  created () {
    document.title = '环球文遗小使者大会'
  },
  methods: {
    goTohome () {
      this.$router.push('/homeView')
    },
    handleToTop() {
      const scrollEle = document.querySelector('#main')
      console.log(scrollEle.scrollTop)
      scrollEle.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }

  },
  components: {
    Topbar,
    Footer,
    toTop
  }
}
</script>

<style lang="less" scoped>
.headview {
    width: 100vw;
    background-color: #000;
    // position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
// .top-leftlogo {
//   widows: 120px;
//   height: 120px;
//   z-index: 100;
//   position: absolute; /*固定在顶部*/
//   top: 0;
//   left: 0px;
// }
// .top-leftlogo-image {
//   width: 70px;
//   height: 94px;
// }
.footerclass {
    // width: 1000px;
    width: 100%;
}
.el-main {
  width: 100%;
  padding: 0px !important;
  // overflow-y: scroll;
  height: calc(100vh - 70px);
  background-color: #e9e9e9;
}
.el-main::-webkit-scrollbar {
    display: none;
}
.el-header {
    height: 70px !important;
    padding: 0;
}
.foot-class {
    width: 100vw;
    display: flex;
    background-color: #d8d8d8;
    justify-content: center;
}
</style>
