<template>
  <div class="top-backview">
    <div class="contnet-backview" id="#contnet-backview">
      <div class="content-centerview">
        <el-container>
          <el-header style="height: 70px">
            <div class="topBox">
              <!-- 顶部导航栏 -->
              <div class="top-tabview">
                <!-- logo图片 -->
                <div class="top-leftlogo">
                  <img
                    src="https://cdn.spbcn.org/ghac/login/HQWY_DL_logo_03.png"
                    alt=""
                    style="width: auto; height: 50px"
                    @click="goTohome()"
                  />
                </div>
                <div>
                  <el-dropdown @command="handleCommand">
                    <span class="username">{{ userName }}
                      <i class="el-icon-arrow-down"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <!-- <el-dropdown-item command="changeRole">切换身份</el-dropdown-item> -->
                      <el-dropdown-item command="logOut"
                        >退出登录</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
            </div>
          </el-header>
          <el-container :style="{ height: fullHeight - 70 + 'px' }">
            <el-aside
              width="190px"
              style="background-color: rgb(238, 241, 246)"
            >
              <el-menu
                :default-active="selectIndex"
                ref="menuRef"
                class="el-menu-vertical-demo"
                background-color="#fff"
                text-color="#666666"
                style="height: 100%"
                active-text-color="#e60012"
                unique-opened
                router
                @open="menuOpen"
              >
                <div v-for="subitem in showMenuList" :key="subitem.menu_id">
                  <!-- 项目菜单 -->
                  <el-submenu :index="subitem.menu_id + ''">
                    <template slot="title">
                      <i :class="subitem.elementImg"></i>{{ subitem.menu_name }}
                      <!-- <span>{{ subitem.menu_name }}</span> -->
                    </template>
                    <!-- 一级菜单 -->
                    <template>
                      <template v-for="chnEngitem in subitem.childMenuList">
                        <el-submenu
                          v-if="
                            chnEngitem.childMenuList &&
                            chnEngitem.childMenuList.length
                          "
                          :index="chnEngitem.menu_id + ''"
                          :key="chnEngitem.menu_id"
                        >
                          <template slot="title"
                            ><i :class="iconsObj[chnEngitem.menu_id]">{{
                              chnEngitem.menu_name
                            }}</i></template
                          >
                          <div
                            v-for="chnItemChild in chnEngitem.childMenuList"
                            :key="chnItemChild.path"
                          >
                            <el-menu-item :index="chnItemChild.path"
                              ><span slot="title">{{
                                chnItemChild.menu_name
                              }}</span></el-menu-item
                            >
                          </div>
                        </el-submenu>
                        <el-menu-item
                          v-else
                          :index="chnEngitem.path"
                          :key="chnEngitem.path"
                          ><span slot="title">{{
                            chnEngitem.menu_name
                          }}</span></el-menu-item
                        >
                      </template>
                    </template>
                  </el-submenu>
                </div>
              </el-menu>
            </el-aside>
            <el-main>
              <keep-alive>
                <router-view v-if="$route.meta.keepAlive"></router-view>
              </keep-alive>
              <router-view v-if="!$route.meta.keepAlive"></router-view>
            </el-main>
          </el-container>
        </el-container>
      </div>
    </div>
  </div>
</template>

<script>
// import BottomTabView from '@/components/BottomTabView.vue'
// import middleJs from '@/middleJs/middleJs.js'
export default {
  props: {
    dataHeight: {
      type: String,
      default: '100%'
    }
  },
  data () {
    return {
      userName: '教师',
      iconsObj: {
        a_02_01: 'icon-b-data-manage',
        a_02_03: 'icon-match-thing-manage',
        b_02_01: 'icon-c',
        b_02_02: 'icon-system-manage',
        d_02_02: 'icon-academic-manage',
        a_02_02: 'icon-product-manage'
      },
      selectIndex: '',
      showMenuList: [],
      teacherMenuList: [
        {
          menu_name: '社团',
          path: '/home',
          menu_id: 'd_01',
          elementImg: 'el-icon-user-solid',
          childMenuList: [
            // { menu_name: '社团管理', menu_id: 'd_02_01', path: '/cebsbInfo_communityMember' },
            { menu_name: '社团课件管理', menu_id: 'd_02_02', path: '/aboutUserData/cebsbCommunityInfoView/cebsbCourseManage/cebsbInfo_community_courseMember' },
            { menu_name: '我的课件', menu_id: 'd_02_03', path: '/aboutUserData/cebsbCommunityInfoView/cebsbCourseManage/cebsbInfo_user_bindSchoolData' }
          ]
        }
      ],
      subMenuList: [{
        menu_name: '学校信息管理',
        path: '/home',
        menu_id: 'a_01',
        elementImg: 'el-icon-school',
        childMenuList: [
          { menu_name: '学校信息', menu_id: 'a_02_01', path: '/userInfo_schoolData' },
          // { menu_name: '权限管理', menu_id: 'a_02_02', path: '/userInfo_rightsManagementData' },
          { menu_name: '管理员信息', menu_id: 'a_02_03', path: '/userInfo_personalData' }
        ]
      },
      {
        menu_name: '学校选拔',
        path: '/home',
        menu_id: 'b_01',
        elementImg: 'el-icon-s-custom',
        childMenuList: [
          // { menu_name: '个人赛进程管理', menu_id: 'b_02_01', path: '/userInfo_individualPapers' },
          // { menu_name: '个人赛成绩查询', menu_id: 'b_02_02', path: '/userInfo_individualScore' }
          { menu_name: '初选', menu_id: 'b_02_03', path: '/userInfo_individualHome' }
        ]
      },
      {
        menu_name: '学校社团',
        path: '/home',
        menu_id: 'd_01',
        elementImg: 'el-icon-user-solid',
        childMenuList: [
          { menu_name: '社团成员管理', menu_id: 'd_02_01', path: '/cebsbInfo_communityMember' },
          { menu_name: '社团全部课件', menu_id: 'd_02_02', path: '/aboutUserData/cebsbCommunityInfoView/cebsbCourseManage/cebsbInfo_community_courseMember' },
          { menu_name: '我的课件', menu_id: 'd_02_03', path: '/aboutUserData/cebsbCommunityInfoView/cebsbCourseManage/cebsbInfo_user_courseMember' }
        ]
      }, {
        menu_name: '环球文遗小使者',
        elementImg: 'el-icon-trophy',
        path: '/home',
        menu_id: 'c_01',
        childMenuList: [
          { menu_name: '展演报名', menu_id: 'c_02_01', path: '/club/home' }
          // { menu_name: '战队进程管理', menu_id: 'c_02_02', path: '/userInfo_teamProcessMembers' },
          // { menu_name: '团体赛成绩查询', menu_id: 'c_02_03', path: '/userInfo_teamScoreSearch' },
          // { menu_name: '历史成绩查询', menu_id: 'c_02_04', path: '/userInfo_teamHistoryScoreSearch' }
        ]
      }
      ],
      menuOpenIndex: '',
      fullHeight: document.body.clientHeight
    }
  },
  methods: {
    menuOpen (value) {
      this.menuOpenIndex = value
    },
    handleCommand (command) {
      switch (command) {
        case 'logOut':
          window.sessionStorage.clear()
          this.$router.push('/loginView')
          break
        case 'changeRole':
          break
      }
    },
    goTohome () {
      this.$router.push('/')
    }
  },

  components: {
    // BottomTabView
  },
  created () {
    document.title = '环球文遗小使者大会'

    const userInfoData = JSON.parse(localStorage.getItem('saveUserInfo'))
    if (userInfoData) {
      this.userName = userInfoData.userName
    }
    const schoolData = JSON.parse(localStorage.getItem('saveSchoolInfo'))
    if (!schoolData) {
      this.$router.push({
        path: '/loginView',
        params: {
          pushUrl: ''
        }
      })
    } else {
      console.log('schoolData_________', schoolData)
      if (schoolData.roleId && schoolData.roleId === '1') {
        this.showMenuList = this.subMenuList
      } else {
        this.showMenuList = this.teacherMenuList
      }
    }
  },
  mounted () {
    // const that = this
    // middleJs.$on('changeSelectIndex', function () {
    //   that.selectIndex = '/userInfo_individualScore'
    // })
    this.fullHeight = document.documentElement.clientHeight
    window.onresize = () => {
      return (() => {
        this.fullHeight = document.documentElement.clientHeight
      })()
    }
    this.selectIndex = this.$route.path // 关键   通过他就可以监听到当前路由状态并激活当前菜单
  },
  watch: {
    $route (to, from) {
      this.selectIndex = this.$route.path // 关键   通过他就可以监听到当前路由状态并激活当前菜单
    }
  }
}
</script>

<style lang="less" scoped>
.topBox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.top-tabview {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(255, 255, 255);
  // position: fixed; /*固定在顶部*/
  // top: 0; /*离顶部的距离为0*/
}
.top-leftlogo {
  margin-left: 10px;
}
.username {
  font-size: 14px;
  color: rgb(102, 102, 102);
  margin-right: 15px;
}
span {
  padding-left: 15px;
}
.el-menu {
  // 解决1px elementui出现展开后子菜单宽度多出1px问题
  border: none;
  border-right-width: 0;
  overflow: hidden;
}
.schoolInfo_class {
  width: 100%;
}
.top-backview {
  width: 100%;
  // padding-bottom: 10px;
  background-color: rgb(216, 216, 216);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.contnet-backview {
  width: 1400px;
  background-color: #e6e6e6;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.content-centerview {
  width: 1400px;
  // display: flex;
  // flex-direction: column;
}
.bottomBar {
  width: 1400px;
}
.topbar {
  width: 1400px;
  padding: 0;
  margin: 0;
  z-index: 1002;
}
.el-header {
    padding: 0;
}
</style>
