<template>
  <div>
    <div class="course-class-topback">
      <img
        class="course-left-backimg"
        src="../../../../../src/assets/images/homepage_left.png"
        @click="backClicked"
      />
      <div class="course-title">社团课程</div>
    </div>
    <!-- 八大主题 -->
    <div class="course-type-back">
      <el-row>
        <el-col :span="4" v-for="(item, index) in courseTypeList" v-bind:key="index" >
          <div class="course-type-content" @click="courseClassClicked(index)">
            <div class="active" v-if="item.active"></div>
            <img class="course-type-image" :src="item.typeLogo" />
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 国内海外 -->
    <div class="type-bar">
      <div class="center-container type-flex">
        <div class="type-item" :class="item.active ? 'active' : ''" v-for="item,index of types" :key="item.id" @click="changeTypeItem(index)">{{item.typeName}}</div>
      </div>
    </div>

    <!-- 二级分类 -->
    <div class="book">
      <el-row>
        <el-col :span="4" v-for="item of activeType.childrenCourseType" :key="item.id">
          <div class="book-item">
            <img @click="handleToDetail(item)" class="book-img" :src="item.typeLogo" alt="">
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
// import { secondaryLinkageCourseQuery } from '@/http/api'
export default {
  components: {
  },
  data () {
    return {
      courseTypeList: [],
      types: [], // 华夏、海外
      activeType: {}, // 选择的区域
      activeTypeIndex: 0, //
      activeTheme: {} // 选中的主题
    }
  },
  created () {
    this.getClasses(1)
    this.getClasses(2)
  },
  methods: {
    backClicked () {
      this.$router.back()
    },
    // 分类点击事件
    courseClassClicked (index) {
      this.courseTypeList.find(item => item.active).active = false
      this.courseTypeList[index].active = true
      this.activeTheme = this.courseTypeList[index]
    },
    changeTypeItem(index) {
      this.types.find(item => item.active).active = false
      this.types[index].active = true
      this.activeType = this.types[index]
      this.activeTypeIndex = index
    },
    getClasses (type) {
      this.$http.get('/external/querySeeEightTopics', { params: { type } }).then(res => {
        if (res.code === 200) {
          res.data.forEach((item, i) => {
            item.active = i === 0
          })
          if (type === 1) {
            this.courseTypeList = res.data
            this.activeTheme = res.data[0]
          } else if (type === 2) {
            this.types = res.data
            this.activeType = res.data[0]
            this.activeTypeIndex = 0
          }
        }
      })
    },
    handleToDetail (item) {
      const query = {
        themeId: this.activeTheme.id,
        cId: this.activeType.id,
        areaId: item.id
      }
      this.$router.push({
        path: '/aboutUserData/cebsbCommunityInfoView/cebsbCourseManage/cebsbInfo_community_course_list',
        query
      })
    }
  }
}
</script>

<style lang="less" scoped>
// .course-class-topback {
//   width: 100%;
//   margin: 20px;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: flex-start;
// }
// .course-title {
//   margin-left: 10px;
//   font-size: 18px;
//   font-weight: 900;
// }
.course-type-back {
  width: 100%;
  margin: 30px 0px 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: row;
}
.course-type-content {
  width: 200px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  cursor: pointer;
  .active{
    width: 180px;
    height: 37px;
    background-color: #b81b26;
    position: absolute;
    left: 10px;
    right: 0;
    top: 15px;
    z-index: 0;
  }
}
.course-type-image {
  width: 180px;
  // height: 80px;
  position: relative;
  z-index: 10;
}
.course-tye-name {
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
  color: black;
  font-weight: 900;
}
.course-cover-image {
  width: 150px;
  height: 100px;
}
.table-backview {
  width: 100%;
}
.table-column-backview {
  width: 100%;
  height: 85px;
  margin: 20px 0px;
  display: flex;
  flex-direction: row;
  // align-items: center;
}
.left-image {
  width: 150px;
  height: 85px;
}
.right-backview {
  display: flex;
  margin-left: 20px;
  flex-direction: column;
}
.right-title {
  font-size: 16px;
  color: black;
}
.right-content {
  font-size: 12px;
  color: rgb(58, 58, 58);
}
.course-class-topback {
  width: 100%;
  height: 60px;
  background-color: rgb(178, 178, 178);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.course-left-backimg {
  width: 30px;
  height: 30px;
  margin-left: 10px;
}
.course-title {
  margin-left: 10px;
  font-size: 18px;
  font-weight: 900;
}

.type-bar{
  background-color: #dcdcdc;
  padding: 10px 0;
}
.type-flex{
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.type-item{
  width: 250px;
  height: 40px;
  background-image: url(https://cdn.spbcn.org/ghac/ghac_button_nomal.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  font-size: 24px;
  font-family: regular;
  color: #fff;
  text-align: center;
  line-height: 36px;
  cursor: pointer;
  &.active{
    background-image: url(https://cdn.spbcn.org/ghac/ghac_button_highlight.png);
    color: #000;
  }
}

.book{

}
.book-item{
  margin: 20px;
  // border: 3px solid ;
  img{
    height: 220px;
    cursor: pointer;
  }
}
</style>
